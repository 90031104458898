<ng-container *ngIf="!standalone">
    <ng-container *lgPanelHeader="let context; order: -1">
        <div
            *ngIf="this._selectedKeys.length > 0"
            class="lg-reference-dropdown-side-icon lg-reference-dropdown-side-icon--round-corners"
        >
            <lg-icon icon="icon-go-back" (click)="_drilldownGoUp()" [clickable]="true"></lg-icon>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="_isModelBuilt">
    <lg-col-definition #colDefinition columnClass="table__column">
        <lg-col-row id="headerRow">
            @for (col of _headerColumnDef; track col.id) {
                <lg-col
                    [id]="col.id"
                    [class]="col.columnCls"
                    [width]="col.width"
                    [type]="col.type"
                ></lg-col>
            }
        </lg-col-row>

        <lg-col-row id="footerRow">
            @for (col of _totalsColumnDef; track col.id) {
                <lg-col
                    [id]="col.id"
                    [class]="col.columnCls"
                    [width]="col.width"
                    [type]="col.type"
                ></lg-col>
            }
        </lg-col-row>

        <lg-col-row id="midHeader">
            <lg-col id="__name"></lg-col>
        </lg-col-row>

        <ng-container *ngFor="let visibleLevel of _visibleLevels; let i = index">
            <ng-container *ngIf="i !== 0 && visibleLevel.type === 'table'">
                <lg-col-row [id]="'midHeader' + i">
                    <lg-col
                        *ngFor="let col of visibleLevel.levelHeaders"
                        [id]="col.id"
                        [class]="col.columnCls"
                        [width]="col.width"
                        [type]="col.type"
                    ></lg-col>
                </lg-col-row>
            </ng-container>
        </ng-container>

        <ng-container *ngFor="let level of _levels; let i = index">
            <ng-container *ngFor="let subLevel of level.columnDefinition; let j = index">
                <lg-col-row [id]="'level_' + i + '_' + j">
                    <lg-col
                        *ngFor="let col of subLevel.columns"
                        [id]="col.id"
                        [type]="col.type"
                        [class]="col.columnCls"
                        [width]="col.width"
                    ></lg-col>
                </lg-col-row>
            </ng-container>
        </ng-container>
    </lg-col-definition>

    @if (_showPivot$ | async) {
        <lg-rectangle #pivotWrapper class="flexcol pivot-table__rectangle" [auditTime]="0">
            <ng-container *ngFor="let level of _visibleLevels; let i = index">
                @if (i === 0 && level.type === "table") {
                    <lgflex-table-header-main-level
                        [colDefinition]="colDefinition"
                        [pivotWrapperWidth]="_pivotWrapperWidth()"
                        [headerRow]="_headerRow"
                        [columnSortBy]="_currentColumnSorting()"
                        [widgetReferences]="widgetReferences()"
                        [referencesDropdown]="_pageReferencesDropdown()"
                        (onReferenceChange)="onReferenceSelectedChange.emit($event)"
                        (onOrderByChange)="_onOrderByChange($event)"
                        (onDifferenceColumnChange)="differenceColumnChange.emit($event)"
                    />
                }
                @if (i !== 0 && level.type === "table") {
                    <lgflex-table-header-sublevel
                        [index]="i"
                        [level]="level"
                        [colDefinition]="colDefinition"
                        [pivotWrapperWidth]="_pivotWrapperWidth()"
                        [headerRow]="_headerRow"
                        [maxVisibleLevel]="_maxVisibleLevel"
                        [referencesDropdown]="_pageReferencesDropdown()"
                        (orderByChange)="_onOrderByChange($event)"
                    />
                }
                @if (i === 0 && level.type === "pivot") {
                    <lgflex-pivot-header-main-level
                        [level]="level"
                        [pivotBody]="_pivotBody"
                        [colDefinition]="colDefinition"
                        [pivotWrapperWidth]="_pivotWrapperWidth()"
                        [columnSortBy]="_currentColumnSorting()"
                        [headerRow]="_headerRow"
                        [widgetReferences]="widgetReferences()"
                        [referencesDropdown]="_pageReferencesDropdown()"
                        (onReferenceChange)="onReferenceSelectedChange.emit($event)"
                        (onLevelsOrderByChange)="_onLevelsOrderByChangePivot($event)"
                        (onOrderByChange)="_onOrderByChange($event)"
                        (onDifferenceColumnChange)="differenceColumnChange.emit($event)"
                    />
                }
                @if (i !== 0 && level.type === "pivot") {
                    <div
                        class="table__row table__row--midheader"
                        [lgColDefinition]="colDefinition"
                        [lgColDefinitionWidth]="_pivotWrapperWidth() - 10"
                        lgColRow="midHeader"
                    >
                        <lg-pivot-table-body-level-headers
                            [body]="_pivotBody"
                            [sortBy]="level.pivot.orderBy"
                            (sortByChange)="_onLevelsOrderByChangeSublevel(level, $event)"
                            lgCol="__name"
                            [levels]="level.levelHeaders"
                        >
                        </lg-pivot-table-body-level-headers>
                    </div>
                }
                @if (level.type === "pivot") {
                    <lg-pivot-table-body
                        #pivotBody
                        [definition]="level.pivot.definition"
                        [filteredData]="
                            level.ignoreOwnFilters && i === _maxVisibleLevelIdx
                                ? level.pivot.all
                                : level.pivot.filtered
                        "
                        (clearAllFilters)="_onClearAllFilters(filters)"
                        [itemHeight]="28"
                        [lgColDefinition]="colDefinition"
                        [lgColDefinitionWidth]="_pivotWrapperWidth() - 10"
                        (maxVisibleLevelChange)="_onMaxVisibleSubLevelChange(i, $event)"
                        [class.flex-flexible]="_maxVisibleLevelIdx === i"
                        [class.table--collapsed]="_maxVisibleLevelIdx !== i"
                    >
                        <ng-container
                            *ngFor="
                                let subLevel of level.subLevels;
                                let j = index;
                                index as rowIndex
                            "
                        >
                            <lg-pivot-table-row
                                *lgPivotTableRowDef="
                                    let row;
                                    onLevel: subLevel.levelId;
                                    items as items
                                "
                                [expandHighlight]="true"
                                [toggleOnClick]="subLevel.openOnClick && _maxVisibleLevelIdx === i"
                                (click)="_drillDown(i, j, row)"
                                [class.table__row--disabled]="row.$disabled"
                            >
                                <div
                                    class="table__row"
                                    [class.table__row--clickable]="!subLevel.isVeryLast"
                                    [lgColRow]="'level_' + i + '_' + j"
                                >
                                    <ng-container *ngFor="let cell of subLevel.cells">
                                        <lg-pivot-table-empty-column
                                            *ngIf="cell.type == 'empty'"
                                            [lgCol]="cell.id"
                                            [level]="0"
                                        ></lg-pivot-table-empty-column>

                                        <lg-pivot-table-expand
                                            *ngIf="cell.type == 'expand'"
                                            [lgCol]="cell.id"
                                        ></lg-pivot-table-expand>

                                        <lgflex-flexible-drilldown-table-row-content
                                            lgflexConditionalFormatting
                                            [conditionalFormattingDisabled]="
                                                cell.conditionalFormatting !== true ||
                                                i !== _visibleLevels.length - 1
                                            "
                                            [conditionalFormattingLevel]="levels[i].at(-1)"
                                            [conditionalFormattingItemId]="
                                                cell.type === 'default' ? cell.fieldName : undefined
                                            "
                                            [conditionalFormattingValue]="
                                                cell.type === 'default'
                                                    ? row[cell.fieldName]
                                                    : undefined
                                            "
                                            [cell]="cell"
                                            [levels]="levels[i]"
                                            [selectedKeys]="_selectedKeys"
                                            [rowIndex]="rowIndex + 1"
                                            [row]="row"
                                            [filters]="filters"
                                            [scheme]="scheme"
                                            [items]="items"
                                            [maxColumnValues]="maxColumnValues"
                                        ></lgflex-flexible-drilldown-table-row-content>
                                    </ng-container>
                                </div>
                            </lg-pivot-table-row>
                        </ng-container>
                    </lg-pivot-table-body>
                }

                @if (level.type === "table") {
                    <lg-pivot-table-body
                        #pivotBody
                        [definition]="level.pivot.definition"
                        [filteredData]="
                            level.ignoreOwnFilters && i === _maxVisibleLevelIdx
                                ? level.pivot.all
                                : level.pivot.filtered
                        "
                        (clearAllFilters)="_onClearAllFilters(filters)"
                        [itemHeight]="28"
                        [lgColDefinition]="colDefinition"
                        [lgColDefinitionWidth]="_pivotWrapperWidth() - 10"
                        (maxVisibleLevelChange)="_onMaxVisibleSubLevelChange(i, $event)"
                        [class.flex-flexible]="_maxVisibleLevelIdx === i"
                        [class.table--collapsed]="_maxVisibleLevelIdx !== i"
                    >
                        <lg-pivot-table-row
                            *lgPivotTableRowDef="let row; onLevel: 0; items as items"
                            [toggleOnClick]="false"
                            (click)="_drillDown(i, 0, row)"
                            [class.table__row--disabled]="row.$disabled"
                        >
                            <div
                                class="table__row"
                                [class.table__row--clickable]="true"
                                [lgColRow]="'level_' + i + '_' + 0"
                            >
                                <ng-container
                                    *ngFor="
                                        let subLevel of level.subLevels;
                                        let j = index;
                                        index as rowIndex
                                    "
                                >
                                    <ng-container
                                        *ngFor="let cell of subLevel.cells; index as cellIndex"
                                    >
                                        <lgflex-flexible-drilldown-table-row-content
                                            lgflexConditionalFormatting
                                            [conditionalFormattingDisabled]="
                                                cell.conditionalFormatting !== true ||
                                                i !== _visibleLevels.length - 1
                                            "
                                            [conditionalFormattingLevel]="levels[i].at(-1)"
                                            [conditionalFormattingItemId]="
                                                cell.type === 'default' ? cell.fieldName : undefined
                                            "
                                            [conditionalFormattingValue]="
                                                cell.type === 'default'
                                                    ? row[cell.fieldName]
                                                    : undefined
                                            "
                                            [cell]="cell"
                                            [levels]="levels[i]"
                                            [selectedKeys]="_selectedKeys"
                                            [rowIndex]="rowIndex + 1"
                                            [row]="row"
                                            [filters]="filters"
                                            [scheme]="scheme"
                                            [items]="items"
                                            [maxColumnValues]="maxColumnValues"
                                        ></lgflex-flexible-drilldown-table-row-content>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </lg-pivot-table-row>
                    </lg-pivot-table-body>
                }

                <lg-pivot-instance-sync
                    [component]="_pivotBody"
                    [pivotInstance]="level.pivot"
                ></lg-pivot-instance-sync>

                <lgflex-flexible-drilldown-table-footer
                    *ngIf="_maxVisibleLevelIdx === i"
                    [colDefinition]="colDefinition"
                    [colDefinitionWidth]="_pivotWrapperWidth() - 10"
                    [totalsRow]="_totalsRow"
                    [pivot]="level.pivot"
                >
                </lgflex-flexible-drilldown-table-footer>
            </ng-container>
        </lg-rectangle>
    }
</ng-container>
