import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";

export interface LgIconSelectorOption {
    id: string;
    icon: string;
    name: string;
}

@Component({
    selector: "lgflex-icon-selector",
    templateUrl: "./lg-icon-selector.template.html",
    styleUrls: ["./lg-icon-selector.styles.scss"],
    encapsulation: ViewEncapsulation.None
})
export class LgIconSelectorComponent implements OnInit {
    @Input("options") public options: LgIconSelectorOption[] = [];
    @Input("current") public currentId: string | undefined;
    @Output() readonly currentChange = new EventEmitter<string>(true);

    // ----------------------------------------------------------------------------------
    //
    ngOnInit(): void {
        if (!this.currentId) this.setCurrent(this.options[0].id);
    }

    setCurrent(id: string) {
        if (this.currentId === id) return;

        this.currentId = id;
        this.currentChange.emit(id);
    }
}
