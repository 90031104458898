export function isEqualShallow(a: any | null | undefined, b: any | null | undefined): boolean {
    a = a ?? null;
    b = b ?? null;
    if (a === b) return true;
    if (a === null || b === null) return false;

    // this is not symmetric, isn't it a problem?
    for (const key in a) {
        if (Object.prototype.hasOwnProperty.call(a, key)) {
            if (a[key] !== b[key]) return false;
        }
    }
    return true;
}
