import { Injectable, ViewContainerRef } from "@angular/core";
import { Observable } from "rxjs";
import { EmbeddedWidgetContext, InlineWidgetConfigurator } from "../../../types";
import { SingleValueChartVisualizationWidgetConfig } from "./single-value-chart-visualization-widget.types";
import { SingleValueChartVisualizationConfigurationComponent } from "./components/single-value-chart-visualization-configuration/single-value-chart-visualization-configuration.component";

@Injectable()
export class SingleValueChartVisualizationWidgetConfigurator
    implements InlineWidgetConfigurator<SingleValueChartVisualizationWidgetConfig>
{
    readonly type = "inline";

    show(
        config: SingleValueChartVisualizationWidgetConfig
    ): Promise<SingleValueChartVisualizationWidgetConfig> {
        return Promise.resolve(config);
    }

    insertInlineComponent(
        containerRef: ViewContainerRef,
        config: SingleValueChartVisualizationWidgetConfig,
        context: EmbeddedWidgetContext
    ): Observable<SingleValueChartVisualizationWidgetConfig> {
        const componentRef = containerRef.createComponent(
            SingleValueChartVisualizationConfigurationComponent
        );
        componentRef.instance.passConfig(config, context);
        return componentRef.instance.config$;
    }

    validate(config: SingleValueChartVisualizationWidgetConfig): boolean {
        return true;
    }

    getDefaultConfiguration(): SingleValueChartVisualizationWidgetConfig {
        return {
            limit: null,
            textSpace: 50
        };
    }
}
