<ng-container *ngIf="config">
    <lgflex-form-row [label]="'.ColorSteps' | lgTranslate">
        <lg-dropdown [definition]="_stepsDropdownDef" [(current)]="config.steps"></lg-dropdown>
    </lgflex-form-row>
    <ng-container *ngIf="_ranges">
        <lgflex-form-row *ngFor="let range of _ranges" [label]="range.name">
            <div class="form-row__controls range__controls">
                <lg-dropdown
                    class="range__controls__type"
                    [definition]="
                        range.key === 'average'
                            ? _rangeTypeAverageDropdownDef
                            : _rangeTypeDropdownDef
                    "
                    [(current)]="range.config.type"
                ></lg-dropdown>
                @if (range.config.type === "manual") {
                    <input
                        class="input range__controls__value"
                        type="number"
                        [(ngModel)]="range.config.value"
                    />
                }
                @if (range.config.type !== "disabled") {
                    <lg-dropdown
                        class="range__controls__color"
                        [definition]="_rangeColorDropdownDef"
                        [(current)]="range.config.color"
                        [hideSearch]="true"
                    ></lg-dropdown>
                }
            </div>
        </lgflex-form-row>
    </ng-container>
</ng-container>
