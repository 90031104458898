import { LgTranslateService } from "@logex/framework/lg-localization";

export function translateNullableName(
    translation: LgTranslateService,
    name: string | null | undefined,
    nameLc: string | null | undefined,
    defaultName = ""
): string {
    if (name != null) return name;
    if (nameLc != null) return translation.translate(nameLc);
    return defaultName;
}
