<div class="lgflex-reference-slot">
    <div class="lgflex-reference-slot__dropdown-wrapper" [style.min-width]="_dropdownMinWidth()">
        <lg-reference-dropdown
            class="lg-reference-dropdown lg-reference-dropdown__pivot-header crop"
            popupClassName="lg-reference-dropdown-popup"
            style=""
            align="center"
            [definition]="referencesDropdown()"
            [current]="headerCell().referenceCode ?? _currentReference()?.code"
            [matchWidth]="headerCell().children.length > 1"
            [isHeader]="true"
            [hideSearch]="true"
            [itemTooltips]="true"
            [showTitle]="true"
            (currentChange)="
                onReferenceChange.emit({ slotIdx: headerCell().referenceIdx, value: $event })
            "
        />
    </div>
    <div class="lgflex-reference-slot__items table__column-group__bottom">
        @for (child of headerCell().children; track child.id) {
            <lgflex-flexible-drilldown-table-header-row
                [headerCell]="child"
                [lgCol]="child.id"
                [sortBy]="columnSortBy()"
                (orderByChange)="onOrderByChange.emit($event)"
                (differenceColumnChange)="onDifferenceColumnChange.emit($event)"
            />
        }
    </div>
</div>
