<div
    class="lgflex-icon-selector__option"
    *ngFor="let option of options"
    [class.lgflex-icon-selector__option--selected]="currentId === option.id"
>
    <lg-icon
        (click)="setCurrent( option.id )"
        [icon]="option.icon"
        [lgTooltip]="option.name"
    ></lg-icon>
</div>
