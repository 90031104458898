import { Component, EventEmitter, input, Input, Output } from "@angular/core";
import { FdpColumnHeader, FdpLevel } from "../../../types";
import { IDropdownDefinition } from "@logex/framework/ui-core";

@Component({
    selector: "lgflex-table-header-sublevel",
    templateUrl: "./table-header-sublevel.html"
})
export class TableHeaderSublevelComponent {
    @Input() index: number;
    @Input() level: FdpLevel;
    @Input() colDefinition: any;
    @Input() headerRow: FdpColumnHeader[];
    @Input() referencesDropdown: IDropdownDefinition<string> | undefined;
    @Input() maxVisibleLevel: FdpLevel;

    pivotWrapperWidth = input(0);

    @Output() readonly orderByChange = new EventEmitter<string>();

    _onOrderByChange(colId: string | string[] | null): void {
        this.orderByChange.emit(colId as string);
    }
}
