import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    signal,
    WritableSignal
} from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

export interface Row<Data = any> {
    id: number;
    name: string;
    icon?: string;
}

@Component({
    selector: "lgflex-row-selector",
    templateUrl: "./row-selector.component.html",
    styleUrls: ["./row-selector.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RowSelectorComponent {
    protected _selectedRowId: WritableSignal<null | number> = signal(null);

    @Input() hideBorder = false;
    @Input("rows") rows: Row[] = [];

    @Output() readonly rowSelected = new EventEmitter<number | null>();
    @Output() readonly rowRemoved = new EventEmitter<number>();
    @Output() readonly rowsSorted = new EventEmitter<Row[]>();

    resetSelection(): void {
        this._selectedRowId.set(null);
    }

    protected _onRowDrop(event: CdkDragDrop<any>): void {
        const sortedRows = this.rows.slice();
        moveItemInArray(sortedRows, event.previousIndex, event.currentIndex);
        this.rowsSorted.emit(sortedRows);
    }

    protected onRowSelected(id: number) {
        this._selectedRowId.set(id);
        this.rowSelected.emit(id);
    }

    protected _onRowRemoved(id: number): void {
        this.rowRemoved.emit(id);
        if (this._selectedRowId() === id) {
            this._selectedRowId.set(null);
            this.rowSelected.emit(null);
        }
    }
}
