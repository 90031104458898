import * as _ from "lodash";
import { LoaderArgumentsMap } from "@logex/load-manager";

export function omitFilters<T extends LoaderArgumentsMap>(args: T, ...names: string[]): T {
    if (args.filters == null) return args;

    const getFilters = args.filters;
    return {
        ...args,
        filters: () => _.omit(getFilters(), names)
    };
}
