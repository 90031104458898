import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/pipe-prefix
    name: "dimensionName",
    pure: true
})
export class DimensionNameTransform implements PipeTransform {
    public transform(dimensionName: string, schemaName: string = ""): string {
        if (schemaName && dimensionName !== schemaName) {
            return `${schemaName} (${dimensionName})`;
        }

        return dimensionName;
    }
}
