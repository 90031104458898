import { computed, inject, Injectable, signal, WritableSignal } from "@angular/core";
import { LayoutGroupItem, LayoutGroup, LayoutGroupAndItem } from "../layout-management.types";
import { LgTranslateService } from "@logex/framework/lg-localization";

@Injectable()
export class LayoutManagementStoreService {
    private readonly _lgTranslate = inject(LgTranslateService);
    private _newGroupIdsNegCounter = 0;
    private _deletedGroupIdsSignal: WritableSignal<number[]> = signal([]);
    private _flatLayoutsSignal: WritableSignal<LayoutGroupItem[]> = signal([]);

    // -----------------------------------SELECTORS----------------------------------------------->
    readonly flatLayouts = computed(() =>
        structuredClone(this._flatLayoutsSignal().sort((a, b) => a.sortOrder - b.sortOrder))
    );

    readonly layoutsGroups = computed(() => this._getLayoutGroupsAndItems(this.flatLayouts()));

    readonly isValid = computed(() => this._validateGroups(this.layoutsGroups()));
    readonly deletedGroupIds = computed(() => this._deletedGroupIdsSignal());

    // -----------------------------------ACTIONS------------------------------------------------->
    setLayouts(layouts: LayoutGroupItem[]): void {
        this._flatLayoutsSignal.set(structuredClone(layouts));
    }

    addGroup(): void {
        const group: LayoutGroupItem = {
            id: --this._newGroupIdsNegCounter,
            name: this._lgTranslate.translate("_Flexible._LayoutCatalogManagementDialog.NewGroup"),
            sortOrder: 0,
            showInNavigation: false,
            expandedInNavigation: false
        };
        this._flatLayoutsSignal.update(layouts => {
            layouts.splice(0, 0, group);
            return [...layouts].map((layout, index) => ({ ...layout, sortOrder: index }));
        });
    }

    removeGroup(id: number): void {
        this._flatLayoutsSignal.update(layouts => {
            return layouts
                .filter(layout => (layout as unknown as LayoutGroup).id !== id)
                .map((layout, index) => {
                    return layout.groupId === id
                        ? { ...layout, sortOrder: index, groupId: undefined }
                        : {
                              ...layout,
                              sortOrder: index
                          };
                });
        });
        this._deletedGroupIdsSignal.update(ids =>
            !ids.includes(id) && id > 0 ? [...ids, id] : ids
        );
    }

    changeShowInNavigation(value: boolean, id: number): void {
        this._flatLayoutsSignal.update(layouts => {
            const updatedLayouts = layouts.map(layout => {
                return layout.id === id || layout.groupId === id
                    ? { ...layout, showInNavigation: value }
                    : layout;
            });
            return updatedLayouts.map(layout => {
                const isGroup = layout.libraries === undefined && layout.dataSources === undefined;
                return isGroup
                    ? {
                          ...layout,
                          expandedInNavigation: updatedLayouts
                              .filter(item => item.groupId === layout.id)
                              .some(item => item.showInNavigation)
                              ? layout.expandedInNavigation
                              : false
                      }
                    : layout;
            });
        });
    }

    changeShowGroupExpandedInNavigation(value: boolean, id: number): void {
        this._flatLayoutsSignal.update(layouts => {
            return layouts.map(layout => {
                return layout.id === id ? { ...layout, expandedInNavigation: value } : layout;
            });
        });
    }

    changeGroupName(name: string, groupId: number): void {
        this._flatLayoutsSignal.update(layouts => {
            return layouts.map(layout => {
                return layout.id === groupId ? { ...layout, name } : layout;
            });
        });
    }

    private _getLayoutGroupsAndItems(layouts: LayoutGroupItem[]): LayoutGroupAndItem[] {
        const flatLayouts = layouts.filter(layout => !layout.groupId);
        const layoutsInGroup = layouts.filter(layout => layout.groupId);

        return flatLayouts.map(layout => {
            const isGroup = layout.libraries === undefined && layout.dataSources === undefined;
            if (isGroup) {
                return {
                    id: layout.id,
                    name: layout.name,
                    sortOrder: layout.sortOrder,
                    layouts: layoutsInGroup.filter(item => item.groupId === layout.id),
                    expandedInNavigation: layout.expandedInNavigation
                };
            }
            return layout;
        }) as LayoutGroupAndItem[];
    }

    private _validateGroups(layouts: LayoutGroupAndItem[]): boolean {
        const groups = layouts.filter((layout: any) => layout?.layouts) as LayoutGroup[];
        const isGroupHasLayouts = groups.every((group: LayoutGroup) => group.layouts.length > 0);
        const layoutNames = groups.map((group: LayoutGroup) => group.name).flat();
        const isGroupNameUniq = layoutNames.length === new Set(layoutNames).size;

        return isGroupHasLayouts && isGroupNameUniq;
    }
}
