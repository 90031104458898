<div class="calculation-progress__container" *ngIf="status != 'hidden'">
    <div
        class="calculation-progress__button lg-panel__header-icon"
        [class.calculation-progress-button--calculating]="status === 'calculating'"
        (click)="onClick()"
    >
        <lg-icon
            *ngIf="status === 'waiting'"
            icon="icon-recalculation"
            width="20"
            height="20"
        ></lg-icon>

        <svg
            *ngIf="status === 'calculating' && _progress < 1"
            height="24"
            width="24"
            [lgTooltip]="tooltip"
        >
            <path [attr.d]="_pathRest" id="path" stroke="#D6E3F0" stroke-width="2" fill="none"/>
            <path [attr.d]="_path" id="path" stroke="#005de4" stroke-width="2" fill="none"/>
            
            <ng-template #tooltip>
                {{'_LoadManager.StaleDataLoading' | lgTranslate}} ({{_progressInPercent}}%)
            </ng-template>
        </svg>

        <lg-icon
            *ngIf="status === 'calculating' && _progress === 1"
            icon="icon-circle-check"
        ></lg-icon>
    </div>
</div>
