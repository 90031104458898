import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PageWidgetConfigBase } from "../../types";
import { Observable } from "rxjs";
import { ITooltipOptions } from "@logex/framework/ui-core";

@Component({
    selector: "lgflex-widget-common-header",
    templateUrl: "./widget-common-header.component.html",
    styleUrls: ["./widget-common-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetCommonHeaderComponent {
    @Input() config: PageWidgetConfigBase = {};
    @Input() isDataComplete: boolean | undefined;
    @Input() isLimitRowsEnabled: boolean | undefined = false;
    @Input() limitRowsTooltipText: string | undefined;
    @Input() isCalculating: Observable<boolean> | undefined;
    @Input() calculationProgress: Observable<number | undefined> | undefined;

    _tooltipOptions: ITooltipOptions = { position: "bottom-right" };
}
