import { inject } from "@angular/core";
import {
    IScenario,
    IUser,
    LG_APP_INFO,
    LG_APP_SESSION,
    LG_AUTHORIZATION_SERVICE,
    LG_USER_INFO,
    LgMatTrackingService,
    ScenarioService,
    UserSettingsService
} from "@logex/framework/lg-application";
import { firstValueFrom } from "rxjs";
import { BootHelperGateway, Client } from "./gateway";
import { AppSession } from "../../common";
import * as _ from "lodash";
import { configureMatomoBasicDimensions } from "../../bases";

export abstract class BootHelperServiceBase<AppUser extends IUser, AppScenario extends IScenario> {
    protected _authorizationService = inject(LG_AUTHORIZATION_SERVICE);
    protected _session = inject<AppSession<AppScenario>>(LG_APP_SESSION);
    protected _userSettingsService = inject(UserSettingsService);
    protected _scenarioService = inject(ScenarioService);
    protected _bootHelperGateway = inject(BootHelperGateway);
    protected _userInfo = inject<AppUser>(LG_USER_INFO);
    protected _matomoTracker = inject(LgMatTrackingService);
    protected _appInfo = inject(LG_APP_INFO);

    isSessionConfigured(): boolean {
        return this._session.scenarioId != null;
    }

    async trySelectLastSession(): Promise<boolean> {
        if (this.isSessionConfigured()) return true;

        // Get last client and scenario
        let clientId, scenarioId;
        try {
            const selectedScenario = await firstValueFrom(
                this._userSettingsService.get({ storageId: "$context" })
            );
            clientId = selectedScenario[0].value.clientId;
            scenarioId = selectedScenario[0].value.scenarioId;
        } catch {}

        // Try to open the same scenario
        if (clientId != null) {
            const scenario = await this.loadPreferredScenario(clientId, scenarioId);

            if (scenario == null) return false;

            return await this.setSessionScenario(scenario);
        }

        return false;
    }

    async loadPreferredScenario(clientId: number, scenarioId: number): Promise<AppScenario> {
        return await this._scenarioService.load(clientId, scenarioId);
    }

    async setSessionScenario(scenario: AppScenario): Promise<boolean> {
        // Get client info by scenario
        // TODO: Refactor to get data directly from a special endpoint
        const clients = await firstValueFrom(this._bootHelperGateway.selectClients());
        const client = _.find(clients, { id: scenario.clientId });

        // Requested client is not available (different DB, data change, etc)
        if (client == null) return false;

        await this._fillScenario(client, scenario);
        await this._fillUserInfo(client);
        await this._configureMatomoDimensions();

        await this._fillMiscSessionInfo(client, scenario);

        return true;
    }

    protected async _fillScenario(client: Client, scenario: AppScenario): Promise<void> {
        this._session.clientId = scenario.clientId;
        this._session.scenarioId = scenario.id;

        this._session.client = {
            id: client.id,
            code: client.code,
            name: client.name,
            abbr: client.abbreviation
        };
    }

    protected async _fillUserInfo(client: Client): Promise<void> {
        // For compatibility with LgSidebarDevWidgetComponent
        this._userInfo.ziekenhuiscode = client.code as any;
        this._userInfo.ziekenhuisnaam = client.name;

        // Load user permissions
        this._userInfo.roles = {};
        const permissions = await firstValueFrom(this._authorizationService.getUserPermissions());
        for (const x of permissions) {
            this._userInfo.roles[x] = true;
        }
    }

    protected async _fillMiscSessionInfo(client: Client, scenario: AppScenario): Promise<void> {
        // To be implemented in the derived class
    }

    protected async _configureMatomoDimensions(): Promise<void> {
        configureMatomoBasicDimensions(this._matomoTracker, {
            session: this._session,
            userInfo: this._userInfo,
            appInfo: this._appInfo
        });
    }
}
