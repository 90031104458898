import { ChartWidgetConfig } from "../chart-widget.configuration.types";

export function chartV3toV4Migration(config: ChartWidgetConfig) {
    config.tablesConfig = config.tablesConfig.map(table => {
        const dimensions = table.dimensions.map(dimension => {
            if (!dimension.orderBy) {
                const firstColumn = config.columns[0];
                dimension.orderBy = [{ item: `${firstColumn.type}${firstColumn.id}`, type: "asc" }];
            }
            return dimension;
        });

        return { ...table, dimensions };
    });
    return 4;
}
