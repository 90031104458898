<ng-container *lgPanelHeader="let context; order: 1">
    <lgflex-widget-common-header
        [config]="_config"
        [isDataComplete]="_isDataComplete"
        [isCalculating]="_isCalculating$"
        [calculationProgress]="_calculationProgress$"
    ></lgflex-widget-common-header>
</ng-container>

<ng-container *lgPanelIcons="let context; order: 1; whenMinimized: false">
    <lgflex-widget-common-icons
        [exportFormats]="_exportOptions"
        [isLoading$]="_isLoading$"
        [isAutoConverted]="_isAutoConverted"
        [isConfigValid]="_isValid"
        (export)="_export()"
        (edit)="showConfigurationUi()"
    ></lgflex-widget-common-icons>
</ng-container>

<ng-container *ngIf="_isValid">
    <lgflex-stale-data-handler
        [state]="_isCalculating$ | async"
        [progress]="_calculationProgress$ | async"
    />

    <div
        class="flexcol"
        *ngIf="_data != null; else pivotIsNull"
        [lgLoaderOverlay]="(_isLoading$ | async) || ((_isConfiguring$ | async) ?? false)"
    >
        <div
            class="mod-crosstab__wrap"
            (mouseout)="onMouseOver($event)"
            [style]="{ 'grid-template-columns': _sidebarWidth + 'px auto' }"
        >
            <div class="mod-crosstab__corner"></div>

            <div class="mod-crosstab__header">
                <div class="mod-crosstab__header-inner" #crosstabHeader>
                    <div
                        class="table__row table__row--header mod-crosstab__row mod-crosstab__row--header"
                    >
                        <div
                            class="table__column mod-crosstab__cell mod-crosstab__cell--header"
                            *ngFor="let name of _colNames; let x = index"
                            [class.mod-crosstab__cell--highlight]="x === _mouseOverColIdx"
                            [style.width]="_cellWidth + 'px'"
                            [attr.highlight-x]="x"
                        >
                            <span [lgTooltip]="fmtCol(x)">{{ fmtCol(x, "code") }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mod-crosstab__sidebar" #crosstabSidebar>
                <div class="mod-crosstab__sidebar-inner">
                    <div
                        class="table__row table__row--header mod-crosstab__row mod-crosstab__row--header"
                        *ngFor="let name of _rowNames; let y = index"
                        [class.mod-crosstab__cell--highlight]="y === _mouseOverRowIdx"
                    >
                        <div class="table__column crop" [attr.highlight-y]="y">
                            <span
                                class="crop-text"
                                innerHtml="{{ fmtRowName(y) }}"
                                [lgTooltip]="fmtRowName(y)"
                            ></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mod-crosstab__body">
                <cdk-virtual-scroll-viewport
                    class="mod-crosstab__body-inner"
                    itemSize="28"
                    (scroll)="onScroll($event)"
                    #scroller
                >
                    <div
                        class="lg-pivot-table__row table__row mod-crosstab__row mod-crosstab__row--table"
                        *cdkVirtualFor="let row of _data; let y = index"
                    >
                        <div
                            class="table__column mod-crosstab__cell mod-crosstab__cell--table"
                            *ngFor="let value of row; let x = index"
                            [class.mod-crosstab__cell--double-highlight]="
                                x === _mouseOverColIdx && y === _mouseOverRowIdx
                            "
                            [class.mod-crosstab__cell--highlight]="
                                x === _mouseOverColIdx || y === _mouseOverRowIdx
                            "
                            [style.width]="_cellWidth + 'px'"
                            [attr.highlight-x]="x"
                            [attr.highlight-y]="y"
                            [innerHTML]="_valueFormatter!(value)"
                        ></div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
        </div>
    </div>

    <ng-template #pivotIsNull>
        <div class="flexcol" [lgLoaderOverlay]="(_isLoading$ | async) ?? false"></div>
    </ng-template>
</ng-container>

<div
    class="flexcol flexcol--full flexcol--center"
    *ngIf="!_isValid"
    style="justify-content: center"
>
    {{ "_Flexible.Widgets.WidgetValidationError" | lgTranslate }}
</div>
