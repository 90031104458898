<div class="form-group">
    <div class="form-row__controls">
        <div style="float: right; margin-top: -6px">
            <lg-button
                buttonClass="button button--condensed"
                icon="icon-add"
                (click)="_addReference()"
                textLc=".AddSlot"
            >
            </lg-button>

            <lg-button
                buttonClass="button button--condensed"
                style="margin-left: 4px"
                icon="icon-delete"
                [isDisabled]="_numReferences <= 1"
                (click)="_removeReference()"
                textLc=".RemoveSlot"
            >
            </lg-button>
        </div>

        <h3 class="with-spacing">{{ ".PreselectedReferences" | lgTranslate }}</h3>
    </div>
</div>
<div class="form-group">
    <div class="form-row--input form-row" *ngFor="let slot of _selectedSlots; let i = index">
        <div class="form-row__label">{{ ".Slot" | lgTranslate }} {{ i + 1 }}:</div>
        <div class="form-row__controls--flex form-row__controls">
            <div class="form-row__control-column">
                <lg-dropdown
                    [emptyAcceptable]="true"
                    [definition]="
                        slot.isLocked == true ? _referencesDropdown : _referencesDropdownWithEmpty
                    "
                    [(current)]="slot.referenceCode"
                    (currentChange)="_onReferenceSelect(i, $event)"
                ></lg-dropdown>
            </div>

            <div>
                <lg-icon
                    [type]="slot.referenceCode == null ? 'disabled' : 'regular'"
                    icon="icon-lock"
                    [class.lg-icon--transparent]="!slot.isLocked"
                    style="margin-top: 6px"
                    [clickable]="true"
                    (click)="_toggleIsLocked(slot)"
                    [lgTooltip]="(slot.isLocked ? '.Locked' : '.Unlocked') | lgTranslate"
                ></lg-icon>
            </div>
        </div>
    </div>
</div>
<div class="form-group form-group--buttons">
    <div class="form-row">
        <lg-button buttonClass="button--primary" textLc="FW.OK" (click)="_save()"></lg-button>

        <lg-button textLc="FW.CLOSE" (click)="_close()"></lg-button>
    </div>
</div>
