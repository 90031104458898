import { DefinitionDisplayMode, IDefinitions } from "@logex/framework/lg-application";

export function getItemNameFromField(
    code: string | number,
    fieldType: string,
    definitions: IDefinitions<any>,
    displayMode?: DefinitionDisplayMode
): string {
    switch (fieldType) {
        case "string":
            return code != null ? (code as string) : "-";
        case "number":
            return code != null ? code?.toString() : "-";
        default:
            return definitions.getDisplayName(fieldType, code, displayMode) ?? "-";
    }
}
