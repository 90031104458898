import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    Input,
    OnDestroy,
    QueryList,
    input,
    Signal,
    InputSignal
} from "@angular/core";
import { FormSectionColumnComponent } from "../form-section-column/form-section-column.component";
import { BehaviorSubject, ReplaySubject, map, startWith, takeUntil } from "rxjs";

@Component({
    selector: "lgflex-form-section",
    templateUrl: "./form-section.component.html",
    styleUrls: ["./form-section.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSectionComponent implements AfterContentInit, OnDestroy {
    sectionTitle: InputSignal<string | null> = input(null);
    @Input() hideDivider = false;

    @ContentChildren(FormSectionColumnComponent) columns: QueryList<FormSectionColumnComponent>;

    readonly destroy$ = new ReplaySubject<void>(1);
    readonly hasColumns$ = new BehaviorSubject<boolean | null>(null);
    readonly vm$ = this.hasColumns$.pipe(map(hasColumns => ({ hasColumns })));

    ngAfterContentInit(): void {
        this._setupHasColumnsListener();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _setupHasColumnsListener(): void {
        this.columns.changes
            .pipe(
                startWith(this.columns.toArray()),
                map(() => this.columns.toArray()),
                map(columns => {
                    const hasColumns = columns.length !== 0;

                    return hasColumns;
                }),
                takeUntil(this.destroy$)
            )
            .subscribe(hasColumns => this.hasColumns$.next(hasColumns));
    }
}
