import * as _ from "lodash-es";
import { Inject, Injectable, Optional, StaticProvider } from "@angular/core";
import { LgConsole } from "@logex/framework/core";
import {
    Constructor,
    EmbeddedWidget,
    LayoutWidget,
    WidgetDecoratorArguments,
    widgetInfoSymbol,
    WidgetTypeInfo,
    WidgetUsage
} from "../../types";
import {
    ConfigMigratorDict,
    EMBEDDED_WIDGETS_TO_MIGRATE,
    EmbeddedWidgetsToMigrate,
    EmbeddedWidgetsToMigrateRecord,
    WIDGET_MIGRATION,
    WIDGET_REPLACEMENT,
    WidgetMigrationRecord,
    WidgetReplacerDeclaration
} from "./widget-types-registry.types";

@Injectable()
export class WidgetTypesRegistry {
    constructor(
        private _lgConsole: LgConsole,
        @Inject(WIDGET_MIGRATION) @Optional() _migrations?: WidgetMigrationRecord[],
        @Inject(EMBEDDED_WIDGETS_TO_MIGRATE)
        @Optional()
        _embeddedWidgetsToMigrate?: EmbeddedWidgetsToMigrateRecord[],
        @Inject(WIDGET_REPLACEMENT)
        @Optional()
        _replacements?: Array<WidgetReplacerDeclaration | WidgetReplacerDeclaration[]>
    ) {
        this._lgConsole = this._lgConsole.withSource("Logex.Flexible");
        if (_migrations) {
            _migrations.forEach(entry => {
                if (this._widgetMigrations[entry.id])
                    this._lgConsole.warn(
                        `Multiple records for widget '${entry.id}' config migrations`
                    );
                this._widgetMigrations[entry.id] = entry.migrations;
            });
        }
        if (_embeddedWidgetsToMigrate) {
            _embeddedWidgetsToMigrate.forEach(entry => {
                if (this._embeddedWidgetsToMigrate[entry.id])
                    this._lgConsole.warn(
                        `Multiple records for widget '${entry.id}' embedded configs`
                    );
                this._embeddedWidgetsToMigrate[entry.id] = entry.embeddedWidgets;
            });
        }
        if (_replacements) {
            for (const entries of _replacements) {
                if (!entries) return;
                const iterateBy = (
                    !_.isArray(entries) ? [entries] : entries
                ) as WidgetReplacerDeclaration[];
                for (const entry of iterateBy) {
                    let stored = this._widgetReplacements[entry.sourceType];
                    if (!stored) this._widgetReplacements[entry.sourceType] = stored = [];
                    const previousIndex = stored.findIndex(
                        el => el.targetType === entry.targetType
                    );
                    if (previousIndex === -1) {
                        stored.push(entry);
                    } else {
                        this._lgConsole.warn(
                            `Multile records for widget '${entry.sourceType}' -> '${entry.targetType}' replacement`
                        );
                        stored[previousIndex] = entry;
                    }
                }
            }
        }
    }

    // ----------------------------------------------------------------------------------
    private _widgetTypes: WidgetTypeInfo[] = [];
    private _widgetProviders: StaticProvider[] = [];
    private _widgetMigrations: Record<string, ConfigMigratorDict> = {};
    private _widgetReplacements: Record<string, WidgetReplacerDeclaration[]> = {};

    private _embeddedWidgetsToMigrate: Record<string, EmbeddedWidgetsToMigrate | undefined> = {};

    // ----------------------------------------------------------------------------------
    add(widgetType: Constructor | WidgetTypeInfo): this {
        let typeInfo: WidgetTypeInfo;
        let decoratorInfo: WidgetDecoratorArguments | undefined;

        if (typeof widgetType === "function") {
            decoratorInfo = (widgetType as any)[widgetInfoSymbol];

            if (decoratorInfo === undefined) {
                throw Error(`Component ${widgetType.name} must have @Widget decorator applied`);
            }

            typeInfo = {
                ...decoratorInfo,
                type: widgetType
            };
        } else {
            decoratorInfo = (widgetType.type as any)[widgetInfoSymbol];
            typeInfo = { ...decoratorInfo, ...widgetType };
        }

        typeInfo.providers = typeInfo.providers ?? [];

        const configurator = typeInfo.configurator;
        if (configurator !== undefined) {
            typeInfo.providers = [
                ...typeInfo.providers,
                { provide: configurator, useClass: configurator }
            ];
        }

        this._widgetTypes.push(typeInfo);

        return this;
    }

    setProviders(providers: StaticProvider[]): this {
        this._widgetProviders = providers;
        return this;
    }

    addProviders(providers: StaticProvider[]): this {
        this._widgetProviders = [...this._widgetProviders, ...providers];
        return this;
    }

    get(typeId: string): WidgetTypeInfo {
        const widgetType = this.tryGet(typeId);

        if (widgetType === undefined) {
            throw Error(`Unknown widget type "${typeId}"`);
        }

        return widgetType;
    }

    tryGet(typeId: string): WidgetTypeInfo | undefined {
        const widgetType = _.find(this._widgetTypes, { id: typeId });

        if (widgetType === undefined) {
            return undefined;
        }

        return this._combineWithProviders(widgetType);
    }

    getAll(): WidgetTypeInfo[] {
        return _.map(this._widgetTypes, x => this._combineWithProviders(x));
    }

    getAllForUsage(usage: WidgetUsage): WidgetTypeInfo[] {
        return this._widgetTypes.filter(x => x.usage === usage);
    }

    getMigrations(typeId: string): ConfigMigratorDict | undefined {
        return this._widgetMigrations[typeId];
    }

    getEmbeddedWidgetsToMigrate(widget: LayoutWidget): EmbeddedWidget[] {
        const getEmbeddedWidgets = this._embeddedWidgetsToMigrate[widget.type];
        if (getEmbeddedWidgets === undefined) return [];
        return getEmbeddedWidgets(widget.config);
    }

    getReplacements(typeId: string): WidgetReplacerDeclaration[] | undefined {
        return this._widgetReplacements[typeId];
    }

    getWidgetConfigVersion(widgetType: object): number {
        const widgetTypeInfo = this._widgetTypes.find(x => x.type === widgetType.constructor)!;
        return widgetTypeInfo?.configVersion;
    }

    private _combineWithProviders(widgetType: WidgetTypeInfo): WidgetTypeInfo {
        return {
            ...widgetType,
            providers: [...(widgetType.providers ?? []), ...this._widgetProviders]
        };
    }
}
