import { DrilldownPivotTableWidgetBaseConfig } from "./pivot-table-conversion.types";

export const migrateVersion2To3 = (config: DrilldownPivotTableWidgetBaseConfig) => {
    for (const column of config.columns) {
        // There should be no value columns with empty width
        if (column.isEnabled && column.width == null) {
            column.width = 100;
        }
    }
};
