import {
    ChangeDetectionStrategy,
    Component,
    computed,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    signal,
    Signal,
    ViewChild,
    ViewEncapsulation,
    WritableSignal
} from "@angular/core";
import { IDropdownDefinition, LgDropdownComponent, LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

import { AvailableDimension, DimensionRow, TableRow } from "./table-dimensions-selector.types";
import { generateId } from "../../utilities";

@Component({
    selector: "lgflex-table-dimensions-selector",
    templateUrl: "./table-dimensions-selector.component.html",
    styleUrls: ["./table-dimensions-selector.component.scss"],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [...useTranslationNamespace("_Flexible.TableDimensionsSelector")]
})
export class TableDimensionsSelectorComponent implements OnInit {
    private _promptDialog = inject(LgPromptDialog);
    private _lgTranslate = inject(LgTranslateService);
    @Input() hideBorder = false;
    @Input("defaultTableRowConfig") defaultTableRowConfig = {};
    @Input("buttonPlaceholderName") public buttonPlaceholderName =
        "pivotLevelsSelector.dropdownButtonContainer";

    @ViewChild("dimensionsDropdown") dimensionsDropdown: LgDropdownComponent<string> | null = null;
    @Output() readonly tablesConfigChange = new EventEmitter<TableRow[]>(true);
    @Output() readonly dimensionSelectChange = new EventEmitter<{
        dimension: DimensionRow;
        tableId: string;
    }>();

    @Output() readonly tableSelectChange = new EventEmitter<TableRow | null>();
    protected _isDimensionsDropdownActive: WritableSignal<boolean> = signal(false);
    protected _currentSelection: WritableSignal<{
        tableId: string | null;
        dimensionId: string | null;
    }> = signal({ tableId: null, dimensionId: null });

    protected readonly JSON = JSON;
    private _tableCounter = 1;
    protected _tablesConfig: WritableSignal<TableRow[]> = signal([]);

    get tablesConfig() {
        return this._tablesConfig();
    }

    @Input("tablesConfig") set tablesConfig(value: TableRow[]) {
        this._tablesConfig.set(value);
    }

    protected _currentTable: Signal<TableRow | null> = computed(() => {
        const table = this._tablesConfig().find(
            table => table.id === this._currentSelection().tableId
        );
        return table ? { ...table } : null;
    });

    protected _nonAggregatedFieldSelected = signal(false);

    get nonAggregatedFieldSelected() {
        return this._nonAggregatedFieldSelected();
    }

    @Input() set nonAggregatedFieldSelected(value: boolean) {
        this._nonAggregatedFieldSelected.set(value);
    }

    protected _addDimensionBtnDisabled: Signal<boolean> = computed(() => {
        const tables = this._tablesConfig();
        const currentTable: any =
            this._currentTable() || (tables.length > 0 ? tables[tables.length - 1] : undefined);

        if (!currentTable) {
            return false;
        }

        return (
            this._nonAggregatedFieldSelected() &&
            ((currentTable.dimensions.length === 1 && !!currentTable.stackedBarDimension) ||
                currentTable.dimensions.length >= 1)
        );
    });

    protected _availableDimensions: WritableSignal<AvailableDimension[]> = signal([]);

    get availableDimensions() {
        return this._availableDimensions();
    }

    @Input() set availableDimensions(value: AvailableDimension[]) {
        this._availableDimensions.set([...value]);
    }

    protected _dimensionsDropdownDefinition: Signal<IDropdownDefinition<string> | undefined> =
        computed(() => {
            const selectedTable =
                this._currentTable() || this._tablesConfig()[this._tablesConfig().length - 1];
            const selectedDimensions = selectedTable?.dimensions.map(l => l.fieldId) || [];

            const entries = this._availableDimensions().filter(
                dimension => selectedDimensions?.indexOf(dimension.fieldId) === -1
            );

            return {
                entryId: "fieldId",
                entryName: "name",
                groups: [{ entries }]
            };
        });

    ngOnInit(): void {
        this._initTablesConfig(this.tablesConfig);
    }

    resetSelection(): void {
        this._currentSelection.set({ tableId: null, dimensionId: null });
    }

    protected _onDimensionSelect(dimensionFieldId: string): void {
        if (this._tablesConfig().length === 0) {
            this._addTable();
        }

        this._addSelectedDimension(dimensionFieldId);
        this.tablesConfigChange.emit(this._tablesConfig());
    }

    protected _dimensionsDropdownActiveChange(isActive: boolean): void {
        this._isDimensionsDropdownActive.set(isActive);
    }

    protected _triggerDimensionsDropdownSelect(): void {
        this.dimensionsDropdown?.triggerSelect(
            () => null /* parameter has to be there, otherwise value must be picked*/
        );
    }

    protected _addTable(): void {
        const tableRow = this._createTableRow();

        if (this._currentSelection().tableId) {
            this._tablesConfig.update(tableConfig => {
                const currentTableIndex = tableConfig.findIndex(
                    table => table.id === this._currentSelection().tableId
                );
                tableConfig.splice(currentTableIndex + 1, 0, tableRow);
                return [...tableConfig];
            });
        } else {
            this._tablesConfig.update(tableConfig => [...tableConfig, tableRow]);
            this._currentSelection.set({ tableId: tableRow.id ?? null, dimensionId: null });
        }

        this.tablesConfigChange.emit(this._tablesConfig());
    }

    protected _addDimension(): void {
        this._triggerDimensionsDropdownSelect();
    }

    protected async _removeTable(tableId: string, removeIdx: number): Promise<void> {
        const table = this._tablesConfig().find(tableRow => tableRow.id === tableId);
        if (table.dimensions.length > 0 && !(await this._confirmRemoveTable())) {
            return;
        }

        this._tablesConfig.update(tableConfig => {
            return tableConfig.filter(table => table.id !== tableId);
        });

        if (Number(table.name.split(" ").pop()) === this._tableCounter - 1) {
            this._updateTableCounter();
        }

        if (this._tablesConfig().length === 0) {
            this._currentSelection.set({ tableId: null, dimensionId: null });
        } else if (this._currentSelection().tableId === tableId) {
            this._currentSelection.set({
                tableId: this._tablesConfig()[this._tablesConfig().length - 1].id ?? null,
                dimensionId: null
            });
        }

        this.tablesConfigChange.emit(this._tablesConfig());
        this.tableSelectChange.emit(this._currentTable());
    }

    protected _removeDimension(fromTable: TableRow, removeIdx: number): void {
        this._tablesConfig.update(tableConfig => {
            return tableConfig.map(table => {
                if (table.id === fromTable.id) {
                    table.dimensions.splice(removeIdx, 1);
                }
                return { ...table };
            });
        });
        this._currentSelection.set({ tableId: fromTable.id, dimensionId: null });

        this.tableSelectChange.emit(this._currentTable());
        this.tablesConfigChange.emit(this._tablesConfig());
    }

    protected onTableClick(table: TableRow) {
        this._currentSelection.set({ tableId: table.id, dimensionId: null });

        this.tableSelectChange.emit(this._currentTable());
    }

    protected onDimensionClick($event: any, dimension: DimensionRow, table: TableRow) {
        this._currentSelection.set({ tableId: table.id, dimensionId: dimension.id });
        this.dimensionSelectChange.emit({ dimension, tableId: table.id });
    }

    protected onTableDrop(event: CdkDragDrop<TableRow>) {
        const flatTablesConfig = [];

        this._tablesConfig().forEach(t => {
            flatTablesConfig.push(t);
            flatTablesConfig.push(...t.dimensions);
        });

        moveItemInArray(flatTablesConfig, event.previousIndex, event.currentIndex);

        this._tablesConfig.set(flatTablesConfig.filter(t => !!t.dimensions));
        this.tablesConfigChange.emit(this._tablesConfig());
    }

    protected onDimensionDrop(event: CdkDragDrop<DimensionRow>) {
        if (this._addDimensionBtnDisabled()) {
            return;
        }

        const flatTablesConfig = [];

        this._tablesConfig().forEach(t => {
            flatTablesConfig.push(t);
            flatTablesConfig.push(...t.dimensions);
        });

        moveItemInArray(
            flatTablesConfig,
            event.previousIndex,
            event.currentIndex === 0 ? 1 : event.currentIndex
        );

        const resultTableConfig = [];
        let table: TableRow;

        flatTablesConfig.forEach(item => {
            if (item.dimensions) {
                table && resultTableConfig.push(table);
                table = {
                    ...item,
                    dimensions: []
                };
            } else {
                table.dimensions.push(item);
            }
        });
        resultTableConfig.push(table);

        const isValid = resultTableConfig.every(t => {
            const dimensionsSet = new Set(t.dimensions.map(d => d.fieldId));

            return dimensionsSet.size === t.dimensions.length;
        });

        if (isValid) {
            this._tablesConfig.set(resultTableConfig);
            this.tablesConfigChange.emit(this._tablesConfig());
        } else {
            this._promptDialog.alert(
                this._lgTranslate.translate(".SameDimensionDragTitle"),
                this._lgTranslate.translate(".SameDimensionDragDescription")
            );
        }
    }

    private _initTablesConfig(initialTablesData: TableRow[]): void {
        const tableConfig = initialTablesData.map(tableData => {
            const dimensionsRows: DimensionRow[] = tableData.dimensions.map((dimension, index) => {
                return {
                    ...dimension,
                    id: generateId()
                } as DimensionRow;
            });
            return {
                ...tableData,
                id: generateId(),
                dimensions: dimensionsRows
            };
        });
        this._tablesConfig.set(tableConfig);

        this._updateTableCounter();

        this.tablesConfigChange.emit(this._tablesConfig());
    }

    private _addSelectedDimension(dimensionFieldId: string) {
        if (!this._currentSelection().tableId) {
            this._currentSelection.set({
                tableId: this._tablesConfig()[this._tablesConfig().length - 1].id ?? null,
                dimensionId: null
            });
        }

        const dimension = this._availableDimensions().find(d => d.fieldId === dimensionFieldId);
        const { name, disabled } = dimension;

        this._tablesConfig.update(tableConfig => {
            return tableConfig.map(table => {
                if (table.id === this._currentSelection().tableId) {
                    table.dimensions.push({
                        id: generateId(),
                        fieldId: dimensionFieldId,
                        schemaName: name,
                        displayMode: "codeAndName",
                        width: null,
                        isSelectable: undefined,
                        name,
                        disabled
                    });
                }
                return { ...table };
            });
        });
    }

    private _createTableRow(): TableRow {
        return {
            ...this.defaultTableRowConfig,
            id: generateId(),
            ignoreOwnFilters: false,
            name: `Table ${this._tableCounter++}`,
            limitRows: 0,
            orderBy: [],
            dimensions: []
        };
    }

    private async _confirmRemoveTable(): Promise<boolean> {
        const dialogResult = await this._promptDialog.confirm(
            this._lgTranslate.translate(".RemoveTableDialogTitle"),
            this._lgTranslate.translate(".RemoveTableDialogBody"),
            {
                buttons: [
                    {
                        id: "delete",
                        name: this._lgTranslate.translate(".RemoveTableDialogDelete"),
                        class: "button--primary",
                        isConfirmAction: true
                    },
                    {
                        id: "cancel",
                        name: this._lgTranslate.translate(".RemoveTableDialogCancel"),
                        isCancelAction: true
                    }
                ],
                columns: 2
            }
        );

        return dialogResult === "delete";
    }

    private _updateTableCounter() {
        this._tableCounter =
            this._tablesConfig().length === 0
                ? 1
                : Math.max(
                      ...this._tablesConfig().map(table => Number(table.name.split(" ").pop()))
                  ) + 1;
    }
}
