import { Component, Injectable } from "@angular/core";
import { mixins } from "@logex/mixin-flavors";

import {
    getDialogFactoryBase,
    IDialogComponent,
    IDropdownDefinition,
    LgPromptDialog,
    LgDialogFactory,
    LgDialogRef
} from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";

import { FlexibleLayoutLibrary } from "../../../../services/flexible-layout-storage/types/types";

interface LibraryConfirmDialogArguments {
    libraries: FlexibleLayoutLibrary[] | null;
    libraryId?: number | undefined;
    name?: string;
    description?: string;
}

interface LibraryConfirmDialogResponse {
    libraryId: number | undefined;
    name: string;
    description: string;
}

export interface LibraryConfirmDialogComponent
    extends DialogMixin<LibraryConfirmDialogComponent>,
        ModalResultDialogMixin<LibraryConfirmDialogArguments, LibraryConfirmDialogResponse> {}

@Component({
    selector: "lgflex-library-confirm-dialog",
    templateUrl: "./library-confirm-dialog.component.html",
    providers: [...useTranslationNamespace("_Flexible._LayoutEditor")]
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class LibraryConfirmDialogComponent
    implements IDialogComponent<LibraryConfirmDialogComponent>
{
    constructor(
        public _lgTranslate: LgTranslateService,
        public _dialogRef: LgDialogRef<LibraryConfirmDialogComponent>,
        public _promptDialog: LgPromptDialog
    ) {
        this._initMixins();
    }

    // ----------------------------------------------------------------------------------
    //

    // Dialog configuration
    _dialogClass = "lg-dialog lg-dialog--3col";
    _title = this._lgTranslate.translate(".SaveAsNew");

    _libraryDropdownDef: IDropdownDefinition<number> | undefined;
    _library: number | undefined;

    _name = "";
    _description = "";

    // ----------------------------------------------------------------------------------
    //
    show(args: LibraryConfirmDialogArguments): Promise<LibraryConfirmDialogResponse> {
        if (args.libraries !== null) {
            this._libraryDropdownDef = {
                groups: [
                    {
                        entries: args.libraries.filter(lib => !lib.readOnly)
                    }
                ]
            };
            this._library = args.libraryId;
        }

        this._name = args.name ?? "";
        this._description = args.description ?? "";

        // TODO: Fix the mixin
        // @ts-ignore
        return null;
    }

    async _save(): Promise<boolean> {
        this._resolve({
            libraryId: this._library,
            name: this._name,
            description: this._description
        });
        return true;
    }

    _close(): Promise<void> {
        this._dialogRef.close();
        // TODO: Fix the mixin
        // @ts-ignore
        return null;
    }

    isValid(): boolean {
        if (this._libraryDropdownDef !== undefined && this._library === undefined) {
            return false;
        }

        return this._name !== "";
    }
}

@Injectable()
export class LibraryConfirmDialog extends getDialogFactoryBase(
    LibraryConfirmDialogComponent,
    "show"
) {
    constructor(_factory: LgDialogFactory) {
        super(_factory);
    }
}
