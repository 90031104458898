import { Component, Input } from "@angular/core";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import { DrilldownKeyItem, FdpCell, FieldInfo } from "../../../types";

@Component({
    selector: "lgflex-flexible-drilldown-table-row-content",
    templateUrl: "./flexible-drilldown-table-row-content.component.html",
    host: {
        class: "flexcol"
    }
})
export class FlexibleDrilldownTableRowContentComponent {
    @Input({ required: true }) cell!: FdpCell;
    @Input({ required: true }) levels: string[] = [];
    @Input({ required: true }) selectedKeys: DrilldownKeyItem[][] = [];
    @Input({ required: true }) rowIndex!: number;
    @Input({ required: true }) row: { [K: string]: number } | null = null;
    @Input({ required: true }) filters: LgFilterSet | undefined = undefined;
    @Input({ required: true }) scheme: FieldInfo[] = [];
    @Input({ required: true }) items!: Record<string, unknown>;
    @Input({ required: true }) maxColumnValues!: Record<string, number>;
}
