<div class="flexrow lg-settings-config-row">
    <div class="lg-settings-config-row__header">
        <div class="form-row form-row--label-above">
            <div class="flexrow">
                <label *ngIf="rowTitle">{{ rowTitle }}</label>
                <span *ngIf="isDirty" class="lg-settings-config-star">*</span>
            </div>
            <div class="form-row__controls form-row__controls--flex">
                <div class="form-row__control-column">
                    <span
                        *ngIf="rowDescription"
                        class="lg-settings-config-row__description"
                        [textContent]="rowDescription"
                    ></span>
                    <div class="lg-settings-config-row__description">
                        <ng-container
                            *ngIf="rowAddDescription"
                            [ngTemplateOutlet]="rowAddDescription"
                        ></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="lg-settings-config-row__body">
        <p *ngIf="controlLabel">{{ controlLabel }}</p>
        <ng-container *ngIf="rowControlRef" [ngTemplateOutlet]="rowControlRef" />
    </div>
</div>
