import { FieldInfo, PivotTableColumn, PivotTableColumnFormula } from "../types";
import * as _ from "lodash-es";
import { PageReferencesService } from "../services/page-references/page-references.service";
import { LgTranslateService } from "@logex/framework/lg-localization";

export function addTitles(
    scheme: Array<Readonly<FieldInfo>>,
    pageReferences: PageReferencesService,
    columns: PivotTableColumn[],
    lgTranslate: LgTranslateService
): void {
    const schemeLookup = _.keyBy(scheme, "field");

    for (const x of columns) {
        if (x.title != null || x.type !== "formula") continue;

        const getReferenceName = (y: PivotTableColumnFormula): string => {
            if (y.variables.a?.type === "constant") return y.variables.a.variable;

            const referenceCode =
                y.variables.a != null &&
                pageReferences.slots[y.variables.a.reference!]?.referenceCode;

            return (
                pageReferences.references.find(y => y.code === referenceCode)?.name ||
                lgTranslate.translate(
                    pageReferences.references.find(y => y.code === referenceCode)?.nameLc!
                )
            );
        };

        Object.assign(x, {
            ...x,
            title:
                x.title ||
                (x.variables.a != null &&
                    x.variables.a.type !== "constant" &&
                    (schemeLookup[x.variables.a.field]?.name ||
                        lgTranslate.translate(schemeLookup[x.variables.a.field].nameLc!) +
                            (pageReferences.isAllowed() ? ` (${getReferenceName(x)})` : "")))
        });
    }
}
