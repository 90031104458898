import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { after, before } from "@logex/mixin-flavors";

@Injectable()
export abstract class NgOnDestroyMixin implements OnDestroy {
    protected _destroyed$!: Subject<void>;

    // ----------------------------------------------------------------------------------

    @before
    _initMixins(): void {
        if (this._destroyed$ == null) {
            this._destroyed$ = new Subject<void>();
        }
    }

    @after
    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
