import * as _ from "lodash-es";
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { Subject, Subscription } from "rxjs";
import { LgTranslateService } from "@logex/framework/lg-localization";

enum State {
    Disabled,
    Enabled,
    Indeterminate
}

export interface Leaf<Data = any> {
    name?: string;
    nameLc?: string;
    icon?: string;

    isChecked?: boolean;
    isDisabled?: boolean;
    isSelectable?: boolean;

    position?: number;

    data?: Data;

    warningIcons?: LeafWarningIcon[];

    children?: Array<Leaf<Data>>;
    row?: Row<Data>;
}

export interface LeafWarningIcon {
    icon: string;
    title: string;
}

interface Row<Data = any> {
    level: number;
    name: string;
    state?: State;
    leaf: Leaf<Data>;
    parentLeaf?: Leaf<Data>;
    parentRow?: Row<Data>;
    icon?: string;
}

@Component({
    selector: "lgflex-tree-selector",
    templateUrl: "./lg-tree-selector.component.html",
    styleUrls: ["./lg-tree-selector.component.scss"],
    host: {
        "[class.lg-tree-selector]": "true"
    },
    encapsulation: ViewEncapsulation.None
})
export class LgTreeSelectorComponent implements OnInit, OnDestroy {
    constructor(public _lgTranslate: LgTranslateService) {}

    // ----------------------------------------------------------------------------------
    //
    @Input() hideBorder = false;

    @Input("config") treeConfig: Leaf[] = [];
    @Input("config$") treeConfig$: Subject<Leaf[]> | undefined;

    @Input("rowHeight") rowHeight = 24;
    @Input("visibleRowsNum") visibleRowsNum = 7;

    @Input("current") currentLeaf: Leaf | undefined;
    @Output() public readonly currentChange = new EventEmitter<Leaf>();

    @Input("enableSingleItemSelection") enableSingleItemSelection = false;
    @Input("enableSelection") enableSelection = false;
    @Input("enableSorting") enableSorting = false;

    @Input("enableDeleting") enableDeleting = false;

    @Output() public readonly checkboxToggle = new EventEmitter<Leaf>();
    @Output() public readonly sort = new EventEmitter<Leaf[]>();

    flatRows: Row[] = [];
    private _configSubscription: Subscription | undefined;

    // ----------------------------------------------------------------------------------
    //
    ngOnInit(): void {
        this._rebuildFlatTree();

        if (this.treeConfig$) {
            this._configSubscription = this.treeConfig$.subscribe(x => {
                this.treeConfig = x;
                this._rebuildFlatTree();
            });
        }
    }

    ngOnDestroy(): void {
        this._configSubscription?.unsubscribe();
    }

    // ----------------------------------------------------------------------------------
    //
    private _rebuildFlatTree(): void {
        this.flatRows = [];
        this._digLevel(0, this.treeConfig);
        if (this.enableSelection) this._updateCheckboxStatuses();
    }

    private _digLevel(level: number, leaves: Leaf[], parentLeaf?: Leaf, parentRow?: Row): void {
        // Set initial sorting position value
        if (this.enableSorting && leaves.length && leaves[0].position == null) {
            this._recalculateSortingForLeaves(leaves);
        }

        const leavesSorted = _.sortBy(leaves, x => x.position);
        for (const leaf of leavesSorted) {
            const flatRow: Row = {
                level,
                name: leaf.name || (leaf.nameLc ? this._lgTranslate.translate(leaf.nameLc) : ""),
                leaf,
                parentLeaf,
                parentRow,
                icon: leaf.icon
            };

            if (this.enableSelection) {
                flatRow.state = !leaf.isDisabled && leaf.isChecked ? State.Enabled : State.Disabled;
            }

            leaf.row = flatRow;
            this.flatRows.push(flatRow);

            // Preserve selected item based on key in spite changes
            if (
                this.enableSingleItemSelection &&
                this.currentLeaf &&
                this.currentLeaf.position === leaf.position &&
                _.isEqual(this.currentLeaf.data?.key, leaf.data?.key)
            ) {
                this.currentLeaf = leaf;
            }

            if (leaf.children) {
                this._digLevel(level + 1, leaf.children, leaf, flatRow);
            }
        }
    }

    // ----------------------------------------------------------------------------------
    //
    private _updateCheckboxStatuses(): void {
        const anchorRows = this.flatRows.filter(
            (x, i) =>
                !x.leaf.children &&
                x.parentLeaf &&
                (i === 0 || this.flatRows[i - 1].parentLeaf !== x.parentLeaf)
        );

        for (const x of anchorRows) {
            const siblingLeaves = x.parentLeaf!.children!;
            const siblingRows = siblingLeaves.map(x => x.row!);

            x.parentRow!.state = _.every(siblingRows, x => x.state === State.Enabled)
                ? State.Enabled
                : _.every(siblingRows, x => x.state === State.Disabled)
                ? State.Disabled
                : State.Indeterminate;

            x.parentRow!.leaf.isChecked = x.parentRow!.state !== State.Disabled;
        }
    }

    // ----------------------------------------------------------------------------------
    //
    onToggle(row: Row): void {
        const leaf = row.leaf;

        const newValue = !leaf.isDisabled && !leaf.isChecked;

        leaf.isChecked = newValue;
        this._setChildrenValue(leaf, newValue);
        this.checkboxToggle.emit(leaf);

        this._rebuildFlatTree();
    }

    onRowDrop(event: CdkDragDrop<Row>): void {
        // Calculate position delta
        const positionDelta = event.currentIndex - event.previousIndex;
        const direction = positionDelta < 0 ? -1 : 1;

        // Find nodes corresponding to the dropped row
        const sourceRow = _.find(this.flatRows, x => x === event.item.data)!;
        const targetRow = this.flatRows[event.previousIndex + positionDelta];
        const afterTargetRow = this.flatRows[event.previousIndex + positionDelta + 1];

        const sourceRowSiblings = this.flatRows.filter(
            x => (sourceRow.level === 0 && x.level === 0) || x.parentLeaf === sourceRow.parentLeaf
        );

        const recalculateSortingForRowsAndRebuild = (): void => {
            this._recalculateSortingForLeaves(sourceRowSiblings.map(x => x.leaf));
            this._rebuildFlatTree();

            const sortedSourceRowSiblings = _.sortBy(sourceRowSiblings, x => x.leaf.position);
            const sortedSourceLeafSiblings = sortedSourceRowSiblings.map(x => x.leaf);
            this.sort.emit(sortedSourceLeafSiblings);
        };

        // Dragged to the end of list
        if (!afterTargetRow) {
            const maxPosition = Math.max(...sourceRowSiblings.map(x => x.leaf.position ?? 0));
            sourceRow.leaf.position = maxPosition + 1;

            const rootTargetRow = targetRow.parentRow || targetRow;
            if (sourceRow === rootTargetRow) return;

            recalculateSortingForRowsAndRebuild();
            return;
        }

        // Swap between siblings
        if (sourceRowSiblings.includes(targetRow)) {
            // Skip if target row has children (otherwise swap is unnatural)
            if (direction > 0 && targetRow.leaf.children?.length) return;

            // Skip if position didn't changed
            if (sourceRow === targetRow) return;

            sourceRow.leaf.position = targetRow.leaf.position! + 0.5 * direction;

            recalculateSortingForRowsAndRebuild();
            return;
        }

        // Swap between nodes with children
        if (sourceRowSiblings.includes(afterTargetRow)) {
            const prevTargetSibling =
                sourceRowSiblings[sourceRowSiblings.indexOf(afterTargetRow) - 1];

            sourceRow.leaf.position =
                direction < 0
                    ? afterTargetRow.leaf.position! + 0.5 * direction
                    : prevTargetSibling.leaf.position! + 0.5 * direction;

            recalculateSortingForRowsAndRebuild();
        }
    }

    private _recalculateSortingForLeaves(leaves: Leaf[]): void {
        const leavesSorted = _.sortBy(leaves, x => x.position);

        let position = 0;
        for (const node of leavesSorted) {
            node.position = position;
            position++;
        }
    }

    onRowClick($event: MouseEvent, row: Row): void {
        if (this.enableSingleItemSelection) {
            if (row.leaf.isSelectable === false) return;

            this.currentLeaf = this.currentLeaf !== row.leaf ? row.leaf : undefined;
        } else {
            this.onToggle(row);
        }

        this.currentChange?.emit(this.currentLeaf);
    }

    private _setChildrenValue(leaf: Leaf, value: boolean): void {
        if (leaf.children) {
            for (const child of leaf.children) {
                child.isChecked = !leaf.isDisabled && value;

                this._setChildrenValue(child, !leaf.isDisabled && value);
            }
        }
    }

    deleteRow(row: Row): void {
        this.treeConfig = this.treeConfig.filter(x => x.row !== row);
        if (this.treeConfig$ != null) {
            this.treeConfig$.next(this.treeConfig);
        }
        this._rebuildFlatTree();
    }

    // ----------------------------------------------------------------------------------
    //
    getScrollerHeight(): number {
        return this.rowHeight * this.visibleRowsNum;
    }
}
