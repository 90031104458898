import { Injectable, ViewContainerRef } from "@angular/core";
import { DialogWidgetConfigurator } from "../../types";
import { BubbleChartWidgetConfig } from "./bubble-chart-widget.types";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { BubbleChartConfigurationDialog } from "./components/bubble-chart-configuration-dialog/bubble-chart-configuration-dialog.component";
import { validateChartWidgetConfigBase } from "../../components/base/chart-configuration-dialog-base";

@Injectable()
export class BubbleChartWidgetConfigurator
    implements DialogWidgetConfigurator<BubbleChartWidgetConfig>
{
    constructor(
        private _flexDataClient: FlexDataClientService,
        private _pageReferences: PageReferencesService,
        private _dialog: BubbleChartConfigurationDialog,
        viewContainerRef: ViewContainerRef
    ) {
        this._dialog = _dialog.bindViewContainerRef(viewContainerRef);
    }

    readonly type = "dialog";

    async show(config: BubbleChartWidgetConfig): Promise<BubbleChartWidgetConfig> {
        const response = await this._dialog.show({
            scheme: this._flexDataClient.scheme,
            pageReferences: this._pageReferences,
            widgetName: config.title ?? "",
            widgetDescription: config.description ?? "",
            widgetTooltip: config.tooltip ?? "",
            widgetTooltipLink: config.tooltipLink ?? "",
            levels: config.levels,
            xAxisColumnFormula: config.xAxisColumnFormula,
            yAxisColumnFormula: config.yAxisColumnFormula,
            sizeDimension: config.sizeDimension,
            chartType: config.chartType
        });

        return {
            title: response.widgetName,
            description: response.widgetDescription,
            tooltip: response.widgetTooltip,
            tooltipLink: response.widgetTooltipLink,
            levels: response.levels,
            xAxisColumnFormula: response.xAxisColumnFormula,
            yAxisColumnFormula: response.yAxisColumnFormula,
            sizeDimension: response.sizeDimension,
            columns: response.columns,
            chartType: response.chartType
        };
    }

    validate(config: BubbleChartWidgetConfig): boolean {
        return validateChartWidgetConfigBase<BubbleChartWidgetConfig>(
            config,
            this._pageReferences,
            this._flexDataClient.scheme
        );
    }
}
