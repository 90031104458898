<ng-template #layoutSlideoutHeaderIcons>
    @if (!_temporaryLayoutInProgress() && (_userCanCreateLayout | async)) {
        <lg-icon
            icon="icon-audit-add"
            [clickable]="true"
            (click)="_createLayout()"
            [lgTooltip]="'.CreateLayout' | lgTranslate"
        ></lg-icon>
    }
</ng-template>

<ng-template #layoutSlideout>
    @if (isLoading) {
        <div [lgLoaderOverlay]="true" [style.width.%]="100" [style.height.%]="100"></div>
    } @else {
        <div class="lg-flexlayout-panel flexcol">
            <div lgScrollable [lgScrollableWrapper]="false">
                <div class="lg-flexlayout-panel__level-1">
                    <div
                        class="lg-flexlayout-panel__header lg-flexlayout-panel-title"
                        [ngClass]="
                            _sections()['personalLayouts']
                                ? 'lg-flexlayout-panel-title--expanded'
                                : ''
                        "
                        (click)="_toggleWrapper('personalLayouts')"
                    >
                        {{ ".PersonalLayouts" | lgTranslate }}
                        <lg-icon
                            icon="icon-arrow-down"
                            class="lg-flexlayout-panel__icon"
                            [class.lg-flexlayout-panel__icon--expanded]="
                                _sections()['personalLayouts']
                            "
                        ></lg-icon>
                    </div>

                    @if (_sections()["personalLayouts"]) {
                        @for (library of _libraries(); track library) {
                            @for (layout of library.layouts; track layout) {
                                <ul class="lg-flexlayout-panel__level-2">
                                    @if (
                                        layout.isPersonal &&
                                        !layout.isShared &&
                                        !layout.hasTemporary
                                    ) {
                                        <li
                                            class="lg-flexlayout-panel-item-parts"
                                            [class.lg-flexlayout-panel-item--selected]="
                                                layoutId === layout.id
                                            "
                                        >
                                            <lgflex-flexible-layouts-item
                                                [layout]="layout"
                                                [isLayoutReadOnly]="library.readOnly"
                                                [isLayoutInProgress]="_temporaryLayoutInProgress()"
                                                (onLayoutLoad)="_loadLayout($event)"
                                                (onLayoutDuplicate)="_duplicateLayout($event)"
                                                (onLayoutEdit)="_editLayout($event)"
                                            />
                                        </li>
                                    }
                                </ul>
                            }
                        }
                    }
                </div>
                <div class="lg-flexlayout-panel__level-1">
                    <div
                        class="lg-flexlayout-panel__header lg-flexlayout-panel-title"
                        [ngClass]="
                            _sections()['sharedLayouts']
                                ? 'lg-flexlayout-panel-title--expanded'
                                : ''
                        "
                        (click)="_toggleWrapper('sharedLayouts')"
                    >
                        {{ ".SharedLayouts" | lgTranslate }}
                        <div class="lg-flexlayout-panel__icon">
                            @if (_userCanEditLayoutGroups | async) {
                                <lg-icon
                                    style="right: 20px"
                                    icon="icon-gear"
                                    class="lg-flexlayout-panel__icon lg-flexlayout-panel__icon--enabled"
                                    [lgTooltip]="'.LayoutManagement' | lgTranslate"
                                    (click)="_openLayoutManagementDialog($event)"
                                />
                            }
                            <lg-icon
                                icon="icon-arrow-down"
                                class="lg-flexlayout-panel__icon"
                                [class.lg-flexlayout-panel__icon--expanded]="
                                    _sections()['sharedLayouts']
                                "
                            />
                        </div>
                    </div>

                    @if (_sections()["sharedLayouts"]) {
                        @for (layout of _sharedLayouts(); track layout.id) {
                            <ul class="lg-flexlayout-panel__level-2">
                                @if (_isCatalogGroup(layout)) {
                                    <div
                                        class="lg-flexlayout-panel__sub-header lg-flexlayout-panel-title"
                                        [ngClass]="{
                                            'lg-flexlayout-panel-title--expanded':
                                                _sections()[layout.name]
                                        }"
                                        (click)="_toggleWrapper(layout.name)"
                                    >
                                        {{ layout.name }}
                                        <lg-icon
                                            icon="icon-arrow-down"
                                            class="lg-flexlayout-panel__icon"
                                            [class.lg-flexlayout-panel__icon--expanded]="
                                                _sections()[layout.name]
                                            "
                                        ></lg-icon>
                                    </div>
                                    @if (_sections()[layout.name]) {
                                        @for (layout of layout.layouts; track layout.id) {
                                            <ul class="lg-flexlayout-panel__level-3">
                                                <li
                                                    class="lg-flexlayout-panel-item-parts"
                                                    [class.lg-flexlayout-panel-item--selected]="
                                                        layoutId === layout.id
                                                    "
                                                >
                                                    <lgflex-flexible-layouts-item
                                                        [layout]="layout"
                                                        [isLayoutReadOnly]="layout.readonly"
                                                        [isLayoutInProgress]="
                                                            _temporaryLayoutInProgress()
                                                        "
                                                        (onLayoutLoad)="_loadLayout($event)"
                                                        (onLayoutDuplicate)="
                                                            _duplicateLayout($event)
                                                        "
                                                        (onLayoutEdit)="_editLayout($event)"
                                                    />
                                                </li>
                                            </ul>
                                        }
                                    }
                                }
                                @if (_isCatalogLayout(layout)) {
                                    <li
                                        class="lg-flexlayout-panel-item-parts"
                                        [class.lg-flexlayout-panel-item--selected]="
                                            layoutId === layout.id
                                        "
                                    >
                                        <lgflex-flexible-layouts-item
                                            [layout]="layout"
                                            [isLayoutReadOnly]="layout.readonly"
                                            [isLayoutInProgress]="_temporaryLayoutInProgress()"
                                            (onLayoutLoad)="_loadLayout($event)"
                                            (onLayoutDuplicate)="_duplicateLayout($event)"
                                            (onLayoutEdit)="_editLayout($event)"
                                        />
                                    </li>
                                }
                            </ul>
                        }
                    }
                </div>

                @if (pageReferences.isAllowed() && pageReferences.slots.length > 0) {
                    <div class="lg-flexlayout-panel__level-1">
                        <div
                            class="lg-flexlayout-panel__header lg-flexlayout-panel-title"
                            [ngClass]="
                                _sections()['references']
                                    ? 'lg-flexlayout-panel-title--expanded'
                                    : ''
                            "
                            (click)="_toggleWrapper('references')"
                        >
                            {{ ".CurrentReferences" | lgTranslate }}
                            <lg-icon
                                icon="icon-arrow-down"
                                class="lg-flexlayout-panel__icon"
                                [class.lg-flexlayout-panel__icon--expanded]="
                                    _sections()['references']
                                "
                            ></lg-icon>
                        </div>
                        @if (_sections()["references"]) {
                            <ul class="lg-flexlayout-panel__level-2">
                                @for (
                                    ref of pageReferences.selectedReferences;
                                    track ref;
                                    let i = $index
                                ) {
                                    <li class="lg-flexlayout-panel-item-parts">
                                        <div class="lg-flexlayout-panel-item">
                                            <lg-dropdown
                                                class="form-row__controls__dropdown"
                                                [lgTooltip]="
                                                    _isReferenceLocked(i)
                                                        ? ('.ReferenceIsLocked' | lgTranslate)
                                                        : ''
                                                "
                                                [current]="ref.code"
                                                (currentChange)="_onReferenceSelected(i, $event)"
                                                [definition]="_referencesDropdown"
                                                [hideSearch]="true"
                                                [condensed]="true"
                                                [disabled]="_isReferenceLocked(i)"
                                            ></lg-dropdown>
                                        </div>
                                    </li>
                                }
                            </ul>
                        }
                    </div>
                }
            </div>
        </div>
    }
</ng-template>
