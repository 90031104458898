<div class="lg-container" lgScrollable [lgScrollableWrapper]="false">
    @if (currentValue.type === "default") {
        <div>
            <lgflex-form-row [label]="'.Title' | lgTranslate">
                <input
                    class="input input--wide"
                    type="text"
                    [ngModel]="currentValue.title"
                    [placeholder]="currentValue.schemaName"
                    (ngModelChange)="_onValuePropertyChange($event, 'title')"
                />
            </lgflex-form-row>

            <lgflex-form-row [label]="'.Field' | lgTranslate">
                <lg-dropdown
                    [definition]="referencedFieldsDropdown"
                    [current]="currentValue.referencedField"
                    (currentChange)="_onReferencedFieldChange($event)"
                />
            </lgflex-form-row>

            <lgflex-form-row [label]="'.Width' | lgTranslate">
                <input
                    class="input"
                    type="number"
                    min="0"
                    max="400"
                    style="width: 100px"
                    [ngModel]="currentValue.width"
                    (ngModelChange)="_onValuePropertyChange($event, 'width')"
                />
            </lgflex-form-row>

            <ng-container *ngTemplateOutlet="visualizationWidgetConfiguration" />
        </div>
    }
    @if (currentValue.type === "formula") {
        <div>
            <lgflex-pivot-table-formula-configuration
                [column]="currentValue"
                [areReferencesAllowed]="areReferencesAllowed"
                [fieldsDropdownDefinition]="fieldsDropdownDefinition"
                [referencesDropdownDefinition]="referenceDropdown"
                (columnPropertyChange)="_updateCurrentValue($event)"
            >
            </lgflex-pivot-table-formula-configuration>
            <ng-container *ngTemplateOutlet="visualizationWidgetConfiguration" />
        </div>
    }
    @if (currentValue.type === "difference") {
        <div>
            <div>
                <lgflex-form-row [label]="'.Title' | lgTranslate">
                    <input
                        class="input input--wide"
                        type="text"
                        [(ngModel)]="currentValue.title"
                        (ngModelChange)="_onValuePropertyChange($event, 'title')"
                    />
                </lgflex-form-row>

                <lgflex-form-row [label]="'.Width' | lgTranslate">
                    <input
                        class="input"
                        type="number"
                        min="0"
                        max="400"
                        style="width: 100px"
                        [ngModel]="currentValue.width"
                        (ngModelChange)="_onValuePropertyChange($event, 'width')"
                    />
                </lgflex-form-row>
            </div>

            <lgflex-form-row [label]="'.Field' | lgTranslate">
                <lg-dropdown
                    [definition]="fieldsDropdownDefinition"
                    [current]="currentValue.field"
                    (currentChange)="_onValuePropertyChange($event, 'field')"
                />
            </lgflex-form-row>

            <lgflex-form-row [label]="'.Type' | lgTranslate">
                <lg-dropdown
                    [definition]="_differenceModeDropdown"
                    [current]="currentValue.mode"
                    (currentChange)="_onValuePropertyChange($event, 'mode')"
                />
            </lgflex-form-row>

            <lgflex-form-row [label]="'.ReferenceLeft' | lgTranslate">
                <lg-dropdown
                    [definition]="referenceDropdown!"
                    [(current)]="currentValue.referenceLeft"
                    (currentChange)="_onReferenceUpdate('left', $event)"
                />
            </lgflex-form-row>

            <lgflex-form-row [label]="'.ReferenceRight' | lgTranslate">
                <lg-dropdown
                    [definition]="referenceDropdown!"
                    [(current)]="currentValue.referenceRight"
                    (currentChange)="_onReferenceUpdate('right', $event)"
                />
            </lgflex-form-row>

            <ng-container *ngTemplateOutlet="visualizationWidgetConfiguration" />
        </div>
    }

    @if (currentValue.type === "widget") {
        <div>
            <lgflex-form-row [label]="'.Widget' | lgTranslate">
                <lg-dropdown
                    [definition]="pivotWidgetTypeDropdown"
                    [(current)]="currentValue.widgetType"
                    (currentChange)="_setPivotWidgetType($event)"
                    [hideSearch]="true"
                />
            </lgflex-form-row>
        </div>
    }

    <ng-template #visualizationInlineComponentContainer></ng-template>

    @if (currentValue != null && currentValue.conditionalFormatting !== undefined) {
        <lgflex-conditional-formatting-configuration
            [config]="currentValue.conditionalFormatting"
        />
    }
</div>

<ng-template #visualizationWidgetConfiguration>
    <div class="divider"></div>
    <lgflex-form-row [label]="'.Visualization' | lgTranslate">
        <div class="form-row__controls">
            <lg-dropdown
                [hideSearch]="true"
                [emptyAcceptable]="true"
                [definition]="_visualizationWidgetTypeDropdown"
                [current]="_getCurrentVisualization()"
                (currentChange)="_setVisualizationWidgetType($event)"
            />
        </div>
    </lgflex-form-row>
</ng-template>
