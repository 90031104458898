import { LgTranslateService } from "@logex/framework/lg-localization";
import { translateNullableName } from "./translateNullableName";
import { dropdownFlat } from "@logex/framework/utilities";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { ReferenceInfo, ReferenceSlot } from "../types";

export interface DropdownReferenceSlot {
    reference: number;
    name: string;
}

export function getDropdownReferenceSlots(
    slots: ReferenceSlot[],
    references: ReferenceInfo[],
    lgTranslate: LgTranslateService
): DropdownReferenceSlot[] {
    return slots.map((x, i) => {
        const ref = references.find(r => r.code === x.referenceCode);
        let name;
        if (ref !== undefined && (ref.name !== null || ref.nameLc !== null)) {
            name = translateNullableName(lgTranslate, ref.name, ref.nameLc, "-");
        }

        return {
            reference: i,
            name:
                name !== undefined
                    ? `${lgTranslate.translate(".Reference")} ${i + 1} - ${name}`
                    : `${lgTranslate.translate(".Reference")} ${i + 1}`
        };
    });
}

export function getReferenceDropdownDefinition(
    referenceSlots: DropdownReferenceSlot[]
): IDropdownDefinition<number> {
    return dropdownFlat({
        entryId: "reference",
        entryName: "name",
        entries: referenceSlots
    });
}
