import { InjectionToken, Signal } from "@angular/core";

export interface DataSource {
    code: string;
    name: string | null;
    nameLc: string | null;
    groupCode: string | null;
    groupName: string | null;
    groupNameLc: string | null;
    rootUrl: string;
}

export interface DataSourceServiceState {
    availableDataSources: DataSource[];
    selectedDataSource: DataSource;
}

export interface DataSourcesServiceConfiguration {
    url: string;
    clientId: number;
    scenarioId: number;
}

export interface IFlexDataSources {
    readonly dataSources: DataSource[];
    readonly dataSourcesSignal?: Signal<DataSource[]>;
}

export const LG_FLEX_DATA_SOURCES_SERVICE = new InjectionToken<IFlexDataSources>(
    "lgFlexDataSourcesService"
);
