import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import { Injectable, OnDestroy } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { LgConsole } from "@logex/framework/core";

import {
    LoaderArguments as LoadManagerArguments,
    LoaderCallback as LoadManagerLoader,
    LoaderConfiguration as ILoadManagerLoader
} from "./configuration";
import { ILoadersCollection, Loader } from "./loader";

type RemapTuple<T extends any[] = any[], N = string> = T extends [infer T1]
    ? [N]
    : T extends [infer T1, ...infer T2]
        ? [N, ...RemapTuple<T2, N>]
        : T extends any[]
            ? N[]
            : never;

/**
 * Important: LoadManager cannot be received by dependency injection, because it
 * should not be a singleton.
 */
@Injectable( { providedIn: "root" } )
export class LoadManager implements ILoadersCollection, OnDestroy {

    // ----------------------------------------------------------------------------------
    // Fields
    private _loaders: Dictionary<Loader> = {};

    // ----------------------------------------------------------------------------------
    //
    constructor(
        private _lgConsole: LgConsole,
    ) {
        this._lgConsole = _lgConsole.withSource( "Logex.Application.LoadManager" );
    }


    ngOnDestroy(): void {
        _.each( this._loaders, x => {
            x.destroy();
        } );
        this._loaders = {};
    }


    /**
     * Adds a named data loader.
     *
     * @param name Identifying name of the loader.
     * @param cfg The loader definition
     */
    addLoader( name: string, cfg: ILoadManagerLoader ): Loader {
        const loader = new Loader( this, name, cfg, this._lgConsole );
        this._loaders[name] = loader;
        return loader;
    }


    /**
     * Adds a set of loaders at once.
     *
     * Usage example:
     * ```typescript
     * const firstReferenceId = () => this.firstReferenceInfo ? this.firstReferenceInfo.id : 999;
     * const secondReferenceId = () => this.secondReferenceInfo ? this.secondReferenceInfo.id : 999;
     * const filterArgs = {
     * 	specialism: () => this.selectedSpecialism
     * 		? [this.selectedSpecialism.specialisme]
     * 		: this.filter.getIntIds( "specialism" ),
     * 	diagnosisGroup: () => this.filter.getStringIds( "diagnosisGroup" ),
     * 	diagnosis: () => this.selectedDiagnosis
     * 		? [this.selectedDiagnosis.diagnose]
     * 		: this.filter.getStringIds( "diagnosis" ),
     * 	tariff: () => this.filter.getIntIds( "tariff" ),
     * 	headGroup: () => this.filter.getIntIds( "headGroup" ),
     * 	productGroup: () => this.filter.getIntIds( "productGroup" ),
     * 	product: () => this.selectedProduct
     * 		? [this.selectedProduct.zorgproduct]
     * 		: this.filter.getIntIds( "product" ),
     * 	productCluster: () => this.filter.getIntIds( "productCluster" ),
     * 	zpk: () => this.filter.getIntIds( "zpk" ),
     * 	activity: () => this.filter.getIntIds( "activity" ),
     * 	activityCategory: () => this.filter.getIntIds( "activityCategory" )
     * } as Logex.Application.LoadManagerArgumentsMap;
     *
     * ...
     *
     * this.loadManager.add( {
     *	productActivities: {
     * 		args: [firstReferenceId, secondReferenceId, filterArgs],
     * 		loader: ( firstReferenceId, secondReferenceId, filter ) => ...
     * ```
     *
     *  @param loaders A dictionary of loaders definitions, where field name will be used
     *    as a loader name.
     */
    add( loaders: Dictionary<ILoadManagerLoader | LoadManagerLoader> ): Dictionary<Loader> {
        const loadersNormalized: Dictionary<ILoadManagerLoader> = _.mapValues( loaders,
            x => {
                if ( _.isFunction( x ) ) {
                    return {
                        loader: x
                    } as ILoadManagerLoader;
                } else {
                    return x as ILoadManagerLoader;
                }
            } );

        const res: Dictionary<Loader> = {};

        _.each( loadersNormalized, ( loader, name ) => {
            res[name] = this.addLoader( name, loader );
        } );

        return res;
    }


    /**
     * Load a set of loaders identified by names. If certain loader was already executed
     * with the same arguments that are actual now, it won't be executed second time.
     * So you can execute [[load]] as ofthen as necessary, it won't issue unneeded requests.
     *
     * If a loader is being executed at the time of issuing another load request, the first
     * loading will be cancelled.
     *
     * @param names Loaders to execute.
     */
    load<T extends any[] = [], K extends string[] = T extends [] ? string[] : RemapTuple<T>>(
        ...names: K
    ): Observable<T extends [] ? RemapTuple<K, any> : T> {
        return this.loadDatasets(names, false, null);
    }


    /**
     * Execute one specific loader, using additional arguments. Given arguments do not fully replace
     * the arguments that were specified in the loader configuration, but rather get applied on top.
     * Since the loader is always only once, the result is also not an array like in [[load]] but just
     * a single object.
     *
     *  @param name Name of the loader.
     * @param argsOverride Array of arguments in the same form that is used in loader configuration.
     *      If certain array item is specified (not undefined), then it can either replace the original
     *      (if it a function or constant) or be overlayed on top of the original argument (if it is a
     *      dictionary)
     */
    loadParamsOverride(name: string, argsOverride: LoadManagerArguments | null): Observable<any> {
        return this.loadDatasets([name], false, argsOverride).pipe(map(([data]) => data));
    }


    /**
     * Acts just like [[load]], but doesn't check last loaded arguments. So that data sets
     * loaders would be executed unconditionally.
     *
     *  @param names
     */
    reload<T extends any[] = [], K extends string[] = T extends [] ? string[] : RemapTuple<T>>(
        ...names: K
    ): Observable<T extends [] ? RemapTuple<K, any> : T> {
        return this.loadDatasets(names, true, null);
    }


    /**
     * Execute all registered loaders. If something was already loaded, it will be skipped.
     */
    loadAll() {
        return this.load( ..._.map( this._loaders, ( loader, name ) => name ) );
    }


    /**
     * Execute all registered loaders unconditionally.
     */
    reloadAll() {
        return this.reload( ..._.map( this._loaders, ( loader, name ) => name ) );
    }


    hasLoader( name: string ): boolean {
        return this._loaders[name] != null;
    }


    getLoader( name: string ): Loader {
        const loader = this._loaders[name];
        if ( loader ) return loader;

        throw Error( `Unknown loader "${name}"` );
    }

    loadDatasets<
        T extends any[] = [],
        K extends string[] = T extends [] ? string[] : RemapTuple<T>
        >(
        names: K,
        forceReload: boolean,
        argsOverride: LoadManagerArguments | null = null
    ): Observable<T extends [] ? RemapTuple<K, any> : T> {
        this._lgConsole.debug(`Request to load [${names.join(",")}]`);
        return forkJoin(
            _.map(names, name => this.getLoader(name).load(argsOverride, forceReload))
        ) as any;
    }


    /**
     * Indicates that certain loader is being executed now. Useful to display loading
     * indicators.
     *
     *  @param name Name of the loader to check.
     */
    isLoading( name: string ): boolean {
        const loader = this.getLoader( name );
        return loader.isLoading;
    }


    isLoadingAsObservable( name: string ): Observable<boolean> {
        const loader = this.getLoader( name );
        return loader.isLoadingAsObservable;
    }


    /**
     * Indicates that certain dataset was loaded at least once and is not loading right now.
     *
     *  @param name
     */
    isLoaded( name: string ): boolean {
        const loader = this.getLoader( name );
        return loader.isLoaded;
    }


    /**
     * Cancels the dataset loading if it is still active.
     *
     *  @param name The dataset name to cancel.
     */
    cancel( name: string ) {
        const loader = this.getLoader( name );
        loader.cancel();
    }


    /**
     * Gets last call's params for the given data set.
     *
     *  @param name Name of the data set
     */
    getLastArgs( name: string ) {
        const loader = this.getLoader( name );
        return loader.lastArgs;
    }


    /**
     * Clears the arguments for the last loaded data sets, so that the set will be reloaded upon the next request
     *
     *  @param names Names of the loaders.
     */
    clearArgs( ...names: string[] ) {
        _.each( names, name => {
            const loader = this.getLoader( name );
            loader.clearLastArgs();
        } );
    }


    /**
     * Cancel active requests and flushes cached loaders
     */
    flush(): void {
        _.each( this._loaders, x => {
            x.cancel();
            x.clearLastArgs();
        } );
    }


    dataAsObservable( name: string ): Observable<any> {
        const loader = this.getLoader( name );
        return loader.dataAsObservable();
    }


    getObservedLoaders(): string[] {
        return _.map(
            _.filter( this._loaders, ( x ) => x.isObserved() ),
            ( x ) => x.name
        );
    }


    getStoredResult( name: string ): any {
        const loader = this.getLoader( name );
        return loader.getStoredResult();
    }

}
