/* eslint-disable */
export enum FlexibleIcons {
    Ambulance = "icon-ambulance",
    EditWidget = "icon-edit-widget",
    FourPanelsLayout = "icon-four-panels-layout",
    LayoutEditor = "icon-layout-editor",
    OneLayout = "icon-one-layout",
    ThreePanelsBottomLayout = "icon-three-panels-bottom-layout",
    ThreePanelsLeftLayout = "icon-three-panels-left-layout",
    ThreePanelsRightLayout = "icon-three-panels-right-layout",
    ThreePanelsTopLayout = "icon-three-panels-top-layout",
    TwoHorizontalPanelsLayout = "icon-two-horizontal-panels-layout",
    TwoVerticalPanelsLayout = "icon-two-vertical-panels-layout",
    Ward = "icon-ward"
}
