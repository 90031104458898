import { LgFilterSet } from "@logex/framework/lg-filterset";
import { LgPivotInstance, SelectedRow } from "@logex/framework/lg-pivot";
import { EmbeddedWidget } from "./widget.types";
import { ConditionalFormattingConfig } from "../components/conditional-formatting/conditional-formattings.types";

export type PivotTableColumn =
    | PivotTableColumnDefault
    | PivotTableColumnDifference
    | PivotTableColumnWidget
    | PivotTableColumnFormula;

export interface PivotTableColumnVisualizationWidget extends EmbeddedWidget {}

export interface PivotTableColumnShared {
    title?: string;
    width?: number;
    isEnabled?: boolean;
    visualizationWidget?: PivotTableColumnVisualizationWidget;
    conditionalFormatting?: ConditionalFormattingConfig;
}

export interface PivotTableColumnDefault extends PivotTableColumnShared {
    type: "default";
    field: string;
    referenceIdx?: number;
}

export type PivotTableColumnDifferenceMode = "diff" | "growth";

export interface PivotTableColumnDifference extends PivotTableColumnShared {
    type: "difference";
    field: string;
    referenceLeft: number;
    referenceRight: number;
    mode: PivotTableColumnDifferenceMode;
}

export interface PivotTableColumnWidget extends PivotTableColumnShared {
    type: "widget";
    widgetType: string;
    config?: object;
}

export enum PivotTableColumnFormulaType {
    A = "A",
    AMinusB = "AMinusB",
    APlusB = "APlusB",
    ADividedB = "ADividedB",
    ADividedB_MinusC = "ADividedB_MinusC",
    AMultiplyBMinusC = "AMultiplyBMinusC",
    PercentOfParent = "PercentOfParent",
    PercentOfTotal = "PercentOfTotal",
    ADivideBDivideC = "ADivideBDivideC",
    ADivideBMultiplyC = "ADivideBMultiplyC"
}

export const pivotTableColumnFormulaTypeLabels: Partial<
    Record<PivotTableColumnFormulaType, string>
> = {
    [PivotTableColumnFormulaType.A]: "A",
    [PivotTableColumnFormulaType.AMinusB]: "A - B",
    [PivotTableColumnFormulaType.APlusB]: "A + B",
    [PivotTableColumnFormulaType.ADividedB]: "A / B",
    [PivotTableColumnFormulaType.ADividedB_MinusC]: "(A / B) - C",
    [PivotTableColumnFormulaType.AMultiplyBMinusC]: "A * (B - C)",
    [PivotTableColumnFormulaType.PercentOfParent]: "A / Parent(A)",
    [PivotTableColumnFormulaType.PercentOfTotal]: "A / Total(A)",
    [PivotTableColumnFormulaType.ADivideBDivideC]: "A / B / C",
    [PivotTableColumnFormulaType.ADivideBMultiplyC]: "A / B * C"
};

export const commonFormulas = [
    PivotTableColumnFormulaType.A,
    PivotTableColumnFormulaType.AMinusB,
    PivotTableColumnFormulaType.APlusB,
    PivotTableColumnFormulaType.ADividedB,
    PivotTableColumnFormulaType.ADividedB_MinusC,
    PivotTableColumnFormulaType.AMultiplyBMinusC,
    PivotTableColumnFormulaType.ADivideBDivideC,
    PivotTableColumnFormulaType.ADivideBMultiplyC
];

export type PivotTableColumnFormulaVariableNames = "a" | "b" | "c";

export type PivotTableColumnFormulaInput<V = PivotTableColumnFormulaVariableNames> =
    | PivotTableColumnFormulaVariable<V>
    | PivotTableColumnFormulaConstant<V>;

export type PivotTableColumnFormulaInputBase<V = PivotTableColumnFormulaVariableNames> = {
    variable: V;
};

export type PivotTableColumnFormulaVariable<V = PivotTableColumnFormulaVariableNames> =
    PivotTableColumnFormulaInputBase<V> & {
        type?: "variable";
        field: string;
        reference?: number; // must be optional
    };

export type PivotTableColumnFormulaConstant<V = PivotTableColumnFormulaVariableNames> =
    PivotTableColumnFormulaInputBase<V> & {
        type: "constant";
        constant: number;
    };

export type PivotTableColumnFormulaFormatting = "float" | "money" | "percentage";

export interface PivotTableColumnFormula extends PivotTableColumnShared {
    type: "formula";
    id?: number;
    formula: PivotTableColumnFormulaType;
    variables: {
        [V in PivotTableColumnFormulaVariableNames]?: PivotTableColumnFormulaInput<V>;
    };
    formatType: PivotTableColumnFormulaFormatting;
    formatPrecision: number;
    formatForceSign?: boolean;
}

export const FORMAT_PRECISION_MIN = 0;
export const FORMAT_PRECISION_MAX = 4;

export interface PivotContext {
    filters: LgFilterSet<any, any>;
    selectedRow: SelectedRow<any, any> | null;
    pivot: LgPivotInstance | null;
}
