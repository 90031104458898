<ng-container *lgPanelHeader="let context; order: -1">
    <div
        *ngIf="_chartCurrentLevel > 0"
        class="lg-reference-dropdown-side-icon lg-reference-dropdown-side-icon--round-corners"
    >
        <lg-icon
            [class.disabled]="_isLoading$ | async"
            icon="icon-go-back"
            (click)="_chartGoUp()"
            [clickable]="true"
        ></lg-icon>
    </div>
</ng-container>

<ng-container *lgPanelHeader="let context; order: 1">
    <lgflex-widget-common-header
        [config]="_config"
        [isDataComplete]="_isDataComplete"
        [isCalculating]="_isCalculating$"
        [calculationProgress]="_calculationProgress$"
    ></lgflex-widget-common-header>
</ng-container>

<ng-container *lgPanelIcons="let context; order: 1; whenMinimized: false">
    <lgflex-widget-common-icons
        [exportDisabled]="_chartLevelData.length === 0"
        [isLoading$]="_isLoading$"
        [isAutoConverted]="_isAutoConverted"
        [isConfigValid]="_isConfigValid"
        (export)="_export($event)"
        (edit)="showConfigurationUi()"
    ></lgflex-widget-common-icons>
</ng-container>

<ng-container *ngIf="_isConfigValid">
    <lgflex-stale-data-handler
        [state]="_isCalculating$ | async"
        [progress]="_calculationProgress$ | async"
    />

    <div class="flexcol" *ngIf="_pivot != null; else pivotIsNull">
        <lgflex-stale-data-handler
            [state]="_isCalculating$ | async"
            [progress]="_calculationProgress$ | async"
        />

        <lg-rectangle
            class="flex-flexible"
            #rect
            style="overflow: hidden; display: flex; align-items: center; justify-content: center"
            [lgLoaderOverlay]="(_isLoading$ | async) ?? false"
            [lgLoaderOverlayDelay]="100"
        >
            <lg-compare-pie-chart
                style="display: block"
                *ngIf="_chartLevelData.length > 0 && rect.width > 0 && rect.height > 0"
                #lgComparePieChart
                [data]="_chartLevelData"
                [width]="rect.width || 800"
                [height]="_getMin([rect.height, 500])"
                [groupNames]="_chartGetGroupNames"
                [groupValues]="_chartGetGroupValues"
                [formatterType]="_chartValueFormatter"
                [formatterOptions]="{ decimals: _chartValueDecimals }"
                (itemClick)="_chartOnClick($event)"
                [clickable]="true"
                [tooltipTemplate]="tooltip"
                [valueName]="_chartGetLabel"
                [noActiveItemsOpacity]="true"
                [valueOpacityFn]="_chartGetColumnOpacity"
                [itemsLimit]="19"
                [noTransition]="true"
            ></lg-compare-pie-chart>

            <div *ngIf="_chartLevelData?.length === 0">
                {{ "_Flexible.Widgets.NoDataWarning" | lgTranslate }}
            </div>
        </lg-rectangle>

        <ng-template #tooltip [lgChartTemplateContextType]="lgComparePieChart" let-context>
            <div class="tooltip-container">
                <div class="lg-tooltip__title">
                    {{
                        context.data.item != null
                            ? _chartGetName(context.data.item)
                            : context.data.valueName
                    }}
                </div>
                <div class="lg-tooltip__table" style="width: 100%">
                    <div class="lg-tooltip__table-row">
                        <div class="lg-tooltip__table-column lg-tooltip__table-column--name">
                            {{
                                _chartCurrentLevelKeyField?.name ??
                                    (_chartCurrentLevelKeyField?.nameLc
                                        ? (_chartCurrentLevelKeyField!.nameLc! | lgTranslate)
                                        : "")
                            }}
                        </div>
                    </div>
                    <div class="lg-tooltip__table-row">
                        <div class="lg-tooltip__table-column lg-tooltip__table-column--name">
                            {{
                                _chartValueFieldName ??
                                    (_chartValueFieldNameLc
                                        ? (_chartValueFieldNameLc | lgTranslate)
                                        : "")
                            }}:
                        </div>
                        <div
                            class="lg-tooltip__table-column lg-tooltip__table-column--value"
                            [innerHTML]="
                                context.data.value
                                    | fmtType: _chartValueFormatter : _chartValueDecimals
                                    | lgMarkSymbols
                            "
                        ></div>
                    </div>
                    <div class="lg-tooltip__table-row lg-tooltip__table-row--emphasize">
                        <div class="lg-tooltip__table-column lg-tooltip__table-column--name">
                            {{ ".Share" | lgTranslate }}
                        </div>
                        <div
                            class="lg-tooltip__table-column lg-tooltip__table-column--value"
                            [innerHTML]="context.fraction | fmtPercent: 1 | lgMarkSymbols"
                        ></div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>

    <ng-template #pivotIsNull>
        <div class="flexcol" [lgLoaderOverlay]="(_isLoading$ | async) ?? false"></div>
    </ng-template>
</ng-container>

<div
    class="flexcol flexcol--full flexcol--center"
    *ngIf="!_isConfigValid"
    style="justify-content: center"
>
    {{ "_Flexible.Widgets.WidgetValidationError" | lgTranslate }}
</div>
