<ng-template [ngIf]="vm$ | async" let-vm>
    @if (sectionTitle()) {
        <lgflex-form-section-header>{{ sectionTitle() }}</lgflex-form-section-header>
    }
    @if (vm.hasColumns !== null) {
        <div
            class="form-section__content"
            [ngClass]="{
                'form-section__content--row': vm.hasColumns === true,
                'form-section__content--column': vm.hasColumns === false
            }"
        >
            <ng-content />
        </div>
    }
</ng-template>

@if (hideDivider === false) {
    <div class="divider"></div>
}
