<ng-template #headerTemplate>
    <span
        *ngFor="let label of labels$ | async; index as i"
        tabindex="0"
        class="lg-panel__header-button"
        [class.lg-panel__header-button--active]="(activeTabIndex$ | async) === i"
        (click)="activeTabIndex = i"
        (mousedown)="$event.stopPropagation()"
    >
        {{ label }}
    </span>
</ng-template>

<div class="content">
    <ng-container *ngIf="(tabs$ | async).length !== 0; else content">
        <ng-container *ngTemplateOutlet="portal$ | async"></ng-container>
    </ng-container>
    <ng-template #content><ng-content /></ng-template>
</div>
