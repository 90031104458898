import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LgApplicationModule } from "@logex/framework/lg-application";
import { LgLayoutModule } from "@logex/framework/lg-layout";
import { LoadManagerModule } from "@logex/load-manager";

import {
    LgConfigurationLayoutComponent,
    LgConfigurationLayoutNavigationComponent,
    LgConfigurationLayoutRowControlComponent
} from "./components";

@NgModule({
    imports: [
        CommonModule,
        LgLocalizationModule,
        UiCoreModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        LgLayoutModule,
        LoadManagerModule,
        LgApplicationModule
    ],
    exports: [
        LgConfigurationLayoutComponent,
        LgConfigurationLayoutNavigationComponent,
        LgConfigurationLayoutRowControlComponent
    ],
    declarations: [
        LgConfigurationLayoutComponent,
        LgConfigurationLayoutNavigationComponent,
        LgConfigurationLayoutRowControlComponent
    ]
})
export class LgConfigurationLayout {
    // empty
}
