<div class="table__column-group__top">
    {{ headerCell().title | modReferenceVariable }}
</div>

<div class="table__column-group__bottom">
    @for (child of headerCell().children; track child.id) {
        <lgflex-flexible-drilldown-table-header-row
            [headerCell]="child"
            [lgCol]="child.id"
            [sortBy]="columnSortBy()"
            (orderByChange)="onOrderByChange.emit($event)"
            (differenceColumnChange)="onDifferenceColumnChange.emit($event)"
        />
    }
</div>
