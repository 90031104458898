<div
    class="lg-pivot-levels-selector__wrapper"
    [class.lg-pivot-levels-selector__wrapper--has-border]="hideBorder === false"
    #wrapper
>
    <div lgScrollable cdkDropList style="height: 100%" [lgScrollableWrapper]="false" #scroller>
        <ng-container *ngFor="let table of _tablesConfig(); let idx = index">
            <!-- Render dimension entry -->
            <div
                class="lg-pivot-levels-selector__row table__row"
                [class.lg-pivot-levels-selector__row--current]="
                    table.id === _currentSelection().tableId && !_currentSelection().dimensionId
                "
                cdkDrag
                [cdkDragData]="table"
                (click)="onTableClick(table)"
                (cdkDragDropped)="onTableDrop($event)"
                cdkDragHandle
            >
                <div
                    class="table__column table__column--icons"
                    [style.width.px]="24"
                    [style.flex-shrink]="0"
                >
                    <lg-icon
                        icon="icon-draggable"
                        class="lg-pivot-levels-selector__drag-icon table__row__hover-only"
                    />
                </div>

                <span class="flexbox">
                    @if (table.dimensions.length) {
                        <lg-icon class="lg-icon-menu hoverable" icon="icon-table" />
                    }
                    @if (!table.dimensions.length) {
                        <lg-icon
                            icon="icon-warning"
                            type="warning"
                            [lgTooltip]="'.TableDimensionWarningMessage' | lgTranslate"
                        />
                    }
                </span>
                <div class="lg-pivot-levels-selector__content crop-text" [title]="table.name">
                    {{ table.name }}
                </div>
                <div
                    class="lg-pivot-levels-selector__icons lg-pivot-levels-selector__row__hover-only"
                >
                    <lg-icon
                        class="lg-icon-menu hoverable"
                        icon="icon-delete"
                        [lgTooltip]="'.Delete' | lgTranslate"
                        (click)="_removeTable(table.id, idx)"
                    />
                </div>
            </div>

            <!-- Render nested levels within the dimension -->
            <ng-container *ngFor="let dimension of table.dimensions; let idx = index">
                <div
                    class="lg-pivot-levels-selector__row table__row"
                    [class.lg-pivot-levels-selector__row--current]="
                        dimension.id === _currentSelection().dimensionId
                    "
                    cdkDrag
                    [cdkDragData]="dimension"
                    (click)="onDimensionClick($event, dimension, table)"
                    (cdkDragDropped)="onDimensionDrop($event)"
                    cdkDragHandle
                >
                    <div class="lg-pivot-levels-selector__spacer" style="width: 21px"></div>
                    <div
                        class="table__column table__column--icons"
                        [style.width.px]="24"
                        [style.flex-shrink]="0"
                    >
                        <lg-icon icon="icon-draggable" class="table__row__hover-only" />
                    </div>

                    @if (!dimension.isValid) {
                        <lg-icon
                            icon="icon-recalculation-err"
                            class="lg-pivot-levels-selector__error-icon"
                        />
                    }

                    <div
                        class="lg-pivot-levels-selector__content crop-text"
                        [title]="dimension.name"
                    >
                        {{ dimension.name | dimensionName: dimension.schemaName }}
                    </div>

                    <div
                        class="lg-pivot-levels-selector__icons lg-pivot-levels-selector__row__hover-only"
                    >
                        <lg-icon
                            class="lg-icon-menu hoverable"
                            icon="icon-delete"
                            [lgTooltip]="'.Delete' | lgTranslate"
                            (click)="_removeDimension(table, idx)"
                        />
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
<ng-container *lgPlaceholderContent="buttonPlaceholderName">
    <div style="display: flex">
        <lg-dropdown
            (activeChange)="_dimensionsDropdownActiveChange($event)"
            [definition]="_dimensionsDropdownDefinition()"
            [current]="null"
            (currentChange)="_onDimensionSelect($event)"
            [emptyAcceptable]="true"
            [condensed]="true"
            [style.visibility]="_isDimensionsDropdownActive() ? 'visible' : 'hidden'"
            class="flex-flexible"
            className="lg-dropdown lg-dropdown--condensed"
            style="position: absolute"
            #dimensionsDropdown
        />
        <lg-button
            [buttonClass]="'button button--condensed'"
            textLc=".AddTable"
            icon="icon-add"
            (click)="_addTable()"
        />
        <lg-button
            style="margin-left: 5px"
            [isDisabled]="_addDimensionBtnDisabled()"
            [buttonClass]="'button button--condensed'"
            textLc=".AddDimension"
            icon="icon-add"
            (click)="_addDimension()"
        />
        @if (_addDimensionBtnDisabled()) {
            <lg-icon
                icon="icon-info"
                type="info"
                [lgTooltip]="'.NonAggregatedValueInfo' | lgTranslate"
            />
        }
    </div>
</ng-container>
