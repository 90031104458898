export function getExportFilename(exportName: string): string {
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    const safeName = exportName.replace(/[.]/g, "");
    return `${date} - ${safeName}`;
}

export function cloneElementWithStyles(source: HTMLElement): Node {
    const clone = source.cloneNode(true) as HTMLElement;

    const srcElements = <HTMLCollectionOf<HTMLElement>>source.getElementsByTagName("*");
    const dstElements = <HTMLCollectionOf<HTMLElement>>clone.getElementsByTagName("*");

    for (let i = srcElements.length; i--; ) {
        cloneStyles(srcElements[i], dstElements[i]);
    }

    return clone;
}

export function cloneStyles(source: HTMLElement, destination: HTMLElement): void {
    const sourceElementStyles = document.defaultView?.getComputedStyle(source);
    if (sourceElementStyles === undefined) throw Error("Can't get source element styles.");

    destination.style.cssText = Array.from(sourceElementStyles).reduce(
        (str, property) => `${str}${property}:${sourceElementStyles.getPropertyValue(property)};`,
        ""
    );
}
