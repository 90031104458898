import { IAppConfiguration } from "@logex/framework/lg-application";
import { SidebarHelpStandardLinks } from "@logex/framework/lg-layout";

type Environment = "development" | "execution" | "production" | string;

export interface IAppServices {
    authentication: {
        authority: string;
        clientId: string;
        audience: string;
        type?: "auth0" | "generic";
    };

    configuration: string;
    userSettings: string;
    authorization: string;
    application: string;
}

export interface IAppSettings {
    product: string;
    instance: string;
    environment: Environment;

    enableLocalizationEditor: boolean;
    availableLanguages: string;
    defaultLanguage: string;
    fallbackLanguage: string;
    currency: string;

    services: IAppServices;

    applicationInsights: {
        instrumentationKey: string;
        excludeDomains: string[];
    };

    matomo: {
        statUrl: string;
        siteId: number;
        useUserEmail?: boolean;
        cookieDomain?: string;
        siteDomains?: string[];
        enableCrossDomainLinking?: boolean;
    };

    userFlow: {
        contentId: string;
        environmentKey: string;
    };

    helpLinks: SidebarHelpStandardLinks;
}

export class AppConfiguration<TAppSettings extends IAppSettings = IAppSettings>
    implements IAppConfiguration
{
    instance: string;
    environment: Environment;

    applicationInsightsInstrumentationKey: string;
    applicationInsightsExcludeDomains?: string[];
    userflowEnvironmentKey?: string;
    userflowContentId?: string;
    logoutUrl: string;
    switchHospital: boolean;
    switchHospitalUrl: string;
    switchMergedHospitals: boolean;
    testMachine: string;
    applicationRoot: string;

    appSettings: TAppSettings;

    ready: Promise<void>;
    markReady: () => void;

    constructor() {
        this.ready = new Promise<void>(resolve => {
            this.markReady = resolve;
        });
    }
}
