import { FieldInfo, PivotTableColumnFormulaFormatting } from "../types";

const formatTypes: { [K in PivotTableColumnFormulaFormatting]: null } = {
    float: null,
    money: null,
    percentage: null
};

export function getFormatTypeFromValueField(field: FieldInfo): PivotTableColumnFormulaFormatting {
    // eslint-disable-next-line no-prototype-builtins
    if (formatTypes.hasOwnProperty(field.type)) {
        return field.type as PivotTableColumnFormulaFormatting;
    }
    throw Error("Must be value field");
}
