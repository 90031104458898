<div class="form-group">
    <div class="form-row--input form-row">
        <div class="form-row__label">{{ ".Name" | lgTranslate }}:</div>
        <div class="form-row__controls">
            <input class="input input--wide" type="text" [(ngModel)]="_name" [required]="true" />
        </div>
    </div>
</div>
<div class="form-group">
    <div class="form-row--input form-row" *ngFor="let slot of _args.slots; let i = index">
        <div class="form-row__label">{{ ".Reference" | lgTranslate }} {{ i + 1 }}:</div>
        <div class="form-row__controls--flex form-row__controls">
            <div class="form-row__control-column">
                <lg-dropdown
                    [emptyAcceptable]="true"
                    [definition]="_referencesDropdown"
                    [(current)]="_selectedReferenceCodes[i]"
                    [disabled]="slot.isLocked"
                ></lg-dropdown>
            </div>

            <div *ngIf="slot.isLocked">
                <lg-icon
                    icon="icon-lock"
                    style="margin-top: 6px"
                    [lgTooltip]="'.Locked' | lgTranslate"
                ></lg-icon>
            </div>
        </div>
    </div>
</div>
<div class="form-group form-group--buttons">
    <div class="form-row">
        <lg-button
            class="form-row--button-left"
            *ngIf="_isDeletable"
            (click)="_delete()"
            buttonClass="button--error"
            textLc=".Delete"
        ></lg-button>

        <lg-button
            buttonClass="button--primary"
            textLc="FW.SAVE"
            (click)="_save()"
            [isDisabled]="!_isValid"
        ></lg-button>

        <lg-button textLc="FW.CLOSE" (click)="_close()"></lg-button>
    </div>
</div>
