import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import { asyncScheduler, Observable, of, scheduled } from "rxjs";
import { switchMap } from "rxjs/operators";
import { LgConsole } from "@logex/framework/core";
import { IDefinitions } from "@logex/framework/lg-application";
import { ILoadersCollection, Loader, LoaderConfiguration } from "@logex/load-manager";
import { DatasetInfo, SelectDataResponse } from "./types/types";

// ----------------------------------------------------------------------------------
type Filters = Dictionary<unknown[]>;

type FiltersArgument = { filters: Filters };

type FlexibleLoaderArguments = [FiltersArgument & Dictionary<unknown>];

interface SplitArguments {
    filters: Filters;
    other: Array<Dictionary<unknown>>;
}

export interface IFlexibleLoadersCollection extends ILoadersCollection {
    getDatasets(): DatasetInfo[];
}

interface LoadingContext {
    doNotStore?: boolean;
}

// ----------------------------------------------------------------------------------
export class FlexibleLoader<T = any> extends Loader<SelectDataResponse<T>> {
    constructor(
        master: IFlexibleLoadersCollection,
        protected _dataset: DatasetInfo,
        cfg: LoaderConfiguration<SelectDataResponse<T>>,
        protected _definitions: IDefinitions<any>,
        console: LgConsole
    ) {
        super(master, _dataset.name, cfg, console);

        // This loader cannot function w/o stored results
        this._storeResult = true;

        // Generate helper functions
        this._fnCompareRecords = this._generateFnCompareRecords(this._dataset.fields);
        this._fnPickFields = this._generateFnPickFields(this._dataset.fields);
    }

    // ----------------------------------------------------------------------------------
    protected override _master!: IFlexibleLoadersCollection;
    protected override _lastArgs: FlexibleLoaderArguments | undefined;
    protected override _loadingArgs: FlexibleLoaderArguments | undefined;

    protected _fnCompareRecords: (a: unknown, b: unknown) => boolean;
    protected _fnPickFields: (x: unknown) => T;

    // ----------------------------------------------------------------------------------
    protected override _startLoading(
        args: FlexibleLoaderArguments,
        forceReload: boolean,
        context: LoadingContext
    ): Observable<SelectDataResponse<T>> {
        this._console.debug(`Start loading ${this.name} with:`, { args, context });

        const newArgs = this._splitArguments(args);

        const loadDefinitions = this._getDefinitionsToLoad();

        // Check if this dataset was already fully loaded with more relaxed filters
        const filteredData = !forceReload ? this._tryFilterOwnData(newArgs) : null;
        if (filteredData != null) {
            context.doNotStore = true;
            return loadDefinitions.pipe(
                switchMap(() =>
                    scheduled(
                        of({
                            data: filteredData,
                            isComplete: true
                        }),
                        asyncScheduler
                    )
                )
            );
        }

        // Check if there are other datasets with the same fields
        const otherDatasetsData = this._tryFindOtherDatasetsData(newArgs);
        if (otherDatasetsData != null) {
            context.doNotStore = true;
            return loadDefinitions.pipe(
                switchMap(() =>
                    scheduled(
                        of({
                            data: otherDatasetsData,
                            isComplete: true
                        }),
                        asyncScheduler
                    )
                )
            );
        }

        // Load data from the server
        return loadDefinitions.pipe(
            switchMap(() => super._startLoading(args, forceReload, context))
        );
    }

    private _getDefinitionsToLoad(): Observable<void> {
        const toLoad = _.reject(this._dataset.requiredDefinitions, x =>
            this._definitions.isLoaded(x)
        );

        if (_.isEmpty(toLoad)) return of(undefined);

        return this._definitions.load(...toLoad);
    }

    private _splitArguments(args: FlexibleLoaderArguments): SplitArguments {
        const [first, ...rest] = args;
        const filters = first?.filters ?? {};
        const other = [_.omit(first, "filters"), ...rest];
        return { filters, other };
    }

    private _isArgumentsSubsetOf(newArgs: SplitArguments, oldArgs: SplitArguments): boolean {
        // Check that non-filter arguments were not changed
        if (!_.isEqual(newArgs.other, oldArgs.other)) {
            return false;
        }

        // Make pairs of old and new filter values
        const filters: Array<{
            name: string;
            newValue: unknown[] | null;
            oldValue: unknown[] | null;
        }> = _.map(newArgs.filters, (value, name) => ({
            name,
            newValue: value,
            oldValue: oldArgs.filters[name] ?? null
        }));

        _.each(oldArgs.filters, (value, name) => {
            if (value != null && !_.has(newArgs.filters, name)) {
                filters.push({
                    name,
                    newValue: null,
                    oldValue: value
                });
            }
        });

        return _.every(filters, filter => {
            // If old filter was not set -> could be a subset
            if (filter.oldValue == null) return true;

            // If new filter value is cleared -> can not be a subset
            if (filter.newValue == null) return false;

            // Can be a subset if all values from the NEW filter were included in the OLD filter
            return _.every(filter.newValue, x => _.includes(filter.oldValue, x));
        });
    }

    private _tryFilterOwnData(newArgs: SplitArguments): any[] | null {
        if (this.isLoaded && this._results?.isComplete) {
            // Check that dataset has all the fields requested by the filter
            if (
                _.some(newArgs.filters, (v, k) => v != null && !_.includes(this._dataset.fields, k))
            )
                return null;

            if (this._lastArgs == null) throw Error("Arguments shouldn't be undefined.");

            const oldArgs = this._splitArguments(this._lastArgs);
            if (this._isArgumentsSubsetOf(newArgs, oldArgs)) {
                // Get data from the stored result
                this._console.debug("Filtering loaded data on the client");
                return this._filterData(this._results.data, newArgs.filters);
            }
        }

        return null;
    }

    private _tryFindOtherDatasetsData(newArgs: SplitArguments): T[] | null {
        // Do not try to calculate value fields using data from another dataset
        if (_.some(this._dataset.serverFields, x => x.isValueField)) return null;

        const filterFields = _.transform(
            newArgs.filters,
            (a, v, k) => {
                if (v != null) {
                    a.push(k);
                }
                return a;
            },
            [] as string[]
        );
        const requiredFields = _.uniq([...this._dataset.fields, ...filterFields]);

        // Find datasets we can use a data source
        const datasets = _.filter(this._master.getDatasets(), dataset => {
            if ((dataset.loader as any) === this) return false; // Skip self
            if (!dataset.loader.isLoaded) return false; // Skip not loaded

            if (!_.every(requiredFields, x => _.includes(dataset.fields, x))) return false; // Skip datasets w/o needed fields

            // TODO: Should this actually happen with DoNotStore? Maybe we can instead filter for both isLoaded and values above
            if (dataset.loader._lastArgs == null)
                throw Error("Dataset loader last arguments shouldn't be undefined.");
            const datasetArgs = this._splitArguments(dataset.loader._lastArgs);

            // TODO: Take into account records limit. If limits are different, but loaded dataset is fully loaded then it's usable

            return this._isArgumentsSubsetOf(newArgs, datasetArgs);
        });

        if (datasets.length === 0) return null;

        // Use source dataset with the smallest amount of records and fields.
        const sortedDatasets = _.sortBy(
            datasets,
            x => x.loader._results?.data.length,
            x => x.fields.length
        );

        const dataset = _.first(sortedDatasets);
        if (dataset?.loader._results == null)
            throw Error("Dataset loader results shouldn't be undefined.");

        this._console.debug(`Getting data from dataset ${dataset.name}`);

        const filteredData = this._filterData(dataset.loader._results.data, newArgs.filters);

        // The dataset found can only have some more fields than requested. If fields arrays
        // have the same lenght, then all the fields are the same. We can use this data as-is.
        if (dataset.fields.length === requiredFields.length) {
            return filteredData;
        }

        const uniqueRecords = _.uniqWith(filteredData, this._fnCompareRecords);
        return _.map(uniqueRecords, this._fnPickFields);
    }

    private _filterData(data: any[], filters: Filters): any[] {
        const filtersArray = _.map(
            filters,
            (v, k) => [k, new Set(v)] as readonly [string, Set<unknown>]
        );
        return _.filter(data, x =>
            _.every(filtersArray, ([name, values]) => {
                if (values == null) return true;
                return values.has(x[name]);
            })
        );
    }

    protected override _onDataLoaded(res: SelectDataResponse<T>, context: LoadingContext): void {
        this._console.debug(`Loaded "${this.name}"`);
        if (!context.doNotStore) {
            this._lastArgs = _.cloneDeep(this._loadingArgs);
            this._results = res;
        }
        this._sendDataToObservers(res);
    }

    private _generateFnCompareRecords(fields: string[]): (a: unknown, b: unknown) => boolean {
        const body = `return ${_.map(fields, field => `a.${field} === b.${field}`).join("&&")};`;
        // eslint-disable-next-line no-new-func
        return new Function("a", "b", body) as any;
    }

    private _generateFnPickFields(fields: string[]): (x: unknown) => T {
        const body = `return {${_.map(fields, field => `"${field}": x.${field}`).join(",")}};`;
        // eslint-disable-next-line no-new-func
        return new Function("x", body) as any;
    }
}
