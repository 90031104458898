import { computed, inject, Injectable, signal, Injector, INJECTOR } from "@angular/core";
import * as _ from "lodash-es";
import { firstValueFrom } from "rxjs";
import { IDefinitions, LG_APP_DEFINITIONS, LG_APP_SESSION } from "@logex/framework/lg-application";
import { LgFiltersPanelService } from "@logex/framework/lg-layout";
import { LgConsole } from "@logex/framework/core";
import { StaleDataService } from "@logex/load-manager";
import { urlConcat } from "@logex/framework/utilities";
import { HandleErrorsMixin } from "@logex/mixins";
import { mixins } from "@logex/mixin-flavors";

import { FieldInfo, MAX_LIMIT_ROWS, ReferenceSlot } from "../../types";
import { FlexibleDataset, FlexibleDatasetDataArgumentsGetter } from "../flexible-dataset";
import {
    IFlexDataSources,
    LG_FLEX_DATA_SOURCES_SERVICE
} from "../flex-data-sources/flex-data-sources.types";
import { FlexDataClientService } from "../flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../page-references/page-references.service";
import { FlexDataClientGateway } from "../flex-data-client/gateway/flex-data-client-gateway";
import { isDefinitionTypeField } from "../../utilities/isDefinitionTypeField";
import { FlexibleFilterFactory } from "../flexible-filter-factory/flexible-filter-factory";
import { GetFlexibleDatasetSchemeResponse } from "../flex-data-client/gateway/flex-data-client-gateway.types";
import { DataSourceConfig } from "./flexible-layout-data-sources.types";

export interface FlexibleLayoutDataSourcesService extends HandleErrorsMixin {}

@Injectable()
@mixins(HandleErrorsMixin)
export class FlexibleLayoutDataSourcesService {
    private readonly _session = inject(LG_APP_SESSION);
    private readonly _injector = inject(INJECTOR);
    private readonly _lgConsole = inject(LgConsole);
    private readonly _pageReferences = inject(PageReferencesService);
    private readonly _flexibleFilterFactory = inject(FlexibleFilterFactory);
    private readonly _filtersPanelService = inject(LgFiltersPanelService);
    private readonly _gateway: FlexDataClientGateway = inject(FlexDataClientGateway);
    private readonly _definitions: IDefinitions<any> = inject(LG_APP_DEFINITIONS);
    private readonly _dataSourcesService: IFlexDataSources = inject(LG_FLEX_DATA_SOURCES_SERVICE);
    private readonly _flexDataClient = inject(FlexDataClientService);

    private _loadedDataSources = new Map<string, DataSourceConfig>();
    private _fieldsDatasetMap = new Map<
        string,
        { dataset: FlexibleDataset; dataSourceCode: string }
    >();

    private _dataSourcesBaseConfig = computed(
        () =>
            new Map(
                this._dataSourcesService
                    ?.dataSourcesSignal()
                    ?.map(item => [item.code, item.rootUrl]) ?? []
            )
    );

    private _defaultLayoutDataSourceCode = signal(null);

    defaultLayoutDataSourceCodeSignal = this._defaultLayoutDataSourceCode.asReadonly();

    public async loadMultipleDataSources(dataSourceCodes: string[]): Promise<void> {
        this._loadedDataSources.clear();
        await Promise.all(dataSourceCodes.map(code => this._loadDataSource(code)));
    }

    public updateDefaultLayoutDataSource(dataSourceCode: string): void {
        this._defaultLayoutDataSourceCode.set(dataSourceCode);
    }

    public async getDataSource(dataSourceCode: string): Promise<DataSourceConfig> {
        if (!this._loadedDataSources.has(dataSourceCode)) {
            await this._loadDataSource(dataSourceCode);
        }

        return this._loadedDataSources.get(dataSourceCode);
    }

    public updateDataSourceSelectedReferences(
        widgetId: string,
        dataSourceCode: string,
        selectedReferences: ReferenceSlot[]
    ) {
        const dataSource = this._loadedDataSources.get(dataSourceCode);

        if (dataSource) {
            dataSource.selectedReferences.set(widgetId, selectedReferences);
        }
    }

    public removeDataSourceSelectedReferences(widgetId: string) {
        this._loadedDataSources.forEach((value, key) => {
            const { selectedReferences } = value;

            selectedReferences.delete(widgetId);
        });
    }

    public updateDatasetDefaultArgs(dataArgs: FlexibleDatasetDataArgumentsGetter) {
        this._loadedDataSources.forEach((value, key) => {
            const { dataset } = value;

            dataset.dataArguments = dataArgs;
        });
    }

    // needed only for validation, since it runs out of component context
    public getDataSourceScheme(dataSourceCode: string): FieldInfo[] {
        return this._loadedDataSources.get(dataSourceCode)?.scheme ?? [];
    }

    public getAllDataSourcesSchemes(): FieldInfo[] {
        const allSchemes = new Set<FieldInfo>();
        this._loadedDataSources.forEach((value, key) => {
            const { scheme } = value;

            scheme.forEach(schemeItem => {
                allSchemes.add(schemeItem);
            });
        });

        return [...allSchemes];
    }

    public getDatasetForField(field: string): FlexibleDataset {
        const { dataset, dataSourceCode } = this._fieldsDatasetMap.get(field);

        if (!dataset) {
            this._flexDataClient.dataset.dataArguments.references = () =>
                this._pageReferences.selected;

            return this._flexDataClient.dataset;
        }

        if (dataSourceCode !== this._defaultLayoutDataSourceCode()) {
            const selectedReferences = this.getSelectedReferencesForDataSource(dataSourceCode);

            dataset.dataArguments.references = () => selectedReferences;

            return dataset;
        }

        dataset.dataArguments.references = () => this._pageReferences.selected;

        return dataset;
    }

    private getSelectedReferencesForDataSource(dataSourceCode: string): string[] {
        const { selectedReferences } = this._loadedDataSources.get(dataSourceCode);
        const allReferences = new Set<string>();

        if (selectedReferences) {
            selectedReferences.forEach(references =>
                references.forEach(reference => allReferences.add(reference.referenceCode))
            );
        }

        return [...allReferences];
    }

    private async _loadSchemeAndReferences(
        dataSourceUrl: string
    ): Promise<GetFlexibleDatasetSchemeResponse> | undefined {
        try {
            return firstValueFrom(
                this._gateway.getScheme(dataSourceUrl, {
                    clientId: this._session.clientId,
                    scenarioId: this._session.scenarioId
                })
            );
        } catch (e) {
            this._onException(e);
            return undefined;
        }
    }

    private async _loadDefinitions(fields: FieldInfo[]): Promise<void> {
        const required = fields
            .map(x => (!x.isValueField && isDefinitionTypeField(x) ? x.type : null))
            .filter((x): x is string => x != null);
        try {
            await firstValueFrom(this._definitions.load(...required));
        } catch (err: any) {
            this._lgConsole.error("Error loading definitions", err);
            throw err;
        }
    }

    private _configureDataset(dataSourceUrl: string, fields: FieldInfo[]): any {
        const injector = Injector.create({
            parent: this._injector,
            providers: [
                {
                    provide: StaleDataService,
                    useClass: StaleDataService
                },
                {
                    provide: FlexibleDataset,
                    useClass: FlexibleDataset
                }
            ]
        });

        const _dataset = injector.get<FlexibleDataset>(FlexibleDataset);
        _dataset.metadataArguments = {
            ..._.mapValues(
                { clientId: this._session.clientId, scenarioId: this._session.scenarioId },
                x => () => x
            )
        };
        _dataset.configure({
            dataUrl: urlConcat(dataSourceUrl, "data"),
            maxRecordsLimit: MAX_LIMIT_ROWS,
            fields
        });

        return _dataset;
    }

    private _configureFilters() {
        const allSchemes: FieldInfo[] = this.getAllDataSourcesSchemes();

        const filters = this._flexibleFilterFactory
            .define(this)
            .configure(allSchemes, null)
            .create(this);

        this._filtersPanelService.setSet(filters);
    }

    private _configureFieldsDatasetMap() {
        this._loadedDataSources.forEach((value, dataSourceCode) => {
            const { dataset, scheme } = value;

            const schemeMap = scheme.reduce((acc, schemeItem) => {
                acc.set(schemeItem.field, { dataset, dataSourceCode });
                return acc;
            }, new Map());

            this._fieldsDatasetMap = new Map([...this._fieldsDatasetMap, ...schemeMap]);
        });
    }

    private async _loadDataSource(dataSourceCode: string): Promise<void> {
        try {
            const dataSourceUrl = this._dataSourcesBaseConfig().get(dataSourceCode);
            const scheme = await this._loadSchemeAndReferences(dataSourceUrl);

            if (!scheme) {
                throw new Error(`Scheme for dataSourceCode ${dataSourceCode} is not loaded`);
            }

            await this._loadDefinitions(scheme.fields);

            const dataset = this._configureDataset(dataSourceUrl, scheme.fields);

            this._loadedDataSources.set(dataSourceCode, {
                scheme: scheme.fields,
                references: scheme.references,
                url: dataSourceUrl,
                dataset,
                selectedReferences: new Map()
            });

            this._configureFilters();
            this._configureFieldsDatasetMap();
        } catch (e) {
            this._lgConsole.error(`Error loading data source with code ${dataSourceCode}`, e);
            throw e;
        }
    }
}
