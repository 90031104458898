import {
    ChangeDetectionStrategy,
    Component,
    computed,
    signal,
    WritableSignal
} from "@angular/core";
import {
    PivotTableColumnFormulaFormatting,
    Widget,
    WidgetComponent,
    WidgetUsage
} from "../../../types";
import {
    SINGLE_VALUE_CHART_VISUALIZATION_WIDGET,
    SingleValueChartVisualizationWidgetConfig
} from "./single-value-chart-visualization-widget.types";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { SingleValueChartVisualizationWidgetConfigurator } from "./single-value-chart-visualization-widget.configurator";
import { ILgFormatterOptions } from "@logex/framework/core";
import { LgFormatTypePipe } from "@logex/framework/ui-core";
import { FlexibleLayoutWidgetVisualizationContext } from "../../../components/flexible-layout/flexible-layout.types";

@Component({
    selector: "lgflex-single-value-chart-visualization-widget",
    templateUrl: "./single-value-chart-visualization-widget.component.html",
    viewProviders: [...useTranslationNamespace("_Flexible.SingleValueChartVisualizationWidget")],
    host: {
        class: "flex-flexible",
        style: "width: 100%"
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
    id: SINGLE_VALUE_CHART_VISUALIZATION_WIDGET,
    nameLc: "_Flexible.SingleValueChartVisualizationWidget.WidgetTitle",
    usage: WidgetUsage.Visualization,
    configVersion: 1,
    configurator: SingleValueChartVisualizationWidgetConfigurator
})
export class SingleValueChartVisualizationWidgetComponent
    implements WidgetComponent<FlexibleLayoutWidgetVisualizationContext>
{
    constructor(protected _formatter: LgFormatTypePipe) {}

    private _isAutoConverted = false;
    protected _config: WritableSignal<SingleValueChartVisualizationWidgetConfig | undefined> =
        signal(undefined);

    protected _value: WritableSignal<number | undefined> = signal(undefined);
    protected _maxValue: WritableSignal<number | undefined> = signal(undefined);
    protected _limit = computed(() => {
        return this._config().limit == null ? this._maxValue() : this._config().limit;
    });

    protected _formatType: PivotTableColumnFormulaFormatting = "float";
    protected _formatOptions: ILgFormatterOptions = {};
    protected _formatFn: (value: number | undefined) => string = this._getFormattedValue;

    // --------------------------------------------------------------------------------
    markAsAutoConverted(): void {
        this._isAutoConverted = true;
    }

    setConfig(config: SingleValueChartVisualizationWidgetConfig): void {
        this._config.set(config);
    }

    setContext(context: FlexibleLayoutWidgetVisualizationContext): void {
        this._value.set(context.value);
        this._maxValue.set(context.maxValue);
        this._formatType = context.formatType ?? this._formatType;
        this._formatOptions = context.formatOptions ?? this._formatOptions;

        if (context.formatFn !== undefined) {
            this._formatFn = context.formatFn;
        }
    }

    setId(_id: string | null): void {
        // Not needed
    }

    showConfigurationUi(): Promise<void> {
        return Promise.resolve(undefined);
    }

    getWidgetType(): string {
        return SINGLE_VALUE_CHART_VISUALIZATION_WIDGET;
    }

    // --------------------------------------------------------------------------------
    private _getFormattedValue(value: number | undefined): string {
        return this._formatter.transform(value, this._formatType, this._formatOptions);
    }
}
