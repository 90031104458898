import { Inject, Injectable } from "@angular/core";
import { IAppConfiguration, LG_APP_CONFIGURATION } from "@logex/framework/lg-application";

@Injectable({ providedIn: "root" })
export class FlexibleLayoutStateStorage<T extends {} = {}> {
    constructor(@Inject(LG_APP_CONFIGURATION) private _appConfiguration: IAppConfiguration) {}

    getState(pageKey: string, layoutId: string): T | null {
        const state = sessionStorage.getItem(this._getStateKey(pageKey, layoutId));
        if (state) {
            return JSON.parse(state);
        } else {
            return null;
        }
    }

    setState(pageKey: string, layoutId: string, state: T | null | undefined): void {
        const key = this._getStateKey(pageKey, layoutId);
        if (state == null) {
            sessionStorage.removeItem(key);
        } else {
            sessionStorage.setItem(key, JSON.stringify(state));
        }
    }

    private _getStateKey(pageKey: string, layoutId: string): string {
        return `LayoutState_${this._appConfiguration.instance}_${pageKey}_${layoutId}`;
    }
}
