import * as _ from "lodash-es";
import { IComboFilter2Definition, LgFilterSet } from "@logex/framework/lg-filterset";

export type PrimitiveArray = Array<number | null> | Array<string | null> | Array<boolean | null>;
// TODO: Probably move this somewhere closer to IFilterList

export function getAllFilters(filters: LgFilterSet): Record<string, PrimitiveArray | undefined> {
    const names = _.keys(filters.filters);
    const filtersDictionary: Record<string, PrimitiveArray | undefined> = {};

    return _.transform(
        names,
        (a, x) => {
            a[x] = getFilterIds(x, filters);
            return a;
        },
        filtersDictionary
    );
}

/**
 * Returns an array of IDs selected in a given combo2 filter.
 *
 * @param filterName
 * @param filters
 */
export function getFilterIds(
    filterName: string,
    filters: LgFilterSet<any, any>
): PrimitiveArray | undefined {
    const filterDef = filters.getFilterDefinition(filterName);

    if (filterDef == null) {
        throw Error(`Unknown filter "${filterName}" is requested.`);
    }

    const curValue = filters.filters[filterName];

    if (curValue == null || curValue.$empty) {
        return undefined;
    }

    if (filterDef.filterType !== "combo2") {
        throw Error(`Filter type ${filterDef.filterType} is not supported by getIds()`);
    }

    const idType = (<IComboFilter2Definition<any>>filterDef).idType as string;
    const parser =
        idType === "number"
            ? (x: string) => (x === "null" ? null : parseFloat(x))
            : idType === "boolean"
            ? (x: string) => (x === "null" ? null : x === "true")
            : (x: string) => (x === "null" ? null : x);

    return _.map(curValue, (v, k: string) => parser(k)) as PrimitiveArray;
}
