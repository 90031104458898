import { LgPanelGridDefinition, LgPanelGridLeafDef } from "@logex/framework/lg-layout";

export function* extractPanelIds(
    grid: LgPanelGridDefinition | LgPanelGridLeafDef
): Generator<string> {
    if (grid == null) return;

    if ("columns" in grid) {
        for (const column of grid.columns) {
            yield* extractPanelIds(column);
        }
    } else if ("rows" in grid) {
        for (const row of grid.rows) {
            yield* extractPanelIds(row);
        }
    } else if ("id" in grid) {
        yield grid.id;
    }
}
