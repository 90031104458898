import { Directive, HostBinding, Input, Optional } from "@angular/core";
import { ConditionalFormattingService } from "./services/conditional-formatting.service";

@Directive({
    selector: "[lgflexConditionalFormatting]"
})
export class ConditionalFormattingDirective {
    @Input("conditionalFormattingDisabled") disabled = false;
    @Input("conditionalFormattingValue") value: number | undefined | null;
    @Input("conditionalFormattingLevel") level: string | number | undefined;
    @Input("conditionalFormattingItemId") itemId: string | undefined;

    constructor(@Optional() private _conditionalFormattingService: ConditionalFormattingService) {}

    @HostBinding("class.conditional-formatting") conditionalFormattingClass = !this.disabled;
    @HostBinding("style.--conditional-formatting-background") get styleBackground():
        | string
        | undefined {
        if (
            this.disabled ||
            this.level === undefined ||
            this.itemId === undefined ||
            this.value == null
        )
            return undefined;

        return this._conditionalFormattingService.getColor(this.level, this.itemId, this.value);
    }
}
