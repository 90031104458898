<div style="display: flex">
    @if (_userCanExport | async) {
        <lg-icon-menu
            icon="icon-export"
            class="lg-panel__header-icon"
            [class.disabled]="(isLoading$ | async) || exportDisabled"
            [definition]="_exportDropdownDefinition"
            [highlightSelected]="false"
            (currentChange)="export.emit($event)"
        />
    }

    @if (_userCanEdit | async) {
        <lg-icon
            icon="icon-edit"
            class="lg-panel__header-icon"
            lgTooltip="{{ '_Flexible.Widgets.WidgetConfiguration' | lgTranslate }}"
            [class.disabled]="(isLoading$ | async) && isConfigValid === true"
            (click)="edit.emit()"
        />
    }
</div>
