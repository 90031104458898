import { IconChartWidgetIcons } from "./icons.enum";
import {
    ChartWidgetStateBase,
    ChartWidgetConfigBase
} from "../../base/chart-widget-base/chart-widget-base.types";

export const ICON_CHART_WIDGET = "iconChartWidget";

export interface IconChartWidgetConfig extends ChartWidgetConfigBase {
    icon: IconChartWidgetIcons;
}

export interface IconChartWidgetState extends ChartWidgetStateBase {}
