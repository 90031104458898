import { PivotTableColumnFormula } from "../../types";
import {
    ChartWidgetStateBase,
    ChartWidgetConfigBase
} from "../base/chart-widget-base/chart-widget-base.types";

export const BUBBLE_CHART_WIDGET = "bubbleChartWidget";

export interface BubbleChartWidgetConfig extends ChartWidgetConfigBase {
    xAxisColumnFormula: PivotTableColumnFormula;
    yAxisColumnFormula: PivotTableColumnFormula;
    sizeDimension: BubbleChartDimension;
    chartType: BubbleChartType;
}

export interface BubbleChartDimension {
    field: string;
    reference?: number;
}

export interface BubbleChartDimensionFormatter {
    format: string;
    decimals: number;
    forceSign: boolean;
}

export type BubbleChartType = "classic" | "centered";

export interface BubbleChartWidgetState extends ChartWidgetStateBase {}
