export function exportSvg(svgEl: SVGElement, name: string): void {
    svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svgEl.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    const svgData = replaceUnsupportedHTMLEntities(svgEl.outerHTML);
    const preface = '<?xml version="1.1" standalone="no"?>\r\n';
    const blob = new Blob([preface, svgData], { type: "image/svg+xml;charset=utf-8" });
    const svgUri = URL.createObjectURL(blob);
    triggerFileDownload(svgUri, name);
}

function replaceUnsupportedHTMLEntities(svgContent: string): string {
    return svgContent.replace(/&nbsp;/g, "&#160;");
}

export function exportSvgToPng(svgEl: SVGElement, width: number, name: string): void {
    const b64Start = "data:image/svg+xml;base64,";
    const xml = new XMLSerializer().serializeToString(svgEl);
    const svg64 = btoa(unescape(encodeURIComponent(xml)));

    const svgWidthAttribute = svgEl.getAttribute("width");
    if (svgWidthAttribute === null) throw Error("Can't get svg width attribute.");
    const svgWidth = parseInt(svgWidthAttribute, 10);

    const svgHeightAttribute = svgEl.getAttribute("height");
    if (svgHeightAttribute === null) throw Error("Can't get svg width attribute.");
    const svgHeight = parseInt(svgHeightAttribute, 10);

    const canvasHeight = (width * svgHeight) / svgWidth;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (ctx === null) throw Error("Canvas API not available!");
    canvas.width = width;
    canvas.height = canvasHeight;

    const image = new Image();
    image.onload = () => {
        ctx.drawImage(image, 0, 0, width, canvasHeight);

        const png = canvas.toDataURL();
        triggerFileDownload(png, name);
    };
    image.src = b64Start + svg64;
}

function triggerFileDownload(uri: string, name: string): void {
    const downloadLink = document.createElement("a");
    downloadLink.href = uri;
    downloadLink.download = name;
    downloadLink.click();
    URL.revokeObjectURL(uri);
}
