import { Component, ContentChild, Input, TemplateRef } from "@angular/core";

/**
 * Component that display one row of form.
 * Template contain two parts:
 *      left: Row title and description
 *      right: From control with label
 *
 * @example scenario-settings.component.html in fadp-app-frontend
 */
@Component({
    selector: "lg-configuration-layout-row-control",
    templateUrl: "./lg-configuration-layout-row-control.component.html"
})
export class LgConfigurationLayoutRowControlComponent {
    @Input() rowTitle = "";
    @Input({ required: true }) isDirty: boolean;
    @Input() rowDescription = "";
    @Input() controlLabel = "";

    // Additional row description, for situation if some special styling of description is needed
    @ContentChild("rowAddDescription") rowAddDescription: TemplateRef<any> | undefined = undefined;
    @ContentChild("rowControl") rowControlRef!: TemplateRef<any>;
}
