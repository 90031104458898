import * as _ from "lodash-es";
import { LoaderArguments, LoaderArgumentsMap } from "./configuration";


export function getConcreteArguments( args: LoaderArguments, forceReload: boolean, argsOverride: LoaderArguments | null = null ): unknown[] {

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function mapArray( argsArray: LoaderArguments ) {
        return _.map( argsArray,
            x => {
                if ( _.isFunction( x ) ) return x( forceReload );
                else if ( _.isObject( x ) ) return mapDictionary( x );
                else return x;
            } );
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    function mapDictionary( argsMap: LoaderArgumentsMap ) {
        return _.mapValues( argsMap, x => _.isFunction( x )
            ? x( forceReload )
            : x );
    }

    // Override arguments if requested
    if ( !_.isEmpty( argsOverride ) ) {
        args = _.cloneDeep( args );
        _.each( argsOverride, ( x, i ) => {
            if ( x === undefined ) return;
            if ( _.isFunction( x ) ) {
                args[i] = x;
            } else if ( _.isObject( x ) ) {
                let argsI: any = args[i];
                if ( !_.isObject( argsI ) ) {
                    args[i] = argsI = {};
                }
                _.each( x, ( val, name ) => {
                    if ( val !== undefined ) argsI[name] = val;
                } );
            } else {
                args[i] = x;
            }
        } );
    }

    return mapArray( args );
}
