import { Injectable, ViewContainerRef } from "@angular/core";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { DialogWidgetConfigurator } from "../../types";
import { CrosstabWidgetConfig } from "./crosstab-widget.types";
import { CrosstabConfigurationDialog } from "./components/crosstab-configuration-dialog/crosstab-configuration-dialog.component";
import { validateCrosstabConfiguration } from "./components/crosstab-configuration-dialog/crosstab-configuration-validation";

@Injectable()
export class CrosstabWidgetConfigurator implements DialogWidgetConfigurator<CrosstabWidgetConfig> {
    constructor(
        private _flexDataClient: FlexDataClientService,
        private _pageReferences: PageReferencesService,
        private _dialog: CrosstabConfigurationDialog,
        viewContainerRef: ViewContainerRef
    ) {
        this._dialog = _dialog.bindViewContainerRef(viewContainerRef);
    }

    readonly type = "dialog";

    async show(config: CrosstabWidgetConfig): Promise<CrosstabWidgetConfig> {
        const response = await this._dialog.show({
            title: config.title,
            description: config.description,
            tooltip: config.tooltip,
            tooltipLink: config.tooltipLink,
            horizontalDimension: config.horizontalDimension,
            verticalDimension: config.verticalDimension,
            value: config.value,
            referenceIdx: config.referenceIdx,
            scheme: this._flexDataClient.scheme,
            pageReferences: this._pageReferences,
            sidebarWidth: config.sidebarWidth,
            cellWidth: config.cellWidth
        });

        return {
            ...response
        };
    }

    validate(config: CrosstabWidgetConfig): boolean {
        return validateCrosstabConfiguration(
            config,
            this._flexDataClient.scheme,
            this._pageReferences.slots
        );
    }
}
