import { Component, inject, Output, EventEmitter, Input, OnInit, OnDestroy } from "@angular/core";
import { LgNavigationService } from "@logex/framework/lg-application";
import { LgPanelGridDefinition } from "@logex/framework/lg-layout";
import { Observable, ReplaySubject } from "rxjs";

@Component({
    selector: "lg-configuration-layout",
    templateUrl: "./lg-configuration-layout.component.html",
    host: {
        "[style.width.%]": "100",
        "[style.height.%]": "100"
    }
})
export class LgConfigurationLayoutComponent<TForm extends { [K in keyof TForm]: any }>
    implements OnInit, OnDestroy
{
    @Input({ required: true }) rootNavigationNodeId = "";
    @Input({ required: true }) isFormInvalid = false;
    @Input({ required: true }) isLoading$: Observable<boolean>;
    @Input() useBulkSaveAndRevertActions = true;
    @Input() unsavedChangesCount = 0;

    @Output() readonly saveForm = new EventEmitter<any>();
    @Output() readonly resetForm = new EventEmitter<any>();

    private _destroyed$ = new ReplaySubject<void>(1);
    protected _navigationService = inject(LgNavigationService);
    protected _currentNode = "";
    protected _gridDefinition: LgPanelGridDefinition = {
        columns: [
            { size: 3, id: "navigation" },
            { size: 9, id: "body" }
        ]
    };

    ngOnInit() {
        this._currentNode = this._navigationService.getCurrentNodeSync().name;
    }

    get _saveDisabled(): boolean {
        return this.unsavedChangesCount === 0 || this.isFormInvalid;
    }

    _save(): void {
        this.saveForm.emit();
    }

    _reset(): void {
        this.resetForm.emit();
    }

    public ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
