import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import {
    IDropdownDefinition,
    IDropdownIconDefinition,
    LgDropdownComponent
} from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { CdkDragDrop } from "@angular/cdk/drag-drop";

export interface Level {
    field: string;
    name: string | null;
    isBlocked?: boolean;
}

interface LevelRow {
    id: string;
    position: number;
    name: string;
    icon?: string;
    isSelectable?: boolean;
    indent: number;
}

interface LevelEntry {
    id: string;
    name: string;
    disabled: boolean;
    icon?: string;
    isSelectable?: boolean;
}

@Component({
    selector: "lgflex-pivot-levels-selector",
    templateUrl: "./lg-pivot-levels-selector.component.html",
    styleUrls: ["./lg-pivot-levels-selector.component.scss"],
    encapsulation: ViewEncapsulation.Emulated,
    providers: [...useTranslationNamespace("_Flexible.PivotLevelsSelector")]
})
export class LgPivotLevelsSelectorComponent implements OnInit, OnDestroy {
    @Input() hideBorder = false;

    @Input("levels") public levels: Level[] = [];
    @Input("levels$") public levels$: Observable<Level[]> | undefined;

    @Input("selectedLevelIds") public selectedLevelIds: string[][] = [];
    @Output() readonly selectedLevelIdsChange = new EventEmitter<string[][]>(true);

    @Input("rowHeight") rowHeight = 24;
    @Input("visibleRowsNum") visibleRowsNum = 7;

    @Input("currentLevelId") public get currentLevelId(): string | null {
        return this._currentLevel?.id ?? null;
    }

    public set currentLevelId(value: string | null) {
        if (value === null) {
            this._currentLevel = null;
        } else {
            if (this._currentLevel?.id === value) return;

            const newCurrentLevel = this._selectedLevels.find(level => level.id === value);

            if (newCurrentLevel === undefined) {
                throw new Error(`Level with id ${value} is not found in selected levels`);
            }

            this._currentLevel = newCurrentLevel;
        }
    }

    @Output() public readonly currentChange = new EventEmitter<string>();

    @Input("enableSorting") enableSorting = false;
    @Input("disableEditing") disableEditing = false;
    @Input("allowDrilldown") allowDrilldown = false;
    @Input("indentWithinDrilldownLevel") indentWithinDrilldownLevel = true;

    @ViewChild("levelsDropdown") levelsDropdown: LgDropdownComponent<string> | null = null;

    @Input("buttonPlaceholderName") public buttonPlaceholderName =
        "pivotLevelsSelector.dropdownButtonContainer";

    _selectedLevels: LevelRow[] = [];
    _currentLevel: LevelRow | null = null;
    // ----------------------------------------------------------------------------------
    //
    private _flatSelectedLevelIds: string[] = [];
    private _flatSelectedLevelIds$: BehaviorSubject<string[] | null> = new BehaviorSubject<
        string[] | null
    >(null);

    _levelsDropdownDefinition: IDropdownDefinition<string> | undefined;
    private _levelEntriesDict: Dictionary<LevelEntry> = {};
    private _levelsSubscription: Subscription | undefined;

    _isLevelsDropdownActive = false;

    private _drilldownLevel: Level = {
        field: "__SEPARATOR___",
        name: this._lgTranslate.translate(".DrilldownLevel")
    };

    _iconDefinitions: Dictionary<IDropdownIconDefinition<string>> = {
        drilldown: {
            icon: "icon-arrow-drill-down1",
            iconClass: "lg-icon-menu"
        }
    };

    // ----------------------------------------------------------------------------------
    //
    constructor(protected _lgTranslate: LgTranslateService) {}

    // ----------------------------------------------------------------------------------
    //
    ngOnInit(): void {
        this._setInitFlatSelectedLevelIds();

        if (!this.levels$) {
            this._initLevelBasedProperties();
        }
        if (this.levels$) {
            this._levelsSubscription = this.levels$.subscribe(levels => {
                this.levels = levels;
                this._initLevelBasedProperties();
            });
        }
        this._subscribeFlatSelectedLevelIds();
    }

    private _initLevelBasedProperties(): void {
        this._setLevelEntries();
        this._setSelectedLevels();
        this._rebuildDefinitions();
    }

    private _setInitFlatSelectedLevelIds(): void {
        this._flatSelectedLevelIds = this._getFlattenIdsFromMultidimension(this.selectedLevelIds);
    }

    _subscribeFlatSelectedLevelIds(): void {
        this._flatSelectedLevelIds$.subscribe(values => {
            if (!values) return;
            this.selectedLevelIds = this._getMultidimensionIdsFromFlat(values);
            this.selectedLevelIdsChange.emit(this.selectedLevelIds);
            if (this._currentLevel && values.indexOf(this._currentLevel?.id) === -1) {
                this._setCurrentLevel(null);
            }
        });
    }

    _setLevelEntries(): void {
        const getEntry = (l: Level, icon?: string, isSelectable?: boolean): LevelEntry => ({
            id: l.field,
            name: l.name ?? "",
            disabled: l.isBlocked ?? false,
            icon,
            isSelectable: isSelectable ?? true
        });

        const entries = this.levels.map(x => getEntry(x));

        if (this.allowDrilldown) {
            entries.unshift(getEntry(this._drilldownLevel, "drilldown", false));
        }
        this._levelEntriesDict = _.keyBy(entries, e => e.id);
    }

    ngOnDestroy(): void {
        this._levelsSubscription?.unsubscribe();
    }

    // ----------------------------------------------------------------------------------
    //
    protected _rebuildDefinitions(): void {
        this._levelsDropdownDefinition = {
            entryId: "id",
            entryName: "name",
            disabled: "disabled",
            iconName: "icon",
            icons: this._iconDefinitions,
            groups: [
                {
                    entries: Object.values(this._levelEntriesDict).filter(
                        level =>
                            (level.id !== this._drilldownLevel.field &&
                                this._flatSelectedLevelIds.indexOf(level.id) === -1) || // only not selected levels so far
                            (level.id === this._drilldownLevel.field &&
                                this._flatSelectedLevelIds[
                                    this._flatSelectedLevelIds.length - 1
                                ] !== this._drilldownLevel.field) // do not include drilldown when last selected level is drilldown
                    )
                }
            ]
        };
    }

    _getFlattenIdsFromMultidimension(selectedLevelIds: string[][]): string[] {
        return selectedLevelIds.length
            ? selectedLevelIds.reduce((a, b) =>
                  b.length ? [...a, this._drilldownLevel.field, ...b] : a
              )
            : [];
    }

    _getMultidimensionIdsFromFlat(flatSelectedLevelIds: string[]): string[][] {
        return flatSelectedLevelIds
            .reduce(
                (acc, id, idx) => {
                    if (id === this._drilldownLevel.field) {
                        acc.push([] as string[]);
                    } else {
                        acc[acc.length - 1].push(id);
                    }
                    return acc;
                },
                [[]] as string[][]
            )
            .filter(levelIds => levelIds.length > 0);
    }

    _setSelectedLevels(): void {
        this._selectedLevels = [];
        let drilldownLevel = 0;
        this._flatSelectedLevelIds.forEach(levelId => {
            const level = this._levelEntriesDict[levelId];
            if (level.id === this._drilldownLevel.field) {
                drilldownLevel++;
            }
            this._selectedLevels.push({
                position: this._selectedLevels.length,
                id: levelId,
                name: level ? level.name : levelId,
                icon: level ? level.icon : "",
                isSelectable: level?.isSelectable,
                indent: this.indentWithinDrilldownLevel
                    ? this._selectedLevels.length
                    : drilldownLevel
            });
        });
    }

    _addSelectedLevel(levelId: string): void {
        this._flatSelectedLevelIds.push(levelId);
        this._flatSelectedLevelIds$.next(this._flatSelectedLevelIds);
        this._setSelectedLevels();
    }

    _onLevelSelect(value: string): void {
        this._addSelectedLevel(value);
        this._rebuildDefinitions();
    }

    onRowDrop(event: CdkDragDrop<LevelRow>) {
        // Calculate position delta
        const positionDelta = event.currentIndex - event.previousIndex;
        const direction = positionDelta < 0 ? -1 : 1;

        // Find nodes corresponding to the dropped row
        const sourceRow = _.find(this._selectedLevels, x => x === event.item.data)!;
        const targetRow = this._selectedLevels[event.previousIndex + positionDelta];
        const afterTargetRow = this._selectedLevels[event.previousIndex + positionDelta + 1];
        const sourceRowPositionOrig = sourceRow.position;

        const recalculateSortingForRowsAndRebuild = (): void => {
            this._recalculateSortingForLeaves(this._selectedLevels);
            this._selectedLevels = _.sortBy(this._selectedLevels, x => x.position);
            this._currentLevel =
                this._selectedLevels.find(l => l.id === this._currentLevel?.id) ?? null;
            this._flatSelectedLevelIds = this._selectedLevels.map(l => l.id);
            this._flatSelectedLevelIds$.next(this._flatSelectedLevelIds);
        };
        // Dragged to the end of list
        if (!afterTargetRow) {
            const maxPosition = Math.max(...this._selectedLevels.map(x => x.position ?? 0));
            sourceRow.position = maxPosition + 1;
            if (sourceRow === targetRow || !this._isTargetPositionValid()) {
                sourceRow.position = sourceRowPositionOrig;
                return;
            }

            recalculateSortingForRowsAndRebuild();
            return;
        }

        sourceRow.position = targetRow.position! + 0.5 * direction;
        if (sourceRow === targetRow || !this._isTargetPositionValid()) {
            sourceRow.position = sourceRowPositionOrig;
            return;
        }

        recalculateSortingForRowsAndRebuild();
    }

    _isTargetPositionValid(): boolean {
        const leavesSorted = _.sortBy(this._selectedLevels, x => x.position);
        let isLastDrilldown = false;
        for (const node of leavesSorted) {
            const isCurrentDrilldown = node.id === this._drilldownLevel.field;
            if (isLastDrilldown && isCurrentDrilldown) {
                return false;
            }
            isLastDrilldown = isCurrentDrilldown;
        }
        return true;
    }

    private _recalculateSortingForLeaves(leaves: LevelRow[]): void {
        const leavesSorted = _.sortBy(leaves, x => x.position);

        let position = 0;
        for (const node of leavesSorted) {
            node.position = position;
            position++;
        }
    }

    private _setCurrentLevel(level: LevelRow | null): void {
        this._currentLevel = level;
        this.currentChange.emit(this._currentLevel?.id);
    }

    onRowClick($event: any, level: LevelRow) {
        if (!level.isSelectable) return;
        this._setCurrentLevel(level);
    }

    // ----------------------------------------------------------------------------------
    //
    getScrollerHeight(): number {
        return this.rowHeight * this.visibleRowsNum + 2;
    }

    _levelsDropdownActiveChange(isActive: boolean): void {
        this._isLevelsDropdownActive = isActive;
    }

    _triggerLevelsDropdownSelect(): void {
        this.levelsDropdown?.triggerSelect(
            () => null /* parameter has to be there, otherwise value must be picked*/
        );
    }

    _addLevel(): void {
        this._triggerLevelsDropdownSelect();
    }

    _removeLevel(levelId: string, removeIdx: number): void {
        const removeItemId = this._flatSelectedLevelIds[removeIdx];
        if (removeItemId !== levelId) return; // make sure on given index is item we want to remove
        this._flatSelectedLevelIds.splice(removeIdx, 1);
        if (
            removeIdx >= 1 &&
            this._flatSelectedLevelIds.length > 1 &&
            this._flatSelectedLevelIds[removeIdx] === this._drilldownLevel.field &&
            this._flatSelectedLevelIds[removeIdx - 1] === this._drilldownLevel.field
        ) {
            this._flatSelectedLevelIds.splice(removeIdx, 1);
        }
        this._flatSelectedLevelIds$.next(this._flatSelectedLevelIds);
        this._setSelectedLevels();
        this._rebuildDefinitions();
    }
}
