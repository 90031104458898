<form>
    <div *ngIf="_libraryDropdownDef != null" class="form-row form-row--input">
        <div class="form-row__label">{{ ".Library" | lgTranslate }}:</div>
        <div class="form-row__controls">
            <lg-dropdown [definition]="_libraryDropdownDef" [(current)]="_library"></lg-dropdown>
        </div>
    </div>

    <div class="form-row form-row--input">
        <div class="form-row__label">{{ ".Name" | lgTranslate }}:</div>
        <div class="form-row__controls">
            <input
                type="text"
                class="input input--wide"
                name="name"
                [(ngModel)]="_name"
                [required]="true"
            />
        </div>
    </div>

    <div class="form-row form-row--input">
        <div class="form-row__label">{{ ".Description" | lgTranslate }}:</div>
        <div class="form-row__controls">
            <textarea [rows]="2" name="description" [(ngModel)]="_description"> </textarea>
        </div>
    </div>

    <div class="form-group--buttons">
        <div class="form-row">
            <lg-button
                buttonClass="button--primary"
                textLc="FW.SAVE"
                (click)="_save()"
                [isDisabled]="!isValid()"
            ></lg-button>

            <lg-button textLc="FW.CANCEL" (click)="_close()"></lg-button>
        </div>
    </div>
</form>
