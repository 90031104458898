import { before } from "@logex/mixin-flavors";

export class TabbedMixin {
    _currentTab = 0;

    // ----------------------------------------------------------------------------------
    //

    @before
    _initMixins(): void {
        this._setCurrentTab(0, true);
    }

    async _setCurrentTab(tab: number, noOnTabChanging = false): Promise<void> {
        const from = this._currentTab;
        if (noOnTabChanging || (await this._onTabChanging(from, this._currentTab))) {
            this._currentTab = tab;
            this._onTabChanged(from, this._currentTab);
        }
    }

    async _onTabChanging(from: number, to: number): Promise<boolean> {
        return true;
    }

    _onTabChanged(from: number, to: number): void {
        // Empty
    }
}
