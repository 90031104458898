import { convertColumns } from "./convertToPivotTable";

export const isOrderCorrect = columns => {
    const defaultColumnsReferencesSet = new Set<number>();
    let hasWidgetOrFormula = false;
    let lastReferenceIdx = -1;

    for (const column of columns) {
        if (column.columnType) {
            return false;
        }
        if (column.type === "default") {
            if (hasWidgetOrFormula) {
                return false;
            }

            if (lastReferenceIdx !== column.referenceIdx) {
                if (defaultColumnsReferencesSet.has(column.referenceIdx)) {
                    return false;
                }
                defaultColumnsReferencesSet.add(column.referenceIdx);
            }

            lastReferenceIdx = column.referenceIdx;
        } else if (
            column.type === "widget" ||
            column.type === "formula" ||
            column.type === "difference"
        ) {
            hasWidgetOrFormula = true;
        }
    }

    return true;
};

export const migrateVersion4To5 = config => {
    config.columns = config.columns ? config.columns.filter(c => c.isEnabled || c.columnType) : [];

    if (isOrderCorrect(config.columns)) {
        config.columns = convertColumns(config.columns);
    }

    return 5;
};
