import { Component, effect, input, InputSignal, output } from "@angular/core";
import {
    FdpColumnHeader,
    FdpDifferenceColumnChangeEvent,
    FdpLevel,
    ReferenceInfo
} from "../../../types";
import { PageReferencesService } from "../../../services/page-references/page-references.service";
import { IDropdownDefinition, LgColDefinitionComponent } from "@logex/framework/ui-core";

@Component({
    selector: "lgflex-table-header-main-level",
    templateUrl: "./table-header-main-level.html"
})
export class TableHeaderMainLevelComponent {
    colDefinition = input<LgColDefinitionComponent>();
    referencesDropdown = input<IDropdownDefinition<string> | undefined>();
    widgetReferences = input<ReferenceInfo[]>([]);
    maxVisibleLevel = input<FdpLevel>();
    headerRow: InputSignal<FdpColumnHeader[]> = input([]);
    pivotWrapperWidth = input(0);
    columnSortBy = input<string>();

    // --------------------------------------------------
    onOrderByChange = output<string>();
    onReferenceChange = output<{ slotIdx: number; value: string; columnGroupId: string }>();
    onDifferenceColumnChange = output<FdpDifferenceColumnChangeEvent>();

    _onReferenceChange(reference: { slotIdx: number; value: string }, columnGroupId: string) {
        this.onReferenceChange.emit({ ...reference, columnGroupId });
    }
}
