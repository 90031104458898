import { Injectable } from "@angular/core";
import { UserSettingsService } from "@logex/framework/lg-application";
import * as _ from "lodash-es";
import { Overwrite } from "../../types";

interface Storage {
    referenceCombinations: ReferenceCombination[];
    selectedCombinationId: number;
}

export interface ReferenceCombination {
    id: number;
    name: string;
    selectedReferenceCodes: string[];
}

@Injectable()
export class ReferenceCombinationsService {
    constructor(private _userSettingsService: UserSettingsService) {
        this._userSettingsService.get({ storageId: "referenceCombinations" }).subscribe(data => {
            if (data[0]?.value != null) {
                this._storage = data[0].value;
            }
        });
    }

    _storage: Storage = this._getDefaults();

    get list(): ReferenceCombination[] {
        return _.orderBy(this._storage.referenceCombinations, x => x.name.toLowerCase());
    }

    getById(id: number): ReferenceCombination | undefined {
        return _.find(this._storage.referenceCombinations, x => x.id === id);
    }

    getByValue(value: string[]): ReferenceCombination | undefined {
        return _.find(this._storage.referenceCombinations, x =>
            _.isEqual(x.selectedReferenceCodes, value)
        );
    }

    isSelected(id: number): boolean {
        return this.selectedCombination?.id === id;
    }

    get selectedCombination(): ReferenceCombination | undefined {
        return (
            _.find(
                this._storage.referenceCombinations,
                x => x.id === this._storage.selectedCombinationId
            ) ?? _.first(this._storage.referenceCombinations)
        );
    }

    async add(args: Overwrite<ReferenceCombination, { id: number | undefined }>): Promise<number> {
        if (args.id !== undefined) {
            _.remove(this._storage.referenceCombinations, x => x.id === args.id!);
        }

        const id = args.id ?? this._nextId;

        this._storage.referenceCombinations.push({
            id,
            name: args.name,
            selectedReferenceCodes: args.selectedReferenceCodes
        });

        await this._sync();

        return id;
    }

    async remove(id: number): Promise<void> {
        _.remove(this._storage.referenceCombinations, x => x.id === id);
        await this._sync();
    }

    async select(id: number): Promise<void> {
        this._storage.selectedCombinationId = id;
        await this._sync();
    }

    // TODO: Probably not needed after proper storage implementation
    private get _nextId(): number {
        const allIds = this._storage.referenceCombinations.map(x => x.id).filter(x => x);

        return Math.max(0, ...allIds) + 1;
    }

    private async _sync(): Promise<void> {
        await this._userSettingsService
            .set({
                key: { storageId: "referenceCombinations" },
                value: this._storage
            })
            .toPromise();
    }

    private _getDefaults(): Storage {
        return {
            referenceCombinations: [
                {
                    id: 1,
                    name: "Default",
                    selectedReferenceCodes: ["-1", "0"]
                }
            ],
            selectedCombinationId: 1
        };
    }
}
