<ng-container>
    <lgflex-form-section [hideDivider]="false">
        <lgflex-form-row [label]="'.Title' | lgTranslate">
            <input
                class="input input--wide"
                type="text"
                [placeholder]="schemaTitle"
                [ngModel]="dimension.name"
                (ngModelChange)="_onDimensionPropertyChange($event, 'name')"
            />
        </lgflex-form-row>

        <lgflex-form-row [label]="'.DisplayMode' | lgTranslate">
            <lg-dropdown
                [definition]="_displayModeDropdown"
                [current]="dimension.displayMode ?? _defaultDisplayMode"
                (currentChange)="_onDimensionPropertyChange($event, 'displayMode')"
            />
        </lgflex-form-row>
    </lgflex-form-section>

    <lgflex-form-section [hideDivider]="true" *ngIf="!disableSorting">
        <lgflex-form-section-header>
            {{ ".OrderBy" | lgTranslate }}
        </lgflex-form-section-header>

        <lgflex-form-row [label]="" style="align-items: center">
            <input
                type="checkbox"
                lgStyledCheckbox
                [(ngModel)]="dimension.overrideFromParent"
                (ngModelChange)="_onDimensionPropertyChange($event, 'overrideFromParent')"
            />
            <span style="margin-left: 5px">{{ ".OverrideFromParent" | lgTranslate }}</span>
        </lgflex-form-row>
        <lgflex-form-row
            class="lgflex-dimension-properties-orderby-row"
            *ngIf="dimension.overrideFromParent"
        >
            <lg-dropdown
                style="max-width: 270px"
                [disabled]="!dimension.overrideFromParent"
                [definition]="orderByItemDropdown"
                [(current)]="dimension.orderBy.item"
                (currentChange)="_orderByChange()"
                [itemTooltips]="true"
            ></lg-dropdown>
            <lg-dropdown
                style="margin-left: 10px; max-width: 130px"
                [disabled]="!dimension.overrideFromParent"
                [definition]="_orderByTypeDropdown"
                [(current)]="dimension.orderBy.type"
                (currentChange)="_orderByChange()"
            ></lg-dropdown>
        </lgflex-form-row>
    </lgflex-form-section>
</ng-container>
