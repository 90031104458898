import { PivotTableWidgetConfig } from "../pivot-table-widget.configuration.types";

export const migrateVersion6To7 = (config: PivotTableWidgetConfig) => {
    const formulaColumnsMap = new Map();
    config.columns = config.columns.map(column => {
        if ("columns" in column) {
            column.columns = column.columns.map(c => {
                if (c.type === "formula") {
                    formulaColumnsMap.set(`${c.field}0`, `formula${c.id}`);
                    c.field = `formula${c.id}`;
                }
                return c;
            });
        } else if (column.type === "formula") {
            formulaColumnsMap.set(`${column.field}0`, `formula${column.id}`);
            column.field = `formula${column.id}`;
        }

        return column;
    });
    config.tablesConfig = config.tablesConfig.map(tableConfig => {
        tableConfig.dimensions = tableConfig.dimensions.map(dimension => {
            if (dimension.orderBy?.item && formulaColumnsMap.has(dimension.orderBy.item)) {
                dimension.orderBy.item = formulaColumnsMap.get(dimension.orderBy.item);
            }
            return dimension;
        });
        return tableConfig;
    });
    return 7;
};
