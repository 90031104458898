import { InjectionToken, StaticProvider, Type, ViewContainerRef } from "@angular/core";
import { FieldInfo } from "./flex-data.types";
import { PivotTableColumn, PivotTableColumnFormulaFormatting } from "./flexible-pivot.types";
import { PageReferencesService } from "../services/page-references/page-references.service";
import { Observable } from "rxjs";

export type Constructor<T = unknown> = new (...args: any[]) => T;

export type WidgetState = { [K: string]: unknown } | null;

export interface WidgetComponent<TContext = object> {
    getWidgetType(): string;

    setConfig(config: object | null): void;

    setContext(context: TContext): void;

    showConfigurationUi(): Promise<void>;

    setId(id: string | null): void;

    markAsAutoConverted(): void;

    onVisibilityChange?(isVisible: boolean): void;

    /**
     * Extract state of the widget. The state shouldn't refer to internal objects (use cloneDeep if necessary).
     * The state should be convertible to json. For future use it's advisable to version the state.
     */
    getState?(): WidgetState;

    /**
     * Set state of the widget. Return true if it wasn't possible to apply (and should be removed).
     * The state should be treated as immutable - use cloneDeep if necessary.
     */
    setState?(state: WidgetState): boolean;
}

export interface EmbeddedWidgetContext {
    formatType?: PivotTableColumnFormulaFormatting;
}

export interface EmbeddedWidget<T extends WidgetConfigBase = WidgetConfigBase> {
    type: string;
    configVersion: number;
    config: T;
}

export interface WidgetInlineComponent<T> {
    config$: Observable<T>;
    passConfig(config: T, context: EmbeddedWidgetContext): void;
}

export type WidgetConfigurator<TConfig extends WidgetConfigBase = WidgetConfigBase> =
    | InlineWidgetConfigurator<TConfig>
    | DialogWidgetConfigurator<TConfig>;

export interface InlineWidgetConfigurator<TConfig extends WidgetConfigBase = WidgetConfigBase>
    extends BaseWidgetConfigurator<TConfig> {
    type: "inline";
    insertInlineComponent(
        containerRef: ViewContainerRef,
        config: TConfig,
        context: EmbeddedWidgetContext
    ): Observable<TConfig>;
    getDefaultConfiguration(): TConfig;
}

export interface DialogWidgetConfigurator<TConfig extends WidgetConfigBase = WidgetConfigBase>
    extends BaseWidgetConfigurator<TConfig> {
    type: "dialog";
}

export interface BaseWidgetConfigurator<TConfig extends WidgetConfigBase = WidgetConfigBase> {
    type: string;
    show(config: TConfig): Promise<TConfig>;
    validate(config: TConfig): boolean;
}

export type WidgetConfigBase = Record<string, unknown>;

export interface PageWidgetConfigBase extends WidgetConfigBase {
    title?: string;
    description?: string;
    tooltip?: string;
    tooltipLink?: string;
    dataSource?: string;
}

export interface LayoutWidgetConfig extends PageWidgetConfigBase {}

// Widget decorator
export const widgetInfoSymbol = Symbol("widgetInfo");

// eslint-disable-next-line @typescript-eslint/naming-convention
export function Widget<T>(cfg: WidgetDecoratorArguments): (widget: Constructor<T>) => void {
    if (cfg.configVersion === undefined) cfg.configVersion = 1;
    return (widget: Constructor<T>) => {
        // TODO: Fix the typing
        // @ts-ignore
        widget[widgetInfoSymbol] = cfg;
    };
}

export enum WidgetUsage {
    Page = "page",
    Pivot = "pivot",
    Visualization = "visualization"
}

export type WidgetDecoratorArguments =
    | WidgetDecoratorPageArguments
    | WidgetDecoratorPivotArguments
    | WidgetDecoratorVisualizationArguments;

export type WidgetDecoratorPageArguments = WidgetDecoratorBaseArguments & {
    usage: WidgetUsage.Page;
    configurator?: Type<DialogWidgetConfigurator<PageWidgetConfigBase>>;
};

export type WidgetDecoratorPivotArguments = WidgetDecoratorBaseArguments & {
    usage: WidgetUsage.Pivot;
    configurator?: Type<DialogWidgetConfigurator>;
};

export type WidgetDecoratorVisualizationArguments = WidgetDecoratorBaseArguments & {
    usage: WidgetUsage.Visualization;
    configurator?: Type<InlineWidgetConfigurator>;
};

export type WidgetDecoratorBaseArguments = {
    id: string;
    nameLc: string;
    usage: WidgetUsage;
    configVersion: number;
    deprecated?: boolean;
    deprecatedReplacementId?: string;
};

export type FilterPatchCallback = (filters: _.Dictionary<unknown[]>) => void;

// ----------------------------------------------------------------------------------
// Widget host
export const LG_FLEX_LAYOUT_WIDGET_HOST = new InjectionToken<IWidgetHost>("lgFlexLayoutWidgetHost");

export interface IWidgetHost {
    updateWidgetConfiguration(
        widgetId: string,
        widgetType: string,
        configVersion: number,
        config: any
    ): Promise<void>;

    /**
     * Add a callback to be called on filters to patch them
     *
     * @returns Function A callback for unsubscription
     */
    addFilterPatchCallback(patchCallback: FilterPatchCallback): () => void;

    notifyWidgetReady(widgetId: string): void;
}

// ----------------------------------------------------------------------------------
export interface WidgetTypeInfo extends WidgetDecoratorBaseArguments {
    type: Constructor<any>;
    usage: WidgetUsage;
    configurator?: Type<WidgetConfigurator>;
    providers?: StaticProvider[];
}

// ----------------------------------------------------------------------------------
export interface WidgetConfigurationDialogArguments {
    widgetName: string;
    widgetDescription: string;
    widgetTooltip: string;
    widgetTooltipLink: string;
    scheme: FieldInfo[];
    levels: string[];
    isReadonly?: boolean;
    dataSource?: string;
    columns: PivotTableColumn[];
    pageReferences: PageReferencesService;
}

export interface WidgetConfigurationDialogResponse {
    widgetName: string;
    widgetDescription: string;
    widgetTooltip: string;
    widgetTooltipLink: string;
    levels: string[];
    columns: PivotTableColumn[];
    dataSource?: string;
}

export interface WidgetConfigLeafData {
    key: {
        field: string;
        referenceIdx?: number;
    };
    column: PivotTableColumn;
}
