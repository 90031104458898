import * as _ from "lodash-es";
import { Component, Inject, Injectable } from "@angular/core";
import { mixins } from "@logex/mixin-flavors";

import {
    getDialogFactoryBase,
    IDialogComponent,
    IDropdownDefinition,
    LgPromptDialog,
    LgDialogFactory,
    LgDialogRef
} from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { DialogMixin, ModalResultDialogMixin, TabbedMixin } from "@logex/mixins";

import { ReferenceInfo, ReferenceSlot } from "../../../../types";
import { translateNullableName } from "../../../../utilities";
import { ReferenceCombinationsService } from "../../reference-combinations.service";

interface ReferenceCombinationEditorDialogArguments {
    id?: number;
    slots: ReferenceSlot[];
    availableReferences: ReferenceInfo[];
}

export interface ReferenceCombinationEditorDialogComponent
    extends DialogMixin<ReferenceCombinationEditorDialogComponent>,
        ModalResultDialogMixin<ReferenceCombinationEditorDialogArguments, void>,
        TabbedMixin {}

@Component({
    selector: "lgflex-reference-combination-editor-dialog",
    templateUrl: "./reference-combination-editor-dialog.component.html",
    providers: [...useTranslationNamespace("_Flexible._ReferenceCombinationEditorDialog")]
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class ReferenceCombinationEditorDialogComponent
    implements IDialogComponent<ReferenceCombinationEditorDialogComponent, void>
{
    constructor(
        @Inject(LG_APP_DEFINITIONS) public _definitions: IDefinitions<any>,
        public _lgTranslate: LgTranslateService,
        public _dialogRef: LgDialogRef<ReferenceCombinationEditorDialogComponent>,
        public _promptDialog: LgPromptDialog,
        public _referenceCombinations: ReferenceCombinationsService
    ) {
        this._initMixins();
    }

    // ----------------------------------------------------------------------------------
    //

    // Dialog configuration
    _dialogClass = "lg-dialog lg-dialog--3col";
    _title = this._lgTranslate.translate(".DialogTitle");

    _id: number | undefined;
    _name: string | undefined;
    _referencesDropdown: IDropdownDefinition<string> | undefined;
    _selectedReferenceCodes: Array<string | null> = [];

    // ----------------------------------------------------------------------------------
    //
    show(args: ReferenceCombinationEditorDialogArguments): Promise<void> {
        if (this._args.id === undefined) {
            this._title = this._lgTranslate.translate(".DialogTitleNew");
        }

        // Update maximum number of references depending on references availability
        // this._maxReferences = Math.max( this._maxReferences, this._args.availableReferences?.length );

        // Set defaults
        this._id = this._args.id;
        if (this._args.id !== undefined) {
            const editItem = this._referenceCombinations.getById(this._args.id);
            if (editItem === undefined) throw Error("Couldn't find reference combinations.");

            this._selectedReferenceCodes = editItem.selectedReferenceCodes;
            this._name = editItem.name;
        }

        if (this._selectedReferenceCodes.length > 0) {
            this._selectedReferenceCodes = _.map(this._args.slots, (x, i) =>
                x.isLocked ? x.referenceCode : this._selectedReferenceCodes[i] || null
            );
        } else {
            this._selectedReferenceCodes = this._args.slots.map(
                () => this._args.availableReferences[0].code
            );
        }

        this._referencesDropdown = dropdownFlat({
            entryId: "code",
            entryName: "name",
            entries: [
                ..._.chain(this._args.availableReferences)
                    .orderBy(x => x.code)
                    .map(x => ({
                        ...x,
                        name: translateNullableName(this._lgTranslate, x.name, x.nameLc)
                    }))
                    .value()
            ]
        });

        // TODO: Fix the mixin
        // @ts-ignore
        return null;
    }

    get _isDeletable(): boolean {
        return this._args.id !== undefined && this._referenceCombinations.list?.length > 1;
    }

    get _isValid(): boolean {
        return !!this._name?.trim().length && !this._selectedReferenceCodes.some(x => x === null);
    }

    async _save(): Promise<boolean> {
        if (this._isValid) throw Error();

        // No await for reason
        this._referenceCombinations.add({
            id: this._id,
            name: this._name!,
            selectedReferenceCodes: this._selectedReferenceCodes as string[]
        });

        this._resolve();
        return true;
    }

    async _delete(): Promise<void> {
        if (!this._isDeletable) throw Error("Delete is not allowed.");

        const response = await this._promptDialog.confirm(
            this._lgTranslate.translate(".ConfirmDeletion.Title"),
            this._lgTranslate.translate(".ConfirmDeletion.Body"),
            {
                buttons: [
                    {
                        id: "yes",
                        name: this._lgTranslate.translate(".ConfirmDeletion.Yes"),
                        isConfirmAction: true
                    },
                    {
                        id: "no",
                        name: this._lgTranslate.translate(".ConfirmDeletion.No"),
                        isCancelAction: true
                    }
                ]
            }
        );

        if (response !== "yes") {
            return;
        }

        // No await for reason
        this._referenceCombinations.remove(this._id!);

        this._resolve();
        this._dialogRef.close();
    }
}

@Injectable()
export class ReferenceCombinationEditorDialog extends getDialogFactoryBase(
    ReferenceCombinationEditorDialogComponent,
    "show"
) {
    constructor(_factory: LgDialogFactory) {
        super(_factory);
    }
}
