/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type */
import { inject, Injectable } from "@angular/core";
import { LG_APP_CONFIGURATION, ServerGatewayBase } from "@logex/framework/lg-application";
import { JobStatus } from "./stale-data-service-gateway.types";

@Injectable({ providedIn: "root" })
export class StaleDataServiceGateway extends ServerGatewayBase {
    constructor() {
        super();

        const appConfiguration = inject(LG_APP_CONFIGURATION);
        this._setBaseUrl(appConfiguration.applicationRoot);
    }

    cancelJobs(jobIds: string[]) {
        return this._post("calc/cancel", {
            body: { jobIds }
        });
    }

    getJobsStatus(jobIds: string[]) {
        return this._post<JobStatus[]>("calc/status", {
            body: { jobIds }
        });
    }
}
