<ng-container *ngIf="_config !== undefined">
    <div class="form-row--input form-row">
        <div class="form-row__label">{{ ".MaximumValue" | lgTranslate }}:</div>
        <div class="form-row__controls">
            <input
                class="input"
                type="number"
                style="width: 100px"
                placeholder="{{ '.Auto' | lgTranslate }}"
                [(ngModel)]="_limit"
                (ngModelChange)="_onConfigChange()"
            />
        </div>
    </div>
    <div class="form-row--input form-row">
        <div class="form-row__label">{{ ".TextSpace" | lgTranslate }}:</div>
        <div class="form-row__controls">
            <input
                class="input"
                type="number"
                style="width: 100px"
                [(ngModel)]="_textSpace"
                (ngModelChange)="_onConfigChange()"
            />
        </div>
    </div>
</ng-container>
