<calculation-progress
    style="margin-left: 8px"
    [status]="(isCalculating | async) ? 'calculating' : 'hidden'"
    [progress]="(calculationProgress | async) ?? 0"
>
</calculation-progress>
@if (!isDataComplete && !isLimitRowsEnabled) {
    <lg-icon
        class="flexpivot-data-limit-warning"
        icon="icon-warning"
        [lgTooltip]="'APP._.DataLimitHit' | lgTranslate"
    />
}
@if (isLimitRowsEnabled) {
    <lg-icon
        class="widget-common-header-limit-rows-icon"
        icon="icon-graph-filter"
        [clickable]="false"
        [lgTooltip]="limitRowsTooltipText"
    />
}
@if (
    (config.tooltip !== undefined && config.tooltip.length > 0) ||
    (config.tooltipLink !== undefined && config.tooltipLink.length > 0)
) {
    <lg-icon
        class="lg-panel__header-icon widget-common-header--help-icon"
        style="cursor: default"
        icon="icon-help"
        [lgTooltip]="tooltipContent"
        [lgTooltipOptions]="_tooltipOptions"
    />
}
@if (config.description) {
    <div class="lg-panel__header__widget-note">
        {{ config.description }}
    </div>
}

<ng-template #tooltipContent>
    <div class="lg-help-tooltip__body">
        @if (config.tooltip !== undefined && config.tooltip.length > 0) {
            <p class="lg-help-tooltip__text">
                {{ config.tooltip }}
            </p>
        }
        @if (config.tooltipLink !== undefined && config.tooltipLink.length > 0) {
            <a target="_blank" [href]="config.tooltipLink">
                {{ "_Flexible.Widgets.MoreInfo" | lgTranslate }}
            </a>
        }
    </div>
</ng-template>
