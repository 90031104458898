import { Component, input, output } from "@angular/core";
import { FdpColumnSimpleHeaderGroup, FdpDifferenceColumnChangeEvent } from "../../../types";

@Component({
    selector: "lgflex-simple-group",
    templateUrl: "./simple-group.component.html"
})
export class SimpleGroupComponent {
    headerCell = input<FdpColumnSimpleHeaderGroup>();
    columnSortBy = input<string | null>();
    // --------------------------------------------------
    onOrderByChange = output<string>();
    onDifferenceColumnChange = output<FdpDifferenceColumnChangeEvent>();
}
