import { Component, input, Input } from "@angular/core";
import { LgPivotInstance } from "@logex/framework/lg-pivot";
import { LgColDefinitionComponent } from "@logex/framework/ui-core";
import { FdpCell } from "../../../types";

@Component({
    selector: "lgflex-flexible-drilldown-table-footer",
    templateUrl: "./flexible-drilldown-table-footer.component.html"
})
export class FlexibleDrilldownTableFooterComponent {
    @Input() colDefinition: LgColDefinitionComponent | undefined = undefined;
    @Input() totalsRow: FdpCell[] = [];
    @Input() pivot: LgPivotInstance | undefined = undefined;

    colDefinitionWidth = input(0);
}
