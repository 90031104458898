import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    input,
    InputSignal
} from "@angular/core";

import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LayoutGroup, LayoutGroupAndItem } from "../layout-management.types";

@Component({
    selector: "lgflex-group-name-icon-warning",
    templateUrl: "./group-name-icon-warning.component.html",
    styleUrls: ["./group-name-icon-warning.component.scss"],
    providers: [...useTranslationNamespace("_Flexible._LayoutCatalogManagementDialog")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupNameIconWarningComponent {
    private _lgTranslate = inject(LgTranslateService);

    layouts: InputSignal<LayoutGroupAndItem[]> = input([]);
    group: InputSignal<LayoutGroup> = input(null);

    warningMessage = computed(() => {
        const noLayouts =
            this.group().layouts.length === 0
                ? this._lgTranslate.translate(
                      "_Flexible._LayoutCatalogManagementDialog.NoLayoutsInGroup"
                  )
                : "";
        const isNotUniqueName =
            this.layouts()
                .filter(item => (item as LayoutGroup).layouts)
                .find(
                    (item: LayoutGroup) =>
                        item.name === this.group().name && item.id !== this.group().id
                ) !== undefined
                ? this._lgTranslate.translate(
                      "_Flexible._LayoutCatalogManagementDialog.GroupNameNotUnique"
                  )
                : "";
        if (noLayouts && isNotUniqueName) {
            return `${noLayouts}. ${isNotUniqueName}`;
        } else if (noLayouts) {
            return noLayouts;
        } else if (isNotUniqueName) {
            return isNotUniqueName;
        } else {
            return "";
        }
    });
}
