import { provideWidgetMigration } from "../../../services/widget-types-registry";
import { BUBBLE_CHART_WIDGET } from "../bubble-chart-widget.types";
import { cloneDeep } from "lodash-es";
import { PivotTableColumnFormulaType } from "../../../types";

/*
interface BubbleChartWidgetConfigV1 extends ChartWidgetConfig {
    xAxisDimension: {
        field: string;
        reference: number;
    };
    yAxisDimension: {
        field: string;
        reference: number;
    };
    sizeDimension: BubbleChartDimension;
}
*/

export const bubbleChartV1toV2MigrationProvider = provideWidgetMigration(BUBBLE_CHART_WIDGET, {
    1: (config: any) => {
        Object.assign(config, {
            title: config.title,
            description: config.description,
            levels: cloneDeep(config.levels),
            columns: cloneDeep(config.columns),
            ignoreOwnFilters: config.ignoreOwnFilters,
            panelView: config.panelView,
            xAxisColumnFormula: {
                formatPrecision: 0,
                formatType: "float",
                formula: PivotTableColumnFormulaType.A,
                id: 0,
                isEnabled: true,
                title: "X Axis Formula",
                type: "formula",
                variables: {
                    a: {
                        field: config.xAxisDimension?.field,
                        reference: config.xAxisDimension?.reference,
                        variable: "a"
                    }
                }
            },
            yAxisColumnFormula: {
                formatPrecision: 0,
                formatType: "float",
                formula: PivotTableColumnFormulaType.A,
                id: 1,
                isEnabled: true,
                title: "Y Axis Formula",
                type: "formula",
                variables: {
                    a: {
                        field: config.yAxisDimension?.field,
                        reference: config.yAxisDimension?.reference,
                        variable: "a"
                    }
                }
            },
            sizeDimension: config.sizeDimension,
            chartType: config.chartType
        });

        delete config.xAxisDimension;
        delete config.yAxisDimension;

        return 2;
    }
});
