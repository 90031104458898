import {
    ChartWidgetStateBase,
    ChartWidgetConfigBase
} from "../base/chart-widget-base/chart-widget-base.types";
import { DefinitionDisplayMode } from "@logex/framework/lg-application";

export const PIE_CHART_WIDGET = "pieChartWidget";

export interface PieChartWidgetConfig extends ChartWidgetConfigBase {
    labelDisplayMode: DefinitionDisplayMode;
}

export interface PieChartWidgetState extends ChartWidgetStateBase {}
