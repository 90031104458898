import { INormalizedLogexPivotDefinition, LgPivotInstance } from "@logex/framework/lg-pivot";

export type PivotExpandedState = Record<
    string,
    {
        $expanded: boolean;
        children?: PivotExpandedState;
    }
>;

function extractPivotExpandedLevel(
    definition: INormalizedLogexPivotDefinition,
    rows: any[]
): PivotExpandedState | null {
    let found = false;
    const result: PivotExpandedState = {};
    const childName = definition.children?.store;
    const column = definition.column;

    if (childName == null || column == null) return null;

    for (const row of rows) {
        let extract: PivotExpandedState | null = null;
        const childRows = row[childName];
        if (childName && childRows?.length) {
            extract = extractPivotExpandedLevel(definition.children!, childRows);
        }
        if (extract) {
            result[row[column]] = {
                $expanded: !!row.$expanded,
                children: extract
            };
            found = true;
        } else if (row.$expanded) {
            result[row[column]] = {
                $expanded: true
            };
            found = true;
        }
    }
    return found ? result : null;
}

export function extractPivotExpanded(
    pivotInstance: LgPivotInstance | null | undefined
): PivotExpandedState | null {
    if (!pivotInstance?.all) return null;
    return extractPivotExpandedLevel(pivotInstance.definition, pivotInstance.all);
}

function applyPivotExpandedLevel(
    definition: INormalizedLogexPivotDefinition,
    rows: any[],
    state: PivotExpandedState
): void {
    const childName = definition.children?.store;
    const column = definition.column;

    if (column == null) return;

    for (const row of rows) {
        const stored = state[row[column]];
        if (stored === undefined) continue;
        row.$expanded = stored.$expanded;
        if (stored.children && childName) {
            applyPivotExpandedLevel(definition.children!, row[childName] ?? [], stored.children);
        }
    }
}

export function applyPivotExpanded(
    pivotInstance: LgPivotInstance | null | undefined,
    state: PivotExpandedState | null
): void {
    if (!pivotInstance?.all || !state) return;
    applyPivotExpandedLevel(pivotInstance.definition, pivotInstance.all, state);
}
