import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { ILgBookmarkStore, ILgKeyedBookmarkStore } from "@logex/framework/lg-layout";

import {
    LayoutBookmarkEditDialog,
    LayoutBookmarkUiModel
} from "./layout-bookmark-edit-dialog.component";
import { LayoutBookmark, LgLayoutFilterSet } from "../../types";

// note: We assume, that the store is never reused across pages, so we just ignore the key
@Injectable()
export class LayoutBookmarksStore
    implements ILgBookmarkStore<LayoutBookmark>, ILgKeyedBookmarkStore<LayoutBookmark>
{
    readonly isReadOnly = new BehaviorSubject<boolean>(true);
    readonly isExclusive: boolean = false;
    readonly priority: number = 1;
    readonly menuGroupName: Observable<string>;
    readonly emptyGroupText = new BehaviorSubject<string>("");

    private _layoutBookmarks: LayoutBookmark[] = [];
    private _layoutBookmarks$: BehaviorSubject<LayoutBookmark[]>;
    private _key: string | undefined;

    constructor(
        translateService: LgTranslateService,
        private _bookmarkEditDialog: LayoutBookmarkEditDialog
    ) {
        this._layoutBookmarks$ = new BehaviorSubject<LayoutBookmark[]>([]);
        this.menuGroupName = new BehaviorSubject<string>(
            translateService.translate(
                "FW._Directives._FiltersetSlideout._BookmarksMenu.Layout_bookmarks_title"
            )
        );
    }

    setTextForEmptyGroup(text: string): void {
        if (text !== this.emptyGroupText.value) {
            this.emptyGroupText.next(text);
        }
    }

    getKeyedStore(key: string): LayoutBookmarksStore {
        if (this._key && this._key !== key)
            throw new Error("Layout bookmark store key already specified");
        this._key = key;
        return this;
    }

    setLayoutFilterSets(sets: LgLayoutFilterSet[]): void {
        this._layoutBookmarks = sets.map(
            lf =>
                ({
                    filterHostId: this._key ?? "",
                    name: lf.name,
                    overwrite: true,
                    shared: false,
                    parts: lf.filters,
                    isOwn: true,
                    isDefault: lf.isDefault ?? false,
                    stateId: this._generateLayoutBookmarkStateId(this._key ?? "", lf.name)
                } satisfies LayoutBookmark)
        );
        this._layoutBookmarks$.next(this._layoutBookmarks);
    }

    setReadOnly(value: boolean): void {
        if (value !== this.isReadOnly.value) {
            this.isReadOnly.next(value);
        }
    }

    getBookmarks(): Observable<LayoutBookmark[]> {
        return this._layoutBookmarks$.asObservable();
    }

    getStoreFilterSetsSync(): LgLayoutFilterSet[] {
        return this._layoutBookmarks.map(lb => ({
            name: lb.name,
            isDefault: lb.isDefault,
            filters: lb.parts
        }));
    }

    getBookmark(id: string): Promise<LayoutBookmark | undefined> {
        return Promise.resolve(this._layoutBookmarks.find(state => state.stateId === id));
    }

    delete(id: string): Promise<void> {
        this._layoutBookmarks = this._layoutBookmarks.filter(lb => lb.stateId !== id);
        this._layoutBookmarks$.next(this._layoutBookmarks);
        return Promise.resolve();
    }

    getDefaultLayoutBookmark(): LayoutBookmark | undefined {
        return this._layoutBookmarks.find(lb => lb.isDefault);
    }

    async updateFilters(id: string, stateParts: Record<string, string>): Promise<void> {
        const currentState = await this.getBookmark(id);
        if (!currentState) throw new Error("Bookmark not found");
        const newState = { ...currentState, parts: stateParts };
        await this._doSave(newState);
    }

    async startEdit(id: string): Promise<void> {
        const currentState = await this.getBookmark(id);
        if (!currentState) throw new Error("Bookmark not found");
        const choices = await this._showEditDialog(currentState);
        const newState = { ...currentState, ...choices };
        await this._doSave(newState);
    }

    async startSaveAs(stateParts: Record<string, string>): Promise<string> {
        const choices = await this._showEditDialog();
        const newState: LayoutBookmark = {
            filterHostId: this._key!,
            isOwn: true,
            dateInsert: new Date(),
            overwrite: true,
            shared: false,
            ...choices,
            parts: stateParts
        };
        return this._doSave(newState);
    }

    private _showEditDialog(currentState?: LayoutBookmarkUiModel): Promise<LayoutBookmarkUiModel> {
        const existingNames = this._layoutBookmarks.map(lb => lb.name);
        return this._bookmarkEditDialog.show(existingNames, {
            name: currentState?.name ?? "",
            isDefault: currentState?.isDefault ?? false
        });
    }

    private _doSave(state: LayoutBookmark): string {
        this._layoutBookmarks = [...this._layoutBookmarks];
        if (state.stateId == null) {
            // create
            state.stateId = this._generateLayoutBookmarkStateId(state.filterHostId, state.name);
            this._layoutBookmarks.push(state);
        } else {
            // update
            const currentIdx = this._layoutBookmarks.findIndex(lb => lb.stateId === state.stateId);
            if (currentIdx !== -1) {
                this._layoutBookmarks[currentIdx] = state;
            } else {
                state.isDefault = false;
            }
        }
        if (state.isDefault) {
            this._updateBookmarksDefaultStatus(state);
        }
        this._layoutBookmarks$.next(this._layoutBookmarks);
        return state.stateId!;
    }

    private _updateBookmarksDefaultStatus(layoutBookmark: LayoutBookmark): void {
        this._layoutBookmarks.forEach(lb => {
            if (lb.isDefault && lb.stateId !== layoutBookmark.stateId) {
                lb.isDefault = false;
            }
        });
    }

    private _generateLayoutBookmarkStateId(key: string, name: string): string {
        return `${key}__${name}__${Date.now()}`;
    }
}
