export const CONDITIONAL_FORMATTING_ID = "conditionalFormatting";

export type ConditionalFormattingRangeTypes = "disabled" | "auto" | "manual";
export type ConditionalFormattingRangeNames = "min" | "average" | "max";

export enum ConditionalFormattingColor {
    Red = "#efc3c3",
    Green = "#78dee0",
    Orange = "#fbc388",
    Blue = "#7bdafc",
    Gray = "#cecece",
    Purple = "#dbc3ff",
    White = "#ffffff"
}

export type ConditionalFormattingRange = {
    type: ConditionalFormattingRangeTypes;
    value: number;
    color: ConditionalFormattingColor;
};

export type ConditionalFormattingConfig = {
    [key in ConditionalFormattingRangeNames]: ConditionalFormattingRange;
} & {
    steps: number;
};

export const DEFAULT_CONDITIONAL_FORMATTING_CONFIG: ConditionalFormattingConfig = {
    steps: 5,
    min: {
        type: "disabled",
        value: 0,
        color: ConditionalFormattingColor.Red
    },
    average: {
        type: "disabled",
        value: 0,
        color: ConditionalFormattingColor.Orange
    },
    max: {
        type: "disabled",
        value: 0,
        color: ConditionalFormattingColor.Green
    }
};
