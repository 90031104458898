@if (oneItemMode) {
    <div class="lgflex-level-properties-sorting-row">
        <lg-dropdown
            class="form-row__drilldown lgflex-level-properties-sorting-item"
            [itemTooltips]="true"
            [definition]="sortingDropdown"
            [(current)]="levelSortingItems[0].item"
            (currentChange)="sortingItemUpdated.emit({ item: levelSortingItems[0], index: 0 })"
        />
        <lg-dropdown
            class="form-row__drilldown lgflex-level-properties-sorting-type"
            [definition]="_sortingByTypeDropdown"
            [(current)]="levelSortingItems[0].type"
            (currentChange)="sortingItemUpdated.emit({ item: levelSortingItems[0], index: 0 })"
        />
    </div>
} @else {
    <div cdkDropList>
        <ng-container *ngFor="let item of levelSortingItems; let idx = index">
            <div
                class="lgflex-level-properties-sorting-row"
                cdkDrag
                cdkDragHandle
                [cdkDragData]="item"
                (cdkDragDropped)="sortingItemDrop.emit($event)"
            >
                <lg-icon
                    class="lgflex-level-properties-sorting-row__hover-only"
                    icon="icon-draggable"
                />
                <lg-dropdown
                    class="form-row__drilldown lgflex-level-properties-sorting-item"
                    [itemTooltips]="true"
                    [definition]="sortingDropdown"
                    [(current)]="item.item"
                    (currentChange)="sortingItemUpdated.emit({ item, index: idx })"
                />
                <lg-dropdown
                    class="form-row__drilldown lgflex-level-properties-sorting-type"
                    [definition]="_sortingByTypeDropdown"
                    [(current)]="item.type"
                    (currentChange)="sortingItemUpdated.emit({ item, index: idx })"
                />
                <lg-icon
                    class="lgflex-level-properties-sorting-row__hover-only"
                    icon="icon-delete"
                    [lgTooltip]="'.Delete' | lgTranslate"
                    (click)="sortingRemoved.emit(idx)"
                />
            </div>
        </ng-container>
    </div>
}

@if (!oneItemMode) {
    <div style="margin-top: 10px">
        <lg-button
            (click)="sortingAdded.emit()"
            [buttonClass]="'button button--condensed'"
            [isDisabled]="addSortingBtnDisabled"
            icon="icon-add"
            textLc=".AddSorting"
        ></lg-button>
    </div>
}
