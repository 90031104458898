import { Inject, Injectable } from "@angular/core";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";

import {
    ICalculatorCallbackDefinition,
    ILogexPivotDefinition,
    LogexPivotService
} from "@logex/framework/lg-pivot";
import { Dictionary } from "lodash";
import { FieldInfo, PivotTableColumn } from "../../types";
import { getColumnFieldName } from "../../utilities";
import {
    fieldNotFoundError,
    FlexiblePivotDefinitionFactoryBase
} from "./flexible-pivot-definition-factory-base";

// ----------------------------------------------------------------------------------
export interface Config {
    scheme: FieldInfo[];
    numReferences: number;
    levels: string[];
    columns: PivotTableColumn[];
    bottomLevelExtension?: ILogexPivotDefinition;
}

export type CalculationStep = string | ICalculatorCallbackDefinition;

export type CalculationStepDefinitions = CalculationStep[][];

// ----------------------------------------------------------------------------------
@Injectable({
    providedIn: "root"
})
export class FlexiblePivotFlatDefinitionFactory extends FlexiblePivotDefinitionFactoryBase {
    constructor(
        _pivotService: LogexPivotService,
        @Inject(LG_APP_DEFINITIONS) _definitions: IDefinitions<any>
    ) {
        super(_pivotService, _definitions);
    }

    _buildLevels(
        config: Config,
        fieldsLookup: Dictionary<FieldInfo>
    ): { readonly root: ILogexPivotDefinition; readonly leaf: ILogexPivotDefinition } {
        const valueColumnNames = config.columns
            ?.filter(col => col.type !== "widget")
            .map(col => getColumnFieldName(col)); // widget type column is not sortable

        const definitions: string[] = [];
        config.levels.forEach(fieldName => {
            const field = fieldsLookup[fieldName];

            if (field === undefined) {
                throw fieldNotFoundError(fieldName);
            }

            if (field.isValueField) {
                throw Error(`Level-defining field should be a dimension, not value field`);
            }

            definitions.push(field.type);
        });

        const root = this._getLevelDefinition(0, config.levels, definitions, valueColumnNames);
        if (root === null) throw Error("Couldn't build levels.");

        return { root, leaf: root };
    }
}
