import { Component, Input, OnChanges, ViewEncapsulation } from "@angular/core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";
import {
    ConditionalFormattingColor,
    ConditionalFormattingConfig,
    ConditionalFormattingRange,
    ConditionalFormattingRangeNames,
    ConditionalFormattingRangeTypes
} from "../../conditional-formattings.types";
import { LgSimpleChanges } from "@logex/framework/types";

@Component({
    selector: "lgflex-conditional-formatting-configuration",
    templateUrl: "./conditional-formatting-configuration.component.html",
    styleUrls: ["./conditional-formatting-configuration.component.scss"],
    viewProviders: [...useTranslationNamespace("_Flexible.ConditionalFormattingConfiguration")],
    encapsulation: ViewEncapsulation.None
})
export class ConditionalFormattingConfigurationComponent implements OnChanges {
    constructor(private _lgTranslate: LgTranslateService) {}

    @Input() config: ConditionalFormattingConfig | undefined;

    protected _stepsDropdownDef: IDropdownDefinition<number> = dropdownFlat({
        entryId: "number",
        entryName: "name",
        entries: [
            { number: 1, name: "1" },
            { number: 2, name: "2" },
            { number: 3, name: "3" },
            { number: 4, name: "4" },
            { number: 5, name: "5" },
            { number: 1000, name: this._lgTranslate.translate(".Continuous") }
        ]
    });

    protected _ranges: Array<{
        key: ConditionalFormattingRangeNames;
        name: string;
        config: ConditionalFormattingRange;
    }> = [];

    private _rangeTypeDropdownEntries = [
        { type: "disabled", name: this._lgTranslate.translate(".Disabled") },
        { type: "auto", name: this._lgTranslate.translate(".Auto") }
    ];

    protected _rangeTypeDropdownDef: IDropdownDefinition<ConditionalFormattingRangeTypes> =
        dropdownFlat({
            entryId: "type",
            entryName: "name",
            entries: [
                ...this._rangeTypeDropdownEntries,
                { type: "manual", name: this._lgTranslate.translate(".Manual") }
            ]
        });

    protected _rangeTypeAverageDropdownDef: IDropdownDefinition<ConditionalFormattingRangeTypes> =
        dropdownFlat({
            entryId: "type",
            entryName: "name",
            entries: this._rangeTypeDropdownEntries
        });

    protected _rangeColorDropdownDef: IDropdownDefinition<ConditionalFormattingColor> = {
        groupId: "id",
        iconName: "icon",
        lookup: {
            [ConditionalFormattingColor.Red]: {
                id: ConditionalFormattingColor.Red,
                icon: "red"
            },
            [ConditionalFormattingColor.Green]: {
                id: ConditionalFormattingColor.Green,
                icon: "green"
            },
            [ConditionalFormattingColor.Orange]: {
                id: ConditionalFormattingColor.Orange,
                icon: "orange"
            },
            [ConditionalFormattingColor.Blue]: {
                id: ConditionalFormattingColor.Blue,
                icon: "blue"
            },
            [ConditionalFormattingColor.Gray]: {
                id: ConditionalFormattingColor.Gray,
                icon: "gray"
            },
            [ConditionalFormattingColor.Purple]: {
                id: ConditionalFormattingColor.Purple,
                icon: "purple"
            },
            [ConditionalFormattingColor.White]: {
                id: ConditionalFormattingColor.White,
                icon: "white"
            }
        },
        icons: {
            red: {
                icon: "icon-circle",
                inCurrent: true,
                iconClass: "conditional-formatting__icon-red"
            },
            green: {
                icon: "icon-circle",
                inCurrent: true,
                iconClass: "conditional-formatting__icon-green"
            },
            orange: {
                icon: "icon-circle",
                inCurrent: true,
                iconClass: "conditional-formatting__icon-orange"
            },
            blue: {
                icon: "icon-circle",
                inCurrent: true,
                iconClass: "conditional-formatting__icon-blue"
            },
            gray: {
                icon: "icon-circle",
                inCurrent: true,
                iconClass: "conditional-formatting__icon-gray"
            },
            purple: {
                icon: "icon-circle",
                inCurrent: true,
                iconClass: "conditional-formatting__icon-purple"
            },
            white: {
                icon: "icon-circle",
                inCurrent: true,
                iconClass: "conditional-formatting__icon-white"
            }
        }
    };

    ngOnChanges(changes: LgSimpleChanges<ConditionalFormattingConfigurationComponent>): void {
        if (this.config === undefined) return;

        this._ranges = [
            {
                key: "min",
                name: this._lgTranslate.translate(".Min"),
                config: this.config.min
            },
            {
                key: "average",
                name: this._lgTranslate.translate(".Average"),
                config: this.config.average
            },
            {
                key: "max",
                name: this._lgTranslate.translate(".Max"),
                config: this.config.max
            }
        ];
    }
}
