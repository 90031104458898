import { ChangeDetectionStrategy, Component, inject, Inject, Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { mixins } from "@logex/mixin-flavors";

import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    getDialogFactoryBase,
    IDialogComponent,
    IDropdownDefinition,
    LgPromptDialog,
    LgDialogFactory,
    LgDialogRef
} from "@logex/framework/ui-core";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { dropdownFlat } from "@logex/framework/utilities";

import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";
import {
    FieldInfo,
    PivotTableColumn,
    PivotTableColumnFormula,
    PivotTableColumnFormulaType,
    ReferenceInfo
} from "../../../../types";
import {
    CalcAstNodeFunctionCall,
    parseCalculate,
    translateNullableName
} from "../../../../utilities";
import {
    BubbleChartDimension,
    BubbleChartType,
    BubbleChartWidgetConfig
} from "../../bubble-chart-widget.types";
import { PageReferencesService } from "../../../../services/page-references/page-references.service";
import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import { LevelLegacy } from "../../../../components/lg-pivot-levels-selector-legacy/lg-pivot-levels-selector-legacy.component";
import { validateChartWidgetConfigBase } from "../../../../components/base/chart-configuration-dialog-base";
import { LgConsole } from "@logex/framework/core";

export interface BubbleChartWidgetConfigurationDialogArguments {
    widgetName: string;
    widgetDescription: string;
    widgetTooltip: string;
    widgetTooltipLink: string;
    scheme: FieldInfo[];
    levels: string[];
    xAxisColumnFormula: PivotTableColumnFormula;
    yAxisColumnFormula: PivotTableColumnFormula;
    sizeDimension: BubbleChartDimension;
    isReadonly?: boolean;
    pageReferences: PageReferencesService;
    chartType: BubbleChartType;
}

export interface BubbleChartWidgetConfigurationDialogResponse {
    widgetName: string;
    widgetDescription: string;
    widgetTooltip: string;
    widgetTooltipLink: string;
    levels: string[];
    xAxisColumnFormula: PivotTableColumnFormula;
    yAxisColumnFormula: PivotTableColumnFormula;
    sizeDimension: BubbleChartDimension;
    columns: PivotTableColumn[];
    chartType: BubbleChartType;
}

export interface BubbleChartConfigurationDialogComponent
    extends DialogMixin<BubbleChartConfigurationDialogComponent>,
        ModalResultDialogMixin<
            BubbleChartWidgetConfigurationDialogArguments,
            BubbleChartWidgetConfigurationDialogResponse
        > {}

@Component({
    selector: "lgflex-bubble-chart-configuration-dialog",
    templateUrl: "./bubble-chart-configuration-dialog.component.html",
    styleUrls: ["./bubble-chart-configuration-dialog.component.scss"],
    providers: [...useTranslationNamespace("_Flexible.BubbleChartWidgetDialog")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class BubbleChartConfigurationDialogComponent
    implements
        IDialogComponent<
            BubbleChartConfigurationDialogComponent,
            BubbleChartWidgetConfigurationDialogResponse
        >
{
    protected _lgConsole: LgConsole = inject(LgConsole);

    constructor(
        @Inject(LG_APP_DEFINITIONS) public _definitions: IDefinitions<any>,
        public _lgTranslate: LgTranslateService,
        public _dialogRef: LgDialogRef<BubbleChartConfigurationDialogComponent>,
        public _promptDialog: LgPromptDialog
    ) {
        this._initMixins();
    }

    _dialogClass = "lg-dialog lg-dialog--6col lg-dialog--no-spacing";
    _title = this._lgTranslate.translate(".DialogTitle");

    _widgetName = "";
    _widgetDescription = "";
    _widgetTooltip = "";
    _widgetTooltipLink = "";
    _availableLevels: LevelLegacy[] = [];
    _availableLevels$ = new BehaviorSubject<LevelLegacy[]>(this._availableLevels);
    _selectedLevelsIds: string[] = [];

    _allowedValueFields: FieldInfo[] = [];
    _valueFieldDropdown: IDropdownDefinition<string> | undefined;
    _sizeDimension: BubbleChartDimension | undefined;
    _xAxisColumnFormula: PivotTableColumnFormula | undefined;
    _yAxisColumnFormula: PivotTableColumnFormula | undefined;

    _chartType: BubbleChartType = "classic";
    _chartTypeDropdown: IDropdownDefinition<BubbleChartType> = dropdownFlat({
        entryId: "type",
        entryName: "name",
        entries: [
            { type: "classic", name: this._lgTranslate.translate(".Classic") },
            { type: "centered", name: this._lgTranslate.translate(".Centered") }
        ]
    });

    _areReferencesAllowed = false;
    _referencesDict: Dictionary<ReferenceInfo> = {};
    _referenceDropdown: IDropdownDefinition<number> = {};

    _isReadOnly = false;

    // ----------------------------------------------------------------------------------
    //
    async _activate(): Promise<void> {
        this._widgetName = this._args.widgetName;
        this._widgetDescription = this._args.widgetDescription;
        this._widgetTooltip = this._args.widgetTooltip;
        this._widgetTooltipLink = this._args.widgetTooltipLink;
        this._selectedLevelsIds = this._selectAvailableLevels(this._args.levels, this._args.scheme);
        this._isReadOnly = this._args.isReadonly ?? this._isReadOnly;
        this._xAxisColumnFormula = this._args.xAxisColumnFormula ?? {
            id: 0,
            title: this._lgTranslate.translate(".XAsisFormula"),
            type: "formula",
            formula: PivotTableColumnFormulaType.A,
            variables: {},
            formatType: "float",
            formatPrecision: 0,
            isEnabled: true
        };
        this._yAxisColumnFormula = this._args.yAxisColumnFormula ?? {
            id: 1,
            title: this._lgTranslate.translate(".YAsisFormula"),
            type: "formula",
            formula: PivotTableColumnFormulaType.A,
            variables: {},
            formatType: "float",
            formatPrecision: 0,
            isEnabled: true
        };
        this._sizeDimension = this._args.sizeDimension ?? { field: null, reference: null };
        this._chartType = this._args.chartType ?? this._chartType;
        this._areReferencesAllowed = this._args.pageReferences.isAllowed();

        this._initializeReferences();
        this._onValueDropdownChange();
    }

    // ----------------------------------------------------------------------------------
    //
    private _selectAvailableLevels(levels: string[], scheme: FieldInfo[]): string[] {
        const result = [];
        const deletedLvls = [];
        levels.forEach(level => {
            if (scheme.some(item => item.field === level)) {
                result.push(level);
            } else {
                deletedLvls.push(level);
            }
        });
        if (deletedLvls.length !== 0) {
            this._lgConsole.warn(
                `Selected levels ${deletedLvls} have been removed from the configuration, because they do not exist in the fields scheme.`
            );
        }
        return result;
    }

    // ----------------------------------------------------------------------------------
    //
    _initializeReferences(): void {
        if (!this._areReferencesAllowed) return;

        this._referencesDict = _.keyBy(this._args.pageReferences.references, x => x.code);

        this._referenceDropdown = {
            entryId: "idx",
            entryName: "name",
            groups: [
                {
                    entries: [
                        ..._.map(this._args.pageReferences.slots, (slot, idx) => {
                            const reference = this._referencesDict[slot.referenceCode ?? ""];
                            const refName = translateNullableName(
                                this._lgTranslate,
                                reference?.name,
                                reference?.nameLc
                            );
                            return {
                                idx,
                                code: reference?.code,
                                name: this._lgTranslate.translate(".ReferenceFormattedName", {
                                    i: idx + 1,
                                    name: refName
                                })
                            };
                        })
                    ]
                }
            ]
        };
    }

    // ----------------------------------------------------------------------------------
    //
    _onValueDropdownChange(): void {
        this._setAllowedValueFields();
        this._createValueFieldDropdown();
        this._refillLevels();
    }

    private _setAllowedValueFields(): void {
        const selectedLevelsInfo = this._args.scheme.filter(x =>
            this._selectedLevelsIds.includes(x.field)
        );
        const blockedBy = new Set<string>();
        selectedLevelsInfo.forEach(x => x.blockedBy?.forEach(val => blockedBy.add(val)));

        this._allowedValueFields = this._args.scheme.filter(
            field =>
                field.isValueField &&
                !this._selectedLevelsIds.some(level => field.blockedBy?.includes(level)) &&
                !this._isColumnFormulasBlocking(blockedBy, field)
        );
    }

    private _isColumnFormulasBlocking(blockedBy: Set<string>, field: FieldInfo): boolean {
        if (field?.calculate) {
            const parsed = parseCalculate(field.calculate) as CalcAstNodeFunctionCall;
            return parsed.params.some(value => blockedBy.has(value));
        }

        return false;
    }

    private _refillLevels(): void {
        this._availableLevels = this._args.scheme
            .filter(x => !x.isValueField)
            .map(x => ({
                field: x.field,
                name: x.name,
                nameLc: x.nameLc ?? "",
                isBlocked: this._isLevelBlocked(x)
            }));

        this._availableLevels$.next(this._availableLevels);
    }

    private _createValueFieldDropdown(): void {
        const entries: Array<{ field: string; name: string }> = this._allowedValueFields.map(x => ({
            field: x.field,
            name: x.name ?? this._lgTranslate.translate(x.nameLc ?? "")
        }));

        this._valueFieldDropdown = dropdownFlat({
            entryId: "field",
            entryName: "name",
            entries
        });
    }

    private _isLevelBlocked(field: Partial<FieldInfo>): boolean {
        if (!field.blockedBy || !field.blockedBy.length) return false;

        const selectedColumnNames: string[] = [
            Object.values(this._xAxisColumnFormula!.variables).map(formulaVar =>
                formulaVar.type === "constant" ? formulaVar.constant : formulaVar.field
            ),
            Object.values(this._yAxisColumnFormula!.variables).map(formulaVar =>
                formulaVar.type === "constant" ? formulaVar.constant : formulaVar.field
            ),
            this._sizeDimension?.field
        ]
            .flat()
            .filter((x): x is string => x != null);

        // Acknowledge fields participating in formulas
        const selectedColumnsWithCalculations = this._args.scheme.filter(
            x => selectedColumnNames.includes(x.field) && x.calculate
        );
        for (const column of selectedColumnsWithCalculations) {
            const parsed = parseCalculate(column.calculate ?? "") as CalcAstNodeFunctionCall;
            selectedColumnNames.push(...parsed.params);
        }

        return field.blockedBy.some(value => selectedColumnNames.includes(value));
    }

    // ----------------------------------------------------------------------------------
    //
    _closeDialog(): void {
        this._close();
    }

    async _save(): Promise<boolean> {
        if (this._sizeDimension == null) throw Error("Size dimension shouldn't be undefined.");
        if (this._xAxisColumnFormula == null)
            throw Error("X axis column formula shouldn't be undefined.");
        if (this._yAxisColumnFormula == null)
            throw Error("Y axis column formula shouldn't be undefined.");

        this._resolve({
            widgetName: this._widgetName,
            widgetDescription: this._widgetDescription,
            widgetTooltip: this._widgetTooltip,
            widgetTooltipLink: this._widgetTooltipLink,
            levels: this._selectedLevelsIds,
            xAxisColumnFormula: this._xAxisColumnFormula,
            yAxisColumnFormula: this._yAxisColumnFormula,
            sizeDimension: this._sizeDimension,
            columns: this.getPivotTableColumns(this._sizeDimension),
            chartType: this._chartType
        });

        return true;
    }

    _isValid(): boolean {
        if (
            this._sizeDimension == null ||
            this._xAxisColumnFormula == null ||
            this._yAxisColumnFormula == null
        ) {
            return false;
        }

        return validateChartWidgetConfigBase<BubbleChartWidgetConfig>(
            {
                title: this._widgetName,
                description: this._widgetDescription,
                levels: this._selectedLevelsIds,
                columns: this.getPivotTableColumns(this._sizeDimension),
                xAxisColumnFormula: this._xAxisColumnFormula,
                yAxisColumnFormula: this._yAxisColumnFormula,
                sizeDimension: this._sizeDimension,
                chartType: this._chartType
            },
            this._args.pageReferences,
            this._args.scheme
        );
    }

    getPivotTableColumns(...dimensions: BubbleChartDimension[]): PivotTableColumn[] {
        const columns: PivotTableColumn[] = dimensions.map(dimension => ({
            type: "default",
            field: dimension.field,
            referenceIdx: dimension.reference,
            isEnabled: true
        }));

        if (this._yAxisColumnFormula && this._xAxisColumnFormula) {
            columns.push(this._xAxisColumnFormula);
            columns.push(this._yAxisColumnFormula);
        }
        return columns;
    }
}

@Injectable()
export class BubbleChartConfigurationDialog extends getDialogFactoryBase(
    BubbleChartConfigurationDialogComponent,
    "show"
) {
    constructor(_factory: LgDialogFactory) {
        super(_factory);
    }
}
