import {
    PivotTableColumnFormula,
    PivotTableColumnFormulaFormatting,
    ReferenceSlot,
    WidgetState
} from "../../types";
import { ChartWidgetConfigBase } from "../base/chart-widget-base/chart-widget-base.types";
import { ChartOrientation, ChartTypes } from "./components/chart/chart.types";
import { ILgFormatterOptions } from "@logex/framework/core";
import { DefinitionDisplayMode } from "@logex/framework/lg-application";

export const CHART_WIDGET = "combinedVerticalChartWidget";

export interface ChartWidgetConfig extends ChartWidgetConfigBase {
    totalsAsTopLevel: boolean;
    formatType: PivotTableColumnFormulaFormatting;
    formatPrecision: number;
    formatForceSign: boolean | undefined;
    // Labels type looks like this:
    // {
    //   chartLabels: boolean;
    //   tooltip: boolean;
    //   legend: boolean;
    // }
    labels: ChartLabelsConfig;
    columns: ChartWidgetColumn[];
    chartOrientation: ChartOrientation;
    tablesConfig: ChartTableRow[];
    selectedReferences: ReferenceSlot[];
    dataSource: string;
}

export interface ChartWidgetColumn extends PivotTableColumnFormula {
    chartType: ChartTypes;
}

export type ChartWidgetState = WidgetState & {
    levelState: LevelState;
    sortField: string;
    sortDescending: boolean;
};

export interface LevelState {
    level: number;
    table: number;
    stack: ChartLevel[];
}

export enum ChartLabelsOption {
    ChartLabels = "chartLabels",
    Tooltip = "tooltip",
    Legend = "legend"
}

export type ChartLabelsConfig = Record<ChartLabelsOption, boolean>;

export interface ChartTableRow {
    id: string;
    name: string;
    dimensions: ChartDimensionRow[];
    stackedBarDimension?: string;
    limitRows?: number;
    ignoreOwnFilters: boolean;
    orderBy: Sorting[];
}

export interface Sorting {
    type: "desc" | "asc";
    item: string;
}

export interface ChartLevel {
    code: string;
    value: number;
    table: number;
}

export interface ChartOptions {
    chartOrientation: ChartOrientation;
    formatterType: PivotTableColumnFormulaFormatting;
    formatterOptions: ILgFormatterOptions;
    labels: ChartLabelsConfig;
    valueLimit: number | undefined;
}

export interface ChartDimensionRow {
    id: string;
    name: string;
    fieldId: string;
    schemaName: string;
    orderBy: Sorting[];
    displayMode: DefinitionDisplayMode;
}
