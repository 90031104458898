import { Injectable, ViewContainerRef } from "@angular/core";
import { DialogWidgetConfigurator } from "../../types";
import { IconChartWidgetConfig } from "./types/icon-chart-widget.types";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { IconChartConfigurationDialog } from "./components/icon-chart-configuration-dialog/icon-chart-configuration-dialog.component";
import { validateIconChartConfiguration } from "./components/icon-chart-configuration-dialog/icon-chart-configuration-validation";

@Injectable()
export class IconChartWidgetConfigurator
    implements DialogWidgetConfigurator<IconChartWidgetConfig>
{
    constructor(
        private _flexDataClient: FlexDataClientService,
        private _pageReferences: PageReferencesService,
        private _dialog: IconChartConfigurationDialog,
        viewContainerRef: ViewContainerRef
    ) {
        this._dialog = _dialog.bindViewContainerRef(viewContainerRef);
    }

    readonly type = "dialog";

    async show(config: IconChartWidgetConfig): Promise<IconChartWidgetConfig> {
        const response = await this._dialog.show({
            scheme: this._flexDataClient.scheme,
            pageReferences: this._pageReferences,
            widgetName: config.title ?? "",
            widgetDescription: config.description ?? "",
            widgetTooltip: config.tooltip ?? "",
            widgetTooltipLink: config.tooltipLink ?? "",
            levels: config.levels,
            columns: config.columns,
            icon: config.icon,
            allowMultipleReferences: false,
            ignoreOwnFilters: config.ignoreOwnFilters ?? false
        });

        return {
            title: response.widgetName,
            description: response.widgetDescription,
            tooltip: response.widgetTooltip,
            tooltipLink: response.widgetTooltipLink,
            levels: response.levels,
            columns: response.columns,
            icon: response.icon,
            ignoreOwnFilters: response.ignoreOwnFilters
        };
    }

    validate(config: IconChartWidgetConfig): boolean {
        return validateIconChartConfiguration(
            config,
            this._pageReferences,
            this._flexDataClient.scheme
        );
    }
}
