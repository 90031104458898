import { Directive, ElementRef, Input, OnChanges } from "@angular/core";
import { LgSimpleChanges } from "@logex/framework/types";

@Directive({
    selector: "lgflex-stale-data-handler"
})
export class StaleDataHandlerDirective implements OnChanges {
    constructor(private _elRef: ElementRef) {}

    @Input() progress: number | undefined | null;
    @Input() state: boolean | undefined | null;

    ngOnChanges(changes: LgSimpleChanges<StaleDataHandlerDirective>): void {
        const bodyEl = this._elRef.nativeElement.closest(".lg-panel__body");
        if (bodyEl == null) return;

        if (changes.progress || changes.state) {
            // If progress has reached 100% or calculating complete, stale data loader should be removed.
            if (changes.progress?.currentValue === 1 || changes.state?.currentValue === false) {
                bodyEl.classList.remove("stale-data-container");
            } else {
                bodyEl.classList.add("stale-data-container");
            }
        }
    }
}
