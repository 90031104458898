<ng-container *lgPanelHeader="let context; order: 1">
    <lgflex-widget-common-header
        [config]="_config"
        [isDataComplete]="_isDataComplete"
        [isCalculating]="_isCalculating$"
        [isLimitRowsEnabled]="_limitRowsCount() > 0 && _limitRowsCount() !== _maxLimitRows"
        [limitRowsTooltipText]="'.LimitRows' | lgTranslate: { count: _limitRowsCount() }"
        [calculationProgress]="_calculationProgress$"
    />
</ng-container>

<ng-container *lgPanelIcons="let context; order: 1; whenMinimized: false">
    <lgflex-widget-common-icons
        [exportFormats]="_exportOptions"
        [isLoading$]="_isLoading$"
        [isAutoConverted]="_isAutoConverted"
        [isConfigValid]="_isConfigValid"
        (export)="_export($event)"
        (edit)="showConfigurationUi()"
    />
</ng-container>

<ng-container *ngIf="_isConfigValid">
    <lgflex-stale-data-handler
        [state]="_isCalculating$ | async"
        [progress]="_calculationProgress$ | async"
    />

    <div class="flexcol" *ngIf="_pivots != null; else pivotIsNull">
        <lgflex-pivot-table
            #table
            [scheme]="_pivotTableDataService.scheme()"
            [columnsGroups]="$any(_columnsAndGroups)"
            [tablesConfig]="_config.tablesConfig"
            [widgetReferences]="_convertedSelectedReferences()"
            [pivots]="_pivots()"
            [filters]="_filters"
            [widgetTypes]="_widgetTypes"
            [lgLoaderOverlay]="(_isLoading$ | async) ?? false"
            [lgLoaderOverlayDelay]="100"
            [maxColumnValues]="_maxColumnValues"
            [initialState]="_initialTableState"
            (drillChange)="_onDrillChange($event)"
            (differenceColumnChange)="_onDifferenceColumnChange($event)"
            (onReferenceSelectedChange)="_onReferenceSelectedChange($event)"
        ></lgflex-pivot-table>
    </div>

    <ng-template #pivotIsNull>
        <div class="flexcol" [lgLoaderOverlay]="(_isLoading$ | async) ?? false"></div>
    </ng-template>
</ng-container>

<div
    class="flexcol flexcol--full flexcol--center"
    *ngIf="!_isConfigValid"
    style="justify-content: center"
>
    {{ "_Flexible.Widgets.WidgetValidationError" | lgTranslate }}
</div>
