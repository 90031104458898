import { Injectable } from "@angular/core";
import { cloneDeep, isObject, orderBy } from "lodash-es";
import { LgConsole } from "@logex/framework/core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LayoutWidget, LayoutWidgetConfig, WidgetConfigBase } from "../../types";
import {
    ConfigMigratorDict,
    WidgetReplacerDeclaration,
    WidgetTypesRegistry
} from "../widget-types-registry";

type ReplacerDict = Record<string, WidgetReplacerDeclaration[]>;

type MigrationWidget = {
    type: string;
    config: WidgetConfigBase;
    configVersion: number;
};

@Injectable()
export class FlexibleLayoutUpgraderService {
    constructor(
        private _widgetTypes: WidgetTypesRegistry,
        private _lgConsole: LgConsole,
        private _lgTranslate: LgTranslateService
    ) {
        this._lgConsole = this._lgConsole.withSource("Logex.Flexible");
    }

    /**
     * Migrate config of the given widgets to the newest version. Returns true, if any migration occured.
     */
    migrateVersions(widgets: LayoutWidget[]): boolean {
        let outdated = false;

        widgets.forEach(widget => {
            if (this._migrateVersion(widget)) {
                outdated = true;
            }
        });

        widgets.forEach(parentWidget =>
            this._widgetTypes.getEmbeddedWidgetsToMigrate(parentWidget).forEach(embeddedWidget => {
                if (this._migrateVersion(embeddedWidget)) {
                    outdated = true;
                }
            })
        );

        return outdated;
    }

    /**
     * Find ids of possible replacements of the specified widget type.
     */
    findPossibleReplacements(widgetTypeId: string, includeDeprecated: boolean): string[] {
        const replacements = this._findPossibleReplacers(widgetTypeId, includeDeprecated);
        return Object.keys(replacements);
    }

    /**
     * Find the best automatic replacement for unknown widget. Returns the target type id, or null
     */
    findBestAutomaticReplacement(widgetTypeId: string): string | null {
        const replacements = this._findPossibleReplacers(widgetTypeId, true);
        const annotated = Object.values(replacements).map(entry => {
            const last = entry[entry.length - 1];
            const deprecated = this._widgetTypes.get(last.targetType).deprecated ?? false;
            return {
                deprecated,
                id: last.targetType,
                length: entry.length,
                // priority is based just on first step; in most situations that should be fine
                priority: entry[0].priority ?? 1
            };
        });
        const sorted = orderBy(
            annotated,
            ["deprecated", "length", "priority"],
            ["asc", "asc", "desc"]
        );
        return sorted.length ? sorted[0].id : null;
    }

    /**
     * Convert config of the specified widget to a new type. Returns the new configuration,
     * or string if the operation failed. Original config is not modified. The config
     * must be already at the newest version
     */
    replaceWidget(widget: LayoutWidget, targetTypeId: string): string | LayoutWidgetConfig {
        const clone = cloneDeep(widget);
        const replacementSteps = this._findPossibleReplacers(widget.type, true)[targetTypeId];
        if (!replacementSteps) return `Unknown transition to ${targetTypeId}`;
        try {
            this._migrateVersion(clone);
            let config = clone.config;
            for (const step of replacementSteps) {
                const result = step.replacer(config);
                if (result == null) return "Conversion failed";
                config = result;
            }
            return config;
        } catch (e: any) {
            return e.toString();
        }
    }

    private _migrateVersion(widget: MigrationWidget): boolean {
        const info = this._widgetTypes.tryGet(widget.type);
        const targetVersion = info?.configVersion ?? null;
        const migrations = this._widgetTypes.getMigrations(widget.type);
        return this._runMigrations(widget, targetVersion, migrations);
    }

    // find all valid replacements for given widget. We allow going through missing or deprecated widgets,
    // but they cannot be the final destination
    private _findPossibleReplacers(widgetTypeId: string, includeDeprecated: boolean): ReplacerDict {
        const results: ReplacerDict = {};
        this._findReplacementsFor(widgetTypeId, widgetTypeId, [], results);
        const finalResult: ReplacerDict = {};
        Object.keys(results).forEach(id => {
            const def = this._widgetTypes.tryGet(id);
            if (def && (includeDeprecated || !def.deprecated)) {
                finalResult[id] = results[id];
            }
        });
        return finalResult;
    }

    // recursively search for replacements of widgetTypeId
    private _findReplacementsFor(
        startTypeId: string,
        widgetTypeId: string,
        path: WidgetReplacerDeclaration[],
        results: ReplacerDict
    ): void {
        const replacers = this._widgetTypes.getReplacements(widgetTypeId);
        if (replacers) {
            for (const entry of replacers) {
                if (entry.targetType === startTypeId) continue;
                // if we already had transformation to given type, go deeper only if our new path is shorter
                if (
                    results[entry.targetType] &&
                    results[entry.targetType].length <= path.length + 1
                ) {
                    continue;
                }
                const newPath = [...path, entry];
                results[entry.targetType] = newPath;
                this._findReplacementsFor(startTypeId, entry.targetType, newPath, results);
            }
        }
    }

    private _runMigrations(
        widget: MigrationWidget,
        targetVersion: number | null,
        migrations: ConfigMigratorDict | undefined
    ): boolean {
        if (targetVersion === null) {
            if (!migrations) return false;
            targetVersion = Object.keys(migrations)
                .map(id => +id)
                .sort()
                .reverse()[0];
            if (targetVersion === undefined) return false;
        }
        if (widget.configVersion >= targetVersion) return false;

        while (widget.configVersion < targetVersion) {
            const currentVersion = widget.configVersion;
            const migrator = migrations?.[widget.configVersion];
            if (!migrator) {
                this._lgConsole.log(
                    `No migration found for widget type '${widget.type}' version ${widget.configVersion}`
                );
            } else {
                const newVersion = migrator(widget.config);
                if (newVersion !== undefined && !isObject(newVersion)) {
                    widget.configVersion = newVersion as number;
                }
            }
            if (widget.configVersion <= currentVersion) widget.configVersion = currentVersion + 1;
        }
        return true;
    }
}
