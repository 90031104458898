import { Component, Inject, Injectable } from "@angular/core";
import { mixins } from "@logex/mixin-flavors";

import {
    getDialogFactoryBase,
    IDialogComponent,
    IDropdownDefinition,
    LgPromptDialog,
    LgDialogFactory,
    LgDialogRef
} from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";

import { CrosstabWidgetConfig } from "../../crosstab-widget.types";
import { FieldInfo } from "../../../../types";
import { translateNullableName } from "../../../../utilities";
import { validateCrosstabConfiguration } from "./crosstab-configuration-validation";
import {
    getDropdownReferenceSlots,
    getReferenceDropdownDefinition
} from "../../../../utilities/references-helpers";
import { PageReferencesService } from "../../../../services/page-references/page-references.service";

export interface CrosstabConfigurationDialogArguments extends CrosstabWidgetConfig {
    scheme: FieldInfo[];
    pageReferences: PageReferencesService;
}

export interface CrosstabConfigurationDialogResponse extends CrosstabWidgetConfig {}

export interface CrosstabConfigurationDialogComponent
    extends DialogMixin<CrosstabConfigurationDialogComponent>,
        ModalResultDialogMixin<
            CrosstabConfigurationDialogArguments,
            CrosstabConfigurationDialogResponse
        > {}

interface DropdownEntry<T = string> {
    id: T;
    name: string;
}

@Component({
    selector: "lgflex-crosstab-configuration-dialog",
    templateUrl: "./crosstab-configuration-dialog.component.html",
    providers: [...useTranslationNamespace("_Flexible._CrosstabConfigurationDialog")]
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class CrosstabConfigurationDialogComponent
    implements
        IDialogComponent<CrosstabConfigurationDialogComponent, CrosstabConfigurationDialogResponse>,
        DialogMixin<CrosstabConfigurationDialogComponent>
{
    constructor(
        @Inject(LG_APP_DEFINITIONS) public _definitions: IDefinitions<any>,
        public _lgTranslate: LgTranslateService,
        public _dialogRef: LgDialogRef<CrosstabConfigurationDialogComponent>,
        public _promptDialog: LgPromptDialog
    ) {
        this._initMixins();
    }

    readonly CROSSTAB_CELL_MAX_WIDTH = 1000;

    _title = this._lgTranslate.translate(".DialogTitle");

    _dialogClass = "lg-dialog lg-dialog--5col lg-dialog--no-spacing";

    _widgetName: string | undefined;
    _widgetDescription: string | undefined;
    _widgetTooltip: string | undefined;
    _widgetTooltipLink: string | undefined;

    private _nonValueEntries: DropdownEntry[] = [];

    _horizontalDimension: string | undefined;
    _horizontalDimensionDropdown: IDropdownDefinition<string> | undefined;

    _verticalDimension: string | undefined;
    _verticalDimensionDropdown: IDropdownDefinition<string> | undefined;

    _valueField: string | undefined;
    _valueFieldDropdown: IDropdownDefinition<string> | undefined;

    _referenceSlot: number | null = null;
    _referenceSlotDropdown: IDropdownDefinition<number> | undefined;
    _enableReferenceDropdown = false;

    _sidebarWidth: number | undefined;
    _cellWidth: number | undefined;

    // ----------------------------------------------------------------------------------
    //
    async _activate(): Promise<void> {
        this._widgetName = this._args.title ?? "";
        this._widgetDescription = this._args.description ?? "";
        this._widgetTooltip = this._args.tooltip ?? "";
        this._widgetTooltipLink = this._args.tooltipLink ?? "";

        const valueEntries: DropdownEntry[] = [];

        Object.values(this._args.scheme).forEach(x => {
            const item = {
                id: x.field,
                name: translateNullableName(this._lgTranslate, x.name, x.nameLc)
            };
            if (x.isValueField) {
                valueEntries.push(item);
            } else {
                this._nonValueEntries.push(item);
            }
        });

        if (this._args.pageReferences.isAllowed()) {
            this._referenceSlotDropdown = getReferenceDropdownDefinition(
                getDropdownReferenceSlots(
                    this._args.pageReferences.slots,
                    this._args.pageReferences.references,
                    this._lgTranslate
                )
            );
        }

        this._horizontalDimension = this._args.horizontalDimension;
        this._verticalDimension = this._args.verticalDimension;
        this._valueField = this._args.value;
        this._referenceSlot = this._args.referenceIdx ?? null;
        this._sidebarWidth = this._args.sidebarWidth ?? 340;
        this._cellWidth = this._args.cellWidth ?? 110;

        this._createHorizontalDimensionDropdown();
        this._createVerticalDimensionDropdown();
        this._valueFieldDropdown = this._createDropdown(valueEntries);
        this._onValueFieldChange();
    }

    _createDropdown<T>(entries: Array<DropdownEntry<T>>): IDropdownDefinition<T> {
        return dropdownFlat({
            entryId: "id",
            entryName: "name",
            entries
        });
    }

    _createHorizontalDimensionDropdown(): void {
        this._verticalDimensionDropdown = this._createDropdown(
            this._nonValueEntries.filter(x => x.id !== this._horizontalDimension)
        );
    }

    _createVerticalDimensionDropdown(): void {
        this._horizontalDimensionDropdown = this._createDropdown(
            this._nonValueEntries.filter(x => x.id !== this._verticalDimension)
        );
    }

    _onValueFieldChange(): void {
        this._enableReferenceDropdown =
            this._args.scheme.find(item => item.field === this._valueField)?.isReferenceBound ??
            false;
        if (this._enableReferenceDropdown === false) {
            this._referenceSlot = null;
        }
    }

    // ----------------------------------------------------------------------------------
    //
    _isValid(): boolean {
        return validateCrosstabConfiguration(
            {
                title: this._widgetName,
                description: this._widgetDescription,
                horizontalDimension: this._horizontalDimension,
                verticalDimension: this._verticalDimension,
                value: this._valueField,
                referenceIdx: this._referenceSlot ?? undefined,
                sidebarWidth: this._sidebarWidth,
                cellWidth: this._cellWidth
            },
            this._args.scheme,
            this._args.pageReferences.isAllowed() ? this._args.pageReferences.slots : []
        );
    }

    // ----------------------------------------------------------------------------------
    //
    async _save(): Promise<boolean> {
        if (
            this._horizontalDimension == null ||
            this._verticalDimension == null ||
            this._valueField == null ||
            this._sidebarWidth == null ||
            this._cellWidth == null
        )
            throw Error("Some configuration parameters are undefined.");

        this._resolve({
            title: this._widgetName,
            description: this._widgetDescription,
            tooltip: this._widgetTooltip,
            tooltipLink: this._widgetTooltipLink,
            horizontalDimension: this._horizontalDimension,
            verticalDimension: this._verticalDimension,
            value: this._valueField,
            referenceIdx: this._referenceSlot ?? undefined,
            sidebarWidth: this._sidebarWidth,
            cellWidth: this._cellWidth
        });

        return true;
    }

    _onClose(): void {
        this._close();
    }
}

@Injectable()
export class CrosstabConfigurationDialog extends getDialogFactoryBase(
    CrosstabConfigurationDialogComponent,
    "show"
) {
    constructor(_factory: LgDialogFactory) {
        super(_factory);
    }
}
