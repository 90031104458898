<ng-container *lgPanelHeader="let context; order: -1">
    <div
        *ngIf="_totalsAsTopLevel() || _currentLevel() > 0 || _currentTableLevel() > 0"
        class="lg-reference-dropdown-side-icon lg-reference-dropdown-side-icon--round-corners"
    >
        <lg-icon
            [class.disabled]="_levelDataService.isLoading$ | async"
            icon="icon-go-back"
            (click)="_onGoBack()"
            [clickable]="true"
        ></lg-icon>
    </div>
</ng-container>

<ng-container *lgPanelHeader="let context; order: 1">
    <lgflex-widget-common-header
        [config]="_config"
        [isDataComplete]="_levelDataService.isDataComplete()"
        [isCalculating]="_levelDataService.isCalculating$"
        [isLimitRowsEnabled]="_limitRows() > 0 && _limitRows() < _maxLimitRows"
        [limitRowsTooltipText]="'.LimitRows' | lgTranslate: { count: _limitRows() }"
        [calculationProgress]="_levelDataService.calculationProgress$"
    ></lgflex-widget-common-header>
</ng-container>

<ng-container *lgPanelIcons="let context; order: 1; whenMinimized: false">
    <lgflex-widget-common-icons
        [exportDisabled]="_levelData().length === 0"
        [isLoading$]="_levelDataService.isLoading$"
        [isAutoConverted]="_isAutoConverted()"
        [isConfigValid]="_isConfigValid()"
        (export)="_export($event)"
        (edit)="showConfigurationUi()"
    ></lgflex-widget-common-icons>

    <lg-icon-menu
        class="lg-panel__header-icon"
        [class.disabled]="_levelDataService.isLoading$ | async"
        [definition]="_sortDropdown()"
        [current]="_sortField()"
        (currentChange)="_onReorderChartLevelData($event)"
        icon="icon-sort"
        lgTooltip="{{ '.Sort' | lgTranslate }}"
    ></lg-icon-menu>
</ng-container>

<ng-container *ngIf="_isConfigValid()">
    <lgflex-stale-data-handler
        [state]="_levelDataService.isCalculating$ | async"
        [progress]="_levelDataService.calculationProgress$ | async"
    />

    <div class="flexcol" *ngIf="_pivot != null; else pivotIsNull">
        <lgflex-stale-data-handler
            [state]="_levelDataService.isCalculating$ | async"
            [progress]="_levelDataService.calculationProgress$ | async"
        />

        <lg-rectangle
            class="flex-flexible"
            #rect
            style="overflow: hidden; display: flex; align-items: center; justify-content: center"
            [lgLoaderOverlay]="(_levelDataService.isLoading$ | async) ?? false"
            [lgLoaderOverlayDelay]="100"
        >
            <lgflex-chart
                *ngIf="_levelData().length > 0 && rect.height && rect.width"
                [chartOrientation]="_chartOptions().chartOrientation"
                [data]="_levelData()"
                [groups]="_chartGroups()"
                [height]="rect.height"
                [width]="rect.width"
                [formatterType]="_chartOptions().formatterType"
                [formatterOptions]="_chartOptions().formatterOptions"
                [legendOptions]="{ visible: _chartOptions().labels.legend, position: 'bottom' }"
                [showTooltip]="_chartOptions().labels.tooltip"
                [showLabels]="_chartOptions().labels.chartLabels"
                (tooltipContext)="_tooltipContext = $event"
                [valueLimit]="_chartOptions().valueLimit"
                (itemClick)="_onChartBarClick($event)"
            ></lgflex-chart>
            @if (_levelData()?.length === 0 && !_levelDataService.isChartBuilding()) {
                <div>{{ "_Flexible.Widgets.NoDataWarning" | lgTranslate }}</div>
            }
        </lg-rectangle>
    </div>

    <ng-template #pivotIsNull>
        <div
            class="flexcol"
            [lgLoaderOverlay]="(_levelDataService.isLoading$ | async) ?? false"
        ></div>
    </ng-template>
</ng-container>

<div
    class="flexcol flexcol--full flexcol--center"
    *ngIf="!_isConfigValid()"
    style="justify-content: center"
>
    {{ "_Flexible.Widgets.WidgetValidationError" | lgTranslate }}
</div>

@if (_levelDataService.isChartBuilding()) {
    <lg-loader-tab></lg-loader-tab>
}
