import { ChartWidgetConfig } from "../../chart-widget.configuration.types";
import { PageReferencesService } from "../../../../services/page-references/page-references.service";
import { FieldInfo } from "../../../../types";
import {
    validateChartColumns,
    validateDescription,
    validateDimensions,
    validateTitle
} from "../../../../utilities/widget-configuration-validations";

export function validateChartConfiguration(
    config: ChartWidgetConfig,
    pageReferences: PageReferencesService,
    scheme: FieldInfo[]
): boolean {
    return (
        validateTitle(config.title) &&
        validateDescription(config.description) &&
        validateDimensions(config.tablesConfig, scheme) &&
        validateChartColumns(config.columns, pageReferences, scheme)
    );
}
