import { inject, PipeTransform } from "@angular/core";
import {
    DefinitionDisplayMode,
    IDefinitions,
    LG_APP_DEFINITIONS
} from "@logex/framework/lg-application";

export abstract class AsPipeBase<TAppDefinitions> implements PipeTransform {
    protected _definitions = inject<IDefinitions<TAppDefinitions>>(LG_APP_DEFINITIONS);

    protected constructor(protected _definitionName: Extract<keyof TAppDefinitions, string>) {}

    // Note: we could add caching here, but normally we expect the pipe is pure; and if not it probably has a reason and doesn't want to be cached

    transform(value: any, format?: DefinitionDisplayMode | string, empty?: string): any {
        if (value == null) return empty;

        // Support for `code | asResource:".resourceTypeCode"` syntax
        if (format != null && format.startsWith(".")) {
            const propertyName = format.substr(1);
            const entry = this._definitions.getEntry(this._definitionName, value);
            if (entry != null) {
                return entry[propertyName];
            } else {
                // Fallback to standard scheme to get "Unknown" display name
                format = null;
            }
        }

        return this._definitions.getDisplayName(
            this._definitionName,
            value,
            format as DefinitionDisplayMode
        );
    }
}
