<ng-container *lgPanelHeader>
    <h2>{{ _navigationMenuTitle }}</h2>
</ng-container>

<div class="lg-block--table">
    <ng-container *ngTemplateOutlet="menu; context: { $implicit: _menu, level: 0 }"></ng-container>
</div>

<ng-template #menu let-items let-level="level">
    <ng-container *ngFor="let item of items">
        <ng-container
            *ngTemplateOutlet="menuItem; context: { $implicit: item, level }"
        ></ng-container>

        <ng-container
            *ngTemplateOutlet="menu; context: { $implicit: item.children, level: level + 1 }"
        ></ng-container>
    </ng-container>
</ng-template>

<ng-template #menuItem let-item let-level="level">
    <div
        (click)="_navigate(item)"
        [class.table__row--clickable]="item.id"
        [class.table__row--highlighted]="_isCurrent(item)"
        class="table__row"
    >
        <div class="table__column table__column--empty first"></div>

        <div [style.width.px]="16 * level" class="table__column table__column--empty"></div>

        <div class="table__column last">
            {{ item.name }}
        </div>
    </div>
</ng-template>
