<form (ngSubmit)="_onSubmit()">
    <div *ngIf="_fields?.length" class="lg-menu-popup__group">
        <div class="lg-menu-popup__group__title">
            {{ ".Field" | lgTranslate }}
        </div>
        <div
            *ngFor="let field of _fields"
            class="lg-menu-popup__item"
            [class.lg-menu-popup__item--active]="_isFieldEqual(field.field)"
            tabindex="0"
            (click)="_onFieldSelect(field)"
            (keyup.enter)="_onFieldSelect(field)"
        >
            {{ field.name ?? (field.nameLc ? (field.nameLc | lgTranslate) : "") }}
        </div>
    </div>

    <div class="lg-menu-popup__group lg-menu-popup--leftIcons">
        <div class="lg-menu-popup__group__title">
            {{ ".DifferenceTitle" | lgTranslate }}
        </div>
        <div
            class="lg-menu-popup__item lg-menu-popup__item--left-icon"
            [class.lg-menu-popup__item--active]="_isModeEqual('growth')"
            tabindex="-1"
            (click)="_onModeSelect('growth')"
            (keyup.enter)="_onModeSelect('growth')"
        >
            <lg-icon
                icon="icon-relativefigures"
                class="lg-menu-popup__item__icon"
                [inline]="true"
            ></lg-icon>
            <span>
                {{ ".RelativeDifference" | lgTranslate }}
            </span>
        </div>

        <div
            class="lg-menu-popup__item lg-menu-popup__item--left-icon"
            [class.lg-menu-popup__item--active]="_isModeEqual('diff')"
            tabindex="-1"
            (click)="_onModeSelect('diff')"
            (keyup.enter)="_onModeSelect('diff')"
        >
            <lg-icon
                icon="icon-absolutefigures"
                class="lg-menu-popup__item__icon"
                [inline]="true"
            ></lg-icon>
            <span>
                {{ ".AbsoluteDifference" | lgTranslate }}
            </span>
        </div>
    </div>

    <ng-container *ngIf="_selectedReferences?.length">
        <div class="lg-menu-popup__separator"></div>

        <div [formGroup]="_form!" class="lg-menu-popup__group">
            <div class="lg-menu-popup__group__title">
                {{ ".ComparisonTitle" | lgTranslate }}
            </div>
            <div
                *ngFor="let reference of _selectedReferences; index as i"
                class="lg-menu-popup__item mi-comparison-source-menu-popup__radio-row"
            >
                <div class="mi-comparison-source-menu-popup__radio-row__radios-wrap">
                    <span class="lg-radio-button">
                        <input
                            type="radio"
                            class="lg-radio-button__hidden {{ 'radio-left-' + i }}"
                            formControlName="referenceLeft"
                            [value]="i"
                            tabindex="-1"
                        />
                        <span
                            class="lg-radio-button__visible"
                            (click)="_onFakeRadioSelect('radio-left-' + i)"
                            (keyup.enter)="_onFakeRadioSelect('radio-left-' + i)"
                            tabindex="0"
                        >
                            <!--<span class="lg-radio-button__visible__number">1</span>-->
                        </span>
                    </span>
                    <span class="lg-radio-button">
                        <input
                            type="radio"
                            class="lg-radio-button__hidden {{ 'radio-right-' + i }}"
                            formControlName="referenceRight"
                            [value]="i"
                            tabindex="-1"
                        />
                        <span
                            class="lg-radio-button__visible"
                            (click)="_onFakeRadioSelect('radio-right-' + i)"
                            (keyup.enter)="_onFakeRadioSelect('radio-right-' + i)"
                            tabindex="0"
                        >
                            <!--<span class="lg-radio-button__visible__number">2</span>-->
                        </span>
                    </span>
                </div>

                <span *ngIf="reference.name != null">{{ reference.name }}</span>
                <span *ngIf="reference.nameLc != null">{{ reference.nameLc | lgTranslate }}</span>
            </div>
        </div>
    </ng-container>
    <div
        *ngIf="_args?.showOkButton"
        class="mi-comparison-source-menu-popup__button-row"
        style="margin-top: 0; margin-bottom: 12px"
    >
        <button class="button button--condensed button--primary">
            {{ ".ConfirmButton" | lgTranslate }}
        </button>
    </div>
</form>
