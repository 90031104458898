<lgflex-dialog
    class="widget-configuration-dialog"
    [isReadOnly]="_isReadOnly"
    [isValid]="_isValid()"
    (saveAction)="_save()"
    (closeAction)="_close()"
>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Information' | lgTranslate">
        <lgflex-widget-configuration-section-information
            [widgetName]="_widgetName()"
            [widgetDescription]="_widgetDescription()"
            [widgetTooltip]="_widgetTooltip()"
            [widgetTooltipLink]="_widgetTooltipLink()"
            [dataSourceCode]="_dataSourceCode()"
            [availableReferences]="_references()"
            [selectedReferences]="_selectedReferences()"
            (widgetNameChange)="_widgetName.set($event)"
            (widgetDescriptionChange)="_widgetDescription.set($event)"
            (widgetTooltipChange)="_widgetTooltip.set($event)"
            (widgetTooltipLinkChange)="_widgetTooltipLink.set($event)"
            (onDataSourceChange)="_onDataSourceChange($event)"
            (onSelectedReferencesChange)="_onSelectedReferences($event)"
        />
        <lgflex-form-section>
            <lgflex-form-section-column>
                <lgflex-form-section-header>
                    {{ "_Flexible.WidgetConfiguration.Visualization" | lgTranslate }}
                </lgflex-form-section-header>
                <lgflex-form-row [label]="'.Orientation' | lgTranslate">
                    <lg-dropdown
                        [definition]="_chartOrientationDropdown"
                        [current]="_chartOrientation()"
                        (currentChange)="_chartOrientation.set($event)"
                    />
                </lgflex-form-row>
            </lgflex-form-section-column>
            <lgflex-form-section-column></lgflex-form-section-column>
        </lgflex-form-section>
        <lgflex-form-section [hideDivider]="true">
            <lgflex-form-section-column>
                <lgflex-form-section-header>
                    {{ "_Flexible.WidgetConfiguration.WidgetSettings" | lgTranslate }}
                </lgflex-form-section-header>

                <lgflex-form-row [label]="'.Labels' | lgTranslate">
                    <lg-multi-filter
                        [placeholder]="'.SelectVisibleLabels' | lgTranslate"
                        [source]="_labelsOptions"
                        [(filter)]="_selectedLabels"
                        style="width: 100%"
                    />
                </lgflex-form-row>

                <lgflex-configuration-dialog-format-and-precision
                    [horizontalLayout]="false"
                    [formatType]="_formatType()"
                    [formatPrecision]="_formatPrecision()"
                    [formatForceSign]="_formatForceSign()"
                    (formatTypeChange)="_formatType.set($event)"
                    (formatPrecisionChange)="_formatPrecision.set($event)"
                    (formatForceSignChange)="_formatForceSign.set($event)"
                />
            </lgflex-form-section-column>
            <lgflex-form-section-column></lgflex-form-section-column>
        </lgflex-form-section>
    </lgflex-dialog-tab>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Configuration' | lgTranslate">
        <lgflex-dialog-grid>
            <lgflex-dialog-grid-column class="lgflex-chart-configuration-column">
                <lgflex-dialog-grid-panel>
                    <lgflex-dialog-grid-panel-header>
                        <lgflex-dialog-grid-panel-header-title>
                            {{ "_Flexible.WidgetConfiguration.Dimensions" | lgTranslate }}
                        </lgflex-dialog-grid-panel-header-title>
                        <lgflex-dialog-grid-panel-header-buttons>
                            <lg-placeholder name="pivotLevelsSelector.dropdownButtonContainer" />
                        </lgflex-dialog-grid-panel-header-buttons>
                    </lgflex-dialog-grid-panel-header>
                    <lgflex-dialog-grid-panel-body>
                        <lgflex-table-dimensions-selector
                            #tableDimensionSelector
                            [availableDimensions]="_availableDimensions()"
                            [tablesConfig]="$any(_tablesConfig())"
                            (tablesConfigChange)="_onTablesConfigChange($event)"
                            (tableSelectChange)="_onTableSelect($event)"
                            (dimensionSelectChange)="_onDimensionSelect($event)"
                            [hideBorder]="true"
                            [nonAggregatedFieldSelected]="_nonAggregatedFieldSelected()"
                        />
                    </lgflex-dialog-grid-panel-body>
                </lgflex-dialog-grid-panel>
                <lgflex-dialog-grid-panel>
                    <lgflex-dialog-grid-panel-header>
                        <lgflex-dialog-grid-panel-header-title>
                            {{ ".Columns" | lgTranslate }}
                        </lgflex-dialog-grid-panel-header-title>
                        <lgflex-dialog-grid-panel-header-buttons>
                            <lg-quick-settings-menu-button
                                [disabled]="!_barsEnabled() && !_linesEnabled()"
                                [definition]="_chartTypeMenuDefinition()"
                                text="{{ '.AddColumn' | lgTranslate }}"
                                icon="icon-add"
                            >
                            </lg-quick-settings-menu-button>
                        </lgflex-dialog-grid-panel-header-buttons>
                    </lgflex-dialog-grid-panel-header>
                    <lgflex-dialog-grid-panel-body>
                        <lgflex-row-selector
                            #rowSelector
                            [hideBorder]="true"
                            [rows]="_rows()"
                            (rowSelected)="_onColumnSelect($event)"
                            (rowRemoved)="_onColumnRemoved($event)"
                            (rowsSorted)="_onRowSort($event)"
                        ></lgflex-row-selector>
                    </lgflex-dialog-grid-panel-body>
                </lgflex-dialog-grid-panel>
            </lgflex-dialog-grid-column>
            <lgflex-dialog-grid-column class="lgflex-chart-configuration-column">
                <lgflex-dialog-grid-panel>
                    <lgflex-dialog-grid-panel-header>
                        <lgflex-dialog-grid-panel-header-title>
                            {{ ".Properties" | lgTranslate }}
                        </lgflex-dialog-grid-panel-header-title>
                    </lgflex-dialog-grid-panel-header>
                    <lgflex-dialog-grid-panel-body>
                        <ng-container *ngIf="_currentColumn()">
                            <lgflex-form-section
                                [hideDivider]="true"
                                class="configuration-container__widget-settings__content"
                                [class.configuration-container__widget-settings__content--centered]="
                                    !_currentColumn()
                                "
                            >
                                <lgflex-form-row [label]="'.ChartType' | lgTranslate">
                                    <lg-dropdown
                                        [definition]="_chartTypeDropdownDefinition()"
                                        [current]="_currentColumn().chartType"
                                        (currentChange)="_onChartTypeChanged($event)"
                                    ></lg-dropdown>
                                </lgflex-form-row>

                                <lgflex-configuration-dialog-formula
                                    [column]="_currentColumn()"
                                    (columnChange)="_onColumnChanged($event)"
                                    [noFormatting]="true"
                                    [fieldsDropdownDefinition]="_valueFieldDropdown()"
                                    [areReferencesAllowed]="_areReferencesAllowed"
                                    [referencesDropdownDefinition]="_referenceDropdown"
                                ></lgflex-configuration-dialog-formula>
                            </lgflex-form-section>
                        </ng-container>

                        <ng-container *ngIf="_currentTable() as table">
                            <lgflex-form-row [label]="'.Title' | lgTranslate">
                                <input
                                    class="input input--wide"
                                    type="text"
                                    [ngModel]="table.name"
                                    (ngModelChange)="_onTablePropertyChange($event, 'name')"
                                />
                            </lgflex-form-row>
                            <lgflex-form-row
                                [label]="'_Flexible.ChartWidgetDialog.Dimension' | lgTranslate"
                            >
                                <lg-dropdown
                                    class="formulaVariableDropdown"
                                    [disabled]="_nonAggregatedFieldSelected()"
                                    [definition]="_dimensionDropdownDefinition()"
                                    [current]="table.stackedBarDimension"
                                    (currentChange)="_onStackedBarDimensionChange($event, table.id)"
                                    [itemTooltips]="true"
                                    [emptyAcceptable]="true"
                                ></lg-dropdown>
                            </lgflex-form-row>
                            <lgflex-form-section [hideDivider]="true">
                                <lgflex-form-section-header>
                                    {{ ".LimitRows" | lgTranslate }}
                                </lgflex-form-section-header>
                                <lgflex-form-row [label]="'.Rows' | lgTranslate">
                                    <div class="lgflex-level-properties-limit-row">
                                        <lg-slider
                                            [min]="1"
                                            [max]="100"
                                            [step]="1"
                                            [highlightStep]="50"
                                            [tickerStep]="10"
                                            [allowPlusInfinity]="true"
                                            [ngModel]="_currentTable().limitRows"
                                            (ngModelChange)="_onLimitRowChange($event)"
                                            [plusInfinityText]="'.MaxRows' | lgTranslate"
                                        />
                                        @if (_currentTable().limitRows === _maxLimitRows) {
                                            <input
                                                [value]="'.MaxRows' | lgTranslate"
                                                [disabled]="true"
                                                width="90px"
                                                class="input lgflex-table-properties-limit-row-input"
                                                type="text"
                                            />
                                        } @else {
                                            <input
                                                [ngModel]="_currentTable().limitRows"
                                                (ngModelChange)="_onLimitRowChange($event)"
                                                [max]="100"
                                                [min]="1"
                                                width="90px"
                                                class="input lgflex-table-properties-limit-row-input"
                                                type="number"
                                            />
                                        }
                                    </div>
                                </lgflex-form-row>
                                <lgflex-form-section-header>
                                    {{ ".OrderBy" | lgTranslate }}
                                </lgflex-form-section-header>
                                <lgflex-level-sorting-form
                                    [levelSortingItems]="_currentTable().orderBy"
                                    [sortingDropdown]="_sortingTableDropdown()"
                                    [addSortingBtnDisabled]="_addSortingBtnDisabled()"
                                    (sortingAdded)="_onSortingAdd()"
                                    (sortingRemoved)="_onSortingRemove($event)"
                                    (sortingItemUpdated)="_onTableSortingItemUpdate($event)"
                                    (sortingItemDrop)="_onSortingDrop($event)"
                                >
                                </lgflex-level-sorting-form>
                            </lgflex-form-section>
                        </ng-container>

                        <ng-container *ngIf="_currentDimension() as dimension">
                            <lgflex-form-row [label]="'.Title' | lgTranslate">
                                <input
                                    class="input input--wide"
                                    type="text"
                                    [placeholder]="_getSchemaFieldTitle(dimension.fieldId)"
                                    [ngModel]="dimension.name"
                                    (ngModelChange)="
                                        _onDimensionPropertyChange($event, 'name', dimension.id)
                                    "
                                />
                            </lgflex-form-row>

                            <lgflex-form-row [label]="'.DisplayMode' | lgTranslate">
                                <lg-dropdown
                                    [definition]="_labelDisplayModeDropdown"
                                    [current]="dimension.displayMode ?? _labelDisplayMode"
                                    (currentChange)="
                                        _onDimensionPropertyChange(
                                            $event,
                                            'displayMode',
                                            dimension.id
                                        )
                                    "
                                />
                            </lgflex-form-row>

                            <lgflex-form-section [hideDivider]="true">
                                <lgflex-form-section-header>
                                    {{ ".OrderBy" | lgTranslate }}
                                </lgflex-form-section-header>
                                <lgflex-level-sorting-form
                                    [levelSortingItems]="_currentDimension().orderBy"
                                    [sortingDropdown]="_sortingDimensionDropdown()"
                                    [oneItemMode]="true"
                                    (sortingItemUpdated)="_onDimensionSortingItemUpdate($event)"
                                >
                                </lgflex-level-sorting-form>
                            </lgflex-form-section>
                        </ng-container>

                        <div
                            style="text-align: center; line-height: 100px"
                            *ngIf="!_currentColumn() && !_currentTable() && !_currentDimension()"
                        >
                            {{ ".SelectColumnText" | lgTranslate }}
                        </div>
                    </lgflex-dialog-grid-panel-body>
                </lgflex-dialog-grid-panel>
            </lgflex-dialog-grid-column>
        </lgflex-dialog-grid>
    </lgflex-dialog-tab>
</lgflex-dialog>
