import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LG_APP_CONFIGURATION, ServerGatewayBase } from "@logex/framework/lg-application";
import { urlConcat } from "@logex/framework/utilities";

import { GetFlexibleDatasetSchemeResponse } from "./flex-data-client-gateway.types";
import { FlexDataClientMetadataArguments } from "../types/types";

@Injectable()
export class FlexDataClientGateway extends ServerGatewayBase {
    constructor() {
        super();

        const appConfiguration = inject(LG_APP_CONFIGURATION);
        this._setBaseUrl(appConfiguration.applicationRoot);
    }

    getScheme(
        flexDataApiUrl: string,
        metadataArgs: FlexDataClientMetadataArguments
    ): Observable<GetFlexibleDatasetSchemeResponse> {
        return this._get<GetFlexibleDatasetSchemeResponse>(urlConcat(flexDataApiUrl, "scheme"), {
            params: metadataArgs
        });
    }
}
