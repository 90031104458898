import { BehaviorSubject, Observable } from "rxjs";
import { around, before, mixin } from "@logex/mixin-flavors";

import { LgPromptDialog, LgDialogRef } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";

export class DialogMixin<TDialogComponent> {
    // ----------------------------------------------------------------------------------
    // Dependencies
    _dialogRef!: LgDialogRef<TDialogComponent>;
    _promptDialog!: LgPromptDialog;
    _lgTranslate!: LgTranslateService;

    // ----------------------------------------------------------------------------------
    // Fields

    _initialized!: boolean;
    _isSaving!: boolean;
    _readySubject!: BehaviorSubject<boolean>;
    _ready!: Observable<boolean>;

    // ----------------------------------------------------------------------------------
    //
    @before
    _initMixins(): void {
        if (this._dialogRef == null) throw Error("_dialogRef must be injected");
        if (this._promptDialog == null) throw Error("_promptDialog must be injected");
        if (this._lgTranslate == null) throw Error("_lgTranslate must be injected");

        this._initialized = false;
        this._isSaving = false;
        this._readySubject = new BehaviorSubject<boolean>(false);
        this._ready = this._readySubject.asObservable();
    }

    @around
    async _activate(): Promise<void> {
        await mixin.applyNextAround();

        this._initialized = true;
        this._readySubject.next(true);
    }

    // Saving

    _isChanged(): boolean {
        return false;
    }

    @around
    async _save(): Promise<boolean> {
        if (this._isSaving) return false;

        let res: boolean;
        try {
            this._isSaving = true;
            res = await mixin.applyNextAround();
        } finally {
            this._isSaving = false;
        }

        // Close the dialog if `_save()` returned `true`
        if (res) {
            this._dialogRef.close();
        }

        return res;
    }

    async _close(): Promise<void> {
        if (this._isChanged()) {
            this._confirmAndClose();
        } else {
            this._dialogRef.close();
        }
    }

    _tryClose(): boolean {
        if (!this._isChanged()) return true;

        this._confirmAndClose(); // No await for a reason

        return false;
    }

    async _confirmAndClose(): Promise<void> {
        const confirmation = await this._promptDialog.confirm(
            this._lgTranslate.translate(".ConfirmDiscard.Title"),
            this._lgTranslate.translate(".ConfirmDiscard.Body"),
            {
                buttons: [
                    {
                        id: "save",
                        name: this._lgTranslate.translate(".ConfirmDiscard.Save"),
                        isConfirmAction: true
                    },
                    {
                        id: "discard",
                        name: this._lgTranslate.translate(".ConfirmDiscard.Discard")
                    },
                    {
                        id: "cancel",
                        name: this._lgTranslate.translate(".ConfirmDiscard.Cancel"),
                        isCancelAction: true
                    }
                ],
                columns: 3
            }
        );

        switch (confirmation) {
            case "save":
                await this._save();
                return;

            case "discard":
                this._dialogRef.close();
                break;

            case "cancel":
        }
    }
}
