import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLayoutModule } from "@logex/framework/lg-layout";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LgChartsModule } from "@logex/framework/lg-charts";
import { LgPivotTableModule } from "@logex/framework/lg-pivot-table";
import { UiToolboxModule } from "@logex/framework/ui-toolbox";
import { LoadManagerModule } from "@logex/load-manager";

import { FlexDataClientGateway } from "./services/flex-data-client/gateway/flex-data-client-gateway";
import { FlexibleDataset } from "./services/flexible-dataset";
import { FlexibleDatasetGateway } from "./services/flexible-dataset/gateways/flexible-dataset-gateway";
import { FlexibleLayoutComponent } from "./components/flexible-layout/flexible-layout.component";
import { FlexibleLayoutIconsDirective } from "./components/flexible-layout/flexible-layout-icons.directive";
import {
    PivotLevelsSelectorDialog,
    PivotLevelsSelectorDialogComponent
} from "./components/pivot-levels-selector-dialog/pivot-levels-selector-dialog.component";
import { FlexiblePivotDifferenceColumnHeaderComponent } from "./components/flexible-pivot-difference-column/difference-column-header.component";
import { FlexiblePivotDifferenceColumnHeaderPopupComponent } from "./components/flexible-pivot-difference-column/difference-column-header-popup.component";
import { FlexibleLayoutEditorSidebarComponent } from "./components/flexible-layout-editor-sidebar/flexible-layout-editor-sidebar.component";
import {
    FlexibleLayoutEditorDialogComponent,
    LayoutEditorDialog
} from "./components/flexible-layout-editor-dialog/flexible-layout-editor-dialog.component";
import {
    ReferenceSlotsEditorDialog,
    ReferenceSlotsEditorDialogComponent
} from "./components/flexible-layout-editor-dialog/components/reference-slots-editor-dialog/reference-slots-editor-dialog.component";
import { ReferenceCombinationsService } from "./components/flexible-layout-editor-sidebar/reference-combinations.service";
import {
    ReferenceCombinationEditorDialog,
    ReferenceCombinationEditorDialogComponent
} from "./components/flexible-layout-editor-sidebar/components/reference-combination-editor-dialog/reference-combination-editor-dialog.component";
import { FlexibleLayoutWidgetHostDirective } from "./components/flexible-layout/flexible-layout-widget-host.directive";
import {
    LibraryConfirmDialog,
    LibraryConfirmDialogComponent
} from "./components/flexible-layout-editor-dialog/components/library-confirm-dialog/library-confirm-dialog.component";
import { PieChartWidgetComponent } from "./widgets/pie-chart";
import { CrosstabWidgetComponent } from "./widgets/crosstab-widget/crosstab-widget.component";
import {
    CrosstabConfigurationDialog,
    CrosstabConfigurationDialogComponent
} from "./widgets/crosstab-widget/components/crosstab-configuration-dialog/crosstab-configuration-dialog.component";
import { IconChartWidgetComponent } from "./widgets/icon-chart";
import {
    IconChartConfigurationDialog,
    IconChartConfigurationDialogComponent
} from "./widgets/icon-chart/components/icon-chart-configuration-dialog/icon-chart-configuration-dialog.component";
import { StaleDataHandlerDirective } from "./components/stale-data-handler/stale-data-handler.directive";
import { LgIconSelectorComponent } from "./components/lg-icon-selector/lg-icon-selector.component";
import { LgPivotLevelsSelectorLegacyComponent } from "./components/lg-pivot-levels-selector-legacy/lg-pivot-levels-selector-legacy.component";
import { LgTreeSelectorComponent } from "./components/lg-tree-selector/lg-tree-selector.component";
import { FlexDataSourcesServiceGateway } from "./services/flex-data-sources/gateway/flex-data-sources-gateway";
import { WidgetExportExcelService } from "./services/widget-export/widget-export-excel.service";
import { BubbleChartWidgetComponent } from "./widgets/bubble-chart";
import {
    BubbleChartConfigurationDialog,
    BubbleChartConfigurationDialogComponent
} from "./widgets/bubble-chart/components/bubble-chart-configuration-dialog/bubble-chart-configuration-dialog.component";
import { LgPivotLevelsSelectorComponent } from "./components/lg-pivot-levels-selector/lg-pivot-levels-selector.component";
import {
    PieChartConfigurationDialog,
    PieChartConfigurationDialogComponent
} from "./widgets/pie-chart/components/pie-chart-configuration-dialog/pie-chart-configuration-dialog.component";
import { bubbleChartV1toV2MigrationProvider } from "./widgets/bubble-chart/conversions";
import { ConfigurationDialogFormulaComponent } from "./components/configuration-dialog-sections/formula/configuration-dialog-formula.component";
import { ConfigurationDialogFormatAndPrecisionComponent } from "./components/configuration-dialog-sections/format-and-precision/configuration-dialog-format-and-precision.component";
import { WidgetCommonHeaderComponent } from "./components/widget-common-header/widget-common-header.component";
import { WidgetConfigurationSectionInformationComponent } from "./components/configuration-dialog-sections/widget-configuration-section-information/widget-configuration-section-information.component";
import {
    LayoutBookmarkEditDialog,
    LayoutBookmarkEditDialogComponent
} from "./services/layout-bookmarks-store";
import { WidgetCommonIconsComponent } from "./components/widget-common-icons/widget-common-icons.component";
import {
    LgFlexibleLayoutsItemComponent,
    LgFlexibleLayoutsPanelComponent
} from "./components/lg-flexible-layouts-panel";
import {
    FlexiblePageAuthorizationService,
    LG_FLEX_PAGE_AUTHORIZATION
} from "./services/flexible-page-authorization";
import {
    ChartConfigurationDialogComponent,
    VerticalChartConfigurationDialog
} from "./widgets/chart/components/chart-configuration-dialog/chart-configuration-dialog.component";
import { ChartWidgetComponent } from "./widgets/chart";
import { ChartComponent } from "./widgets/chart/components/chart/chart.component";
import { DrilldownPivotFormulaConfiguration } from "./widgets/base/drilldown-table-base/drilldown-pivot-configuration-dialog/components/drilldown-pivot-formula-configuration/drilldown-pivot-formula-configuration.component";
import {
    DrilldownPivotConfigurationDialog,
    DrilldownPivotConfigurationDialogComponent
} from "./widgets/base/drilldown-table-base/drilldown-pivot-configuration-dialog/drilldown-pivot-configuration-dialog.component";

import {
    PivotHeaderMainLevelComponent,
    PivotTableComponent,
    ReferenceSlotComponent,
    SimpleGroupComponent,
    TableHeaderMainLevelComponent,
    TableHeaderSublevelComponent
} from "./components/pivot-table";
import { FlexibleDrilldownTableFooterComponent } from "./components/base/flexible-drilldown-base/flexible-drilldown-table-footer.component";
import { FlexibleDrilldownTableRowContentComponent } from "./components/base/flexible-drilldown-base/flexible-drilldown-table-row-content.component";
import { FlexibleDrilldownTableHeaderRowComponent } from "./components/base/flexible-drilldown-base/flexible-drilldown-table-header-row.component";
import { FlexibleLayoutHost } from "./components/lgflex-flexible-layout-host/lgflex-flexible-layout-host.component";
import {
    SingleValueChartVisualizationWidgetComponent,
    SingleValueChartVisualizationWidgetConfigurator
} from "./widgets/visualization/single-value-chart";
import { SingleValueChartVisualizationConfigurationComponent } from "./widgets/visualization/single-value-chart/components/single-value-chart-visualization-configuration/single-value-chart-visualization-configuration.component";
import { SingleValueChartComponent } from "./widgets/visualization/single-value-chart/components/single-value-chart/single-value-chart.component";
import { ConditionalFormattingConfigurationComponent } from "./components/conditional-formatting/components/conditional-formatting-configuration/conditional-formatting-configuration.component";
import { ConditionalFormattingDirective } from "./components/conditional-formatting/conditional-formatting.directive";
import { ModReferenceVariablePipe } from "./pipes/mod-reference-variable.pipe";
import { DialogComponent } from "./components/dialog/dialog.component";
import { DialogTabComponent } from "./components/dialog-tab/dialog-tab.component";
import { FormSectionComponent } from "./components/form-section/form-section.component";
import { FormRowComponent } from "./components/form-row/form-row.component";
import { FormSectionHeaderComponent } from "./components/form-section-header/form-section-header.component";
import { FormSectionColumnComponent } from "./components/form-section-column/form-section-column.component";
import {
    DialogGridColumnComponent,
    DialogGridComponent,
    DialogGridPanelBodyComponent,
    DialogGridPanelComponent,
    DialogGridPanelHeaderButtonsComponent,
    DialogGridPanelHeaderComponent,
    DialogGridPanelHeaderTitleComponent,
    DialogGridRowComponent
} from "./components/dialog-grid";

import {
    ColumnsGroupPropertiesComponent,
    ColumnsValuePropertiesComponent,
    DimensionPropertiesComponent,
    drilldownFlatTableMigrationProvider,
    drilldownPivotTableEmbeddedWidgetsToMigrateProvider,
    drilldownPivotTableMigrationProvider,
    LevelPropertiesComponent,
    PivotTableColumnsGroupsComponent,
    PivotTableConfigurationDialog,
    PivotTableConfigurationDialogComponent,
    PivotTableConfigurationService,
    PivotTableDataService,
    PivotTableFormulaConfigurationComponent,
    pivotTableMigrationProvider,
    PivotTableWidgetComponent,
    replaceWithdrilldownPivotTableConversions
} from "./widgets/pivot-table";
import { chartMigrationProvider } from "./widgets/chart/conversions";
import { LevelSortingFormComponent } from "./components/level-sorting-form/level-sorting-form.component";
import { TableDimensionsSelectorComponent } from "./components/table-dimensions-selector/table-dimensions-selector.component";
import { DimensionNameTransform } from "./components/table-dimensions-selector/pipes/dimension-name-transform";
import { RowSelectorComponent } from "./components/row-selector/row-selector.component";
import { replaceHorizontalChartWithChart } from "./widgets/chart/conversions/replace-horizontal-chart-with-chart";
import {
    GroupNameIconWarningComponent,
    LayoutManagementDataSourceNamesPipe,
    LayoutManagementDialog,
    LayoutManagementDialogComponent,
    LayoutManagementGatewayAdapterService,
    LayoutManagementGroupSelectionPipe
} from "./components/layout-management-dialog";
import { FlexibleBreadcrumbComponent } from "./components/flexible-breadcrumb/flexible-breadcrumb.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UiToolboxModule,
        UiCoreModule,
        LgLayoutModule,
        LgLayoutModule,
        LgLocalizationModule,
        LgChartsModule.forRoot({ useNewColorPalette: true, useNewLabels: true }),
        LgPivotTableModule,
        DragDropModule,

        DimensionNameTransform,
        LoadManagerModule,
        LayoutManagementGroupSelectionPipe,
        LayoutManagementDataSourceNamesPipe
    ],
    declarations: [
        FlexibleLayoutComponent,
        FlexibleLayoutIconsDirective,
        FlexibleLayoutWidgetHostDirective,
        FlexibleLayoutHost,

        PivotTableComponent,
        TableHeaderMainLevelComponent,
        TableHeaderSublevelComponent,
        PivotHeaderMainLevelComponent,
        ReferenceSlotComponent,
        SimpleGroupComponent,
        FlexiblePivotDifferenceColumnHeaderComponent,
        FlexiblePivotDifferenceColumnHeaderPopupComponent,

        FormSectionComponent,
        FormRowComponent,
        FormSectionHeaderComponent,
        FormSectionColumnComponent,
        DialogTabComponent,
        DialogGridComponent,
        DialogGridColumnComponent,
        DialogGridRowComponent,
        DialogGridPanelComponent,
        DialogGridPanelHeaderComponent,
        DialogGridPanelHeaderTitleComponent,
        DialogGridPanelHeaderButtonsComponent,
        DialogGridPanelBodyComponent,

        DialogComponent,
        PivotLevelsSelectorDialogComponent,
        DrilldownPivotConfigurationDialogComponent,
        PivotTableWidgetComponent,
        PivotTableColumnsGroupsComponent,
        LevelPropertiesComponent,
        DimensionPropertiesComponent,
        ColumnsGroupPropertiesComponent,
        ColumnsValuePropertiesComponent,
        PivotTableConfigurationDialogComponent,
        PivotTableFormulaConfigurationComponent,
        PieChartConfigurationDialogComponent,
        CrosstabConfigurationDialogComponent,
        IconChartConfigurationDialogComponent,
        BubbleChartConfigurationDialogComponent,
        ChartConfigurationDialogComponent,

        FlexibleLayoutEditorSidebarComponent,
        FlexibleLayoutEditorDialogComponent,
        ReferenceSlotsEditorDialogComponent,
        ReferenceCombinationEditorDialogComponent,
        LibraryConfirmDialogComponent,

        PieChartWidgetComponent,
        IconChartWidgetComponent,
        CrosstabWidgetComponent,
        BubbleChartWidgetComponent,
        ChartWidgetComponent,
        SingleValueChartVisualizationWidgetComponent,

        StaleDataHandlerDirective,
        LgIconSelectorComponent,
        LgPivotLevelsSelectorLegacyComponent,
        LgPivotLevelsSelectorComponent,
        LgTreeSelectorComponent,
        DrilldownPivotFormulaConfiguration,
        ConfigurationDialogFormulaComponent,
        ConfigurationDialogFormatAndPrecisionComponent,
        LgFlexibleLayoutsPanelComponent,
        LgFlexibleLayoutsItemComponent,
        WidgetConfigurationSectionInformationComponent,
        WidgetCommonHeaderComponent,
        WidgetCommonIconsComponent,
        ChartComponent,
        SingleValueChartVisualizationConfigurationComponent,
        SingleValueChartComponent,
        ConditionalFormattingConfigurationComponent,
        ConditionalFormattingDirective,

        LayoutBookmarkEditDialogComponent,
        FlexibleDrilldownTableFooterComponent,
        FlexibleDrilldownTableRowContentComponent,
        FlexibleDrilldownTableHeaderRowComponent,
        LevelSortingFormComponent,
        TableDimensionsSelectorComponent,
        RowSelectorComponent,
        LayoutManagementDialogComponent,
        GroupNameIconWarningComponent,

        ModReferenceVariablePipe,
        FlexibleBreadcrumbComponent
    ],
    exports: [
        FlexibleLayoutComponent,
        FlexibleLayoutIconsDirective,
        FlexibleLayoutWidgetHostDirective,
        FlexibleLayoutHost,

        PivotTableComponent,

        FlexibleLayoutEditorSidebarComponent,

        StaleDataHandlerDirective,
        LgIconSelectorComponent,
        LgPivotLevelsSelectorLegacyComponent,
        LgPivotLevelsSelectorComponent,
        LgTreeSelectorComponent,
        LgFlexibleLayoutsPanelComponent,
        FlexibleDrilldownTableFooterComponent,
        FlexibleDrilldownTableRowContentComponent,
        FlexibleDrilldownTableHeaderRowComponent,
        LevelSortingFormComponent,
        TableDimensionsSelectorComponent,
        RowSelectorComponent,
        FlexibleBreadcrumbComponent
    ],
    providers: [
        FlexDataClientGateway,
        FlexibleDataset,
        FlexibleDatasetGateway,
        LayoutManagementGatewayAdapterService,
        SingleValueChartVisualizationWidgetConfigurator,
        PivotLevelsSelectorDialog,
        DrilldownPivotConfigurationDialog,
        PivotTableConfigurationDialog,
        PieChartConfigurationDialog,
        CrosstabConfigurationDialog,
        IconChartConfigurationDialog,
        BubbleChartConfigurationDialog,
        VerticalChartConfigurationDialog,

        LayoutEditorDialog,
        ReferenceCombinationsService,
        ReferenceSlotsEditorDialog,
        ReferenceCombinationEditorDialog,
        LibraryConfirmDialog,
        LayoutBookmarkEditDialog,

        WidgetExportExcelService,
        FlexDataSourcesServiceGateway,

        ModReferenceVariablePipe,

        LayoutManagementDialog,

        PivotTableDataService,
        PivotTableConfigurationService,

        replaceWithdrilldownPivotTableConversions,
        replaceHorizontalChartWithChart,
        drilldownPivotTableMigrationProvider,
        drilldownPivotTableEmbeddedWidgetsToMigrateProvider,
        drilldownFlatTableMigrationProvider,
        pivotTableMigrationProvider,

        bubbleChartV1toV2MigrationProvider,
        chartMigrationProvider,

        { provide: LG_FLEX_PAGE_AUTHORIZATION, useClass: FlexiblePageAuthorizationService }
    ]
})
export class FlexibleModule {}
