import { FactoryProvider, ValueProvider } from "@angular/core";
import { PageWidgetConfigBase } from "../../types";
import {
    ConfigMigratorDict,
    EMBEDDED_WIDGETS_TO_MIGRATE,
    EmbeddedWidgetsToMigrate,
    EmbeddedWidgetsToMigrateRecord,
    WIDGET_MIGRATION,
    WIDGET_REPLACEMENT,
    WidgetMigrationRecord,
    WidgetReplacer,
    WidgetReplacerDeclaration
} from "./widget-types-registry.types";

/**
 * Create provider for handling widget configuration version upgrades. You're expected to provide
 * a dictionary of sourceVersion -> handler mapping. The handler is expected to upgrade the configuration
 * "in place". It can then return the new version it just created. If it doesn't, it will be increased
 * by 1 automatically.
 *
 * The handling code will automatically run multiple migrations, until it arrives at the current version
 * (but by returning new version, you can implement multi-version jump should you wish to)
 *
 * Past migrators should be never removed, unless you are absolutely sure no old configuration can exist
 * in the database.
 *
 * @example
 * provideWidgetMigration(PIVOT_TABLE_WIDGET, {
 *   1: config => {
 *        config.description = "From version 1";
 *      }
 * })
 *
 * For the general overview, please see https://logexgroup.atlassian.net/wiki/spaces/faDevPlatform/pages/2579366089/Widgets+configuration+upgrade
 */
export function provideWidgetMigration(id: string, migrations: ConfigMigratorDict): ValueProvider {
    const useValue: WidgetMigrationRecord = {
        id,
        migrations
    };

    return {
        provide: WIDGET_MIGRATION,
        useValue,
        multi: true
    };
}

/**
 * Create provider for handling version conversion, which depends on additional services (injection factory-like)
 *
 * @example
 * provideWidgetMigrationFactory(
 *   PIVOT_TABLE_WIDGET,
 *   [LgTranslateService],
 *   (translate: LgTranslateService) => ({
 *   1: config => {
 *        config.description = translate.translate(
 *          "_Flexible.StandardLayouts.SplitHorizontal"
 *        );
 *      }
 *   })
 * )
 *
 * @see provideWidgetMigration
 */
export function provideWidgetMigrationFactory(
    id: string,
    deps: any[],
    factory: (...deps: any[]) => ConfigMigratorDict
): FactoryProvider {
    return {
        provide: WIDGET_MIGRATION,
        useFactory: (deps: any[]) => ({
            id,
            migrations: factory(deps)
        }),
        deps,
        multi: true
    };
}

/**
 * Create provider for widget replacement function. The function must accept the original
 * widget's configuration (newest version) and return the target widget configuration (newest version).
 *
 * The handling code can evaluate a whole chain of conversions (widget1 -> widget2 -> widget3), even
 * if some on the path are removed. You therefore don't need to support N*N converters (obviously, depending
 * on how losy the individual steps are). The code will also automatically run version migration on the
 * initial config.
 *
 * @param sourceType ID of the original widget
 * @param targetType ID of the target widget
 * @param replacer the conversion function
 * @param priority priority of the conversion. This may be used to pick automatic convertor for removed widgets
 */
export function provideWidgetReplacement<
    TSrc extends PageWidgetConfigBase = any,
    TRes extends PageWidgetConfigBase = any
>(
    sourceType: string,
    targetType: string,
    replacer: WidgetReplacer<TSrc, TRes>,
    priority: number = 1
): ValueProvider {
    return {
        provide: WIDGET_REPLACEMENT,
        useValue: {
            sourceType,
            targetType,
            replacer,
            priority
        },
        multi: true
    };
}

/**
 * Create provider for multiple widget replacers
 *
 * @see provideWidgetReplacement
 */
export function provideWidgetReplacements(
    replacements: WidgetReplacerDeclaration[]
): ValueProvider {
    return {
        provide: WIDGET_REPLACEMENT,
        useValue: replacements,
        multi: true
    };
}

/**
 * Create provider for multiple widget replacers, using injected dependencies (factory).
 *
 * @see provideWidgetReplacement
 */
export function provideWidgetReplacementsFactory(
    deps: any[],
    factory: (...deps: any[]) => WidgetReplacerDeclaration | WidgetReplacerDeclaration[]
): FactoryProvider {
    return {
        provide: WIDGET_REPLACEMENT,
        useFactory: factory,
        deps,
        multi: true
    };
}

/**
 * Create provider for embedded widgets attached to parent widget.
 *
 * @see provideWidgetReplacement
 */
export function provideEmbeddedWidgetsToMigrate(
    id: string,
    embeddedWidgets?: EmbeddedWidgetsToMigrate
): ValueProvider {
    const useValue: EmbeddedWidgetsToMigrateRecord = {
        id,
        embeddedWidgets
    };

    return {
        provide: EMBEDDED_WIDGETS_TO_MIGRATE,
        useValue,
        multi: true
    };
}
