import { Inject, Injectable } from "@angular/core";
import { IFlexiblePageAuthorization } from "./flexible-page-authorization.types";
import { IUser, LG_USER_INFO } from "@logex/framework/lg-application";
import { ApplicationPermissions } from "../../types/app-permissions";

@Injectable()
export class FlexiblePageAuthorizationService implements IFlexiblePageAuthorization {
    constructor(@Inject(LG_USER_INFO) private _userInfo: IUser) {}

    canCreateLayout(): Promise<boolean> {
        return Promise.resolve(
            this._userInfo.hasPermission(ApplicationPermissions.MANAGE_LAYOUTS_PERSONAL)
        );
    }

    canEditWidget(): Promise<boolean> {
        return Promise.resolve(
            this._userInfo.hasPermission(ApplicationPermissions.MANAGE_LAYOUTS_PERSONAL)
        );
    }

    canEditWidgetTooltipLink(): Promise<boolean> {
        return Promise.resolve(this._userInfo.hasPermission(ApplicationPermissions.INTERNAL));
    }

    canExportWidget(): Promise<boolean> {
        return Promise.resolve(this._userInfo.hasPermission(ApplicationPermissions.WIDGET_EXPORT));
    }

    canEditLayoutGroups(): Promise<boolean> {
        return Promise.resolve(
            this._userInfo.hasPermission(ApplicationPermissions.MANAGE_LAYOUTS_CATALOG)
        );
    }
}
