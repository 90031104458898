<div class="form-group">
    <div class="form-split">
        <div class="form-split__primary">
            {{ ".SelectedItemsLabel" | lgTranslate }}
        </div>

        <div class="form-split__primary">
            {{ ".AvailableItemsLabel" | lgTranslate }}
            <div class="text-gray">
                {{ ".SelectedItemsCount" | lgTranslate : _getCounterTranslateParams() }}
            </div>
        </div>
    </div>

    <div class="form-split">
        <div class="form-split__primary">
            <div class="form-row form-row--table form-row--no-bottom-margin">
                <div
                    class="pivot-levels-selector__chosen-items form-row__controls lg-block--table flexcol flexcol--full"
                    cdkDropList
                    #selectedItems="cdkDropList"
                    [cdkDropListData]="_selectedLevels"
                    [cdkDropListConnectedTo]="[availableItems]"
                    (cdkDropListDropped)="_drop($event)"
                    style="height: 40vh"
                >
                    <div
                        class="table__row pivot-levels-selector__draggable-row"
                        *ngFor="let item of _selectedLevels; index as idx"
                        cdkDrag
                    >
                        <div class="table__column--icons first table__column">
                            <lg-icon
                                class="pivot-levels-selector__handle"
                                icon="icon-draggable"
                            ></lg-icon>
                        </div>
                        <div class="table__column" style="width: 100%">
                            <div class="pivot-levels-selector__text-and-expand-part">
                                <div
                                    class="table__column--fake-empty"
                                    *ngFor="let i of _getNewArray(idx)"
                                ></div>
                                <lg-icon
                                    class="pivot-levels-selector__fake-expand-icon"
                                    icon="icon-arrow-down"
                                    [inline]="true"
                                ></lg-icon>

                                {{ _renderItemName(item) }}
                            </div>
                        </div>
                        <div class="table__column--icons icons table__column">
                            <lg-icon
                                class="table__row__hover-only"
                                icon="icon-close"
                                [clickable]="true"
                                (click)="_removeItem(item)"
                            ></lg-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-split__primary">
            <div class="form-row form-row--table form-row--no-bottom-margin">
                <div
                    class="pivot-levels-selector__available-items form-row__controls lg-block--table flexcol flexcol--full"
                    cdkDropList
                    #availableItems="cdkDropList"
                    [cdkDropListData]="_availableLevels"
                    [cdkDropListConnectedTo]="[selectedItems]"
                    (cdkDropListDropped)="_drop($event)"
                    [cdkDropListSortingDisabled]="true"
                    style="height: 40vh"
                >
                    <div
                        class="table__row pivot-levels-selector__draggable-row"
                        *ngFor="let item of _availableLevels"
                        [cdkDragDisabled]="_isMaximumReached() || _isSelected(item)"
                        [class.pivot-levels-selector__draggable-row--selected]="_isSelected(item)"
                        cdkDrag
                    >
                        <div class="table__column--icons first table__column">
                            <lg-icon
                                class="pivot-levels-selector__handle"
                                icon="icon-draggable"
                            ></lg-icon>
                        </div>
                        <div class="table__column pivot-levels-selector__main-col last">
                            <div class="pivot-levels-selector__text-and-expand-part">
                                <lg-multi-checkbox
                                    [ngModel]="_isSelected(item)"
                                    (ngModelChange)="_onCheckboxClick($event, item)"
                                    [effective]="_isSelected(item)"
                                    [disabled]="_isMaximumReached()"
                                ></lg-multi-checkbox>

                                {{ _renderItemName(item) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="form-group form-group--buttons">
    <div class="form-row">
        <lg-button
            *ngIf="!_args.isReadonly"
            textLc="FW.OK"
            (click)="_save()"
            [isDisabled]="!_isValid()"
        ></lg-button>

        <lg-button textLc="FW.CLOSE" (click)="_close()"></lg-button>
    </div>
</div>
