<div
    [lgColDefinitionWidth]="pivotWrapperWidth() + 6"
    [lgColDefinition]="colDefinition()"
    class="table__row table__row--header"
    lgColRow="headerRow"
>
    @for (headerCell of $any(headerRow()); track headerCell.id; let idx = $index) {
        @if (headerCell.type === "levels") {
            <lg-pivot-table-body-level-headers
                [body]="pivotBody()"
                [sortBy]="level().pivot.orderBy"
                (sortByChange)="onLevelsOrderByChange.emit({ level: level(), orderBy: $event })"
                [lgCol]="headerCell.id"
                [levels]="headerCell.levelHeaders"
            />
        }
        @if (headerCell.type === "referenceSlot" || headerCell.type === "simple") {
            <div class="table__column table__column-group">
                @if (headerCell.type === "referenceSlot") {
                    <lgflex-reference-slot
                        [headerCell]="headerCell"
                        [widgetReferences]="widgetReferences()"
                        [referencesDropdown]="referencesDropdown()"
                        [columnSortBy]="columnSortBy()"
                        (onReferenceChange)="_onReferenceChange($event, headerCell.id)"
                        (onOrderByChange)="onOrderByChange.emit($event)"
                        (onDifferenceColumnChange)="onDifferenceColumnChange.emit($event)"
                    />
                }
                @if (headerCell.type === "simple") {
                    <lgflex-simple-group
                        [headerCell]="headerCell"
                        [columnSortBy]="columnSortBy()"
                        (onOrderByChange)="onOrderByChange.emit($event)"
                        (onDifferenceColumnChange)="onDifferenceColumnChange.emit($event)"
                    />
                }
            </div>
        }

        <lgflex-flexible-drilldown-table-header-row
            [headerCell]="headerCell"
            [sortBy]="columnSortBy()"
            (orderByChange)="onOrderByChange.emit($event)"
            (differenceColumnChange)="onDifferenceColumnChange.emit($event)"
        />
    }
</div>
