<lg-panel-grid [definition]="_gridDefinition" class="flexcol">
    <lg-panel
        id="navigation"
        location="navigation"
        panelClass="lg-block--table"
        [resizeActions]="[]"
    >
        <ng-container *ngIf="rootNavigationNodeId">
            <lg-configuration-layout-navigation
                [unsavedChangesCount]="unsavedChangesCount"
                [rootNavigationNodeId]="rootNavigationNodeId"
            ></lg-configuration-layout-navigation>
        </ng-container>
    </lg-panel>

    <lg-panel id="body" location="body" [resizeActions]="[]">
        <ng-container *lgPanelHeader>
            <h2>{{ _currentNode }}</h2>
        </ng-container>
        <div [lgLoaderOverlay]="this.isLoading$ | async" style="height: 100%">
            <lg-rectangle
                lgScrollable
                [lgScrollableWrapper]="false"
                lgScrollableAutoHide="true"
                style="height: 100%"
            >
                <ng-content></ng-content>
            </lg-rectangle>
        </div>

        <div class="table__row--footer lg-settings-config-layout__footer">
            <div>
                <span>
                    <span *ngIf="unsavedChangesCount" class="lg-settings-config-star">*</span>
                    {{
                        "FADP._SettingsConfigLayout.UnsavedChanges"
                            | lgTranslate: { count: unsavedChangesCount }
                    }}
                </span>
                <ng-container *ngIf="useBulkSaveAndRevertActions && unsavedChangesCount">
                    <lg-button
                        [text]="'FW.SAVE' | lgTranslate"
                        buttonClass="button--primary button--small"
                        [isDisabled]="_saveDisabled"
                        (click)="_save()"
                    ></lg-button>
                    <lg-button
                        buttonClass="button--small"
                        [text]="'FADP._SettingsConfigLayout.RevertButton' | lgTranslate"
                        [isDisabled]="isLoading$ | async"
                        (click)="_reset()"
                    ></lg-button>
                </ng-container>
            </div>
        </div>
    </lg-panel>
</lg-panel-grid>
