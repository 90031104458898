import { Injectable } from "@angular/core";
import {
    ConditionalFormattingConfig,
    ConditionalFormattingRangeNames
} from "../conditional-formattings.types";
import { ScaleThreshold, scaleThreshold } from "d3";

type ConditionalFormattingStorage = Record<string | number, ConditionalFormattingStorageLevel>;
type ConditionalFormattingStorageLevel = Record<string, ConditionalFormattingStorageItem>;
type ConditionalFormattingStorageItem = {
    valueMin: number;
    valueMax: number;
    config: ConditionalFormattingConfig;
    scale: ScaleThreshold<number, ConditionalFormattingRangeNames | undefined>;
    enabledRanges: ConditionalFormattingRangeNames[];
};

const ALL_RANGES: ConditionalFormattingRangeNames[] = ["min", "average", "max"];

@Injectable()
export class ConditionalFormattingService {
    private _storage: ConditionalFormattingStorage = {};

    setConditionalFormatting(
        config: ConditionalFormattingConfig,
        level: string | number,
        itemId: string,
        data: Array<Record<string, unknown>>
    ): void {
        if (
            config.min.type === "disabled" &&
            config.average.type === "disabled" &&
            config.max.type === "disabled"
        ) {
            if (this._storage[level] && this._storage[level][itemId]) {
                delete this._storage[level][itemId];
            }
            return;
        }

        let valueMin: number | undefined;
        let valueMax: number | undefined;

        const thresholds: number[] = [];
        let enabledRanges: Array<ConditionalFormattingRangeNames | undefined> = ALL_RANGES.filter(
            rangeName => config[rangeName].type !== "disabled"
        );

        data.forEach(datum => {
            const value = datum[itemId];
            if (typeof value !== "number" || isNaN(value) || !isFinite(value)) return;

            if (valueMin === undefined || value < valueMin) valueMin = value;
            if (valueMax === undefined || value > valueMax) valueMax = value;
        });

        if (valueMin === undefined || valueMax === undefined) return;

        if (enabledRanges.length === 1) {
            if (enabledRanges[0] === "min") {
                if (config.min.type === "manual") {
                    enabledRanges.push(undefined);
                    thresholds.push(config.min.value);
                }
            }
            if (enabledRanges[0] === "max") {
                if (config.max.type === "manual") {
                    enabledRanges.unshift(undefined);
                    thresholds.push(config.max.value);
                }
            }
        } else if (
            config.min.type === "manual" &&
            config.average.type === "disabled" &&
            config.max.type === "manual"
        ) {
            enabledRanges = [...ALL_RANGES];
            thresholds.push(config.min.value, config.max.value);
        } else {
            // Thresholds
            const valueDiff = valueMax - valueMin;
            switch (config.min.type) {
                case "auto":
                    if (config.average.type === "disabled") {
                        break;
                    }
                    thresholds.push(valueMin + valueDiff / enabledRanges.length);
                    break;
                case "manual":
                    thresholds.push(config.min.value);
                    break;
            }
            switch (config.max.type) {
                case "auto":
                    thresholds.push(valueMax - valueDiff / enabledRanges.length);
                    break;
                case "manual":
                    thresholds.push(config.max.value);
                    break;
            }
        }

        const scale = scaleThreshold<number, ConditionalFormattingRangeNames | undefined>(
            thresholds,
            enabledRanges
        );

        if (this._storage[level] === undefined) {
            this._storage[level] = {};
        }

        this._storage[level][itemId] = {
            valueMin,
            valueMax,
            config,
            scale,
            enabledRanges: this._getEnabledRanges(config)
        };
    }

    getColor(level: string | number, itemId: string, value: number): string | undefined {
        const item = this._storage[level]?.[itemId];
        if (item === undefined || isNaN(value) || !isFinite(value)) return undefined;

        const config = item.config;
        const rangeName = item.scale(value);
        if (rangeName === undefined) return undefined;

        if (config[rangeName].type === "disabled") {
            return undefined;
        }

        const color = config[rangeName].color;
        const range = item.scale.invertExtent(rangeName);
        const min = range[0] ?? item.valueMin;
        const max = range[1] ?? item.valueMax;
        const diff = Math.abs(max - min);
        const valueDiff = Math.abs(value - min);
        let opacity = 1;

        // Calculate opacity steps
        switch (rangeName) {
            case "min":
                opacity = Math.floor((valueDiff / diff) * config.steps) / config.steps;
                opacity = Math.abs(opacity - 1);
                break;
            case "max":
                opacity = Math.ceil((valueDiff / diff) * config.steps) / config.steps;
                break;
        }

        // Handle edge values
        if (opacity === 0) opacity = 1 / config.steps;
        if (isNaN(opacity)) opacity = 0;

        opacity = Math.max(opacity, 0);
        opacity = Math.min(opacity, 1);

        return color + this._opacityToHex(opacity);
    }

    private _opacityToHex(opacity: number): string {
        const opacityValue = Math.round(opacity * 255);
        return opacityValue.toString(16).padStart(2, "0");
    }

    private _getEnabledRanges(
        config: ConditionalFormattingConfig
    ): ConditionalFormattingRangeNames[] {
        return ALL_RANGES.filter(rangeName => config[rangeName].type !== "disabled");
    }
}
