import { CalcAstNodeFunctionCall, parseCalculate } from "../utilities";
import { intersection } from "lodash";
import { FieldInfo } from "../types";

export function getAllowedFields(
    scheme: FieldInfo[],
    selected: string[],
    isValuedField = true
): FieldInfo[] {
    const schemeMap = new Map<string, FieldInfo>(scheme.map(field => [field.field, field]));
    const selectedFieldsInfo = scheme.filter(field => selected.includes(field.field));

    const [selectedFields, selectedFieldsBlockedBy] = selectedFieldsInfo.reduce(
        ([fieldsAcc, blockedByFieldsAcc], field) => {
            let calcField = [];
            let calcFieldBlockedBy = [];

            if (field.calculate) {
                const { params } = parseCalculate(field.calculate) as CalcAstNodeFunctionCall;
                calcField = [...params];
                calcFieldBlockedBy = params.reduce((acc, fieldId) => {
                    return [...acc, ...(schemeMap.get(fieldId)?.blockedBy ?? [])];
                }, []);
            }

            return [
                [...new Set([...fieldsAcc, ...calcField, field.field])],
                [
                    ...new Set([
                        ...blockedByFieldsAcc,
                        ...(field.blockedBy ?? []),
                        ...calcFieldBlockedBy
                    ])
                ]
            ];
        },
        [[], []]
    );

    return scheme.filter(
        field =>
            field.isValueField === isValuedField &&
            !isFieldBlockedBySelectedFields(
                field,
                selectedFields,
                selectedFieldsBlockedBy,
                schemeMap
            )
    );
}

function isFieldBlockedBySelectedFields(
    field: FieldInfo,
    selectedFields: string[],
    selectedFieldsBlockedBy: string[],
    schemeMap: Map<string, FieldInfo>
) {
    let calcFieldBlockedBy = [];
    let calcFields = [];
    if (field.calculate) {
        const { params } = parseCalculate(field.calculate) as CalcAstNodeFunctionCall;
        calcFieldBlockedBy = params.reduce((acc, fieldId) => {
            return [...acc, ...(schemeMap.get(fieldId)?.blockedBy ?? [])];
        }, []);
        calcFields = [...params];
    }
    return (
        intersection([...(field.blockedBy ?? []), ...calcFieldBlockedBy], selectedFields).length >
            0 || intersection(selectedFieldsBlockedBy, [field.field, ...calcFields]).length > 0
    );
}
