import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";


export type CalculationProgressStatus = "hidden" | "waiting" | "calculating";

const STARTING_PROGRESS_VALUE = 0.1;


@Component( {
    selector: "calculation-progress",
    templateUrl: "./calculation-progress.component.html",
    styleUrls: ["./calculation-progress.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class CalculationProgressComponent {

    _progress = STARTING_PROGRESS_VALUE;
    _progressInPercent = 0;
    _path: string | null = null;
    _pathRest: string | null = null;

    @Input() set progress( value: number ) {
        this._progress = value > STARTING_PROGRESS_VALUE ? value : STARTING_PROGRESS_VALUE;
        this._progressInPercent = Math.round( value * 100 );
        this._path = this.describeArc( 12, 12, 7, 0, this._progress * 360 );
        this._pathRest = this.describeArc( 12, 12, 7, this._progress * 360, 360 );
    }

    get progress(): number {
        return this._progress;
    }

    @Input() status: CalculationProgressStatus = "hidden";

    @Output() readonly triggerCalculation = new EventEmitter<void>();


    describeArc( x: number, y: number, radius: number, startAngle: number, endAngle: number ): string {
        const fullCircle = endAngle - startAngle === 360;
        const start = this.polarToCartesian( x, y, radius, endAngle - 0.01 );
        const end = this.polarToCartesian( x, y, radius, startAngle );
        const arcSweep = endAngle - startAngle <= 180 ? "0" : "1";

        const d = [
            "M", start.x, start.y,
            "A", radius, radius, 0, arcSweep, 0, end.x, end.y,
        ];
        if ( fullCircle ) d.push( "z" );

        return d.join( " " );
    }

    polarToCartesian( centerX: number, centerY: number, radius: number, angleInDegrees: number ): { x: number; y: number; } {
        const angleInRadians = ( angleInDegrees - 90 ) * Math.PI / 180.0;

        return {
            x: centerX + ( radius * Math.cos( angleInRadians ) ),
            y: centerY + ( radius * Math.sin( angleInRadians ) )
        };
    }

    onClick(): void {
        this.triggerCalculation.emit();
    }
}
