import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { finalize, forkJoin, Observable, of } from "rxjs";
import { mixins } from "@logex/mixin-flavors";

import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgConsole } from "@logex/framework/core";
import {
    IDefinitions,
    ILgDefinitionsHierarchyService,
    LG_APP_DEFINITIONS,
    LG_DEFINITIONS_HIERARCHY_SERVICE
} from "@logex/framework/lg-application";
import {
    LoaderArguments as LoadManagerArguments,
    LoaderArguments,
    LoaderArgumentsMap,
    LoaderConfiguration,
    StaleDataService
} from "@logex/load-manager";
import { HandleErrorsMixin } from "@logex/mixins";

import { FieldInfo } from "../../types";
import {
    DatasetInfo,
    DatasetInfoDerivedField,
    FieldInfoExt,
    FlexibleDatasetDataArguments,
    FlexibleDatasetDataArgumentsGetter,
    SelectDataResponse
} from "./types/types";
import { FlexibleDatasetGateway } from "./gateways/flexible-dataset-gateway";
import { FlexibleLoader, IFlexibleLoadersCollection } from "./flexible-loader";
import { parseCalculate } from "../../utilities";
import { isDefinitionTypeField } from "../../utilities/isDefinitionTypeField";
import { FlexDataClientMetadataArguments } from "../flex-data-client/types/types";

// ----------------------------------------------------------------------------------
export interface FlexibleDatasetConfiguration {
    fields: FieldInfo[];
    dataUrl: string;
    maxRecordsLimit: number | null;
}

export interface FlexibleDataset extends HandleErrorsMixin {}

type DerivedFieldInfo = {
    derived: FieldInfoExt;
    source: FieldInfoExt[];
};

@Injectable()
@mixins(HandleErrorsMixin)
export class FlexibleDataset implements OnDestroy {
    constructor(
        public _promptDialog: LgPromptDialog,
        public _lgTranslate: LgTranslateService,
        public _lgConsole: LgConsole,
        @Inject(LG_APP_DEFINITIONS) private _definitions: IDefinitions<any>,
        @Inject(LG_DEFINITIONS_HIERARCHY_SERVICE)
        private _definitionsHierarchy: ILgDefinitionsHierarchyService,
        private _staleDataService: StaleDataService,
        private _gateway: FlexibleDatasetGateway
    ) {
        this._initMixins();

        this._lgConsole = _lgConsole.withSource("Logex.Application.FlexibleDataset");
        this._staleDataService.loaders = this._loadersCollection;
    }

    // ----------------------------------------------------------------------------------
    private _dataUrl: string | undefined;
    private _fields: FieldInfo[] = [];
    private _fieldsLookup: Dictionary<FieldInfoExt> | undefined;
    private _metadataArgs: LoaderArgumentsMap | undefined;
    private _dataArgs: FlexibleDatasetDataArgumentsGetter | undefined;
    private _maxRecordsLimit: number | null = null;

    private _datasets: DatasetInfo[] = [];

    private _configured = false;
    private _idCount = 0;

    // ----------------------------------------------------------------------------------
    configure(args: FlexibleDatasetConfiguration): void {
        this._dataUrl = args.dataUrl;

        this._fields = args.fields;
        this._fieldsLookup = _.keyBy(
            _.map(this._fields, x => this._extendFieldInfo(x)),
            "field"
        );

        this._maxRecordsLimit = args.maxRecordsLimit;

        this._configured = true;
    }

    private _extendFieldInfo(field: FieldInfo): FieldInfoExt {
        if (field.calculate == null) return field;

        const parsed = parseCalculate(field.calculate);
        switch (parsed.type) {
            case "functionCall":
                // const fieldA = parsed.params[0];
                // const fieldB = parsed.params[1];
                return {
                    ...field,
                    sourceFields: parsed.params
                    // fn: ( record ) => {
                    //     const a = record[fieldA];
                    //     const b = record[fieldB];
                    //     return b !== 0 ? ( a / b ) : 0;
                    // },
                };

            case "error":
                throw Error(parsed.message);

            default:
                throw Error("Shouldn't happen");
        }
    }

    // ----------------------------------------------------------------------------------
    set metadataArguments(args: LoaderArgumentsMap) {
        this._metadataArgs = args;
    }

    get metadataArguments(): LoaderArgumentsMap {
        if (this._metadataArgs == null) throw Error("Metadata arguments are not set yet");
        return this._metadataArgs;
    }

    set dataArguments(args: Partial<FlexibleDatasetDataArgumentsGetter>) {
        if (!("filters" in args)) {
            args = {
                ...args,
                filters: () => ({})
            };
        }
        if (!("limit" in args)) {
            args = {
                ...args,
                limit: () => this._maxRecordsLimit
            };
        }
        this._dataArgs = args as FlexibleDatasetDataArgumentsGetter;
    }

    get dataArguments(): FlexibleDatasetDataArgumentsGetter {
        if (this._dataArgs == null) throw Error("Data arguments are not set yet");
        return this._dataArgs;
    }

    // ----------------------------------------------------------------------------------
    addDataset(fields: string[], args?: FlexibleDatasetDataArgumentsGetter): void {
        this._getDatasetInfo(fields, args);
    }

    getValidFilters(
        filters: Record<string, Array<string | number | boolean>>
    ): Record<string, Array<string | number | boolean>> {
        return Object.keys(filters).reduce((acc, filter) => {
            if (this._fieldsLookup[filter]) {
                acc[filter] = filters[filter];
            }
            return acc;
        }, {});
    }

    private _getDatasetInfo(
        fields: string[],
        args: FlexibleDatasetDataArgumentsGetter | null = null
    ): DatasetInfo {
        if (!this._configured) {
            throw Error("FlexibleDataset is not initialized");
        }

        // Try to get dataset from cache
        fields = _.sortBy(fields);

        const sameFieldsDatasets = _.filter(this._datasets, x => _.isEqual(x.fields, fields));

        if (!_.isEmpty(sameFieldsDatasets)) {
            const dataset = _.find(sameFieldsDatasets, x => x.dataArgs === args);

            if (dataset != null) {
                return dataset;
            }
        }

        // Check if any fields are derived
        const { serverFields, derivedFields, calculatedFields } = this._analyzeFields(fields);
        const serverFieldNames = _.map(serverFields, x => x.field);

        const newDataset: DatasetInfo = {
            name: `${fields.join("-")}-${this._idCount++}`,
            fields,
            dataArgs: args,
            serverFields,
            derivedFields,
            calculatedFields,
            loader: undefined! // will be filled below
        };

        if (!_.isEqual(fields, serverFieldNames)) {
            // Requested fields have fields derived from a server dataset
            const serverDataset = this._getDatasetInfo(serverFieldNames, args);
            newDataset.serverDataset = serverDataset;
            serverDataset.derivedDatasets = [...(serverDataset.derivedDatasets ?? []), newDataset];

            newDataset.requiredDefinitions = this._definitionsHierarchy.addIntermediateDefinitions([
                ..._.map(derivedFields, x => x.derived.type),
                ..._.map(
                    _.filter(serverFields, x => !x.isValueField && isDefinitionTypeField(x)),
                    x => x.type
                )
            ]);
            newDataset.projectionFn = this._getProjectionFunction(derivedFields, calculatedFields);
            this._createDerivedLoader(newDataset);
        } else {
            // Requested fields correspond to a server dataset
            this._createServerLoader(newDataset);
        }

        if (_.some(this._datasets, x => x.name === newDataset.name)) {
            throw Error(
                `Dataset "${newDataset.name}" already exists in this FlexibleDatasetService.`
            );
        }

        this._datasets.push(newDataset);

        return newDataset;
    }

    private _analyzeFields(fields: string[]): {
        serverFields: FieldInfoExt[];
        derivedFields: DatasetInfoDerivedField[];
        calculatedFields: FieldInfoExt[];
    } {
        const getFieldInfos = (fields: string[]): FieldInfoExt[] =>
            _.map(fields, x => {
                if (this._fieldsLookup == null)
                    throw Error("Fields lookup shouldn't be undefined.");

                const field = this._fieldsLookup[x];

                if (field == null) {
                    throw Error(`Unknown field ${x} requested`);
                }

                return field;
            });

        const splitIntoSourceAndCalculatedFields = (
            fieldInfos: FieldInfoExt[]
        ): [FieldInfoExt[], FieldInfoExt[]] => {
            const sourceFields: FieldInfoExt[] = [];
            const calculatedFields: FieldInfoExt[] = [];

            _.each(fieldInfos, x => {
                // This is NOT a calculate field
                if (x.sourceFields == null) {
                    sourceFields.push(x);
                } else {
                    calculatedFields.push(x);

                    const [sf2, cf2] = splitIntoSourceAndCalculatedFields(
                        getFieldInfos(x.sourceFields)
                    );
                    sourceFields.push(...sf2);
                    calculatedFields.push(...cf2);
                }
            });

            return [_.uniq<FieldInfoExt>(sourceFields), calculatedFields];
        };

        const splitIntoSourceAndDerivedFields = (
            fields: FieldInfoExt[]
        ): [FieldInfoExt[], DatasetInfoDerivedField[]] => {
            const sourceFields: FieldInfoExt[] = [];
            const derivedDefinitionFields: DerivedFieldInfo[] = [];

            for (const fi of fields) {
                if (fi.isValueField) {
                    // Values fields cannot be a source of definition field
                    sourceFields.push(fi);
                } else {
                    // Check if this field can be derived from some other field
                    let derivedFromFields: FieldInfoExt[] | null = null;
                    if (fi.sourceField != null) {
                        const sourceField = _.find(fields, { field: fi.sourceField });
                        if (sourceField != null) {
                            const referredBy = this._definitionsHierarchy.getDescendants(fi.type);
                            if (!_.includes(referredBy, sourceField.type)) {
                                this._lgConsole.error(
                                    `Scheme error: source field ${sourceField.field} cannot be used to derive field ${fi.field} ` +
                                        `because definition ${fi.type} is not in hierarchy of source definition ${sourceField.type}`
                                );
                            } else {
                                derivedFromFields = [sourceField];
                            }
                        }
                    }

                    if (derivedFromFields === null) {
                        // If cannot be derived from any other field - query it from the server
                        sourceFields.push(fi);
                    } else {
                        // Store derived field along with all variants of source fields
                        derivedDefinitionFields.push({ derived: fi, source: derivedFromFields });
                    }
                }
            }

            // Filter possible sources of derived fields so that there will be only one field coming from the server
            const derivedFields = _.map(derivedDefinitionFields, x => ({
                derived: x.derived,
                // todo: simplify? Currently we have always only 1 source
                source: _.find(x.source, y => _.includes(sourceFields, y))!
            }));

            return [sourceFields, derivedFields];
        };

        // ---
        const [sourceFields, calculatedFields] = splitIntoSourceAndCalculatedFields(
            getFieldInfos(fields)
        );
        const [serverFields, derivedFields] = splitIntoSourceAndDerivedFields(sourceFields);
        return {
            serverFields: _.sortBy(serverFields, x => x.field),
            derivedFields,
            calculatedFields
        };
    }

    private _getProjectionFunction(
        derivedFields: DatasetInfoDerivedField[],
        calculatedFields: FieldInfoExt[]
    ): (unknown: unknown) => unknown {
        const noDerivedFields = _.isEmpty(derivedFields);
        const noCalculatedFields = _.isEmpty(calculatedFields);

        if (noDerivedFields && noCalculatedFields) return x => x;

        const derivedFieldsBySource = _.map(
            _.groupBy(derivedFields, x => x.source.field),
            fields => ({
                source: fields[0].source,
                mapping: _.transform(
                    fields,
                    (a, x) => {
                        a[x.derived.field] = x.derived.type;
                        return a;
                    },
                    {} as Dictionary<string>
                )
            })
        );

        return (record: unknown) => {
            if (!noDerivedFields) {
                for (const x of derivedFieldsBySource) {
                    const ancestorKeys = this._definitionsHierarchy.getHierarchyKeys(
                        x.source.type,
                        // TODO: Fix typing
                        // @ts-ignore
                        record[x.source.field],
                        x.mapping
                    );
                    _.extend(record, ancestorKeys);
                }
            }

            if (!noCalculatedFields) {
                // Decided to remove calculation from the dataset for now
                //
                // for ( const calculatedField of calculatedFields ) {
                //     record[calculatedField.field] = calculatedField.fn( record );
                // }
            }

            return record;
        };
    }

    private _createServerLoader(dataset: DatasetInfo): void {
        if (this._dataArgs == null) throw Error("Arguments shouldn't be undefined.");
        if (this._dataUrl == null) throw Error("Data url shouldn't be undefined.");

        const config = this._staleDataService.configureLoader(
            dataset.name,
            {
                args: [
                    this._metadataArgs,
                    {
                        ...(dataset.dataArgs ?? this._dataArgs),
                        fields: () => [...dataset.fields]
                    }
                ],
                loader: (
                    metadataArgs: FlexDataClientMetadataArguments,
                    dataArgs: FlexibleDatasetDataArguments
                ) => this._gateway.selectData(this._dataUrl!, metadataArgs, dataArgs)
            },
            (data, isStale) => data
        )[dataset.name];

        dataset.loader = this._newLoader(dataset, config);
    }

    private _createDerivedLoader(dataset: DatasetInfo): void {
        if (this._dataArgs == null) throw Error("Arguments shouldn't be undefined.");

        const serverDataset = dataset.serverDataset;
        if (serverDataset == null) throw Error("Server dataset shouldn't be undefined.");

        dataset.loader = this._newLoader(dataset, {
            args: [this._metadataArgs, dataset.dataArgs ?? this._dataArgs],
            require: [serverDataset.name],
            loader: () => {
                const data = serverDataset.loader.getStoredResult();
                return of({
                    data: _.map(data.data, dataset.projectionFn),
                    isComplete: data.isComplete
                });
            }
        });
    }

    private _newLoader(dataset: DatasetInfo, config: LoaderConfiguration): FlexibleLoader {
        return new FlexibleLoader(
            this._loadersCollection,
            dataset,
            config,
            this._definitions,
            this._lgConsole
        );
    }

    /**
     * Host object for loaders
     *
     * @private
     */
    private _loadersCollection: IFlexibleLoadersCollection = {
        loadDatasets: (
            names: string[],
            forceReload: boolean,
            argsOverride: LoadManagerArguments | null
        ): Observable<any[]> =>
            forkJoin<any[]>(
                _.map(names, name => {
                    const dataset = _.find(this._datasets, { name });

                    if (dataset == null) {
                        this._lgConsole.warn("Dataset to be loaded is not found", { name });
                        return of(null);
                    }

                    return this._doLoad(dataset, forceReload, argsOverride);
                })
            ),

        getDatasets: () => this._datasets
    };

    load(
        fields: string[],
        argsOverride?: FlexibleDatasetDataArgumentsGetter
    ): Observable<SelectDataResponse | null> {
        return this._doLoad(this._getDatasetInfo(fields, argsOverride), false);
    }

    reload(
        fields: string[],
        argsOverride?: FlexibleDatasetDataArgumentsGetter
    ): Observable<SelectDataResponse | null> {
        return this._doLoad(this._getDatasetInfo(fields, argsOverride), true);
    }

    private _doLoad(
        dataset: DatasetInfo,
        forceReload: boolean,
        argsOverride: LoaderArguments | null = null
    ): Observable<SelectDataResponse | null> {
        _.each(dataset.derivedDatasets, x => this._doLoad(x, forceReload, argsOverride));
        return dataset.loader.load(null, forceReload);
    }

    dataAsObservable(
        fields: string[],
        args?: FlexibleDatasetDataArgumentsGetter
    ): Observable<SelectDataResponse> {
        const dataset = this._getDatasetInfo(fields, args);
        return dataset.loader.dataAsObservable().pipe(
            finalize(() => {
                this._datasets = this._datasets.filter(ds => ds.name !== dataset.name);
            })
        );
    }

    isLoading$(fields: string[], args?: FlexibleDatasetDataArgumentsGetter): Observable<boolean> {
        const dataset = this._getDatasetInfo(fields, args);
        return dataset.loader.isLoadingAsObservable;
    }

    isCalculating$(
        fields: string[],
        args?: FlexibleDatasetDataArgumentsGetter
    ): Observable<boolean> {
        let dataset = this._getDatasetInfo(fields, args);

        // Only datasets loaded from the server can be calculated
        while (dataset.serverDataset != null) {
            dataset = dataset.serverDataset;
        }

        return this._staleDataService.isCalculating$(dataset.name);
    }

    calculationProgress$(
        fields: string[],
        args?: FlexibleDatasetDataArgumentsGetter
    ): Observable<number | undefined> {
        let dataset = this._getDatasetInfo(fields, args);

        // Only datasets loaded from the server can be calculated
        while (dataset.serverDataset != null) {
            dataset = dataset.serverDataset;
        }

        return this._staleDataService.calculationProgress$(dataset.name);
    }

    loadObservedDatasets(): Observable<any> {
        return this._loadObservedDatasetsDo(false);
    }

    reloadObservedDatasets(): Observable<any> {
        return this._loadObservedDatasetsDo(true);
    }

    private _loadObservedDatasetsDo(force: boolean): Observable<any> {
        return forkJoin(
            _.filter(
                _.map(this._datasets, ds => {
                    return ds.loader.isObserved() ? ds.loader.load(null, force) : null;
                }),
                (x): x is Observable<any> => x != null
            )
        );
    }

    ngOnDestroy(): void {
        _.each(this._datasets, dataset => {
            dataset.loader.cancel();
        });

        // eslint-disable-next-line @angular-eslint/no-lifecycle-call
        this._staleDataService.ngOnDestroy();
    }
}
