<ng-container
    *ngIf="colDefinition !== undefined && colDefinitionWidth() !== undefined && pivot !== undefined"
>
    <div
        class="table__row table__row--footer"
        [lgColDefinition]="colDefinition"
        [lgColDefinitionWidth]="colDefinitionWidth()"
        lgColRow="footerRow"
    >
        <ng-container *ngFor="let cell of totalsRow">
            <lg-pivot-table-empty-column
                *ngIf="cell.type == 'empty'"
                [lgCol]="cell.id"
                [level]="0"
            ></lg-pivot-table-empty-column>

            <div
                *ngIf="cell.type == 'default'"
                [lgCol]="cell.id"
                [innerHTML]="cell.formatFn(pivot.totals[cell.fieldName])"
                [lgTooltip]="
                    cell.tooltip != null
                        ? cell.tooltip.formatFn(pivot.totals[cell.tooltip.fieldName])
                        : undefined
                "
            ></div>

            <div *ngIf="cell.type == 'text'" [lgCol]="cell.id" [innerHTML]="cell.value"></div>

            <div
                *ngIf="cell.type == 'filter' || cell.type == 'widget' || cell.type == 'templateRef'"
                [lgCol]="cell.id"
            ></div>
        </ng-container>
    </div>
</ng-container>
