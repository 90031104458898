export default function formatServerException(exception: any): string {
    let errorText = [""];
    if (exception != null) {
        if (exception.Message != null) {
            errorText = [exception.Message];
            if (exception.ExceptionMessage != null) {
                errorText.push(`<b>${exception.ExceptionMessage}</b>`);
            }
            if (exception.StackTrace != null) {
                errorText.push(
                    `<div class="confirm-message__section confirm-message__scrollable" ><b>Stack:</b><br />
                        ${exception.StackTrace.replace(/\n/g, "<br/>")}</div>`
                );
            }
        } else {
            const find = /<b>\s*Description:\s*<\/b>([^<]*)<br>/.exec(exception.data);
            if (find) {
                errorText = [find[1]];
            }
        }
    }

    return errorText.join("<br/>");
}
