import { provideWidgetMigration } from "../../../services/widget-types-registry";
import { chartV1toV2Migration } from "./v1-v2";
import { CHART_WIDGET } from "../chart-widget.configuration.types";
import { chartV2toV3Migration } from "./v2-v3";
import { chartV3toV4Migration } from "./v3-v4";

export const chartMigrationProvider = provideWidgetMigration(CHART_WIDGET, {
    1: chartV1toV2Migration,
    2: chartV2toV3Migration,
    3: chartV3toV4Migration
});
