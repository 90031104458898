import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { CoreModule } from "@logex/framework/core";
import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";

import { CalculationProgressComponent } from "./stale-data";


@NgModule({
  declarations: [
      CalculationProgressComponent
  ],
  imports: [
      CoreModule,
      UiCoreModule,
      LgLocalizationModule,
      CommonModule
  ],
  exports: [
      CalculationProgressComponent
  ]
})
export class LoadManagerModule { }
