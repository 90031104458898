import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import {
    DeleteFlexibleLayoutArguments,
    GetFlexibleLayoutArguments,
    GetFlexibleLayoutConfigArguments,
    GetFlexibleLayoutConfigResponse,
    GetFlexibleLayoutLibraries,
    GetFlexibleLayoutResponse,
    GetLibrariesResponse,
    LayoutNavigation,
    SaveFlexibleLayoutArguments,
    SaveFlexibleLayoutResponse
} from "./types/types";

import { LG_APP_CONFIGURATION, ServerGatewayBase } from "@logex/framework/lg-application";
import {
    LayoutCatalogGroupRequestParams,
    LayoutCatalogGroupResponse
} from "../../components/layout-management-dialog";

@Injectable({ providedIn: "root" })
export class FlexibleLayoutStorageGatewayService extends ServerGatewayBase {
    constructor() {
        super();

        const appConfiguration = inject(LG_APP_CONFIGURATION);
        this._setBaseUrl(appConfiguration.applicationRoot);
    }

    saveLayout(args: SaveFlexibleLayoutArguments): Observable<SaveFlexibleLayoutResponse> {
        return this._post<SaveFlexibleLayoutResponse>("flexibleLayout/save", { body: args });
    }

    deleteLayout(args: DeleteFlexibleLayoutArguments): Observable<void> {
        return this._post("flexibleLayout/delete", { body: args });
    }

    getLayouts(args: GetFlexibleLayoutArguments): Observable<GetFlexibleLayoutResponse[]> {
        return this._postQuery<GetFlexibleLayoutResponse[]>("flexibleLayout/select", {
            body: args
        });
    }

    getLibraries(args: GetFlexibleLayoutLibraries): Observable<GetLibrariesResponse[]> {
        return this._postQuery<GetLibrariesResponse[]>("flexibleLayout/library", { body: args });
    }

    getLayoutConfig(
        args: GetFlexibleLayoutConfigArguments
    ): Observable<GetFlexibleLayoutConfigResponse> {
        return this._postQuery<GetFlexibleLayoutConfigResponse>("flexibleLayout/config", {
            body: args
        });
    }

    loadSharedLayouts(
        clientId: number,
        page: string,
        scenarioId: number
    ): Observable<LayoutCatalogGroupResponse> {
        return this._get<LayoutCatalogGroupResponse>("flexibleLayout/public/catalog/select", {
            params: { clientId, page, scenarioId }
        });
    }

    loadNavigationLayouts(clientId: number): Observable<LayoutNavigation[]> {
        return this._get<LayoutNavigation[]>("flexibleLayout/navigation/select", {
            params: { clientId }
        });
    }

    saveSharedLayouts(args: LayoutCatalogGroupRequestParams): Observable<string[]> {
        return this._post<any>("flexibleLayout/catalog/save", { body: args });
    }
}
