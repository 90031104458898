import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    inject,
    OnDestroy,
    OnInit,
    signal,
    Signal,
    ViewChild,
    WritableSignal
} from "@angular/core";
import { takeUntil } from "rxjs/operators";

import { IDropdownDefinition } from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgPivotInstance } from "@logex/framework/lg-pivot";

import { getColumnFieldName } from "../../utilities";
import {
    ExportFormat,
    IWidgetHost,
    LG_FLEX_LAYOUT_WIDGET_HOST,
    MAX_LIMIT_ROWS,
    PERCENTAGE_FORMATTER_LIMIT,
    PivotTableColumn,
    PivotTableColumnFormulaFormatting,
    Widget,
    WidgetComponent,
    WidgetUsage
} from "../../types";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { WidgetTypesRegistry } from "../../services/widget-types-registry";
import { WidgetExportExcelService } from "../../services/widget-export/widget-export-excel.service";
import { exportSvg, exportSvgToPng } from "../../services/widget-export/widget-export-image";
import {
    cloneElementWithStyles,
    getExportFilename
} from "../../services/widget-export/widget-export-utils";
import { ChartWidgetConfigurator } from "./chart-widget-configurator.service";
import {
    CHART_WIDGET,
    ChartDimensionRow,
    ChartOptions,
    ChartWidgetColumn,
    ChartWidgetConfig,
    ChartWidgetState
} from "./chart-widget.configuration.types";
import { addTitles } from "../../utilities/addTitles";
import {
    ChartData,
    ChartGroup,
    ChartRenderBaseDatum,
    ChartTooltipContext
} from "./components/chart/chart.types";
import { ModReferenceVariablePipe } from "../../pipes/mod-reference-variable.pipe";
import { mixins } from "@logex/mixin-flavors";
import { NgOnDestroyMixin } from "@logex/mixins";
import { ChartWidgetLevelDataService } from "./chart-widget-level-data.service";
import { dropdownFlat } from "@logex/framework/utilities";

export interface ChartWidgetComponent<TConfig> extends NgOnDestroyMixin {}

@Component({
    selector: "lgflex-chart-widget",
    templateUrl: "./chart-widget.component.html",
    host: {
        class: "flex-flexible flexcol flexcol--full"
    },
    viewProviders: [...useTranslationNamespace("_Flexible.Chart")],
    providers: [ChartWidgetLevelDataService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@Widget({
    id: CHART_WIDGET,
    nameLc: "_Flexible.Chart.WidgetTitle",
    usage: WidgetUsage.Page,
    configurator: ChartWidgetConfigurator,
    configVersion: 4
})
@mixins(NgOnDestroyMixin)
export class ChartWidgetComponent<TConfig> implements OnInit, OnDestroy, WidgetComponent {
    private readonly _widgetHost: IWidgetHost = inject(LG_FLEX_LAYOUT_WIDGET_HOST);
    private readonly _flexDataClient = inject(FlexDataClientService);
    private readonly _pageReferences = inject(PageReferencesService);
    private readonly _widgetTypes = inject(WidgetTypesRegistry);
    private readonly _exportService = inject(WidgetExportExcelService);
    private readonly _configurator = inject(ChartWidgetConfigurator);
    private readonly _referenceVariablePipe = inject(ModReferenceVariablePipe);
    protected readonly _levelDataService = inject(ChartWidgetLevelDataService);
    readonly _lgTranslate = inject(LgTranslateService);

    // ----------------------------------------------------------------------------------

    private _id: string | undefined;
    private _isActivated = false;
    protected _tooltipContext: ChartTooltipContext | null = null;
    protected _sortDescending = true;
    protected _config!: ChartWidgetConfig;
    protected _maxLimitRows = MAX_LIMIT_ROWS;
    protected _chartOptions: WritableSignal<ChartOptions> = signal({
        chartOrientation: "vertical",
        formatterType: "float",
        formatterOptions: {
            decimals: 0
        },
        labels: {
            chartLabels: false,
            tooltip: true,
            legend: true
        },
        valueLimit: undefined
    });

    protected _chartGroups: WritableSignal<ChartGroup[]> = signal([]);
    protected _isConfigValid = signal(false);
    protected _isAutoConverted = signal(false);

    protected readonly _totalsAsTopLevel = this._levelDataService.totalsAsTopLevel;
    protected readonly _currentLevel: Signal<number> = this._levelDataService.currentLevel;
    protected readonly _currentTableLevel: Signal<number> =
        this._levelDataService.currentTableLevel;

    protected readonly _levelData: Signal<ChartData<Record<string, number | string>>> =
        this._levelDataService.levelData;

    protected readonly _columns: Signal<PivotTableColumn[]> = this._levelDataService.columns;
    protected readonly _levels: Signal<string[]> = this._levelDataService.levels;
    protected readonly _currentLevelDimension: Signal<ChartDimensionRow> =
        this._levelDataService.currentLevelDimension;

    protected readonly _sortField: Signal<string | undefined> = this._levelDataService.sortField;
    protected readonly _limitRows = this._levelDataService.limitRows;
    protected readonly _pivot: Signal<LgPivotInstance<unknown, unknown>> =
        this._levelDataService.pivot;

    protected readonly _sortDropdown: Signal<IDropdownDefinition<string | "diff"> | undefined> =
        computed(() => {
            const entries = this._columns()
                .filter(col => col.type === "formula")
                .map(field => ({
                    code: getColumnFieldName(field),
                    name: this._referenceVariablePipe.transform(field.title)
                }));
            return dropdownFlat({
                entryId: "code",
                entryName: "name",
                entries: [
                    ...entries,
                    {
                        code: this._currentLevelDimension()?.fieldId,
                        name: this._currentLevelDimension()?.name
                    }
                ]
            });
        });

    @ViewChild("rect", { read: ElementRef }) rectEl: ElementRef | undefined;

    // ----------------------------------------------------------------------------------

    constructor() {
        this._initMixins();
    }

    setId(id: string): void {
        this._id = id;
    }

    setContext(context: object): void {}

    markAsAutoConverted(): void {
        this._isAutoConverted.set(true);
    }

    onVisibilityChange(isVisible: boolean): void {
        if (!isVisible) {
            this._levelDataService.unsubscribe();
        } else {
            this._levelDataService.resubscribeToData();
        }
    }

    async setConfig(config: ChartWidgetConfig): Promise<void> {
        this._config = config;

        this._isConfigValid.set(this._configurator.validate(config));
        if (!this._isConfigValid()) {
            return;
        }

        // As format and precision are set up on a higher level first we attach them to all columns
        this._attachFormat(config.columns, config.formatType, config.formatPrecision);

        // Add titles from the schema field names when missing
        addTitles(
            this._flexDataClient.scheme,
            this._pageReferences,
            config.columns,
            this._lgTranslate
        );

        this._chartOptions.update(charOptions => ({
            ...charOptions,
            formatterType: config.formatType,
            formatPrecision: config.formatPrecision,
            formatterOptions: {
                ...charOptions.formatterOptions,
                decimals: config.formatPrecision,
                forceSign: config.formatForceSign,
                max:
                    (config.formatType === "percentage" && PERCENTAGE_FORMATTER_LIMIT) ||
                    charOptions.formatterOptions.max,
                min:
                    (config.formatType === "percentage" && -PERCENTAGE_FORMATTER_LIMIT) ||
                    charOptions.formatterOptions.min
            },
            valueLimit:
                (config.formatType === "percentage" && PERCENTAGE_FORMATTER_LIMIT / 100) ||
                charOptions.valueLimit,
            labels: config.labels || charOptions.labels,
            chartOrientation: config.chartOrientation
        }));

        if (this._isActivated) {
            await this._levelDataService.init(
                this._config,
                this._widgetHost.notifyWidgetReady.bind(this, this._id),
                this._id
            );
        }
    }

    async ngOnInit(): Promise<void> {
        if (!this._isConfigValid()) {
            return;
        }

        await this._levelDataService.init(
            this._config,
            this._widgetHost.notifyWidgetReady.bind(this, this._id),
            this._id
        );
        this._isActivated = true;

        if (this._pageReferences.isAllowed()) {
            this._pageReferences.stateChange.pipe(takeUntil(this._destroyed$)).subscribe(() => {
                this._prepareChartFields();
            });
        }

        this._prepareChartFields();
    }

    getState(): ChartWidgetState | null {
        if (!this._isConfigValid() || !this._pivot() || this._sortField() == null) return null;

        return {
            sortDescending: this._sortDescending,
            sortField: this._sortField(),
            levelState: this._levelDataService.getLevelState()
        };
    }

    setState(state: ChartWidgetState): boolean {
        this._sortDescending = state.sortDescending;
        this._levelDataService.setSorting(state.sortField, this._sortDescending);
        const levelState = !state.levelState
            ? {
                  table: 0,
                  level: 0,
                  stack: []
              }
            : state.levelState;
        this._levelDataService.setLevelState(levelState);

        return true;
    }

    async showConfigurationUi(): Promise<void> {
        const config = await this._configurator.show(this._config);

        const configVersion = this._widgetTypes.getWidgetConfigVersion(this);
        await this._widgetHost.updateWidgetConfiguration(
            this._id!,
            this.getWidgetType(),
            configVersion,
            config
        );

        this.setConfig(config);
    }

    getWidgetType(): string {
        return CHART_WIDGET;
    }

    private _attachFormat(
        columns: PivotTableColumn[],
        formatType: PivotTableColumnFormulaFormatting,
        formatPrecision: number
    ): void {
        for (const x of columns) {
            Object.assign(x, {
                ...x,
                formatType,
                formatPrecision
            });
        }
    }

    private _prepareChartFields(): void {
        this._levelDataService.setChartFields();

        this._chartGroups.set(
            (this._columns() as ChartWidgetColumn[]).map(column => ({
                id: getColumnFieldName(column),
                name: this._referenceVariablePipe.transform(column.title),
                type: column.chartType
            }))
        );
    }

    _onReorderChartLevelData(reference: string): void {
        this._sortDescending = this._sortField() === reference ? !this._sortDescending : true;
        this._levelDataService.setSorting(reference, this._sortDescending);
        this._levelDataService.prepareLevelData(true);
    }

    _onChartBarClick(item: ChartRenderBaseDatum<Record<string, any>>): void {
        this._levelDataService.navigateToNextLevel(item);
    }

    _onGoBack(): void {
        this._levelDataService.navigateToPrevLevel();
    }

    // ----------------------------------------------------------------------------------
    _export(option: ExportFormat): void {
        if (this._config == null) throw Error("Config shouldn't be undefined.");
        if (this._pivot() == null) throw Error("Pivot shouldn't be undefined.");

        switch (option) {
            case ExportFormat.XLSX: {
                this._exportService.export(
                    this._pivot(),
                    this._levelDataService.scheme(),
                    this._levels(),
                    this._columns(),
                    this._pageReferences.selectedReferences,
                    this._config.title
                );
                break;
            }
            case ExportFormat.SVG: {
                const svg = cloneElementWithStyles(
                    this.rectEl?.nativeElement.getElementsByTagName("svg")[0]
                ) as SVGElement;
                exportSvg(svg, getExportFilename(this._config.title));
                break;
            }
            case ExportFormat.PNG: {
                const svg = cloneElementWithStyles(
                    this.rectEl?.nativeElement.getElementsByTagName("svg")[0]
                ) as SVGElement;
                exportSvgToPng(
                    svg,
                    2000,
                    getExportFilename(
                        this._config.title ?? this._lgTranslate.translate(".WidgetTitle")
                    )
                );
                break;
            }
        }
    }
}
