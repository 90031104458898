import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
    LG_APP_CONFIGURATION,
    MaybeStaleData,
    ServerGatewayBase
} from "@logex/framework/lg-application";
import { FlexDataClientMetadataArguments } from "../../flex-data-client/types/types";
import { FlexibleDatasetDataArguments, SelectDataResponse } from "../types/types";

@Injectable()
export class FlexibleDatasetGateway extends ServerGatewayBase {
    constructor() {
        super();

        const appConfiguration = inject(LG_APP_CONFIGURATION);
        this._setBaseUrl(appConfiguration.applicationRoot);
    }

    // ----------------------------------------------------------------------------------
    selectData(
        url: string,
        metadataArgs: FlexDataClientMetadataArguments,
        dataArgs: FlexibleDatasetDataArguments
    ): Observable<MaybeStaleData<SelectDataResponse>> {
        return this._postMaybeStaleQuery<SelectDataResponse>(url, {
            params: metadataArgs,
            body: dataArgs
        });
    }
}
