<a
    class="lg-flexlayout-panel-item clickable"
    style="display: flex; align-items: center"
    (click)="onLayoutLoad.emit(layout().id)"
    [lgTooltip]="layout().description"
    [lgTooltipDelay]="[1000, 0]"
>
    {{ layout().name }}
    @if (isLayoutReadOnly()) {
        <lg-icon class="lg-flexlayout-panel__icon--lock" icon="icon-lock" />
        <lg-icon
            class="lg-flexlayout-panel__icon"
            icon="icon-duplicate"
            [lgTooltip]="'.DuplicateLayout' | lgTranslate"
            [class.lg-flexlayout-panel__icon--hover-only]="!layout().isTemporary"
            [clickable]="true"
            (click)="onLayoutDuplicate.emit(layout().id)"
        />
    }
</a>
@if ((!isLayoutInProgress() || layout().isTemporary) && !isLayoutReadOnly()) {
    <lg-icon
        class="lg-flexlayout-panel__icon lg-flexlayout-panel__icon--duplicate"
        icon="icon-duplicate"
        [lgTooltip]="'.DuplicateLayout' | lgTranslate"
        [class.lg-flexlayout-panel__icon--hover-only]="!layout().isTemporary"
        [clickable]="true"
        (click)="onLayoutDuplicate.emit(layout().id)"
    />
    <lg-icon
        class="lg-flexlayout-panel__icon"
        icon="icon-edit"
        [lgTooltip]="'.EditLayout' | lgTranslate"
        [class.lg-flexlayout-panel__icon--hover-only]="!layout().isTemporary"
        [clickable]="true"
        (click)="onLayoutEdit.emit(layout().id)"
    />
}
