<div #chart [ngClass]="{ 'lgflex-chart__horizontal': chartOrientation === 'horizontal' }"></div>

<lg-chart-legend
    *ngIf="legendOptions.visible"
    [className]="legendOptions.className!"
    [definition]="_legendDefinition"
    [position]="legendOptions.position"
    [cropLongNames]="legendOptions.cropLongNames!"
    [wrapMultilineDefinition]="legendOptions.wrapMultilineDefinition!"
    [height]="_spaceForLegend"
    [width]="width"
    [style.margin-bottom.px]="_margin.bottom"
    (onClick)="_onLegendItemClick($event)"
>
</lg-chart-legend>

<ng-template #defaultTooltip>
    <ng-container *ngIf="_tooltipContext$ | async as c">
        <div class="lg-d3-tooltip__tip-name">
            {{ c.column.column }}
        </div>
        <div style="display: flex; gap: 20px" *ngIf="c.column">
            <div>
                <table class="lg-chart__tooltip__table">
                    <tr
                        *ngFor="let item of c.column.values | keyvalue"
                        class="lg-chart__tooltip__table__item"
                        [class.lg-chart__tooltip__table__item--not-active]="item.key !== c.group.id"
                    >
                        <td class="lg-chart__tooltip__table__item__color_column">
                            <span [style.background]="c.groupColors[item.key]"></span>
                        </td>
                        <td class="lg-chart__tooltip__table__item__name_column">
                            {{ _groupsDictionary[item.key].name }}:
                        </td>
                        <td align="right" class="lg-chart__tooltip__table__item__value_column">
                            {{ _formatter.format(item.value.value) }}
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <table
                    *ngIf="c.stackedBars !== undefined && c.currentStackedBar !== undefined"
                    class="lg-chart__tooltip__table"
                >
                    <tr
                        *ngFor="let item of c.stackedBars"
                        class="lg-chart__tooltip__table__item"
                        [class.lg-chart__tooltip__table__item--not-active]="
                            item.name !== c.currentStackedBar.name
                        "
                    >
                        <td class="lg-chart__tooltip__table__item__name_column">
                            {{ item.name }}:
                        </td>
                        <td align="right" class="lg-chart__tooltip__table__item__value_column">
                            {{ _formatter.format(item.value) }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </ng-container>
</ng-template>
