<ng-container *lgPlaceholderContent="'breadcrumb-row.left.first'">
    <lg-breadcrumb
        [titlePrefixLc]="titlePrefixLc"
        [toolNameLc]="toolNameLc"
        [homeId]="homeId"
        [toolNameParams]="toolNameParams"
        [noLastHighlight]="(_state$ | async).length > 0"
    ></lg-breadcrumb>
    <div class="lg-breadcrumb lg-breadcrumb--secondary">
        <div
            class="lg-breadcrumb__element"
            *ngFor="let element of _state$ | async; let index = index"
        >
            <div class="lg-breadcrumb__arrow">
                <lg-icon icon="icon-arrow-down"></lg-icon>
            </div>
            <div class="lg-breadcrumb__element__text" [title]="element.name">
                {{ element.name }}
            </div>
        </div>
    </div>
</ng-container>
