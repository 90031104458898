<div
    class="lg-pivot-levels-selector__wrapper"
    [class.lg-pivot-levels-selector__wrapper--has-border]="hideBorder === false"
    #wrapper
>
    <div lgScrollable cdkDropList style="height: 100%" [lgScrollableWrapper]="false" #scroller>
        <ng-container *ngFor="let level of _selectedLevels; let idx = index">
            <div
                class="lg-pivot-levels-selector__row table__row"
                [class.lg-pivot-levels-selector__row--current]="
                    !disableEditing && level.position === _currentLevel?.position
                "
                [class.clickable]="!disableEditing"
                [style.height.px]="rowHeight"
                cdkDrag
                [cdkDragDisabled]="!enableSorting"
                [cdkDragData]="level"
                cdkDragBoundary=".lgVirtualForOf-holder"
                cdkDragLockAxis="y"
                (cdkDragDropped)="onRowDrop($event)"
                (click)="onRowClick($event, level)"
                cdkDragHandle
            >
                <div
                    *ngIf="enableSorting"
                    class="table__column table__column--icons"
                    [style.width.px]="24"
                    [style.flex-shrink]="0"
                >
                    <lg-icon icon="icon-draggable" class="table__row__hover-only"></lg-icon>
                </div>

                <div
                    class="lg-pivot-levels-selector__spacer"
                    [style]="{ width: level.indent * 21 + 'px' }"
                ></div>

                <span class="flexbox">
                    <lg-icon
                        *ngIf="level.icon"
                        [icon]="_iconDefinitions[level.icon]?.icon ?? ''"
                        [class]="_iconDefinitions[level.icon]?.iconClass ?? ''"
                    ></lg-icon>
                </span>
                <div class="lg-pivot-levels-selector__content crop-text" [title]="level.name">
                    {{ level.name }}
                </div>

                <div
                    class="lg-pivot-levels-selector__icons lg-pivot-levels-selector__row__hover-only"
                >
                    <lg-icon
                        (click)="_removeLevel(level.id, idx)"
                        class="lg-icon-menu hoverable"
                        icon="icon-delete"
                        [lgTooltip]="'.Delete' | lgTranslate"
                    ></lg-icon>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<ng-container *lgPlaceholderContent="buttonPlaceholderName">
    <lg-dropdown
        (activeChange)="_levelsDropdownActiveChange($event)"
        [definition]="_levelsDropdownDefinition"
        [current]="null"
        (currentChange)="_onLevelSelect($event)"
        [emptyAcceptable]="true"
        [condensed]="true"
        [style.visibility]="_isLevelsDropdownActive ? 'visible' : 'hidden'"
        class="flex-flexible"
        className="lg-dropdown lg-dropdown--condensed"
        style="position: absolute"
        #levelsDropdown
    ></lg-dropdown>
    <lg-button
        style="display: flex"
        [buttonClass]="'button button--condensed'"
        textLc=".AddLevel"
        icon="icon-add"
        (click)="_addLevel()"
    ></lg-button>
</ng-container>
