<div
    class="lg-layout-editor lg-sidebar-panel lg-sidebar-flexlayout flexcol"
    *lgSidebarPanel="
        let sidebar;
        nameLc: '_Flexible._LayoutEditorSidebar.Layout';
        icon: 'icon-layout-editor';
        top: true;
        nameAlwaysVisible: false;
        id: 'flexLayout'
    "
    [lgLoaderOverlay]="(_isLoading$ | async) ?? false"
>
    <ul class="lg-sidebar-flexlayout__level-1">
        <li>
            <div class="lg-sidebar-flexlayout__header">
                {{ ".Layouts" | lgTranslate }}

                <span class="lg-sidebar-flexlayout__icon" style="display: flex">
                    <lg-icon
                        *ngIf="!_temporaryLayoutInProgress() && (_userCanCreateLayout | async)"
                        icon="icon-audit-add"
                        [clickable]="true"
                        (click)="_createLayout()"
                        [lgTooltip]="'.CreateLayout' | lgTranslate"
                    ></lg-icon>
                </span>
            </div>

            <ng-container *ngFor="let library of _libraries$ | async">
                <ul class="lg-sidebar-flexlayout__level-2" *ngFor="let layout of library.layouts">
                    <ng-container *ngIf="!layout.hasTemporary">
                        <li class="lg-sidebar-flexlayout-item">
                            <a
                                class="lg-sidebar-flexlayout-item-link clickable"
                                style="display: flex; align-items: center"
                                [class.lg-sidebar-flexlayout-item-link--selected]="
                                    layoutId === layout.id
                                "
                                (click)="_loadLayout(layout.id)"
                                [lgTooltip]="layout.description"
                                [lgTooltipDelay]="[1000, 0]"
                            >
                                {{ layout.name }}
                                <lg-icon
                                    style="margin-left: 4px"
                                    *ngIf="library.readOnly"
                                    icon="icon-lock"
                                ></lg-icon>
                            </a>

                            <lg-icon
                                class="lg-sidebar-flexlayout__icon"
                                *ngIf="
                                    (!_temporaryLayoutInProgress() || layout.isTemporary) &&
                                    !library.readOnly
                                "
                                [class.lg-sidebar-flexlayout__icon--hover-only]="
                                    !layout.isTemporary
                                "
                                icon="icon-edit"
                                [clickable]="true"
                                (click)="_editLayout(layout.id)"
                            ></lg-icon>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
        </li>

        <ng-container *ngIf="pageReferences.isAllowed() && pageReferences.slots.length > 0">
            <li>
                <div class="lg-sidebar-flexlayout__header">
                    {{ ".CurrentReferences" | lgTranslate }}
                </div>

                <ul class="lg-sidebar-flexlayout__level-2">
                    <li
                        class="lg-sidebar-flexlayout-item lg-sidebar-flexlayout-item-parts"
                        *ngFor="let ref of pageReferences.selectedReferences; let i = index"
                    >
                        <div>
                            <lg-dropdown
                                [current]="ref.code"
                                (currentChange)="_onReferenceSelected(i, $event)"
                                [definition]="_referencesDropdown"
                                [hideSearch]="true"
                                [condensed]="true"
                                [className]="'lg-dropdown lg-dropdown--condensed'"
                                [disabled]="_isReferenceLocked(i)"
                            ></lg-dropdown>
                        </div>
                        <div class="lg-sidebar-flexlayout-item-parts__icon" [style.width.px]="24">
                            <lg-icon
                                *ngIf="_isReferenceLocked(i)"
                                icon="icon-lock"
                                [lgTooltip]="'.ReferenceIsLocked' | lgTranslate"
                                lgTooltipPosition="right-bottom"
                            ></lg-icon>
                        </div>
                    </li>
                </ul>
            </li>

            <li>
                <div class="lg-sidebar-flexlayout__header">
                    {{ ".ReferenceCombinations" | lgTranslate }}

                    <lg-icon
                        class="lg-sidebar-flexlayout__icon"
                        icon="icon-add"
                        [clickable]="true"
                        (click)="_editReferenceCombination()"
                        [lgTooltip]="'.AddCombination' | lgTranslate"
                    ></lg-icon>
                </div>

                <ul class="lg-sidebar-flexlayout__level-2">
                    <li
                        class="lg-sidebar-flexlayout-item"
                        *ngFor="let x of _referenceCombinations.list"
                    >
                        <a
                            class="lg-sidebar-flexlayout-item-link clickable"
                            [class.lg-sidebar-flexlayout-item-link--selected]="
                                _isSelectedReferenceCombination(x.id)
                            "
                            (click)="_selectReferenceCombination(x.id)"
                        >
                            {{ x.name }}
                        </a>

                        <lg-icon
                            class="lg-sidebar-flexlayout__icon lg-sidebar-flexlayout__icon--hover-only"
                            icon="icon-edit"
                            [clickable]="true"
                            (click)="_editReferenceCombination(x.id)"
                            [lgTooltip]="'.EditCombination' | lgTranslate"
                        ></lg-icon>
                    </li>
                </ul>
            </li>
        </ng-container>
    </ul>
</div>
