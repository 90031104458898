import { CrosstabWidgetConfig } from "../../crosstab-widget.types";
import { FieldInfo, ReferenceSlot } from "../../../../types";
import { validateLevels } from "../../../../utilities/widget-configuration-validations";

export function validateCrosstabConfiguration(
    config: Partial<CrosstabWidgetConfig>,
    scheme: FieldInfo[],
    referenceSlots: ReferenceSlot[]
): boolean {
    const fieldInfo = scheme.find(x => x.field === config.value);
    if (fieldInfo === undefined) return false;

    const referenceValid =
        !fieldInfo.isReferenceBound ||
        (config.referenceIdx != null && config.referenceIdx < referenceSlots.length);

    return (
        config.title != null &&
        config.title.length > 0 &&
        config.horizontalDimension != null &&
        config.verticalDimension != null &&
        config.value != null &&
        config.sidebarWidth != null &&
        config.sidebarWidth >= 0 &&
        config.sidebarWidth <= 1000 &&
        config.cellWidth != null &&
        config.cellWidth > 0 &&
        config.cellWidth <= 1000 &&
        (fieldInfo.type === "float" || fieldInfo.type === "money") &&
        referenceValid &&
        validateLevels(new Set([config.horizontalDimension, config.verticalDimension]), scheme)
    );
}
