import {
    ComponentRef,
    Directive,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    StaticProvider,
    Type,
    ViewContainerRef
} from "@angular/core";
import { LgConsole } from "@logex/framework/core";
import { LgSimpleChanges } from "@logex/framework/types";
import { WidgetComponent } from "../../types";
import { isEqualShallow } from "../../utilities/isEqualShallow";

export interface FlexibleLayoutWidgetHostChangeEvent<TContext> {
    id: string;
    instance: WidgetComponent<TContext> | null;
}

@Directive({
    selector: "[lgflexWidgetHost]"
})
export class FlexibleLayoutWidgetHostDirective<TContext> implements OnInit, OnChanges, OnDestroy {
    constructor(
        private _viewContainerRef: ViewContainerRef,
        private _injector: Injector,
        private _lgConsole: LgConsole
    ) {}

    @Input("widgetId") id: string | undefined;
    @Input("widgetType") type: Type<WidgetComponent<TContext>> | undefined;
    @Input("dependencyProviders") providers: StaticProvider[] | undefined;
    @Input("widgetConfig") config: object | null = null;
    @Input("context") context: TContext | undefined;
    @Input("widgetVisibility") isVisible = false;
    @Input("autoConverted") autoConverted = false;
    @Input() widgetState: {} | null | undefined;
    @Output() readonly widgetInstanceChange = new EventEmitter<
        FlexibleLayoutWidgetHostChangeEvent<TContext>
    >(true);

    private _widget: WidgetComponent<TContext> | undefined = undefined;
    private _componentRef: ComponentRef<WidgetComponent<TContext>> | undefined = undefined;
    private _instanceId: string | undefined = undefined;
    private _initialized = false;

    ngOnInit(): void {
        this._attachWidget();
        this._initialized = true;
    }

    ngOnChanges(changes: LgSimpleChanges<FlexibleLayoutWidgetHostDirective<TContext>>): void {
        if (!this._initialized) return;

        // note that we don't handle changing state after the creation, specifically since the state may be updated
        // in the lifetime of the widget by the widget itself
        if (changes.type || changes.id || changes.providers || changes.config) {
            this._detachWidget();
            this._attachWidget();
        } else if (this._widget !== undefined) {
            if (
                changes.context &&
                !isEqualShallow(changes.context.currentValue, changes.context.previousValue)
            ) {
                this._widget.setContext(changes.context.currentValue);
            }

            if (changes.isVisible) {
                this._widget.onVisibilityChange?.(changes.isVisible.currentValue);
            }
        }
    }

    ngOnDestroy(): void {
        this._detachWidget();
    }

    private _attachWidget(): void {
        if (this.type === undefined) throw Error("Widget type is not specified");
        if (this.id === undefined) return;

        let injector = this._injector;

        if (this.providers !== undefined) {
            injector = Injector.create({
                parent: injector,
                providers: this.providers
            });
        }

        this._componentRef = this._viewContainerRef.createComponent<WidgetComponent<TContext>>(
            this.type,
            {
                injector
            }
        );

        this._widget = this._componentRef.instance;
        // because we now allow changing the parameters, we need to preserve the original id at the time of creation
        // (this seems safer to track than obtaining it from changes and passing it to _detachWidget explicitly)
        this._instanceId = this.id;
        this._widget.setConfig(this.config);
        this._widget.setId(this.id);
        if (this.autoConverted) this._widget.markAsAutoConverted();
        if (this.context !== undefined) {
            this._widget.setContext(this.context);
        }
        if (!this.isVisible) {
            this._widget.onVisibilityChange?.(false);
        }
        if (this.widgetState) {
            this._widget.setState?.(this.widgetState);
        }

        this.widgetInstanceChange.emit({ id: this._instanceId, instance: this._widget });
    }

    private _detachWidget(): void {
        if (this._componentRef) {
            const id = this._instanceId!;
            this._componentRef.destroy();
            this._componentRef = undefined;
            this._widget = undefined;
            this._instanceId = undefined;
            this.widgetInstanceChange.emit({ id, instance: null });
        }
    }
}
