import { InjectionToken } from "@angular/core";
import { EmbeddedWidget, PageWidgetConfigBase, WidgetConfigBase } from "../../types";

export type ConfigMigrator<T extends WidgetConfigBase = any> = (config: T) => void | number;
export type ConfigMigratorDict = Record<number, ConfigMigrator>;

export type EmbeddedWidgetsToMigrate<T extends PageWidgetConfigBase = any> = (
    config: T
) => EmbeddedWidget[];

export interface WidgetMigrationRecord {
    id: string;
    migrations: ConfigMigratorDict;
}

export interface EmbeddedWidgetsToMigrateRecord {
    id: string;
    embeddedWidgets?: EmbeddedWidgetsToMigrate;
}

export const WIDGET_MIGRATION = new InjectionToken<WidgetMigrationRecord>("WidgetMigration");

export type WidgetReplacer<
    TSrc extends PageWidgetConfigBase = any,
    TRes extends PageWidgetConfigBase = any
> = (config: TSrc) => TRes;

export type WidgetReplacerDeclaration<
    TSrc extends PageWidgetConfigBase = any,
    TRes extends PageWidgetConfigBase = any
> = {
    sourceType: string;
    targetType: string;
    replacer: WidgetReplacer<TSrc, TRes>;
    priority?: number;
};

export const WIDGET_REPLACEMENT = new InjectionToken<
    WidgetReplacerDeclaration | WidgetReplacerDeclaration[]
>("WidgetReplacement");

export const EMBEDDED_WIDGETS_TO_MIGRATE = new InjectionToken<EmbeddedWidgetsToMigrateRecord>(
    "EmbeddedWidgetsToMigrate"
);
