import * as _ from "lodash-es";
import { Observable } from "rxjs";
import { mixins } from "@logex/mixin-flavors";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { IDefinitions } from "@logex/framework/lg-application";
import {
    IComboFilter2Definition,
    IComboFilter2WrappedId,
    IFilterDefinition,
    LgFilterSet,
    LgFilterSetService
} from "@logex/framework/lg-filterset";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { LoaderArgumentsMap } from "@logex/load-manager";
import { HandleErrorsMixin } from "@logex/mixins";
import * as Pivots from "@logex/framework/lg-pivot";

import { FlexDataClientService } from "../flex-data-client/flex-data-client.service";

import { FieldInfo } from "../../types";
import {
    FiltersLayoutGroup,
    GroupFilter,
    IFilterFactoryEntry
} from "./flexible-filter-factory.types";

// ---------------------------------------------------------------------------------------------
export interface FlexibleFilterFactoryCreatorBase extends HandleErrorsMixin {}

@mixins(HandleErrorsMixin)
export abstract class FlexibleFilterFactoryCreatorBase {
    protected constructor(
        protected _filterSetService: LgFilterSetService,
        public _promptDialog: LgPromptDialog,
        public _lgTranslate: LgTranslateService,
        protected _appDefinitions: IDefinitions<any>,
        protected _flexDataClient: FlexDataClientService
    ) {
        this._initMixins();
    }

    protected _definition: IFilterDefinition[] = [];
    private _startGroup = false;
    private _startGroupLC: string | null = null;

    private _cursor = 0;

    configure(
        scheme: FieldInfo[],
        filtersLayout: FiltersLayoutGroup[]
    ): FlexibleFilterFactoryCreatorBase {
        const layout = filtersLayout != null ? _.clone(filtersLayout) : [];

        const filterNamesInLayout = _.chain(layout)
            .map(x => x.filters)
            .flatten()
            .map(x => x.filter)
            .value();

        const fieldsNotInLayout = _.filter(
            scheme,
            x => !x.isValueField && !filterNamesInLayout.includes(x.field)
        );
        const otherFilterNames = fieldsNotInLayout.map(x => x.field);

        if (fieldsNotInLayout.length > 0) {
            layout.push({
                lc: "_Flexible._FilterGroups.Other",
                filters: fieldsNotInLayout.map(x => ({
                    name: x.name ?? undefined,
                    lc: x.nameLc ?? undefined,
                    filter: x.field
                }))
            });
        }

        for (const group of layout) {
            const groupFilters = group.filters
                .map(x => {
                    const field = _.find(scheme, { field: x.filter });
                    if (field == null) return null;
                    return {
                        name: x.name,
                        lc: x.lc,
                        field
                    };
                })
                .filter((x): x is GroupFilter => x != null);

            if (_.isEmpty(groupFilters)) continue;

            let isFirst = true;
            for (const { lc, name, field } of groupFilters) {
                if (field.isValueField) {
                    throw Error(`Cannot filter by value field ${field.field}`);
                }

                let idType: "string" | "number" | "boolean";
                if (field.type === "string" || field.type === "number") {
                    idType = field.type;
                } else {
                    const codeType = this._appDefinitions.getSection(field.type).codeType;
                    if (codeType === undefined) {
                        throw Error(`Undefined codeType field of definion ${field.type}.`);
                    } else {
                        idType = codeType;
                    }
                }

                const nameFinal = name ?? (lc ? this._lgTranslate.translate(lc) : "");
                const label = otherFilterNames.includes(field.field) && nameFinal;
                const placeholder =
                    label &&
                    this._lgTranslate.translate("FW._Directives.ComboFilterRenderer_Search") +
                        label.substring(0, 1).toLowerCase() +
                        label.substring(1);

                const filterDef = <IFilterFactoryEntry<IComboFilter2Definition<string | number>>>{
                    filterType: "combo2",
                    main: true,
                    idType,
                    visible: () => true,
                    allowNullValues: true,
                    name,
                    nameLC: lc,
                    label,
                    placeholder,
                    mapToOptions:
                        field.type === "string" || field.type === "number"
                            ? this._createPlainMapper()
                            : this._createMapper(field.type as any),
                    source: this.createFilterDefinitionSourceFn(field)
                };
                filterDef.startGroupLC = isFirst ? group.lc : undefined;

                this._addFilter(field.field, filterDef);

                isFirst = false;
            }
        }

        return this;
    }

    abstract createFilterDefinitionSourceFn<T extends string | number>(
        field: FieldInfo
    ):
        | Pivots.IGatherFilterIdsFactoryResult<T>
        | (() => IterableIterator<T>)
        | (() => Promise<T[]>)
        | (() => Observable<T[]>)
        | (() => Array<IComboFilter2WrappedId<T>>)
        | (() => IterableIterator<IComboFilter2WrappedId<T>>)
        | (() => Observable<Array<IComboFilter2WrappedId<T>>>)
        | (() => Promise<Array<IComboFilter2WrappedId<T>>>);

    create(context: any): LgFilterSet {
        return this._filterSetService.create(this._definition, this._lgTranslate, context);
    }

    protected _addFilter<TConfig extends IFilterDefinition>(
        id: string,
        params: IFilterFactoryEntry<TConfig>
    ): void {
        const entry: TConfig = _.clone(params) as TConfig;
        entry.id = id;

        // Getting rid of some legacy behaviour
        if (entry.visible === undefined) entry.visible = () => true;
        if (entry.main === undefined) entry.main = true;

        // Add the separately configured startgroups
        if (this._startGroup) {
            entry.startGroup = true;
            this._startGroup = false;
        } else if (this._startGroupLC) {
            entry.startGroupLC = this._startGroupLC;
            this._startGroupLC = null;
        }

        this._definition.splice(this._cursor, 0, entry);
        this._cursor++;
    }

    protected _createMapper<T extends number | string>(
        definition: string
    ): (codes: T[]) => Array<{ id: T; name: string; order: unknown }> {
        return (codes: T[]) =>
            _.chain(codes)
                .map(code => ({
                    id: code,
                    name: this._appDefinitions.getDisplayName(definition, code),
                    order: this._appDefinitions.getOrderBy(definition, code)
                }))
                .sortBy(f => f.order)
                .value();
    }

    protected _createPlainMapper<T extends number | string>(): (
        codes: T[]
    ) => Array<{ id: T; name: string; order: T }> {
        return (codes: T[]) =>
            _.chain(codes)
                .map(code => ({
                    id: code,
                    name: code?.toString(),
                    order: code
                }))
                .sortBy(f => f.order)
                .value();
    }

    protected _omitFilters<T extends LoaderArgumentsMap>(args: T, ...names: string[]): T {
        if (args["filters"] == null) return args;

        const getFilters = args["filters"];
        return {
            ...args,
            filters: () => {
                const filters = _.omit(getFilters(), names);
                if (_.isEmpty(filters)) return undefined;
                return filters;
            }
        };
    }
}
