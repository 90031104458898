<div
    class="lg-row-selector__wrapper"
    [class.lg-row-selector__wrapper--has-border]="hideBorder === false"
    #wrapper
>
    <div class="lg-row-selector" lgScrollable cdkDropList [lgScrollableWrapper]="false" #scroller>
        <ng-container *ngFor="let row of rows; let i = index">
            <div
                cdkDrag
                cdkDragHandle
                class="lg-row-selector__row table__row"
                [class.lg-row-selector__row--current]="row.id === _selectedRowId()"
                [cdkDragData]="row"
                (cdkDragDropped)="_onRowDrop($event)"
                (click)="onRowSelected(row.id)"
            >
                <div
                    class="lg-row-selector__drag-handle table__column table__column--icons"
                    [style.width.px]="24"
                    [style.flex-shrink]="0"
                >
                    <lg-icon icon="icon-draggable" class="table__row__hover-only"></lg-icon>
                </div>

                <div *ngIf="row.icon !== undefined" class="lg-row-selector__row-icon">
                    <lg-icon [icon]="row.icon"></lg-icon>
                </div>

                <div class="lg-row-selector__content crop-text" [title]="row.name">
                    {{ row.name }}
                </div>

                <div class="lg-row-selector__icons table__column--icons icons table__column">
                    <lg-icon
                        class="table__row__hover-only"
                        icon="icon-delete"
                        [clickable]="true"
                        [lgTooltip]="'_Flexible._.Delete' | lgTranslate"
                        (click)="_onRowRemoved(row.id)"
                    ></lg-icon>
                </div>
            </div>
        </ng-container>
    </div>
</div>
