import {
    FlexibleDrilldownPivotTableState,
    PageWidgetConfigBase,
    ReferenceSlot,
    WidgetState
} from "../../types";
import { GroupOrColumn, Column, TableConfig, PivotTableType } from "../../types/configuration";
export const PIVOT_TABLE_WIDGET_2 = "PivotTableWidget2";

export type PivotTableWidgetColumn = Column;
export type PivotTableLevelType = PivotTableType;
export type PivotTableGroupOrColumn = GroupOrColumn & {
    referenceCode?: string;
};
export interface PivotTableWidgetConfig extends PageWidgetConfigBase {
    dataSource: string;
    selectedReferences: ReferenceSlot[];
    title: string;
    columns: PivotTableGroupOrColumn[];
    tablesConfig: TableConfig[];
}

export type PivotTableWidgetState = WidgetState & {
    version: number;
    tableState: FlexibleDrilldownPivotTableState | null;
};
