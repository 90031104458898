import { Injector, ViewContainerRef } from "@angular/core";
import {
    EmbeddedWidget,
    EmbeddedWidgetContext,
    WidgetConfigurator,
    WidgetTypeInfo
} from "../types";
import { PageReferencesService } from "../services/page-references/page-references.service";
import { firstValueFrom } from "rxjs";
import { WidgetTypesRegistry } from "../services/widget-types-registry";
import { FlexibleLayoutUpgraderService } from "../services/flexible-layout-upgrader";

export function getConfiguratorInstance(
    parentInjector: Injector,
    type: WidgetTypeInfo,
    pageReferencesService: PageReferencesService
): WidgetConfigurator | undefined {
    if (type.configurator === undefined) return undefined;

    const providers = [...(type.providers ?? [])];
    providers.push({ provide: PageReferencesService, useValue: pageReferencesService });

    const injector = Injector.create({
        parent: parentInjector,
        providers
    });

    return injector.get(type.configurator);
}

export async function getEmbeddedWidgetConfiguration(
    parentInjector: Injector,
    widgetTypes: WidgetTypesRegistry,
    pageReferences: PageReferencesService,
    upgrader: FlexibleLayoutUpgraderService,
    visualizationInlineComponentContainer: ViewContainerRef,
    widget: EmbeddedWidget | undefined,
    widgetTypeName: string | null,
    widgetContext: EmbeddedWidgetContext
): Promise<EmbeddedWidget | undefined> {
    visualizationInlineComponentContainer.clear();

    if (widgetTypeName === null) {
        return undefined;
    }

    const widgetType = widgetTypes.tryGet(widgetTypeName);
    if (widgetType === undefined) {
        return undefined;
    }

    const configurator = getConfiguratorInstance(parentInjector, widgetType, pageReferences);
    if (configurator === undefined || configurator.type !== "inline")
        throw Error("Widget configurator is not valid");

    let updatedWidget: EmbeddedWidget;
    if (widget?.type === widgetTypeName) {
        updatedWidget = widget;
    } else {
        updatedWidget = {
            type: widgetTypeName,
            configVersion: widgetType.configVersion,
            config: configurator.getDefaultConfiguration()
        };
    }

    // TODO: Add subscription
    updatedWidget.config = await firstValueFrom(
        configurator!.insertInlineComponent(
            visualizationInlineComponentContainer,
            updatedWidget.config,
            widgetContext
        )
    );

    return updatedWidget;
}
