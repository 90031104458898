<lgflex-dialog
    class="widget-configuration-dialog"
    [isReadOnly]="_isReadOnly"
    [isValid]="_isValid()"
    (saveAction)="_save()"
    (closeAction)="_close()"
>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Information' | lgTranslate">
        <lgflex-widget-configuration-section-information
            [(widgetName)]="_widgetName"
            [(widgetDescription)]="_widgetDescription"
            [(widgetTooltip)]="_widgetTooltip"
            [(widgetTooltipLink)]="_widgetTooltipLink"
        />
    </lgflex-dialog-tab>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Configuration' | lgTranslate">
        <lgflex-form-section [hideDivider]="true">
            <lgflex-form-section-column>
                <lgflex-form-row [label]="'_Flexible.WidgetConfiguration.Levels' | lgTranslate">
                    <lgflex-pivot-levels-selector-legacy
                        [levels$]="_availableLevels$"
                        [(selectedLevelIds)]="_selectedLevelsIds"
                        (selectedLevelIdsChange)="_onDropdownChange()"
                        [minRowNum]="3"
                    />
                </lgflex-form-row>

                <lgflex-form-row>
                    <label>
                        <input
                            type="checkbox"
                            lgStyledCheckbox
                            [(ngModel)]="_ignoreOwnFilters"
                            [required]="true"
                        />
                        &nbsp;
                        {{ "_Flexible.WidgetConfiguration.IgnoreOwnFilters" | lgTranslate }}
                    </label>
                </lgflex-form-row>

                <lgflex-form-row [label]="'_Flexible.WidgetConfiguration.ValueField' | lgTranslate">
                    <lg-dropdown
                        [definition]="_valueFieldDropdown!"
                        [(current)]="_valueField!"
                        (currentChange)="_onDropdownChange()"
                    />
                </lgflex-form-row>

                <lgflex-form-row
                    *ngIf="_referenceDropdown != null && !_allowMultipleReferences"
                    [label]="'_Flexible.WidgetConfiguration.Reference' | lgTranslate"
                >
                    <lg-dropdown
                        [definition]="_referenceDropdown"
                        [current]="_getFirstEnabledReference()"
                        (currentChange)="_setFirstEnabledReference($event)"
                    />
                </lgflex-form-row>

                <lgflex-form-row [label]="'.Icon' | lgTranslate">
                    <lg-dropdown [definition]="_iconDropdown" [(current)]="_icon" />
                </lgflex-form-row>
            </lgflex-form-section-column>
        </lgflex-form-section>
    </lgflex-dialog-tab>
</lgflex-dialog>
