import { cloneDeep } from "lodash-es";
import {
    DrilldownPivotTableWidgetBaseConfig,
    ChartWidgetConfigBase
} from "./pivot-table-conversion.types";

export function convertPivotTableToDrilldownPivotTable(
    config: ChartWidgetConfigBase
): DrilldownPivotTableWidgetBaseConfig {
    return {
        title: config.title,
        description: config.description,
        levels: [[...config.levels]],
        levelProperties: {},
        columns: cloneDeep(config.columns),
        ignoreOwnFilters: config.ignoreOwnFilters,
        defaultOrderBy: config.defaultOrderBy
    };
}
