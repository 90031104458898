import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import { IComboFilter2Definition, LgFilterSet } from "@logex/framework/lg-filterset";
import { IColumnFilterDictionary, IColumnFilterList } from "@logex/framework/types";
import { SelectedRow } from "@logex/framework/lg-pivot";

/**
 * Returns an array of IDs selected in a given combo2 filter.
 *
 * @param filterName
 * @param filters
 */
export function getFilterIds(filterName: string, filters: LgFilterSet<any, any>) {
    const filterDef = filters.getFilterDefinition(filterName);

    if (filterDef == null) {
        throw Error(`Unknown filter "${filterName}" is requested.`);
    }

    const curValue = filters.filters[filterName];

    if (curValue == null || curValue.$empty) {
        return undefined;
    }

    if (filterDef.filterType !== "combo2") {
        throw Error(`Filter type ${filterDef.filterType} is not supported by getIds()`);
    }

    const idType = (<IComboFilter2Definition<any>>filterDef).idType as string;
    const parser =
        idType === "number"
            ? (x: string) => (x === "null" ? null : parseFloat(x))
            : idType === "boolean"
            ? (x: string) => (x === "null" ? null : x === "true")
            : (x: string) => (x === "null" ? null : x);

    return _.map(curValue, (v, k: string) => parser(k));
}

/**
 * Returns filterSet configuration object for the pivot level.
 * Example:
 * ```typescript
 * filterSet: ( context: Controller ) => context.filter.getPivotFilter( {
 *     "specialisme_uitvoerend": context.FILTER_EXECUTING_SPECIALISM,
 *     [context.FILTER_ERRORS]: ( id, row: IActivityPivotLevel2 ) => row.errors > 0
 * } ),
 * ```
 *
 * @param cfg Configuration object where keys represent pivot fields and values - filter names or callbacks.
 * * If value is string, then it's used as the filter name and the key is used as the field name.
 * * If value is a function (( fieldValue, row ) => boolean) then key is the filter name and callback function
 * will be used to filter rows. If in this case filter name and field are the same, then callback would receive
 * field value as the first parameter.
 * * If value is [[Infrastructure.IColumnFilterDictionary]] then it will be applied as-is to the
 * field specified by key.
 * @param filterSet
 */
export function getPivotFilter(
    cfg: Dictionary<string | ((value: unknown, row: unknown) => boolean) | IColumnFilterDictionary>,
    filterSet: LgFilterSet<any, any>
): IColumnFilterList {
    const config: PivotLevelFilter[] = _.map(cfg, (x, key) => {
        if (_.isString(x)) {
            const match = key.match(/(\w+)#\d+/);
            if (match != null) {
                key = match[1];
            }
            return { filter: x, field: key };
        } else if (_.isFunction(x)) {
            return { filter: key, fn: x };
        } else {
            return { filter: key, field: key, value: x } as PivotLevelFilter;
        }
    });
    return configurePivotFilter(config, filterSet);
}

export interface PivotLevelFilter {
    filter: string;
    field?: string;
    fn?: (value: unknown, row: unknown) => boolean;
    value?: IColumnFilterDictionary | SelectedRow<any, any>;
}

// noinspection TsLint
/**
 * Returns filter configuration object for the pivot level. This is stricter version than [[getPivotFilter]]
 * method.
 *
 * @param cfg Array of individual filter configuration. Each configuration must specify: obligatory filter name,
 * field name (and optionally a value) or callback function.
 * * If field name without value is given, then filter value is taken automatically from filtering object
 * and applied to the field according to the rules of the filter type. Works for well-know filter types.
 * @param filterSet
 */
export function configurePivotFilter(
    cfg: PivotLevelFilter[],
    filterSet: LgFilterSet<any, any>
): IColumnFilterList {
    return _.reduce(
        cfg,
        (res: IColumnFilterList, x) => {
            if (x.filter == null) {
                throw Error("Filter name must be specified");
            }

            if (x.field == null && x.fn == null) {
                throw Error("Either 'field' or 'fn' must be specified");
            }

            if (x.field != null && x.fn != null) {
                throw Error("You can specify only one parameter: either 'field' or 'fn'");
            }

            const filterDefinition = filterSet.getFilterDefinition(x.filter);
            let filterType: string | null = null;

            // If filter is not active, then short-circuit its execution
            if (filterDefinition != null) {
                if (!filterSet.isActive(x.filter)) {
                    res[x.filter] = () => "$empty";
                    return res;
                }

                filterType = filterDefinition.filterType;
            }

            if (x.field != null) {
                // Field is specified

                if (x.value != null) {
                    // Direct filter value is specified, don't try to take it from filter
                    if (x.value instanceof SelectedRow) {
                        res[x.filter] = (code: any, row: undefined) => {
                            if (row === undefined && (x.value == null || x.value.isEmpty)) {
                                return "$empty";
                            }
                            return row === x.value?.row;
                        };
                    } else {
                        res[x.field] = x.value;
                    }
                } else if (filterType === "combo" || filterType === "combo2") {
                    const values = filterSet.filters[x.filter];
                    if (!values.$empty) {
                        res[x.filter] = (skip: unknown, row: Record<string, any>) => {
                            if (row === undefined) return true;
                            if (x.field == null) throw Error("Field shouldn't be undefined.");
                            return !!values[row[x.field]];
                        };
                    } else {
                        res[x.filter] = () => "$empty";
                    }
                } else if (filterType === "selected") {
                    const selectedId = filterSet.filters[x.filter];
                    if (selectedId !== undefined) {
                        res[x.filter] = (skip: unknown, row: Record<string, any>) => {
                            if (row === undefined) return true;
                            if (x.field == null) throw Error("Field shouldn't be undefined.");
                            return row[x.field] === selectedId;
                        };
                    } else {
                        res[x.filter] = () => "$empty";
                    }
                } else if (filterDefinition == null) {
                    throw Error(`Filter ${x.filter} is not registered on the page`);
                } else {
                    throw Error(`Unsupported filter type ${filterType}`);
                }
            } else {
                // Function is specified
                res[x.filter] = (value: unknown, row: unknown) => {
                    if (row === undefined) return true;
                    return x.fn?.(value, row);
                };
            }

            return res;
        },
        {}
    );
}
