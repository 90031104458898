import { scaleBand, ScaleBand, ScaleLinear, select, Selection } from "d3";
import {
    ChartData,
    ChartGroup,
    ChartRenderBaseDatum,
    ChartRenderColumnDatum,
    ChartRenderLineGroup,
    ChartRenderStackedBarDatum
} from "./chart.types";
import { ILgFormatter } from "@logex/framework/core";
import { Margin } from "@logex/framework/lg-charts";

const SPACE_FOR_LEGEND_BELOW = 30;
const X_AXIS_TITLE_HEIGHT = 0;
const X_AXIS_LABELS_HEIGHT = 26;
const Y_AXIS_TITLE_WIDTH = 200;
export const SPACE_BETWEEN_Y_LABELS_AND_GRID = 8;
export const SPACE_FOR_Y_AXIS_LABELS = 20;
export const VERTICAL_TICK_COUNT = 10;
export const SPACE_FOR_ELLIPSIS = 4;
export const STACKED_BAR_GAP = 1;
export const MARGIN: Required<Margin> = { top: 16, right: 16, bottom: 16, left: 16 };
export const VERTICAL_MIN_COLUMN_WIDTH = 80;
export const Y_AXIS_OFFSET = 0;
export const SPACE_BETWEEN_BAR_AND_LABEL_Y = 8;
export const LABEL_TEXT_NEGATIVE_Y_ADJUSTMENT = 10;
export const HORIZONTAL_MIN_COLUMN_WIDTH = 30;
export const SPACE_FOR_Y_AXIS = 180;
export const X_AXIS_OFFSET_RIGHT = 50;
export const X_AXIS_OFFSET = 0;
export const SPACE_BETWEEN_BAR_AND_LABEL_X = 8;

export abstract class ChartStrategy {
    protected _svg: Selection<any, any, any, any> | undefined;
    protected _chart: Selection<any, any, any, any> | undefined;

    protected _width: number;
    protected _height: number;

    protected _xAxisG: Selection<any, any, any, any> | undefined;
    protected _yAxisG: Selection<any, any, any, any> | undefined;

    protected _yAxisGridG: Selection<any, any, any, any> | undefined;
    protected _xAxisGridG: Selection<any, any, any, any> | undefined;

    protected _yAxisTitle: Selection<any, any, any, any> | undefined;
    protected _xAxisTitle: Selection<any, any, any, any> | undefined;

    protected yAxisTitle: string;
    protected xAxisTitle: string;

    protected _xGroupScale: ScaleBand<string> = scaleBand();
    protected _yGroupScale: ScaleBand<string> = scaleBand();

    protected _spaceBelowAxis =
        X_AXIS_LABELS_HEIGHT + X_AXIS_TITLE_HEIGHT + SPACE_FOR_LEGEND_BELOW + MARGIN.bottom;

    protected _spaceForYAxisLabels = 0;

    protected _axisLabelsFormatter: ILgFormatter<unknown>;

    protected _min: number;
    protected _max: number;

    protected constructor() {}

    initialize(svg: Selection<any, any, any, any>): void {
        this._svg = svg;

        this._addGridG();

        this._yAxisG = this._svg.append("g").attr("class", "y__axis");
        this._xAxisG = this._svg.append("g").attr("class", "x__axis");

        this._yAxisTitle = this._svg
            .append("text")
            .attr("class", "axis__title")
            .text(this.yAxisTitle)
            .attr("transform", "rotate(-90)");

        this._xAxisTitle = this._svg
            .append("text")
            .attr("class", "axis__title")
            .text(this.xAxisTitle)
            .attr("text-anchor", "middle");

        this._chart = this._svg.append("g").attr("class", "lg-chart-grouped-bar__groups-wrapper");
    }

    setSize(width: number, height: number): ChartStrategy {
        this._width = width;
        this._height = height;
        return this;
    }

    setTitles(xAxisTitle: string, yAxisTitle: string): ChartStrategy {
        this.xAxisTitle = xAxisTitle;
        this.yAxisTitle = yAxisTitle;
        return this;
    }

    setMinMaxValues(min: number, max: number): ChartStrategy {
        this._min = min;
        this._max = max;
        return this;
    }

    setAxisLabelFormatter(axisLabelsFormatter: ILgFormatter<unknown>): ChartStrategy {
        this._axisLabelsFormatter = axisLabelsFormatter;
        return this;
    }

    defineScale(min: number, max: number, limitedData: ChartData<any>): void {
        this._svg!.attr("width", Math.max(0, this._width)).attr(
            "height",
            Math.max(0, this._height - SPACE_FOR_LEGEND_BELOW - MARGIN.bottom)
        );
    }

    getColumnGroups(
        renderColumnsData: Array<ChartRenderColumnDatum<any>>
    ): Selection<SVGGElement, ChartRenderColumnDatum<any>, any, any> {
        const columnGroups = this._chart
            .selectAll<SVGGElement, ChartRenderColumnDatum<any>>(".column-group")
            .data(renderColumnsData, d => d.column);
        columnGroups.exit().remove();

        return columnGroups.enter().append("g").attr("class", "column-group").merge(columnGroups);
    }

    abstract defineBarAxis(columnGroupIds: string[]): void;

    abstract defineAxisValuesPosition(): void;

    abstract defineTitleAxis(): void;

    abstract drawAxes(count: number): void;

    abstract renderSimpleBars(
        mergedBars: Selection<SVGGElement, ChartRenderColumnDatum<any>, any, any>
    ): void;

    abstract renderStackedBars(
        mergedStackedBars: Selection<
            SVGRectElement,
            ChartRenderStackedBarDatum<any>,
            SVGGElement,
            ChartRenderColumnDatum<any>
        >
    ): void;

    abstract renderStackedBarSeparators(
        stackedBarsSeparators: Selection<
            SVGRectElement,
            ChartRenderStackedBarDatum<any>,
            SVGGElement,
            ChartRenderColumnDatum<any>
        >
    ): void;

    getMergedLineGroups(
        dataGroups: ChartGroup[],
        limitedData: ChartData,
        getLimitedValue: (value: number) => number
    ): Selection<SVGGElement, ChartRenderLineGroup<any>, any, any> {
        const lineGroupsData: Array<ChartRenderLineGroup<any>> = dataGroups.map(group => ({
            ...group,
            data: limitedData.map(column => ({
                column,
                value: getLimitedValue(column.values[group.id].value),
                group
            }))
        }));

        const lineGroups = this._chart
            .selectAll<SVGGElement, ChartRenderLineGroup<any>>(".line-group")
            .data(lineGroupsData, d => d.id);
        lineGroups.exit().remove();

        const newLineGroups = lineGroups.enter().append("g");
        newLineGroups.attr("class", "line-group").attr("id", d => d.id);

        return newLineGroups.merge(lineGroups);
    }

    abstract renderLines(
        newLines: Selection<SVGPathElement, Array<ChartRenderBaseDatum<any>>, any, any>
    ): void;

    abstract renderCircles(
        mergedCircles: Selection<
            SVGCircleElement,
            ChartRenderBaseDatum<any>,
            SVGGElement,
            ChartGroup & {
                data: Array<ChartRenderBaseDatum<any>>;
            }
        >
    ): void;

    abstract renderLabels(
        mergedLabels: Selection<
            SVGTextElement,
            ChartRenderBaseDatum<any>,
            SVGGElement,
            ChartRenderColumnDatum<any>
        >,
        formatter: ILgFormatter<unknown>
    ): void;

    protected _getXAxisWidth(): number {
        return this._width - MARGIN.right - this._horizontalPositionOfYAxis;
    }

    protected get _horizontalPositionOfYAxis(): number {
        return (
            MARGIN.left +
            (this.yAxisTitle ? Y_AXIS_TITLE_WIDTH : 0) +
            this._spaceForYAxisLabels +
            SPACE_BETWEEN_Y_LABELS_AND_GRID
        );
    }

    protected get _verticalPositionOfXAxis(): number {
        return this._height - this._spaceBelowAxis;
    }

    protected _getYAxisHeight(): number {
        return this._height - (this._height - this._verticalPositionOfXAxis);
    }

    protected _getSpaceForYAxisLabels(scale: ScaleLinear<number, number>): number {
        let maxWidth = 0;

        const fakeSvg = select("body").append("svg");

        fakeSvg
            .append("g")
            .selectAll("text")
            .data(scale.ticks())
            .enter()
            .append("text")
            .text(d => this._axisLabelsFormatter.format(d))
            .each(function () {
                maxWidth = Math.max(maxWidth, (this as SVGTextContentElement).getBBox().width);
            });
        fakeSvg.remove();

        return Math.min(Math.ceil(maxWidth), this._width / Math.PI);
    }

    protected _cutXAxisLabels(count: number): void {
        const maxXAxisLabelWidth = this._getXAxisWidth() / count;
        this._truncateText(this._xAxisG, maxXAxisLabelWidth);
    }

    protected _truncateText(axis: Selection<any, any, any, any>, labelWidth: number): void {
        axis!.selectAll<SVGTextElement, any>(".tick text").each((_datum, index, textElements) => {
            const element = textElements[index];
            const selection = select(textElements[index]);

            let text = selection.text();
            let textLength = element.getComputedTextLength();
            if (textLength > labelWidth) {
                while (textLength > labelWidth) {
                    text = text.substring(0, text.length - 1);
                    selection.text(text);
                    textLength = element.getComputedTextLength();
                }
                text = text.substring(0, text.length - 3) + "...";
                selection.text(text);
            }
        });
    }

    protected abstract _addGridG(): void;
}
