import { PivotTableColumnFormula } from "../../types";

export function getElementParam(columnConfig: any) {
    return columnConfig.column ? "element." + columnConfig.variable : columnConfig.variable;
}

export function getStoreParam(columnConfig: any) {
    return columnConfig.column ? "store." + columnConfig.variable : columnConfig.variable;
}

export function validateABC(column: PivotTableColumnFormula) {
    if (column.variables.a === undefined) throw Error("Formula variable A is undefined.");
    if (column.variables.b === undefined) throw Error("Formula variable B is undefined.");
    if (column.variables.c === undefined) throw Error("Formula variable C is undefined.");
}

export function validateAB(column: PivotTableColumnFormula) {
    if (column.variables.a === undefined) throw Error("Formula variable A is undefined.");
    if (column.variables.b === undefined) throw Error("Formula variable B is undefined.");
}
