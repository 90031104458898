import { IconChartWidgetConfig } from "../../types/icon-chart-widget.types";
import { IconChartWidgetIcons } from "../../types/icons.enum";
import { PageReferencesService } from "../../../../services/page-references/page-references.service";
import { validateChartWidgetConfigBase } from "../../../../components/base/chart-configuration-dialog-base";
import { FieldInfo } from "../../../../types";

export function validateIconChartConfiguration(
    config: IconChartWidgetConfig,
    pageReferences: PageReferencesService,
    scheme: FieldInfo[]
): boolean {
    if (!Object.values(IconChartWidgetIcons).includes(config.icon)) {
        return false;
    }

    return validateChartWidgetConfigBase<IconChartWidgetConfig>(config, pageReferences, scheme);
}
