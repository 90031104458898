import { HttpErrorResponse } from "@angular/common/http";
import { before } from "@logex/mixin-flavors";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";

import formatServerError from "../helpers/formatServerError";

export class HandleErrorsMixin {
    // ----------------------------------------------------------------------------------
    // Dependencies
    _promptDialog!: LgPromptDialog;
    _lgTranslate!: LgTranslateService;

    // ----------------------------------------------------------------------------------
    //
    @before
    _initMixins(): void {
        if (this._promptDialog == null) throw Error("_promptDialog must be injected");
        if (this._lgTranslate == null) throw Error("_lgTranslate must be injected");
    }

    _onServerFailure(error: HttpErrorResponse): void {
        this._promptDialog.alert(
            this._lgTranslate.translate("FW.FAILED_REQUEST_TO_SERVER"),
            formatServerError(error),
            {
                columns: 5,
                buttons: [LgPromptDialog.CLOSE_BUTTON]
            }
        );
    }

    _onException(error: Error): void {
        if (error instanceof HttpErrorResponse) {
            this._onServerFailure(error);
        } else {
            this._promptDialog.alert(
                this._lgTranslate.translate("FW.FAILED_REQUEST_TO_SERVER"),
                error.message,
                {
                    columns: 5,
                    buttons: [LgPromptDialog.CLOSE_BUTTON]
                }
            );
        }
    }
}
