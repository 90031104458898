import * as _ from "lodash-es";
import { PivotTableColumn } from "../types";

export function getColumnFieldName(column: PivotTableColumn): string {
    switch (column.type) {
        case "default":
            return column.referenceIdx == null
                ? column.field
                : `${column.field}${column.referenceIdx}`;

        case "difference":
            const operation = column.mode === "diff" ? "diff" : "growth";
            return _.camelCase(
                `${operation}_${column.field}_${column.referenceLeft}${column.referenceRight}`
            );

        case "formula":
            return `formula${column.id}`;
    }

    throw Error();
}
