import { Pipe, PipeTransform } from "@angular/core";
import { LayoutItem } from "../layout-management.types";

type SelectionCheckboxType = 0 | 1 | 2;

@Pipe({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/pipe-prefix
    name: "lgflexGroupSelection",
    pure: true
})
export class LayoutManagementGroupSelectionPipe implements PipeTransform {
    transform(layouts: LayoutItem[]): SelectionCheckboxType {
        if (layouts.length === 0) {
            return 0;
        }
        return layouts.every(layout => layout.showInNavigation)
            ? 1
            : layouts.some(layout => layout.showInNavigation)
              ? 2
              : 0;
    }
}
