<div
    *ngIf="cell.type == 'default' && row != null && cell.visualizationWidget === undefined"
    [lgCol]="cell.id"
    [lgTooltip]="
        cell.tooltip != null ? cell.tooltip.formatFn(row[cell.tooltip.fieldName]) : undefined
    "
    [innerHTML]="cell.formatFn(row[cell.fieldName])"
></div>

<div
    *ngIf="cell.type == 'default' && row != null && cell.visualizationWidget !== undefined"
    [lgCol]="cell.id"
    [lgTooltip]="
        cell.tooltip != null ? cell.tooltip.formatFn(row[cell.tooltip.fieldName]) : undefined
    "
>
    <ng-container
        lgflexWidgetHost
        [widgetId]="cell.id"
        [widgetType]="cell.visualizationWidget.widgetType"
        [dependencyProviders]="cell.visualizationWidget.providers"
        [widgetConfig]="cell.visualizationWidget.config"
        [context]="{
            value: row[cell.fieldName],
            maxValue: maxColumnValues[cell.fieldName],
            formatFn: cell.formatFn,
            formatType: cell.formatType
        }"
    >
    </ng-container>
</div>

<div
    *ngIf="cell.type == 'dimension' && row != null"
    [lgCol]="cell.id"
    class="crop-text"
    [lgLongText]="cell.formatFn(row[cell.fieldName])"
></div>

<div *ngIf="cell.type == 'text'" [lgCol]="cell.id" [innerHTML]="cell.value" class="crop-text"></div>

<div *ngIf="cell.type == 'templateRef'" [lgCol]="cell.id">
    <ng-template
        [ngTemplateOutlet]="cell.templateRef"
        [ngTemplateOutletContext]="{
            items: items,
            row: row,
            rowIndex: rowIndex
        }"
    ></ng-template>
</div>

<div *ngIf="cell.type == 'filter'" [lgCol]="cell.id">
    <lg-pivot-table-filterable
        *ngIf="row != null && filters"
        [expand]="false"
        [config]="{ set: filters, filter: cell.fieldName, id: cell.fieldName }"
        [notifyFilterSet]="true"
        [allowNullValues]="true"
    ></lg-pivot-table-filterable>
</div>

<div *ngIf="cell.type == 'widget'" [lgCol]="cell.id" class="table__column--icons">
    <ng-container
        lgflexWidgetHost
        [widgetId]="cell.id"
        [widgetType]="cell.widgetType"
        [dependencyProviders]="cell.providers"
        [widgetConfig]="cell.config"
        [context]="{
            items: items,
            row: row,
            rowIndex: rowIndex,
            scheme: scheme,
            selectedKeys: selectedKeys,
            levels: levels
        }"
    >
    </ng-container>
</div>
