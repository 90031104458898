import {
    provideEmbeddedWidgetsToMigrate,
    provideWidgetMigration,
    provideWidgetReplacements
} from "../../../services/widget-types-registry";
import { PivotTableColumnVisualizationWidget } from "../../../types";
import {
    DEPRECATED_DRILLDOWN_FLAT_TABLE_WIDGET,
    DEPRECATED_DRILLDOWN_PIVOT_TABLE_WIDGET,
    DrilldownPivotTableWidgetBaseConfig
} from "./pivot-table-conversion.types";
import { convertPivotTableToDrilldownPivotTable } from "./convertPivotTableToDrilldownPivotTable";
import { PIVOT_TABLE_WIDGET_2 } from "../pivot-table-widget.configuration.types";
import { convertPivotToPivotTable, convertTableToPivotTable } from "./convertToPivotTable";
import { migrateVersion1To2 } from "./v1-v2";
import { migrateVersion2To3 } from "./v2-v3";

export const PIVOT_TABLE_WIDGET = "pivotTableWidget";

export const replaceWithdrilldownPivotTableConversions = provideWidgetReplacements([
    {
        sourceType: PIVOT_TABLE_WIDGET,
        targetType: DEPRECATED_DRILLDOWN_PIVOT_TABLE_WIDGET,
        replacer: convertPivotTableToDrilldownPivotTable
    },
    {
        sourceType: DEPRECATED_DRILLDOWN_FLAT_TABLE_WIDGET,
        targetType: PIVOT_TABLE_WIDGET_2,
        replacer: convertTableToPivotTable
    },
    {
        sourceType: DEPRECATED_DRILLDOWN_PIVOT_TABLE_WIDGET,
        targetType: PIVOT_TABLE_WIDGET_2,
        replacer: convertPivotToPivotTable
    }
]);

export const drilldownPivotTableMigrationProvider = provideWidgetMigration(
    DEPRECATED_DRILLDOWN_PIVOT_TABLE_WIDGET,
    {
        1: migrateVersion1To2,
        2: migrateVersion2To3
    }
);

export const drilldownFlatTableMigrationProvider = provideWidgetMigration(
    DEPRECATED_DRILLDOWN_FLAT_TABLE_WIDGET,
    {
        1: migrateVersion1To2,
        2: migrateVersion2To3
    }
);

export const drilldownPivotTableEmbeddedWidgetsToMigrateProvider = provideEmbeddedWidgetsToMigrate(
    DEPRECATED_DRILLDOWN_PIVOT_TABLE_WIDGET,
    (config: DrilldownPivotTableWidgetBaseConfig) => {
        return config.columns
            .map(x => x.visualizationWidget)
            .filter((x): x is PivotTableColumnVisualizationWidget => x != null);
    }
);
