<ng-container>
    <lgflex-form-row [label]="'.GroupType' | lgTranslate">
        <lg-dropdown
            [definition]="_groupTypeDropdown"
            [current]="currentGroup.columnType"
            (currentChange)="_onGroupPropertyChange($event, 'columnType')"
        />
    </lgflex-form-row>
    <lgflex-form-row
        *ngIf="currentGroup.columnType === 'simple'"
        [label]="'.GroupTitle' | lgTranslate"
    >
        <input
            class="input input--wide"
            type="text"
            [ngModel]="currentGroup.title"
            (ngModelChange)="_onGroupPropertyChange($event, 'title')"
        />
    </lgflex-form-row>
    <lgflex-form-row
        *ngIf="currentGroup.columnType === 'referenceSlot'"
        [label]="'.ReferenceSlots' | lgTranslate"
    >
        <lg-dropdown
            [definition]="referenceDropdown"
            [current]="currentGroup.title"
            (currentChange)="_onGroupReferenceChange($event)"
        />
    </lgflex-form-row>
</ng-container>
