import {
    PivotTableConfigurationColumn,
    PivotTableConfigurationGroupOrColumn,
    PivotTableConfigurationValidationConfig,
    PivotTableConfigurationColumnGroupOrColumn
} from "./pivot-table-configuration-dialog.types";
import { PageReferencesService } from "../../../../services/page-references/page-references.service";
import { FieldInfo, PivotTableColumnFormula } from "../../../../types";
import {
    validateDescription,
    validateDifferenceColumn,
    validateDimensions,
    validateFormulaColumn,
    validateTitle,
    validateWidgetColumn
} from "../../../../utilities/widget-configuration-validations";
import { WidgetTypesRegistry } from "../../../../services/widget-types-registry";
import { Injector } from "@angular/core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { getAllowedFields } from "../../../../utilities";

export const COLUMN_WIDTH_DEFAULT = 100;

export function validatePivotTableConfiguration(
    lgTranslate: LgTranslateService,
    injector: Injector,
    config: PivotTableConfigurationValidationConfig,
    pageReferences: PageReferencesService,
    scheme: FieldInfo[],
    widgetTypes: WidgetTypesRegistry
): boolean {
    if (config.tablesConfig.length < 1) return false;

    const selectedDimensions = config.tablesConfig.reduce(
        (selectedDimensions, t) => [...selectedDimensions, ...t.dimensions.map(d => d.fieldId)],
        []
    );

    const selectedColumns = config.columns.reduce(
        (selected, group: PivotTableConfigurationColumnGroupOrColumn) => {
            if ("columns" in group) {
                selected.push(
                    ...group.columns.filter(c => (c.type === "default" ? c.field : null))
                );
            } else {
                group.type === "default" ? selected.push(group.field) : null;
            }

            return selected;
        },
        []
    );

    return (
        validateTitle(config.title) &&
        validateDescription(config.description) &&
        validateDimensions(config.tablesConfig, scheme) &&
        validateColumnsGroupsConfig(
            config.columns,
            scheme,
            lgTranslate,
            pageReferences,
            getAllowedFields(scheme, [...selectedDimensions, ...selectedColumns])
        )
    );
}

export function validateColumnsGroupsConfig(
    columnsGroupsConfig: PivotTableConfigurationGroupOrColumn[],
    scheme: FieldInfo[],
    lgTranslate: LgTranslateService,
    pageReferences: PageReferencesService,
    allowedValueFields
): boolean {
    return columnsGroupsConfig
        .map(columnGroup => {
            return "columns" in columnGroup
                ? columnGroup.columns.every(column =>
                      validateColumn(
                          column,
                          scheme,
                          lgTranslate,
                          pageReferences,
                          allowedValueFields
                      )
                  )
                : validateColumn(
                      columnGroup,
                      scheme,
                      lgTranslate,
                      pageReferences,
                      allowedValueFields
                  );
        })
        .every(v => v);
}

function validateColumn(
    column: PivotTableConfigurationColumn,
    scheme: FieldInfo[],
    lgTranslate: LgTranslateService,
    pageReferences: PageReferencesService,
    allowedValueFields
): boolean {
    let isValid = true;
    switch (column.type) {
        case "default":
            isValid = validateDefaultColumn(column, scheme, lgTranslate);
            break;
        case "formula":
            isValid = validateFormulaColumn(
                column as unknown as PivotTableColumnFormula,
                pageReferences,
                allowedValueFields
            );
            break;
        case "difference":
            isValid = validateDifferenceColumn(column, pageReferences, allowedValueFields);
            break;
        case "widget":
            isValid = validateWidgetColumn(column);
            break;
    }

    column.isValid = isValid;

    return isValid;
}

function validateDefaultColumn(
    column: any,
    scheme: FieldInfo[],
    lgTranslate: LgTranslateService
): boolean {
    const schemaField = scheme.find(s => s.field === column.field);

    if (!schemaField) {
        return false;
    }

    if (!column.width) {
        column.width = COLUMN_WIDTH_DEFAULT;
    }

    if (!column.title && column.field) {
        column.title = lgTranslate.translate(schemaField.nameLc);
    }

    return true;
}
