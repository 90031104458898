@if (headerCell !== undefined) {
    @if (headerCell.type === "default") {
        <div
            [lgCol]="headerCell.id"
            [lgSortByColumn]="headerCell.orderBy"
            [sortBy]="sortBy"
            (sortByChange)="_onOrderByChange($event)"
        >
            @if (headerCell.fieldNumber != null) {
                <div class="flexpivot-field-number">
                    <span class="flexpivot-field-number__circle">
                        {{ headerCell.fieldNumber }}
                    </span>
                </div>
            }
            @if (headerCell.header != null) {
                <span
                    style="width: fit-content"
                    class="crop-text"
                    [lgTooltipPosition]="'top-left'"
                    [lgTooltip]="headerCell.header"
                >
                    {{ headerCell.header | modReferenceVariable }}
                </span>
            }

            @if (headerCell.header == null || headerCell.header === "") {
                <span class="crop-text" style="width: fit-content" lgTranslate>
                    {{ headerCell.headerLc }}
                </span>
            }
        </div>
    }

    @if (headerCell.type === "difference") {
        <div
            [lgCol]="headerCell.id"
            [lgSortByColumn]="headerCell.orderBy"
            [sortBy]="sortBy"
            (sortByChange)="_onOrderByChange($event)"
        >
            <lgflex-flexpivot-difference-column-header
                [column]="headerCell.column"
                [formula]="headerCell.formula"
                [title]="headerCell.header"
                (configurationChange)="_onDiffColumnChanged(headerCell.column, $event)"
            >
            </lgflex-flexpivot-difference-column-header>
        </div>
    }

    @if (headerCell.type === "formula") {
        <div
            [lgCol]="headerCell.id"
            [lgSortByColumn]="headerCell.orderBy"
            [sortBy]="sortBy"
            (sortByChange)="_onOrderByChange($event)"
            [lgTooltip]="getFormulaLabel(headerCell.formula)"
        >
            @if (headerCell.header != null) {
                <span class="crop-text" style="width: fit-content">
                    {{ headerCell.header | modReferenceVariable }}
                </span>
            }
        </div>
    }

    @if (headerCell.type === "icons") {
        <div [lgCol]="headerCell.id"></div>
    }

    @if (headerCell.type === "widget") {
        <div [lgCol]="headerCell.id">
            @if (headerCell.header != null) {
                <span class="crop-text" style="width: fit-content">
                    {{ headerCell.header }}
                </span>
            }
        </div>
    }
}
