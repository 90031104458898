import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
    FdpColumnHeader,
    FdpDifferenceColumnChangeEvent,
    PivotTableColumnDifference
} from "../../../types";
import { DifferenceColumnConfiguration } from "../../flexible-pivot-difference-column/difference-column-header.types";
import { getFormulaLabel } from "./flexible-drilldown-base.component";

@Component({
    selector: "lgflex-flexible-drilldown-table-header-row",
    templateUrl: "./flexible-drilldown-table-header-row.component.html",
    styleUrls: ["./flexible-drilldown-table-header-row.scss"],
    host: { class: "table__column--header-items" }
})
export class FlexibleDrilldownTableHeaderRowComponent {
    @Input() headerCell: FdpColumnHeader | undefined = undefined;
    @Input() sortBy: string | string[] = [];

    @Output() readonly orderByChange = new EventEmitter<string>();
    @Output() readonly differenceColumnChange = new EventEmitter<FdpDifferenceColumnChangeEvent>();

    readonly getFormulaLabel = getFormulaLabel;

    _onOrderByChange(colId: string | string[] | null): void {
        this.orderByChange.emit(colId as string);
    }

    _onDiffColumnChanged(
        column: PivotTableColumnDifference,
        changes: Partial<DifferenceColumnConfiguration>
    ): void {
        this.differenceColumnChange.emit({ column, changes });
    }
}
