import { ChangeDetectionStrategy, Component } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { EmbeddedWidgetContext, WidgetInlineComponent } from "../../../../../types";
import { SingleValueChartVisualizationWidgetConfig } from "../../single-value-chart-visualization-widget.types";

@Component({
    selector: "lgflex-single-value-chart-configuration",
    templateUrl: "./single-value-chart-visualization-configuration.component.html",
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [...useTranslationNamespace("_Flexible.SingleValueChartVisualizationWidget")]
})
export class SingleValueChartVisualizationConfigurationComponent
    implements WidgetInlineComponent<SingleValueChartVisualizationWidgetConfig>
{
    protected _limit = null;
    protected _textSpace = 0;

    protected _config: SingleValueChartVisualizationWidgetConfig | undefined = undefined;
    config$: BehaviorSubject<SingleValueChartVisualizationWidgetConfig> =
        new BehaviorSubject<SingleValueChartVisualizationWidgetConfig>(this._config!);

    protected _context: EmbeddedWidgetContext | undefined;

    passConfig(
        config: SingleValueChartVisualizationWidgetConfig,
        context: EmbeddedWidgetContext
    ): void {
        this._context = context;
        this._limit =
            context.formatType === "percentage" && config.limit != null
                ? config.limit * 100
                : config.limit;
        this._textSpace = config.textSpace;
        this._config = { ...config };
        this._onConfigChange();
    }

    protected _onConfigChange(): void {
        if (this._config === undefined)
            throw Error(
                "Configuration component wasn't initialized properly. Config must be passed first."
            );

        this._config.limit =
            this._context?.formatType === "percentage" ? this._limit / 100 : this._limit;
        this._config.textSpace = this._textSpace;

        this.config$.next(this._config);
    }
}
