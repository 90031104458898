import { Inject, Injectable } from "@angular/core";

import {
    IAppConfiguration,
    LG_APP_CONFIGURATION,
    ServerGatewayBase
} from "@logex/framework/lg-application";

export interface Client {
    id: number;
    code: string;
    name: string;
    abbreviation: string;
}

@Injectable({ providedIn: "root" })
export class BootHelperGateway extends ServerGatewayBase {
    constructor(@Inject(LG_APP_CONFIGURATION) private _appConfiguration: IAppConfiguration) {
        super();
        this._setBaseUrl(this._appConfiguration.applicationRoot);
    }

    selectClients() {
        return this._postQuery<Client[]>("boot/clients");
    }
}
