import { around, mixin } from "@logex/mixin-flavors";

export class ModalResultDialogMixin<TArgs, TResult> {
    _resolve!: (result: TResult) => void;
    _args!: TArgs;

    // ----------------------------------------------------------------------------------
    //

    @around
    show(args: TArgs): Promise<TResult> {
        this._args = args;

        mixin.applyNextAround();

        return new Promise(resolve => {
            this._resolve = resolve;
        });
    }
}
