<span (click)="_onClick($event)" class="lg-multilevel-select" [lgTooltip]="title">
    <span class="flexpivot-difference-column-header__formula" *ngIf="!_isFormulaEmpty()">
        {{ formula }}
    </span>

    <lg-icon [icon]="_symbolIconClass" [inline]="true"></lg-icon>
    <lg-icon
        [icon]="_popupIsActive ? 'icon-arrow-up' : 'icon-arrow-down'"
        [inline]="true"
    ></lg-icon>
</span>
