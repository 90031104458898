import { LgApplicationPermissionsBase } from "@logex/framework/lg-application";

export class ApplicationPermissions extends LgApplicationPermissionsBase {
    static readonly INTERNAL = "internal";
    static readonly WIDGET_EXPORT = "widget_export";
    static readonly MANAGE_LAYOUTS_PERSONAL = "manage_layouts_personal";
    static readonly MANAGE_LAYOUTS_CLIENT = "manage_layouts_client";
    static readonly MANAGE_LAYOUTS_SHARED = "manage_layouts_shared";
    static readonly MANAGE_LAYOUTS_CATALOG = "manage_layouts_catalog";
}
