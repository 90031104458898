import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    inject,
    Injectable,
    OnDestroy,
    Signal,
    signal,
    ViewChild,
    WritableSignal
} from "@angular/core";
import { mixins } from "@logex/mixin-flavors";

import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    getDialogFactoryBase,
    IDialogComponent,
    IDropdownDefinition,
    IDropdownIconDefinition,
    IQuickSettingsMenuItem,
    LgDialogFactory,
    LgDialogRef,
    LgPromptDialog,
    QuickSettingsMenuType
} from "@logex/framework/ui-core";
import {
    DefinitionDisplayMode,
    IDefinitions,
    LG_APP_DEFINITIONS
} from "@logex/framework/lg-application";

import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";
import {
    FieldInfo,
    MAX_LIMIT_ROWS,
    PivotTableColumnFormulaFormatting,
    PivotTableColumnFormulaType,
    ReferenceInfo,
    ReferenceSlot,
    WidgetConfigurationDialogResponse
} from "../../../../types";
import { WidgetTypesRegistry } from "../../../../services/widget-types-registry";
import {
    ChartWidgetConfigurationDialogArgumentsBase,
    ChartWidgetConfigurationDialogResponseBase
} from "../../../../components/base/chart-configuration-dialog-base";

import { Subscription } from "rxjs";
import * as _ from "lodash-es";
import { IFilterOption } from "@logex/framework/types";
import { addTitles } from "../../../../utilities/addTitles";
import {
    ChartDimensionRow,
    ChartLabelsConfig,
    ChartLabelsOption,
    ChartTableRow,
    ChartWidgetColumn,
    Sorting
} from "../../chart-widget.configuration.types";
import { validateChartConfiguration } from "./chart-configuration-validation";
import { ChartOrientation, ChartTypes } from "../chart/chart.types";
import { dropdownFlat } from "@logex/framework/utilities";
import { Dictionary } from "lodash";
import {
    CalcAstNodeFunctionCall,
    getAllowedFields,
    getColumnFieldName,
    getDropdownReferenceSlots,
    getReferenceDropdownDefinition,
    parseCalculate,
    translateNullableName
} from "../../../../utilities";
import { LgConsole } from "@logex/framework/core";
import {
    DimensionRow,
    TableRow
} from "../../../../components/table-dimensions-selector/table-dimensions-selector.types";
import { TableDimensionsSelectorComponent } from "../../../../components/table-dimensions-selector/table-dimensions-selector.component";
import { ConfigurationDialogColumnFormula } from "../../../../components/configuration-dialog-sections/formula/configuration-dialog-formula.types";
import { Row } from "../../../../components/row-selector/row-selector.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { FlexibleLayoutDataSourcesService } from "../../../../services/flexible-layout-data-sources";
import { PageReferencesService } from "../../../../services/page-references/page-references.service";

export interface ChartWidgetConfigurationDialogArguments
    extends ChartWidgetConfigurationDialogArgumentsBase {
    formatType: PivotTableColumnFormulaFormatting;
    formatPrecision: number;
    formatForceSign: boolean;
    totalsAsTopLevel: boolean;
    labels: ChartLabelsConfig | undefined;
    columns: ChartWidgetColumn[];
    chartOrientation: "vertical" | "horizontal";
    tablesConfig: ChartTableRow[] | undefined;
    selectedReferences: ReferenceSlot[];
    dataSource: string;
}

export interface ChartWidgetConfigurationDialogResponse
    extends ChartWidgetConfigurationDialogResponseBase {
    formatType: PivotTableColumnFormulaFormatting;
    formatPrecision: number;
    formatForceSign: boolean;
    totalsAsTopLevel: boolean;
    labels: ChartLabelsConfig;
    columns: ChartWidgetColumn[];
    chartOrientation: ChartOrientation;
    tablesConfig: ChartTableRow[] | undefined;
    selectedReferences: ReferenceSlot[];
    dataSource: string;
}

export interface ChartConfigurationDialogComponent
    extends DialogMixin<ChartConfigurationDialogComponent>,
        ModalResultDialogMixin<
            ChartWidgetConfigurationDialogArguments,
            ChartWidgetConfigurationDialogResponse
        > {}

const BARS_LIMIT = 3;
const LINES_LIMIT = 3;

@Component({
    selector: "lgflex-chart-configuration-dialog",
    templateUrl: "./chart-configuration-dialog.component.html",
    styleUrls: [
        "./chart-configuration-dialog.component.scss",
        "../../../../components/base/chart-configuration-dialog-base/chart-configuration-dialog-base.component.scss"
    ],
    providers: [...useTranslationNamespace("_Flexible.ChartWidgetDialog")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class ChartConfigurationDialogComponent
    implements
        IDialogComponent<ChartConfigurationDialogComponent, WidgetConfigurationDialogResponse>,
        OnDestroy
{
    public readonly _lgConsole: LgConsole = inject(LgConsole);
    public readonly _lgTranslate: LgTranslateService = inject(LgTranslateService);
    public readonly _dialogRef: LgDialogRef<ChartConfigurationDialogComponent> = inject(
        LgDialogRef<ChartConfigurationDialogComponent>
    );

    public readonly _promptDialog: LgPromptDialog = inject(LgPromptDialog);
    protected readonly _widgetTypes: WidgetTypesRegistry = inject(WidgetTypesRegistry);
    protected readonly _definitions: IDefinitions<any> = inject(LG_APP_DEFINITIONS);
    protected readonly _changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
    protected readonly _layoutDataSources: FlexibleLayoutDataSourcesService = inject(
        FlexibleLayoutDataSourcesService
    );

    constructor() {
        this._initMixins();
    }

    public _dialogClass = "lg-dialog lg-dialog--6col lg-dialog--no-spacing";

    private _columnTreeSubscription: Subscription | undefined;

    private _stackedBarDimension: WritableSignal<boolean> = signal(false);
    private _chartIcons: Record<ChartTypes, IDropdownIconDefinition<ChartTypes>> = {
        simpleBar: { icon: "icon-bar-chart" },
        stackedBar: { icon: "icon-stacked-bar-chart" },
        line: { icon: "icon-line-chart" }
    };

    protected _dataSourceCode = signal(null);
    protected _scheme = signal([]);
    protected _references = signal(null);
    protected _selectedReferences = signal(null);
    protected _isDefaultDataSource = computed(
        () => this._dataSourceCode() === this._layoutDataSources.defaultLayoutDataSourceCodeSignal()
    );

    private _referencesDict: Signal<Dictionary<ReferenceInfo>> = computed(() => {
        return _.keyBy(this._references(), x => x.code);
    });

    private _schemaLookup: Signal<Dictionary<FieldInfo>> = computed(() => {
        return _.keyBy(this._scheme(), x => x.field);
    });

    protected _widgetName = signal("");
    protected _widgetDescription = signal("");
    protected _widgetTooltip = signal("");
    protected _widgetTooltipLink = signal("");
    protected _formatType: WritableSignal<PivotTableColumnFormulaFormatting> = signal("float");
    protected _formatPrecision = signal(0);
    protected _formatForceSign = signal(false);
    protected _columns: WritableSignal<ChartWidgetColumn[]> = signal([]);
    protected _chartOrientation: WritableSignal<ChartOrientation> = signal("vertical");
    protected _tablesConfig: WritableSignal<ChartTableRow[]> = signal([]);
    protected _currentColumnId: WritableSignal<number | null> = signal(null);
    protected _totalsAsTopLevel = false;
    protected _maxLimitRows = MAX_LIMIT_ROWS;
    protected _areReferencesAllowed = false;
    protected _ignoreOwnFilters = false;
    protected _referenceDropdown: IDropdownDefinition<number> | undefined;
    protected _isReadOnly = false;
    protected _labelsOptions: IFilterOption[] = [
        {
            id: ChartLabelsOption.ChartLabels,
            name: this._lgTranslate.translate(".Chart")
        },
        {
            id: ChartLabelsOption.Tooltip,
            name: this._lgTranslate.translate(".Tooltip")
        },
        {
            id: ChartLabelsOption.Legend,
            name: this._lgTranslate.translate(".Legend")
        }
    ];

    protected _selectedLabels: Record<string, string> = {
        [ChartLabelsOption.Tooltip]: this._labelsOptions.find(
            item => item.id === ChartLabelsOption.Tooltip
        )!.name,
        [ChartLabelsOption.Legend]: this._labelsOptions.find(
            item => item.id === ChartLabelsOption.Legend
        )!.name
    };

    protected _labelDisplayMode: DefinitionDisplayMode = "code";
    protected _labelDisplayModeDropdown: IDropdownDefinition<DefinitionDisplayMode> = dropdownFlat({
        entryId: "mode",
        entryName: "name",
        entries: [
            {
                mode: "code",
                name: this._lgTranslate.translate(".Code")
            },
            {
                mode: "name",
                name: this._lgTranslate.translate(".Name")
            },
            {
                mode: "codeAndName",
                name: this._lgTranslate.translate(".CodeAndName")
            }
        ]
    });

    protected _chartOrientationDropdown: IDropdownDefinition<ChartOrientation> = dropdownFlat({
        entryId: "chartOrientation",
        entryName: "name",
        entries: [
            {
                chartOrientation: "vertical",
                name: this._lgTranslate.translate(".VerticalOrientation")
            },
            {
                chartOrientation: "horizontal",
                name: this._lgTranslate.translate(".HorizontalOrientation")
            }
        ]
    });

    protected readonly _currentColumn = computed(() => {
        return this._columns().find(column => column.id === this._currentColumnId()) ?? null;
    });

    protected readonly _nonAggregatedFieldSelected = computed(() => {
        return this._columns().some(column =>
            Object.values(column.variables).some((variable: any) =>
                this._isNonAggField(variable.field)
            )
        );
    });

    protected readonly _selectedColumnValuesSet = computed(() => {
        return this._columns().reduce((codesSet: Set<string>, column: ChartWidgetColumn) => {
            Object.values(column.variables).forEach((value: any) => {
                codesSet.add(value.field);
            });
            return codesSet;
        }, new Set());
    });

    protected readonly _availableValues = computed(() => {
        const selectedLevels = this._tablesConfig().map(t => t.dimensions.map(d => d.fieldId));
        return getAllowedFields(this._scheme(), [
            ...new Set(selectedLevels.flat()),
            ...this._selectedColumnValuesSet()
        ]);
    });

    protected readonly _availableDimensions = computed(() => {
        const allowedDimensions = getAllowedFields(
            this._scheme(),
            Array.from(this._selectedColumnValuesSet()),
            false
        );

        return allowedDimensions.map(x => ({
            fieldId: x.field,
            name: x.name ?? (x.nameLc && this._lgTranslate.translate(x.nameLc)),
            nameLc: null,
            disabled: false
        }));
    });

    protected readonly _valueFieldDropdown = computed(() => {
        const nonAggregatedFieldsDisabled = this._tablesConfig().some(
            t => this._stackedBarDimension() || t.dimensions.length >= 2
        );

        const entries = this._availableValues().map(x => ({
            field: x.field,
            name: translateNullableName(this._lgTranslate, x.name, x.nameLc),
            disabled: nonAggregatedFieldsDisabled && this._isNonAggField(x.field)
        }));

        return dropdownFlat({
            entryId: "field",
            entryName: "name",
            entries: [...entries, { field: null, name: "-" }]
        });
    });

    protected readonly _chartTypeMenuDefinition: Signal<IQuickSettingsMenuItem[]> = computed(() => {
        return [
            this._stackedBarDimension()
                ? {
                      type: QuickSettingsMenuType.Choice,
                      onClick: (_, itemContext) => {
                          this._addColumn(itemContext?.id as ChartTypes);
                      },
                      name: this._lgTranslate.translate(".StackedBarChart"),
                      id: "stackedBar",
                      visible: () => this._barsEnabled()
                  }
                : {
                      type: QuickSettingsMenuType.Choice,
                      onClick: (_, itemContext) => {
                          this._addColumn(itemContext?.id as ChartTypes);
                      },
                      name: this._lgTranslate.translate(".BarChart"),
                      id: "simpleBar",
                      visible: () => this._barsEnabled()
                  },
            {
                type: QuickSettingsMenuType.Choice,
                onClick: (_, itemContext) => {
                    this._addColumn(itemContext?.id as ChartTypes);
                },
                name: this._lgTranslate.translate(".LineChart"),
                id: "line",
                visible: () => this._linesEnabled()
            }
        ];
    });

    protected readonly _sortingTableDropdown = computed(() => {
        const entriesMap = this._columns().reduce(
            (entries: Map<string, any>, column: ChartWidgetColumn) => {
                Object.values(column.variables).forEach((value: any, index) => {
                    const code = `${value.field}${value.reference}`;
                    entries.set(code, {
                        code,
                        title: `${this._getSchemaFieldTitle(
                            value.field
                        )} - ${this._getReferenceName(value.reference)}`,
                        disabled: !!this._currentTable()?.orderBy?.find(
                            item => item.item === `${value.field}${value.reference}`
                        )
                    });
                });
                return entries;
            },
            new Map()
        );

        return dropdownFlat({
            entryId: "code",
            entryName: "title",
            entries: Array.from(entriesMap, ([_, entry]) => entry)
        });
    });

    protected readonly _addSortingBtnDisabled = computed(() =>
        this._sortingTableDropdown()?.groups.every(group =>
            group.entries.every(item => item.disabled)
        )
    );

    protected readonly _sortingDimensionDropdown = computed<
        IDropdownDefinition<string> | undefined
    >(() => {
        const entries = this._columns().map(column => ({
            code: getColumnFieldName(column),
            name: column.title
        }));

        return dropdownFlat({
            entryId: "code",
            entryName: "name",
            entries: [
                ...entries,
                {
                    code: this._currentDimension().fieldId,
                    name: this._currentDimension().name
                }
            ]
        });
    });

    protected _chartTypeDropdownDefinition = computed<IDropdownDefinition<string> | undefined>(
        () => {
            const disableBars =
                this._currentColumn?.()?.chartType === "line" && !this._barsEnabled();
            const disableLines = !this._linesEnabled?.();

            const entries: Array<{
                id: string;
                name: string;
                icon: ChartTypes;
                disabled: boolean;
            }> = [
                this._stackedBarDimension()
                    ? {
                          id: "stackedBar",
                          name: this._lgTranslate.translate(".StackedBarChart"),
                          icon: "stackedBar",
                          disabled: disableBars
                      }
                    : {
                          id: "simpleBar",
                          name: this._lgTranslate.translate(".BarChart"),
                          icon: "simpleBar",
                          disabled: disableBars
                      },
                {
                    id: "line",
                    name: this._lgTranslate.translate(".LineChart"),
                    icon: "line",
                    disabled: disableLines
                }
            ];

            return {
                entryId: "id",
                entryName: "name",
                iconName: "icon",
                icons: this._chartIcons,
                allIconsInCurrent: true,
                groups: [
                    {
                        entries
                    }
                ]
            };
        }
    );

    protected readonly _dimensionDropdownDefinition: Signal<
        IDropdownDefinition<string> | undefined
    > = computed(() => {
        return dropdownFlat({
            entryId: "fieldId",
            entryName: "name",
            entries: [...this._availableDimensions(), { fieldId: undefined, name: "-" }]
        });
    });

    protected readonly _currentSelection: WritableSignal<{
        type: "table" | "dimension";
        tableId: string;
        dimensionId?: string;
    } | null> = signal(null);

    protected readonly _currentTable: Signal<ChartTableRow | null> = computed(() => {
        if (this._currentSelection()?.type === "table") {
            let currentTable = null;
            this._tablesConfig().forEach(table => {
                if (table.id === this._currentSelection().tableId) {
                    currentTable = {
                        ...table,
                        limitRows:
                            !table.limitRows || table.limitRows === 0
                                ? MAX_LIMIT_ROWS
                                : table.limitRows
                    };
                }
            });
            return currentTable;
        }
        return null;
    });

    protected readonly _currentDimension: Signal<ChartDimensionRow | null> = computed(() => {
        if (this._currentSelection()?.type === "dimension") {
            let currentDimension = null;
            this._tablesConfig().forEach(table => {
                if (table.id === this._currentSelection().tableId) {
                    table.dimensions.forEach(dimension => {
                        if (dimension.id === this._currentSelection().dimensionId) {
                            currentDimension = { ...dimension };
                        }
                    });
                }
            });
            return currentDimension;
        }

        return null;
    });

    protected readonly _isValid: Signal<boolean> = computed(() => {
        return validateChartConfiguration(
            {
                title: this._widgetName(),
                description: this._widgetDescription(),
                columns: this._columns(),
                formatType: this._formatType(),
                formatPrecision: this._formatPrecision(),
                formatForceSign: this._formatForceSign(),
                chartOrientation: this._chartOrientation(),
                tablesConfig: this._tablesConfig(),
                labels: this._getResponseLabels(),
                levels: [],
                ignoreOwnFilters: this._ignoreOwnFilters,
                totalsAsTopLevel: this._totalsAsTopLevel,
                dataSource: this._dataSourceCode(),
                selectedReferences: this._selectedReferences()
            },
            !this._isDefaultDataSource()
                ? ({
                      isAllowed: () => true,
                      slots: this._selectedReferences()
                  } as unknown as PageReferencesService)
                : this._args.pageReferences,
            this._scheme()
        );
    });

    protected readonly _barsEnabled = computed(
        () =>
            this._columns().filter(
                column => column.chartType === "simpleBar" || column.chartType === "stackedBar"
            ).length < BARS_LIMIT
    );

    protected readonly _linesEnabled = computed(
        () => this._columns().filter(column => column.chartType === "line").length < LINES_LIMIT
    );

    protected readonly _rows: Signal<Row[]> = computed(() =>
        this._columns().map(column => ({
            id: column.id,
            name: column.title,
            icon: this._chartIcons[column.chartType].icon
        }))
    );

    @ViewChild("tableDimensionSelector")
    private _tableDimensionSelector: TableDimensionsSelectorComponent | null = null;

    @ViewChild("rowSelector")
    private _rowSelector: TableDimensionsSelectorComponent | null = null;

    // ----------------------------------------------------------------------------------

    ngOnDestroy(): void {
        this._columnTreeSubscription?.unsubscribe();
    }

    public async _activate(): Promise<void> {
        this._widgetName.set(this._args.widgetName);
        this._widgetDescription.set(this._args.widgetDescription);
        this._widgetTooltip.set(this._args.widgetTooltip);
        this._widgetTooltipLink.set(this._args.widgetTooltipLink);
        this._isReadOnly = this._args.isReadonly ?? this._isReadOnly;
        this._ignoreOwnFilters = this._args.ignoreOwnFilters;

        await this._setDataSource(this._args.dataSource ?? null);

        this._onSelectedReferences(
            this._args.selectedReferences ?? [{ referenceCode: null, isLocked: false }]
        );

        this._formatType.update(formatType => this._args.formatType ?? formatType);
        this._formatPrecision.set(this._args.formatPrecision ?? 0);
        this._formatForceSign.set(this._args.formatForceSign);
        this._totalsAsTopLevel = this._args.totalsAsTopLevel;
        this._areReferencesAllowed = this._args.pageReferences.isAllowed();
        this._columns.set(_.cloneDeep(this._args.columns));
        this._chartOrientation.set(this._args.chartOrientation ?? "vertical");
        this._setTablesConfig();

        // Add titles from the schema field names when missing
        addTitles(this._scheme(), this._args.pageReferences, this._columns(), this._lgTranslate);

        this._setLabelOptions();
        this._setStackedBarDimension();
    }

    private _initializeReferences(): void {
        if (!this._args.pageReferences.isAllowed()) return;

        const slots = !this._isDefaultDataSource()
            ? this._selectedReferences()
            : this._args.pageReferences.slots;

        const referenceSlots = getDropdownReferenceSlots(
            slots,
            this._references(),
            this._lgTranslate
        );
        this._referenceDropdown = getReferenceDropdownDefinition(referenceSlots);
    }

    private _setLabelOptions(): void {
        if (this._args.labels !== undefined) {
            this._selectedLabels = {};
            this._labelsOptions.forEach(option => {
                if (this._args.labels![option.id as ChartLabelsOption]) {
                    this._selectedLabels[option.id as ChartLabelsOption] = option.name;
                }
            });
        }
    }

    private _setTablesConfig(): void {
        const tablesConfigWidthDimensions = (this._args.tablesConfig ?? []).map(table => {
            return {
                ...table,
                dimensions: table.dimensions.map(dimension => {
                    const schemaTitle = this._getSchemaFieldTitle(dimension.fieldId);
                    if (!dimension.name) {
                        dimension.name = schemaTitle;
                    }
                    dimension.schemaName = schemaTitle;

                    return dimension;
                })
            };
        });
        this._tablesConfig.set(tablesConfigWidthDimensions);
    }

    protected _getSchemaFieldTitle(fieldName: string | undefined): string {
        if (fieldName == null) return "";

        const field = this._schemaLookup()[fieldName];

        if (field === undefined) {
            this._lgConsole.error(
                `Field ${fieldName} does not exist in the flexible dataset scheme`
            );
            return "";
        }

        return (
            field.name ?? (field.nameLc != null ? this._lgTranslate.translate(field.nameLc) : "")
        );
    }

    protected _onColumnChanged(updatedColumn: ConfigurationDialogColumnFormula): void {
        this._columns.update(columns => {
            return columns.map(column => {
                return (updatedColumn as ChartWidgetColumn)?.id === column.id
                    ? ({ ...updatedColumn } as ChartWidgetColumn)
                    : column;
            });
        });

        this._updateTableOrderByValidity();
    }

    protected _onChartTypeChanged(chartType: string): void {
        this._columns.update(columns => {
            return columns.map(column => {
                return this._currentColumnId() === column.id
                    ? { ...column, chartType: chartType as ChartTypes }
                    : column;
            });
        });
    }

    protected async _onDataSourceChange(dataSource: string): Promise<void> {
        await this._setDataSource(dataSource);
        this._initializeReferences();
    }

    protected _onSelectedReferences(references: ReferenceSlot[]): void {
        this._selectedReferences.set(references);
        this._initializeReferences();
    }

    private async _setDataSource(dataSourceCode?: string): Promise<void> {
        if (!dataSourceCode) {
            dataSourceCode = this._layoutDataSources.defaultLayoutDataSourceCodeSignal();
        }

        this._dataSourceCode.set(dataSourceCode);
        const { scheme, references } = await this._layoutDataSources.getDataSource(dataSourceCode);

        this._scheme.set(scheme);
        this._references.set(references);
    }

    private _addColumn(chartType: ChartTypes): void {
        this._columns.update(columns => {
            return [
                ...columns,
                {
                    isEnabled: true,
                    type: "formula",
                    title: this._lgTranslate.translate(".NewColumn"),
                    id: this._getNewColumnId(),
                    formula: PivotTableColumnFormulaType.A,
                    variables: {},
                    formatType: "float",
                    formatPrecision: 0,
                    formatForceSign: false,
                    chartType
                }
            ];
        });

        this._updateDimensionOrderByValidity();
    }

    private _getNewColumnId(): number {
        let id = this._columns().length;
        while (this._columns().find(column => column.id === id)) id++;
        return id;
    }

    protected _onColumnSelect(id: number): void {
        this._resetSelections();
        this._tableDimensionSelector.resetSelection();

        this._currentColumnId.set(id);
    }

    protected _onColumnRemoved(id: number): void {
        this._columns.update(columns => columns.filter(column => column.id !== id));
        this._updateTableOrderByValidity();
        this._updateDimensionOrderByValidity();
    }

    private _updateTableOrderByValidity(): void {
        this._tablesConfig.update(tablesConfig => {
            return tablesConfig.map(table => {
                if (table.orderBy) {
                    const columnCodesSet = this._columns().reduce(
                        (codesSet: Set<string>, column: ChartWidgetColumn) => {
                            Object.values(column.variables).forEach((value: any, index) => {
                                const code = `${value.field}${value.reference}`;
                                codesSet.add(code);
                            });
                            return codesSet;
                        },
                        new Set()
                    );
                    table.orderBy = table.orderBy.map(orderByItem => {
                        if (!columnCodesSet.has(orderByItem.item)) {
                            orderByItem.item = null;
                        }

                        return orderByItem;
                    });
                }
                return table;
            });
        });
    }

    private _updateDimensionOrderByValidity(): void {
        this._tablesConfig.update(tablesConfig => {
            return tablesConfig.map(table => {
                const columnDimensionCodesSet = this._columns().reduce(
                    (codesSet: Set<string>, column: ChartWidgetColumn) => {
                        codesSet.add(getColumnFieldName(column));
                        return codesSet;
                    },
                    new Set()
                );

                table.dimensions = table.dimensions.map(dimension => {
                    columnDimensionCodesSet.add(dimension.fieldId);
                    if (!columnDimensionCodesSet.has(dimension.orderBy[0].item)) {
                        const firstColumn = this._columns()[0];
                        dimension.orderBy = [
                            {
                                item: firstColumn
                                    ? getColumnFieldName(firstColumn)
                                    : dimension.fieldId,
                                type: dimension.orderBy[0].type
                            }
                        ];
                    }
                    return dimension;
                });
                return table;
            });
        });
    }

    protected _onRowSort(rows: Row[]): void {
        this._columns.update(columns => {
            return rows.map(row => {
                return columns.find(col => col.id === row.id);
            });
        });
    }

    public async _save(): Promise<boolean> {
        const response: ChartWidgetConfigurationDialogResponse = {
            widgetName: this._widgetName(),
            widgetDescription: this._widgetDescription(),
            widgetTooltip: this._widgetTooltip(),
            widgetTooltipLink: this._widgetTooltipLink(),
            columns: this._columns(),
            formatType: this._formatType(),
            formatPrecision: this._formatPrecision(),
            formatForceSign: this._formatForceSign(),
            chartOrientation: this._chartOrientation(),
            tablesConfig: this._tablesConfig(),
            labels: this._getResponseLabels(),
            levels: [],
            totalsAsTopLevel: this._totalsAsTopLevel,
            ignoreOwnFilters: this._ignoreOwnFilters,
            dataSource: this._dataSourceCode(),
            selectedReferences: this._selectedReferences()
        };
        this._resolve(response);

        return true;
    }

    private _getResponseLabels(): ChartLabelsConfig {
        const labels: ChartLabelsConfig = {
            [ChartLabelsOption.ChartLabels]: false,
            [ChartLabelsOption.Tooltip]: false,
            [ChartLabelsOption.Legend]: false
        };

        Object.keys(this._selectedLabels).forEach(option => {
            if (Object.values(ChartLabelsOption).includes(option as ChartLabelsOption)) {
                labels[option as ChartLabelsOption] = true;
            }
        });

        return labels;
    }

    protected _onDimensionSelect({
        dimension,
        tableId
    }: {
        dimension: DimensionRow;
        tableId: string;
    }): void {
        this._resetSelections();
        this._rowSelector.resetSelection();
        this._currentSelection.set({ type: "dimension", tableId, dimensionId: dimension.id });
    }

    protected _onTablesConfigChange(tablesConfig: TableRow[]) {
        this._rowSelector.resetSelection();
        this._tablesConfig.set([...(tablesConfig as unknown as ChartTableRow[])]);
        this._setStackedBarDimension();
        this._updateDimensionSorting();
    }

    protected _onTableSelect(selectedTable: TableRow | null): void {
        this._resetSelections();
        this._rowSelector.resetSelection();

        if (!selectedTable) {
            return;
        }

        this._currentSelection.set({ type: "table", tableId: selectedTable.id });
    }

    private _resetSelections(): void {
        this._currentSelection.set(null);
        this._currentColumnId.set(null);
    }

    private _updateDimensionSorting(): void {
        this._tablesConfig.update(tablesConfig => {
            return tablesConfig.map(t => {
                t.dimensions.map(d => {
                    if (!d.orderBy) {
                        const firstColumn = this._columns()[0];
                        d.orderBy = [
                            {
                                item: firstColumn ? getColumnFieldName(firstColumn) : d.fieldId,
                                type: "asc"
                            }
                        ];
                    }
                    return d;
                });
                return t;
            });
        });
    }

    protected _onTablePropertyChange(value: string, property: keyof ChartTableRow): void {
        this._tablesConfig.update(tablesConfig => {
            return tablesConfig.map(t => {
                if (t.id === this._currentTable().id) {
                    t[property as string] = value;
                }
                return t;
            });
        });
    }

    protected _onDimensionPropertyChange(
        value: string,
        property: keyof DimensionRow,
        dimensionId: string
    ): void {
        this._tablesConfig.update(tablesConfig => {
            return tablesConfig.map(t => {
                t.dimensions.map(d => {
                    if (d.id === dimensionId) {
                        if (property === "name" && value === "") {
                            d[property as string] = this._getSchemaFieldTitle(d.fieldId);
                        } else {
                            d[property as string] = value;
                        }
                    }
                    return d;
                });
                return t;
            });
        });
    }

    protected _onLimitRowChange(value: number): void {
        this._tablesConfig.update(tableConfig => {
            return tableConfig.map(table => {
                if (table.id === this._currentTable().id) {
                    table.limitRows =
                        value === Infinity || value > MAX_LIMIT_ROWS ? MAX_LIMIT_ROWS : value;
                }
                return table;
            });
        });
    }

    protected _onStackedBarDimensionChange(stackedBarDimension: string, id: string): void {
        this._tablesConfig.update(tableConfig => {
            return tableConfig.map(table => {
                if (table.id === id) {
                    table.stackedBarDimension = stackedBarDimension;
                }
                return table;
            });
        });
        this._setStackedBarDimension();
    }

    private _applyColumnsStackBarDimension(): void {
        this._columns.update(columns => {
            return columns.map(column => {
                if (column.chartType !== "line") {
                    column.chartType = this._stackedBarDimension() ? "stackedBar" : "simpleBar";
                }
                return column;
            });
        });
    }

    private _setStackedBarDimension(): void {
        this._stackedBarDimension.set(
            this._tablesConfig().some(table => !!table.stackedBarDimension)
        );
        this._applyColumnsStackBarDimension();
    }

    protected _onSortingAdd() {
        this._tablesConfig.update(tableConfig => {
            return tableConfig.map(table => {
                if (table.id === this._currentTable().id) {
                    table.orderBy = table.orderBy
                        ? [...table.orderBy, { item: null, type: null }]
                        : [];
                }
                return table;
            });
        });
    }

    protected _onSortingRemove(index: number) {
        this._tablesConfig.update(tableConfig => {
            return tableConfig.map(table => {
                if (table.id === this._currentTable().id) {
                    table.orderBy = table.orderBy.filter((_, idx) => idx !== index);
                }
                return table;
            });
        });
    }

    protected _onTableSortingItemUpdate({ item, index }: { item: Sorting; index: number }) {
        this._tablesConfig.update(tableConfig => {
            return tableConfig.map(table => {
                if (table.id === this._currentTable().id) {
                    table.orderBy = table.orderBy.map((oderByItem, idx) => {
                        if (idx === index) {
                            return item;
                        }
                        return oderByItem;
                    });
                }
                return table;
            });
        });
    }

    protected _onDimensionSortingItemUpdate({ item, index }: { item: Sorting; index: number }) {
        this._tablesConfig.update(tableConfig => {
            return tableConfig.map(table => {
                if (table.id === this._currentSelection().tableId) {
                    table.dimensions = table.dimensions.map(dimension => {
                        if (dimension.id === this._currentDimension().id) {
                            dimension.orderBy = dimension.orderBy.map((oderByItem, idx) => {
                                if (idx === index) {
                                    return item;
                                }
                                return oderByItem;
                            });
                        }
                        return dimension;
                    });
                }
                return table;
            });
        });
    }

    protected _onSortingDrop(event: CdkDragDrop<{ item: string; type: string }>) {
        moveItemInArray(this._currentTable().orderBy, event.previousIndex, event.currentIndex);
    }

    private _getReferenceName(referenceIdx: number): string {
        const slots = !this._isDefaultDataSource()
            ? this._selectedReferences()
            : this._args.pageReferences.slots;

        if (slots[referenceIdx] === undefined) {
            return this._lgTranslate.translate(".Unknown");
        }

        const reference = this._referencesDict()[slots[referenceIdx].referenceCode ?? ""];

        return (
            this._lgTranslate.translate(".Reference") +
            " " +
            (referenceIdx + 1) +
            (reference
                ? ` (${translateNullableName(this._lgTranslate, reference.name, reference.nameLc)})`
                : "")
        );
    }

    private _isNonAggField(fieldId: string): boolean {
        const field = this._schemaLookup()[fieldId];

        if (!field) {
            return false;
        } else if (field.calculate != null) {
            const { params } = parseCalculate(field.calculate) as CalcAstNodeFunctionCall;
            return params.some(calcFieldId =>
                isNonAggregatedFieldHelper(this._schemaLookup()[calcFieldId])
            );
        } else {
            return isNonAggregatedFieldHelper(field);
        }

        function isNonAggregatedFieldHelper(field: FieldInfo) {
            return field.aggregate === null && field.calculate === null;
        }
    }
}

@Injectable()
export class VerticalChartConfigurationDialog extends getDialogFactoryBase(
    ChartConfigurationDialogComponent,
    "show"
) {
    constructor(_factory: LgDialogFactory) {
        super(_factory);
    }
}
