<ng-container *ngIf="!horizontalLayout">
    <ng-container *ngTemplateOutlet="formattingRow"></ng-container>
    <ng-container *ngTemplateOutlet="decimalsRow"></ng-container>
    <ng-container *ngTemplateOutlet="signRow"></ng-container>
</ng-container>

<ng-container *ngIf="horizontalLayout">
    <div class="lgg lgg--full lgg--horizontal">
        <div class="lgg__col">
            <ng-container *ngTemplateOutlet="formattingRow"></ng-container>
        </div>
        <div class="lgg__col">
            <ng-container *ngTemplateOutlet="decimalsRow"></ng-container>
        </div>
        <div class="lgg__col">
            <ng-container *ngTemplateOutlet="signRow"></ng-container>
        </div>
    </div>
</ng-container>

<ng-template #formattingRow>
    <div class="form-row--input form-row">
        <div class="form-row__label">{{ ".Formatting" | lgTranslate }}</div>
        <div class="form-row__controls">
            <lg-dropdown
                [definition]="_formattingDropdownDef"
                [(current)]="formatType"
                (currentChange)="formatTypeChange.emit(formatType)"
            ></lg-dropdown>
        </div>
    </div>
</ng-template>

<ng-template #decimalsRow>
    <div class="form-row--input form-row">
        <div class="form-row__label">{{ ".DecimalPlaces" | lgTranslate }}</div>
        <div class="form-row__controls">
            <input
                class="input"
                type="number"
                style="width: 100px"
                [pattern]="_formatPrecisionPattern"
                [(ngModel)]="formatPrecision"
                (ngModelChange)="formatPrecisionChange.emit(formatPrecision)"
                [min]="minFormatPrecision"
                [max]="maxFormatPrecision"
            />
        </div>
    </div>
</ng-template>

<ng-template #signRow>
    <div class="form-row--input form-row force-sign">
        <div class="form-row__label">{{ ".ForceSign" | lgTranslate }}</div>
        <div class="form-row__controls">
            <input
                type="checkbox"
                lgStyledCheckbox
                [ngModel]="formatForceSign"
                (ngModelChange)="formatForceSignChange.emit($event)"
            />

            <lg-help-info
                header="{{ '.ForceSign' | lgTranslate }}"
                description="{{ '.ForceSignHelp' | lgTranslate }}"
            ></lg-help-info>
        </div>
    </div>
</ng-template>
