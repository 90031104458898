import * as _ from "lodash-es";
import { Component, Inject, Injectable } from "@angular/core";
import { mixins } from "@logex/mixin-flavors";

import {
    getDialogFactoryBase,
    IDialogComponent,
    IDropdownDefinition,
    LgPromptDialog,
    LgDialogFactory,
    LgDialogRef
} from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { DialogMixin, ModalResultDialogMixin, TabbedMixin } from "@logex/mixins";

import { LayoutWidget, PivotTableColumn, ReferenceInfo, ReferenceSlot } from "../../../../types";
import { translateNullableName } from "../../../../utilities";

interface ReferenceSlotsEditorDialogArguments {
    numReferences: number;
    referenceSlots: ReferenceSlot[];
    availableReferences: ReferenceInfo[];
    widgets: LayoutWidget[];
}

export interface ReferenceSlotsEditorDialogResponse {
    numReferences: number;
    referenceSlots: ReferenceSlot[];
}

export interface ReferenceSlotsEditorDialogComponent
    extends DialogMixin<ReferenceSlotsEditorDialogComponent>,
        ModalResultDialogMixin<
            ReferenceSlotsEditorDialogArguments,
            ReferenceSlotsEditorDialogResponse
        >,
        TabbedMixin {}

@Component({
    selector: "lgflex-reference-slots-editor-dialog",
    templateUrl: "./reference-slots-editor-dialog.component.html",
    styleUrls: ["./reference-slots-editor-dialog.component.scss"],
    providers: [...useTranslationNamespace("_Flexible._ReferenceSlotsEditorDialog")]
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class ReferenceSlotsEditorDialogComponent
    implements
        IDialogComponent<ReferenceSlotsEditorDialogComponent, ReferenceSlotsEditorDialogResponse>
{
    constructor(
        @Inject(LG_APP_DEFINITIONS) public _definitions: IDefinitions<any>,
        public _lgTranslate: LgTranslateService,
        public _dialogRef: LgDialogRef<ReferenceSlotsEditorDialogComponent>,
        public _promptDialog: LgPromptDialog
    ) {
        this._initMixins();
    }

    // ----------------------------------------------------------------------------------
    //

    // Dialog configuration
    _dialogClass = "lg-dialog lg-dialog--3col";
    _title = this._lgTranslate.translate(".DialogTitle");

    _referencesDropdown: IDropdownDefinition<string> | undefined;
    _referencesDropdownWithEmpty: IDropdownDefinition<string> | undefined;
    _selectedSlots: ReferenceSlot[] = [];
    _numReferences = 1;

    // ----------------------------------------------------------------------------------
    //
    show(args: ReferenceSlotsEditorDialogArguments): Promise<ReferenceSlotsEditorDialogResponse> {
        // Update maximum number of references depending on references availability
        // this._maxReferences = Math.max( this._maxReferences, this._args.availableReferences?.length );

        // Set defaults
        this._numReferences = this._args.numReferences;
        this._selectedSlots = this._args.referenceSlots.map((slot, i) => {
            const selectedReference = _.find(this._args.availableReferences, {
                code: slot.referenceCode
            });
            return selectedReference != null ? slot : this._getEmptySlot();
        });

        // Normalize data for the dialog
        for (let i = 0; i < this._numReferences; i++) {
            if (this._selectedSlots[i] === undefined) {
                this._selectedSlots[i] = this._getEmptySlot();
            }
        }

        const availableReferences = [
            ...this._args.availableReferences,
            { code: "", name: "-", nameLc: null }
        ]; // default empty reference

        this._referencesDropdown = this._configureReferenceDropdown(this._args.availableReferences);
        this._referencesDropdownWithEmpty = this._configureReferenceDropdown(availableReferences);

        // TODO: Fix the mixin
        // @ts-ignore
        return null;
    }

    _configureReferenceDropdown(
        availableReferences: ReferenceInfo[]
    ): IDropdownDefinition<string> | undefined {
        return dropdownFlat({
            entryId: "code",
            entryName: "name",
            entries: [
                ..._.chain(availableReferences)
                    .orderBy(x => x.code)
                    .map(x => ({
                        ...x,
                        code: x.code === "" ? null : x.code,
                        name: translateNullableName(this._lgTranslate, x.name, x.nameLc)
                    }))
                    .value()
            ]
        });
    }

    _onReferenceSelect(index: number, referenceCode: string): void {
        const duplicateIndexes = this._selectedSlots
            .map((slot, index) => ({ index, referenceCode: slot.referenceCode }))
            .filter(x => x.index !== index && x.referenceCode === referenceCode)
            .map(x => x.index);

        for (const duplicateIndex of duplicateIndexes) {
            this._selectedSlots[duplicateIndex] = this._getEmptySlot();
        }

        // Unset locked status from empty slots
        for (const slot of this._selectedSlots) {
            if (slot.referenceCode == null) {
                slot.isLocked = false;
            }
        }
    }

    _addReference(): void {
        this._numReferences++;

        this._selectedSlots.push({
            referenceCode: null,
            isLocked: false
        });
    }

    async _removeReference(): Promise<void> {
        const refAfterChangeCount = this._numReferences - 1;
        const blockingWidgets = new Set<string>();
        this._args.widgets.forEach(widget => {
            const config = widget.config;
            // this is kinda nasty assumption, can we make it more generic?
            if (config["columns"]) {
                (config["columns"] as PivotTableColumn[]).forEach(col => {
                    if (
                        col.type === "default" &&
                        col.isEnabled &&
                        col.referenceIdx !== undefined &&
                        col.referenceIdx >= refAfterChangeCount
                    ) {
                        blockingWidgets.add(config.title ?? "?");
                    }
                    if (
                        col.type === "difference" &&
                        col.isEnabled &&
                        (col.referenceLeft >= refAfterChangeCount ||
                            col.referenceRight >= refAfterChangeCount)
                    ) {
                        blockingWidgets.add(config.title ?? "?");
                    }
                });
            }
        });

        if (blockingWidgets.size > 0) {
            let warnText = `${this._lgTranslate.translate(".RemoveSlotWarning")} `;
            blockingWidgets.forEach(x => {
                warnText += `${x}, `;
            });
            warnText = warnText.substring(0, warnText.length - 2);

            await this._promptDialog.warning(this._lgTranslate.translate(".Warning"), warnText);
            return;
        }

        this._numReferences--;
        this._selectedSlots = this._selectedSlots.splice(0, this._numReferences);
    }

    _toggleIsLocked(slot: ReferenceSlot): void {
        if (slot.referenceCode == null) return;
        slot.isLocked = !slot.isLocked;
    }

    async _save(): Promise<boolean> {
        this._resolve({
            numReferences: this._numReferences,
            referenceSlots: this._selectedSlots as ReferenceSlot[]
        });

        return true;
    }

    private _getEmptySlot(): ReferenceSlot {
        return {
            referenceCode: null,
            isLocked: false
        };
    }
}

@Injectable()
export class ReferenceSlotsEditorDialog extends getDialogFactoryBase(
    ReferenceSlotsEditorDialogComponent,
    "show"
) {
    constructor(_factory: LgDialogFactory) {
        super(_factory);
    }
}
