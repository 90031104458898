import { InjectionToken } from "@angular/core";

export const LG_FLEX_PAGE_AUTHORIZATION = new InjectionToken<IFlexiblePageAuthorization>(
    "lgFlexPageAuthorization"
);

export interface IFlexiblePageAuthorization {
    canCreateLayout(): Promise<boolean>;
    canEditWidget(): Promise<boolean>;
    canEditWidgetTooltipLink(): Promise<boolean>;
    canExportWidget(): Promise<boolean>;
    canEditLayoutGroups(): Promise<boolean>;
}
