import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { translateNullableName } from "../../utilities";

export interface LevelLegacy {
    field: string;
    name: string | null;
    nameLc: string | null;
    isBlocked?: boolean;
    allowDuplicates?: boolean;
}

@Component({
    selector: "lgflex-pivot-levels-selector-legacy",
    templateUrl: "./lg-pivot-levels-selector-legacy.component.html",
    styleUrls: ["./lg-pivot-levels-selector-legacy.component.scss"],
    encapsulation: ViewEncapsulation.Emulated
})
export class LgPivotLevelsSelectorLegacyComponent implements OnInit, OnDestroy {
    @Input("levels") public levels: LevelLegacy[] = [];
    @Input("levels$") public levels$: Observable<LevelLegacy[]> | undefined;

    @Input("selectedLevelIds") public selectedLevelIds: string[] = [];
    @Output() readonly selectedLevelIdsChange = new EventEmitter<string[]>(true);

    @Input("maxLevels") public maxLevels = Infinity;
    @Input("minRowNum") public minRowNum = 1;

    // ----------------------------------------------------------------------------------
    //
    _levelsDropdowns: Array<IDropdownDefinition<string>> | undefined;
    private _levelsDict: Dictionary<LevelLegacy> | undefined;
    private _levelsSubscription: Subscription | undefined;

    // ----------------------------------------------------------------------------------
    //
    constructor(protected _lgTranslate: LgTranslateService) {}

    // ----------------------------------------------------------------------------------
    //
    ngOnInit(): void {
        if (!this.levels$) {
            this._rebuildDefinitions();
        }

        if (this.levels$) {
            this._levelsSubscription = this.levels$.subscribe(levels => {
                this.levels = levels;
                this._levelsDict = _.keyBy(levels, x => x.field);
                this._rebuildDefinitions();
            });
        }
    }

    ngOnDestroy(): void {
        this._levelsSubscription?.unsubscribe();
    }

    // ----------------------------------------------------------------------------------
    //
    protected _rebuildDefinitions(): void {
        const levels: Array<string | null> = _.clone(this.selectedLevelIds);

        if (levels.length < this.maxLevels) {
            levels.push(null);
        }

        this._levelsDropdowns = levels.map(() => ({
            entryId: "id",
            entryName: "name",
            groups: [
                {
                    entries: [
                        {
                            id: null,
                            name: "-",
                            disabled: false
                        },
                        ...this.levels.map(x => ({
                            id: x.field,
                            name: translateNullableName(this._lgTranslate, x.name, x.nameLc),
                            disabled: x.isBlocked
                        }))
                    ]
                }
            ]
        }));
    }

    _levelText(level: number): string | null {
        return level === this.selectedLevelIds.length
            ? this._lgTranslate.translate("_Flexible.LgPivotLevelsSelectorLegacy.SelectLevel")
            : null;
    }

    _onValueChange(level: number, value: string): void {
        // Remove existing level if any
        if (this._levelsDict != null && !this._levelsDict[value]?.allowDuplicates) {
            const alreadyExistingIdx = this.selectedLevelIds.indexOf(value);
            if (alreadyExistingIdx >= 0) {
                this.selectedLevelIds.splice(alreadyExistingIdx, 1);
                if (alreadyExistingIdx < level) {
                    level--;
                }
            }
        }

        if (value != null) {
            this.selectedLevelIds[level] = value;
        } else {
            this.selectedLevelIds.splice(level, 1);
        }

        this.selectedLevelIdsChange.emit(this.selectedLevelIds);
        this._rebuildDefinitions();
    }
}
