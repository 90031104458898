export const migrateVersion5To6 = config => {
    config.columns = config.columns.map(column => {
        if (
            column.visualizationWidget &&
            column.visualizationWidget.type === "singleValueChartVisualizationWidget"
        ) {
            column.visualizationWidget.config.limit =
                column.visualizationWidget.config.limit === 10
                    ? null
                    : column.visualizationWidget.config.limit;
        }
        return column;
    });

    return 6;
};
