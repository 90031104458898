import _ from "lodash-es";
import { MaybeStaleData } from "@logex/framework/lg-application";

export function combineMaybeStaleData<T1, T2, TRes>(
    data: [MaybeStaleData<T1>, MaybeStaleData<T2>],
    aggregate: (data: [T1, T2]) => TRes
): MaybeStaleData<TRes>;

export function combineMaybeStaleData<T1, T2, T3, TRes>(
    data: [MaybeStaleData<T1>, MaybeStaleData<T2>, MaybeStaleData<T3>],
    aggregate: (data: [T1, T2, T3]) => TRes
): MaybeStaleData<TRes>;

export function combineMaybeStaleData<T1, T2, T3, T4, TRes>(
    data: [MaybeStaleData<T1>, MaybeStaleData<T2>, MaybeStaleData<T3>, MaybeStaleData<T4>],
    aggregate: (data: [T1, T2, T3, T4]) => TRes
): MaybeStaleData<TRes>;

export function combineMaybeStaleData<TRes>(
    data: Array<MaybeStaleData<any>>,
    aggregate: (data: any[]) => any
): MaybeStaleData<TRes>;
export function combineMaybeStaleData<TRes>(
    data: Array<MaybeStaleData<any>>,
    aggregate: (data: any) => any
): MaybeStaleData<TRes> {
    let jobs: string[] | null = _.flatten(_.filter(_.map(data, x => x.jobs))) as (string[] | null);
    jobs = !_.isEmpty(jobs) ? jobs : null;
    return {
        data: aggregate(_.map(data, x => x.data)),
        isStale: _.reduce(data, (a, x) => a || x.isStale, <boolean>false),
        jobs: jobs as any
    };
}
