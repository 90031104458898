import {
    Axis,
    axisBottom,
    axisLeft,
    axisTop,
    interpolateRound,
    line,
    ScaleBand,
    scaleBand,
    ScaleLinear,
    scaleLinear,
    Selection
} from "d3";
import {
    ChartStrategy,
    HORIZONTAL_MIN_COLUMN_WIDTH,
    MARGIN,
    SPACE_BETWEEN_BAR_AND_LABEL_X,
    SPACE_FOR_ELLIPSIS,
    SPACE_FOR_Y_AXIS,
    SPACE_FOR_Y_AXIS_LABELS,
    STACKED_BAR_GAP,
    VERTICAL_TICK_COUNT,
    X_AXIS_OFFSET,
    X_AXIS_OFFSET_RIGHT,
    Y_AXIS_OFFSET
} from "./chart-strategy";
import {
    ChartData,
    ChartGroup,
    ChartRenderBaseDatum,
    ChartRenderColumnDatum,
    ChartRenderStackedBarDatum
} from "./chart.types";
import { coerceNumberProperty } from "@angular/cdk/coercion";
import { CHART_SEPARATOR_SIZE } from "@logex/framework/lg-charts";
import { ILgFormatter } from "@logex/framework/core";

export class HorizontalChartStrategy extends ChartStrategy {
    private _xScale: ScaleLinear<number, number>;
    private _yScale: ScaleBand<string>;

    constructor() {
        super();
    }

    getLimit(): number {
        return Math.max(1, this._getYAxisHeight() / HORIZONTAL_MIN_COLUMN_WIDTH);
    }

    override defineScale(min: number, max: number, limitedData: ChartData<any>): void {
        super.defineScale(min, max, limitedData);

        this._spaceForYAxisLabels = SPACE_FOR_Y_AXIS;
        this._xScale = scaleLinear()
            .domain([min, max])
            .range([this._horizontalPositionOfYAxis, this._width - X_AXIS_OFFSET_RIGHT])
            .nice()
            .interpolate(interpolateRound);

        this._yScale = scaleBand()
            .domain(limitedData.reverse().map(x => x.column))
            .range([this._height - this._spaceBelowAxis, MARGIN.top])
            .paddingInner(0.5)
            .paddingOuter(0.3)
            .round(true);
    }

    override defineBarAxis(columnGroupIds: string[]): void {
        this._yGroupScale
            .domain(columnGroupIds)
            .range([0, this._yScale.bandwidth()])
            .padding(0)
            .round(true);
    }

    override defineAxisValuesPosition() {
        this._xAxisG!.attr("transform", `translate(0, ${this._verticalPositionOfXAxis})`);
        this._xAxisGridG!.attr("transform", `translate(0, ${this._verticalPositionOfXAxis})`);
    }

    override defineTitleAxis(): void {
        this._yAxisTitle!.attr(
            "transform",
            `translate(${MARGIN.left + 6}, ${this._xScale(0)}) rotate( -90 )`
        );
        this._xAxisTitle!.attr(
            "transform",
            `translate(
                ${SPACE_FOR_Y_AXIS_LABELS + this._width / 2},
                ${this._height - MARGIN.bottom}
            )`
        );
    }

    override drawAxes(): void {
        this._xAxisG!.call(this._getXAxis(this._xScale));

        const firstDomainValue = this._xScale.domain()[0];

        this._yAxisG!.call(this._getYAxis(this._yScale)).attr(
            "transform",
            `translate(${this._xScale(firstDomainValue)},0)`
        );

        // align text left
        this._yAxisG!.selectAll("text")
            .attr("transform", `translate(-${this._xScale(firstDomainValue) - MARGIN.right},0)`)
            .attr("text-anchor", "start");

        this._cutYAxisLabels();

        this._xAxisGridG!.call(this._getXAxisGrid(this._xScale));
    }

    private _getXAxis(scale: ScaleLinear<number, number>): Axis<any> | any {
        return axisBottom(scale)
            .tickSize(0)
            .tickPadding(12)
            .tickFormat(d => this._axisLabelsFormatter.format(d))
            .ticks(this._horizontalTickCount);
    }

    private get _horizontalTickCount(): number {
        const leftPanelWidth = this._xScale(this._xScale.domain()[0]) - MARGIN.right;
        const xAxisWidth = this._width - leftPanelWidth;

        const integer =
            Math.max(Math.abs(Math.trunc(this._min)), Math.abs(Math.trunc(this._max))).toString()
                .length - 1;
        const decimal =
            this._axisLabelsFormatter.options.decimals !== undefined
                ? this._axisLabelsFormatter.options.decimals + 1
                : 0;
        const forceSign = this._axisLabelsFormatter.options.forceSign ? 1 : 0;
        const margin = 20;

        const maxHorizontalTexItemWidth = (integer + decimal + forceSign) * 10 + margin * 2;

        return Math.round(xAxisWidth / maxHorizontalTexItemWidth);
    }

    private _getYAxis(scale: ScaleBand<string>): Axis<any> | any {
        return axisLeft(scale).tickSize(0).tickPadding(0).ticks(VERTICAL_TICK_COUNT);
    }

    private _cutYAxisLabels(): void {
        const firstDomainValue = this._xScale.domain()[0];
        const maxYAxisLabelWidth =
            this._xScale(firstDomainValue) - MARGIN.left - SPACE_FOR_ELLIPSIS;
        this._truncateText(this._yAxisG, maxYAxisLabelWidth);
    }

    private _getXAxisGrid(scale: ScaleLinear<number, number>): Axis<any> {
        return axisTop(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(coerceNumberProperty(this._horizontalTickCount))
            .tickSizeOuter(0)
            .tickSizeInner(this._verticalPositionOfXAxis - MARGIN.top);
    }

    override renderSimpleBars(
        mergedBars: Selection<SVGRectElement, any, SVGGElement, ChartRenderColumnDatum<any>>
    ): void {
        mergedBars
            .attr("x", d =>
                d.value > 0
                    ? (this._xScale as ScaleLinear<number, number>)(X_AXIS_OFFSET)
                    : (this._xScale as ScaleLinear<number, number>)(d.value)
            )
            .attr("y", d => this._calculateYPosition(d.column.column, d.group.id))
            .attr("height", d => this._calculateBarWidth())
            .attr("width", d =>
                Math.abs(
                    (this._xScale as ScaleLinear<number, number>)(X_AXIS_OFFSET) -
                        (this._xScale as ScaleLinear<number, number>)(d.value)
                )
            );
    }

    private _calculateBarWidth(): number {
        const bandwidth = this._yGroupScale.bandwidth();
        return bandwidth - CHART_SEPARATOR_SIZE;
    }

    private _calculateYPosition(column: string, groupId: string): number {
        return (this._yScale as ScaleBand<string>)(column)! + this._yGroupScale(groupId)!;
    }

    override _addGridG(): void {
        this._xAxisGridG = this._svg.append("g").attr("class", "x__axis__grid");
    }

    override renderStackedBars(
        mergedStackedBars: Selection<
            SVGRectElement,
            ChartRenderStackedBarDatum<any>,
            SVGGElement,
            ChartRenderColumnDatum<any>
        >
    ): void {
        mergedStackedBars
            .attr("x", d => {
                const domainMinLimit = (this._xScale as ScaleLinear<number, number>).domain()[0];
                return d.value > 0
                    ? (this._xScale as ScaleLinear<number, number>)(d.yValueOffset)
                    : (this._xScale as ScaleLinear<number, number>)(
                          Math.abs(d.value + d.yValueOffset) > Math.abs(domainMinLimit)
                              ? domainMinLimit
                              : d.value + d.yValueOffset
                      );
            })
            .attr("y", d => this._calculateYPosition(d.column.column, d.group.id))
            .attr("height", this._calculateBarWidth())
            .attr("width", d => {
                const width = Math.abs(
                    (this._xScale as ScaleLinear<number, number>)(X_AXIS_OFFSET) -
                        (this._xScale as ScaleLinear<number, number>)(d.value)
                );
                return Math.max(width, 0);
            });
    }

    override renderStackedBarSeparators(
        stackedBarsSeparators: Selection<
            SVGRectElement,
            ChartRenderStackedBarDatum<any>,
            SVGGElement,
            ChartRenderColumnDatum<any>
        >
    ): void {
        stackedBarsSeparators
            .attr("y", d => this._calculateYPosition(d.column.column, d.group.id))
            .attr("x", d =>
                d.value > 0
                    ? (this._xScale as ScaleLinear<number, number>)(d.value + d.yValueOffset) - 1
                    : (this._xScale as ScaleLinear<number, number>)(d.value + d.yValueOffset)
            )
            .attr("height", this._calculateBarWidth())
            .attr("width", d =>
                Math.abs(
                    (this._xScale as ScaleLinear<number, number>)(Y_AXIS_OFFSET) -
                        (this._xScale as ScaleLinear<number, number>)(d.value)
                ) > STACKED_BAR_GAP
                    ? STACKED_BAR_GAP
                    : 0
            );
    }

    override renderLines(
        newLines: Selection<SVGPathElement, Array<ChartRenderBaseDatum<any>>, any, any>
    ): void {
        newLines.attr(
            "d",
            line<ChartRenderBaseDatum<any>>()
                .x(d => (this._xScale as ScaleLinear<number, number>)(d.value))
                .y(d => this._calculateLineYPosition(d.column.column))
        );
    }

    private _calculateLineYPosition(column: string): number {
        return (this._yScale as ScaleBand<string>)(column)! + this._yGroupScale.range()[1] / 2;
    }

    override renderCircles(
        mergedCircles: Selection<
            SVGCircleElement,
            ChartRenderBaseDatum<any>,
            SVGGElement,
            ChartGroup & { data: Array<ChartRenderBaseDatum<any>> }
        >
    ): void {
        mergedCircles
            .attr("cx", d => (this._xScale as ScaleLinear<number, number>)(d.value))
            .attr("cy", d => this._calculateLineYPosition(d.column.column));
    }

    override renderLabels(
        mergedLabels: Selection<
            SVGTextElement,
            ChartRenderBaseDatum<any>,
            SVGGElement,
            ChartRenderColumnDatum<any>
        >,
        formatter: ILgFormatter<unknown>
    ) {
        mergedLabels
            .attr("y", d => this._calculateLabelYPosition(d.column.column, d.group.id))
            .attr("x", d => {
                return d.value > 0
                    ? (this._xScale as ScaleLinear<number, number>)(d.value) +
                          SPACE_BETWEEN_BAR_AND_LABEL_X
                    : (this._xScale as ScaleLinear<number, number>)(d.value) -
                          SPACE_BETWEEN_BAR_AND_LABEL_X;
            })
            .attr("text-anchor", d => (d.value > 0 ? "start" : "end"))
            .text(d => {
                return formatter.format(d.value);
            });
    }

    private _calculateLabelYPosition(column: string, groupId: string): number {
        return this._calculateYPosition(column, groupId) + this._calculateBarWidth();
    }
}
