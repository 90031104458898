<lgflex-dialog
    class="widget-configuration-dialog"
    [isReadOnly]="_isReadOnly"
    [isValid]="_isValid()"
    (saveAction)="_save()"
    (closeAction)="_close()"
>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Information' | lgTranslate">
        <lgflex-form-section [hideDivider]="true">
            <lgflex-form-section-column>
                <lgflex-form-section-header>
                    {{ "_Flexible.WidgetConfiguration.WidgetInformation" | lgTranslate }}
                </lgflex-form-section-header>
                <lgflex-widget-configuration-section-information
                    [(widgetName)]="_widgetName"
                    [(widgetDescription)]="_widgetDescription"
                    [(widgetTooltip)]="_widgetTooltip"
                    [(widgetTooltipLink)]="_widgetTooltipLink"
                />
            </lgflex-form-section-column>
        </lgflex-form-section>
    </lgflex-dialog-tab>
    <lgflex-dialog-tab [label]="'_Flexible.WidgetConfiguration.Configuration' | lgTranslate">
        <lgflex-dialog-grid>
            <lgflex-dialog-grid-column>
                <lgflex-dialog-grid-panel>
                    <lgflex-dialog-grid-panel-header>
                        <lgflex-dialog-grid-panel-header-title>
                            {{ ".PivotLevels" | lgTranslate }}
                        </lgflex-dialog-grid-panel-header-title>
                        <lgflex-dialog-grid-panel-header-buttons
                            class="level-selector-header-buttons"
                        >
                            <lg-placeholder
                                class="level-selector-header-buttons__placeholder"
                                name="pivotLevelsSelector.dropdownButtonContainer"
                            />
                        </lgflex-dialog-grid-panel-header-buttons>
                    </lgflex-dialog-grid-panel-header>
                    <lgflex-dialog-grid-panel-body>
                        <lgflex-pivot-levels-selector
                            [hideBorder]="true"
                            [levels$]="_availableLevels$"
                            [(selectedLevelIds)]="_selectedLevelsIds"
                            (selectedLevelIdsChange)="_onLevelsChange()"
                            [currentLevelId]="_currentLevel?.field ?? null"
                            (currentChange)="_onLevelSelect($event)"
                            [enableSorting]="true"
                            [allowDrilldown]="_settings.allowDrilldown"
                            [indentWithinDrilldownLevel]="!_settings.isFlatTable"
                            #levelsSelector
                        />
                    </lgflex-dialog-grid-panel-body>
                    <lgflex-form-row *ngIf="_settings.forceIgnoreOwnFilters !== false">
                        <label>
                            <input
                                type="checkbox"
                                lgStyledCheckbox
                                [disabled]="
                                    _settings.forceIgnoreOwnFilters != null || _hasDrilldown
                                "
                                [(ngModel)]="_ignoreOwnFilters"
                                [required]="true"
                            />
                            &nbsp;
                            {{ ".IgnoreOwnFilters" | lgTranslate }}
                        </label>
                    </lgflex-form-row>
                </lgflex-dialog-grid-panel>
                <lgflex-dialog-grid-panel>
                    <lgflex-dialog-grid-panel-header>
                        <lgflex-dialog-grid-panel-header-title>
                            {{ ".Columns" | lgTranslate }}
                        </lgflex-dialog-grid-panel-header-title>
                        <lgflex-dialog-grid-panel-header-buttons>
                            <lg-quick-settings-menu-button
                                *ngIf="_addColumnMenuDefinition != null"
                                [compact]="true"
                                [definition]="_addColumnMenuDefinition"
                                text="{{ '.AddColumn' | lgTranslate }}"
                                icon="icon-add"
                            ></lg-quick-settings-menu-button>
                        </lgflex-dialog-grid-panel-header-buttons>
                    </lgflex-dialog-grid-panel-header>
                    <lgflex-dialog-grid-panel-body>
                        <lgflex-tree-selector
                            [hideBorder]="true"
                            [config$]="_columnsTree$"
                            [enableSingleItemSelection]="true"
                            [enableSelection]="true"
                            [enableSorting]="true"
                            [visibleRowsNum]="9"
                            [current]="_currentColumnLeaf ?? undefined"
                            (currentChange)="_onColumnSelect($event)"
                            (checkboxToggle)="_onColumnToggle($event)"
                            (sort)="_onColumnReordering($event)"
                        />
                    </lgflex-dialog-grid-panel-body>
                </lgflex-dialog-grid-panel>
                <lgflex-dialog-grid-panel class="dialog-grid-panel--without-body">
                    <lgflex-dialog-grid-panel-header>
                        <lgflex-dialog-grid-panel-header-title>
                            {{ ".OrderBy" | lgTranslate }}
                        </lgflex-dialog-grid-panel-header-title>
                        <lgflex-dialog-grid-panel-header-buttons>
                            <div class="flex-row justify-end">
                                <lg-dropdown
                                    class="form-row__drilldown"
                                    [definition]="_orderByItemDropdown"
                                    [(current)]="_orderByItem"
                                    [itemTooltips]="true"
                                ></lg-dropdown>
                                <lg-dropdown
                                    class="form-row__drilldown"
                                    [definition]="_orderByTypeDropdown"
                                    [(current)]="_orderByType"
                                ></lg-dropdown>
                            </div>
                        </lgflex-dialog-grid-panel-header-buttons>
                    </lgflex-dialog-grid-panel-header>
                </lgflex-dialog-grid-panel>
            </lgflex-dialog-grid-column>
            <lgflex-dialog-grid-column>
                <lgflex-dialog-grid-panel>
                    <lgflex-dialog-grid-panel-header>
                        <lgflex-dialog-grid-panel-header-title>
                            {{ ".Properties" | lgTranslate }}
                        </lgflex-dialog-grid-panel-header-title>
                    </lgflex-dialog-grid-panel-header>
                    <lgflex-dialog-grid-panel-body>
                        <ng-container *ngIf="_currentColumn as current">
                            <div *ngIf="current.type === 'default'">
                                <lgflex-form-row [label]="'.Title' | lgTranslate">
                                    <input
                                        class="input input--wide"
                                        type="text"
                                        [placeholder]="_getSchemaFieldTitle(current.field)"
                                        [(ngModel)]="current.title"
                                        (ngModelChange)="_onColumnPropertyChange()"
                                    />
                                </lgflex-form-row>

                                <lgflex-form-row [label]="'.Width' | lgTranslate">
                                    <input
                                        class="input"
                                        type="number"
                                        min="0"
                                        max="400"
                                        style="width: 100px"
                                        [ngModel]="current.width"
                                        (ngModelChange)="_onColumnWidthChange($event)"
                                    />
                                </lgflex-form-row>

                                <ng-container
                                    *ngTemplateOutlet="visualizationWidgetConfiguration"
                                ></ng-container>
                            </div>
                            <div *ngIf="current.type === 'difference'">
                                <div>
                                    <lgflex-form-row [label]="'.Title' | lgTranslate">
                                        <input
                                            class="input input--wide"
                                            type="text"
                                            [(ngModel)]="current.title"
                                            (ngModelChange)="_onColumnPropertyChange()"
                                        />
                                    </lgflex-form-row>

                                    <lgflex-form-row [label]="'.Width' | lgTranslate">
                                        <input
                                            class="input"
                                            type="number"
                                            min="0"
                                            max="400"
                                            style="width: 100px"
                                            [ngModel]="current.width"
                                            (ngModelChange)="_onColumnWidthChange($event)"
                                        />
                                    </lgflex-form-row>
                                </div>

                                <lgflex-form-row [label]="'.Field' | lgTranslate">
                                    <lg-dropdown
                                        [definition]="_fieldsDropdownDefinition!"
                                        [(current)]="current.field"
                                        (currentChange)="_onColumnPropertyChange()"
                                    />
                                </lgflex-form-row>

                                <lgflex-form-row [label]="'.Type' | lgTranslate">
                                    <lg-dropdown
                                        [definition]="_differenceModeDropdown"
                                        [(current)]="current.mode"
                                        (currentChange)="_onColumnPropertyChange()"
                                    />
                                </lgflex-form-row>

                                <lgflex-form-row [label]="'.ReferenceLeft' | lgTranslate">
                                    <lg-dropdown
                                        [definition]="_referenceDropdown!"
                                        [(current)]="current.referenceLeft"
                                        (currentChange)="_onReferenceUpdate('left', $event)"
                                    />
                                </lgflex-form-row>

                                <lgflex-form-row [label]="'.ReferenceRight' | lgTranslate">
                                    <lg-dropdown
                                        [definition]="_referenceDropdown!"
                                        [(current)]="current.referenceRight"
                                        (currentChange)="_onReferenceUpdate('right', $event)"
                                    />
                                </lgflex-form-row>

                                <ng-container
                                    *ngTemplateOutlet="visualizationWidgetConfiguration"
                                />
                            </div>
                            <div *ngIf="current.type === 'formula'">
                                <lgflex-drilldown-pivot-formula-configuration
                                    [column]="current"
                                    [areReferencesAllowed]="_areReferencesAllowed"
                                    (columnPropertyChange)="_onColumnPropertyChange()"
                                    [fieldsDropdownDefinition]="_fieldsDropdownDefinition!"
                                    [referencesDropdownDefinition]="_referenceDropdown"
                                >
                                </lgflex-drilldown-pivot-formula-configuration>
                                <ng-container
                                    *ngTemplateOutlet="visualizationWidgetConfiguration"
                                />
                            </div>
                            <div *ngIf="current.type === 'widget'">
                                <lgflex-form-row [label]="'.Widget' | lgTranslate">
                                    <lg-dropdown
                                        [definition]="_pivotWidgetTypeDropdown"
                                        [(current)]="current.widgetType"
                                        (currentChange)="_setPivotWidgetType($event)"
                                        [hideSearch]="true"
                                    />
                                </lgflex-form-row>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="_currentLevel as current">
                            <lgflex-form-row [label]="'.Title' | lgTranslate">
                                <input
                                    class="input input--wide"
                                    type="text"
                                    [placeholder]="_getSchemaFieldTitle(current.field)"
                                    [ngModel]="_levelProperties[current.field].title"
                                    (ngModelChange)="_onLevelPropertyChange($event, current.field)"
                                />
                            </lgflex-form-row>

                            <lgflex-form-row
                                *ngIf="_isDisplayModeDropdownVisible"
                                [label]="'.DisplayMode' | lgTranslate"
                            >
                                <lg-dropdown
                                    [definition]="_displayModeDropdown"
                                    [current]="
                                        _levelProperties[current.field].displayMode ??
                                        _defaultDisplayMode
                                    "
                                    (currentChange)="
                                        _onLevelDisplayModeChange($event, current.field)
                                    "
                                />
                            </lgflex-form-row>

                            <lgflex-form-row
                                *ngIf="_settings.isFlatTable"
                                [label]="'.Width' | lgTranslate"
                            >
                                <input
                                    class="input"
                                    type="number"
                                    min="0"
                                    max="400"
                                    style="width: 100px"
                                    [ngModel]="_levelProperties[current.field].width"
                                    (ngModelChange)="_onLevelWidthChange($event, current.field)"
                                />
                            </lgflex-form-row>
                        </ng-container>
                        <ng-container *ngIf="!_currentColumn && !_currentLevel">
                            <div style="text-align: center; line-height: 100px">
                                {{ ".SelectColumnText" | lgTranslate }}
                            </div>
                        </ng-container>
                    </lgflex-dialog-grid-panel-body>
                </lgflex-dialog-grid-panel>
            </lgflex-dialog-grid-column>
        </lgflex-dialog-grid>
    </lgflex-dialog-tab>
</lgflex-dialog>

<ng-template #visualizationWidgetConfiguration>
    <div class="divider"></div>
    <lgflex-form-row [label]="'.Visualization' | lgTranslate">
        <lg-dropdown
            [definition]="_visualizationWidgetTypeDropdown"
            [current]="_getCurrentVisualization()"
            (currentChange)="_setVisualizationWidgetType($event)"
            [hideSearch]="true"
            [emptyAcceptable]="true"
        >
        </lg-dropdown>
    </lgflex-form-row>

    <ng-template #visualizationInlineComponentContainer></ng-template>
    <lgflex-conditional-formatting-configuration
        *ngIf="_currentColumn != null && _currentColumn.conditionalFormatting !== undefined"
        [config]="_currentColumn.conditionalFormatting"
    ></lgflex-conditional-formatting-configuration>
</ng-template>
