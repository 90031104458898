import { PageReferencesService } from "../../../services/page-references/page-references.service";
import {
    validateChartColumns,
    validateDescription,
    validateLevels,
    validateTitle
} from "../../../utilities/widget-configuration-validations";
import { FieldInfo } from "../../../types";
import { ChartWidgetConfigBase } from "../../../widgets/base/chart-widget-base/chart-widget-base.types";

export function validateChartWidgetConfigBase<
    TConf extends ChartWidgetConfigBase = ChartWidgetConfigBase
>(config: TConf, pageReferences: PageReferencesService, scheme: FieldInfo[]): boolean {
    return (
        validateTitle(config.title) &&
        validateDescription(config.description) &&
        validateChartColumns(config.columns, pageReferences, scheme) &&
        validateLevels(new Set(config.levels), scheme)
    );
}
