import { Dictionary } from "lodash";
import { Inject, Injectable } from "@angular/core";
import {
    IColumnExportDefinition,
    IInfoboxSpecification,
    IMultiPartDefinition,
    IMultiSaveOptions,
    LgExcelFactoryService,
    LogexXlsxApi
} from "@logex/framework/lg-exports";
import {
    LG_LOCALIZATION_SETTINGS,
    LgLocalizationSettings,
    LgTranslateService
} from "@logex/framework/lg-localization";
import {
    IAppSession,
    IDefinitions,
    LG_APP_DEFINITIONS,
    LG_APP_SESSION
} from "@logex/framework/lg-application";
import { LgPivotInstance } from "@logex/framework/lg-pivot";

import {
    FieldInfo,
    MAX_FILENAME_LENGTH,
    MAX_SHEET_NAME_LENGTH,
    PivotTableColumn,
    PivotTableColumnFormula,
    PivotTableColumnFormulaVariableNames,
    PivotTableLevel
} from "../../types";
import { ReferenceInSlot } from "../page-references/page-references.types";
import { getColumnFieldName } from "../../utilities";
import { getExportFilename } from "./widget-export-utils";

@Injectable()
export class WidgetExportExcelService {
    constructor(
        lgXlsxService: LgExcelFactoryService,
        @Inject(LG_APP_SESSION) private _session: IAppSession,
        @Inject(LG_LOCALIZATION_SETTINGS) private _localizationSettings: LgLocalizationSettings,
        @Inject(LG_APP_DEFINITIONS) private _definitions: IDefinitions<any>,
        private _lgTranslate: LgTranslateService
    ) {
        this._lgXlsx = lgXlsxService.create();
        this._lgXlsx.setDefaultStyle(this._lgXlsx.styles.logex);
    }

    protected _lgXlsx: LogexXlsxApi;
    _translationNamespace = "_Flexible.WidgetExportExcelService.Export";

    // ----------------------------------------------------------------------------------
    //
    export(
        pivotInstance: LgPivotInstance,
        scheme: FieldInfo[],
        levels: string[],
        additionalPivotColumns: PivotTableColumn[],
        references: ReferenceInSlot[],
        title: string,
        levelProperties?: Dictionary<PivotTableLevel>
    ): void {
        const options: IMultiSaveOptions = {
            filename: getExportFilename(title).slice(0, MAX_FILENAME_LENGTH)
        };

        const excelCommonProps: Partial<IMultiPartDefinition> = {
            bigHeader: title,
            sheetName: title?.slice(0, MAX_SHEET_NAME_LENGTH),
            infobox: this.getDefaultInfobox(),
            data: pivotInstance.filtered
        };

        switch (levels.length) {
            case 0: {
                throw Error("Pivot levels are not defined.");
            }

            case 1: {
                const levelInfo = scheme.find(x => x.field === levels[0]);
                const currentLevelProperties = levelProperties && levelProperties[levels[0]];
                const itemName = "item";

                if (levelInfo === undefined)
                    throw Error(`Level ${levels[0]} id not found in schema.`);

                this._lgXlsx.saveMultiple(options, [
                    {
                        ...excelCommonProps,
                        type: "array",
                        itemName,
                        definition: [
                            ...this._getLevelExportDefinitions(
                                itemName,
                                levelInfo,
                                currentLevelProperties
                            ),
                            ...this._getOtherExportDefinitions(
                                additionalPivotColumns,
                                scheme,
                                references,
                                itemName
                            )
                        ]
                    }
                ]);

                break;
            }

            default: {
                const levelExcelDefinitions: IColumnExportDefinition[] = [];

                levels.forEach(level => {
                    const levelInfo = scheme.find(x => x.field === level);
                    if (levelInfo === undefined) throw Error(`Level ${level} not found in schema.`);
                    const currentLevelProperties =
                        levelProperties && levelProperties[levelInfo.field];

                    levelExcelDefinitions.push(
                        ...this._getLevelExportDefinitions(
                            `level${levels.length}`,
                            levelInfo,
                            currentLevelProperties
                        )
                    );
                });

                this._lgXlsx.saveMultiple(options, [
                    {
                        ...excelCommonProps,
                        type: "pivot",
                        pivotDefinition: pivotInstance.definition,
                        itemNames: levels.map((x, i) => `level${i + 1}`),
                        definition: [
                            ...levelExcelDefinitions,
                            ...this._getOtherExportDefinitions(
                                additionalPivotColumns,
                                scheme,
                                references,
                                `level${levels.length}`
                            )
                        ]
                    }
                ]);

                break;
            }
        }
    }

    _getLevelExportDefinitions(
        itemName: string | undefined,
        levelInfo: FieldInfo,
        levelProperties?: PivotTableLevel
    ): IColumnExportDefinition[] {
        const levelExportDefinitions: IColumnExportDefinition[] = [];
        const hasDefinitions = levelInfo.type !== "string" && levelInfo.type !== "number";
        const levelName =
            (levelProperties?.title || levelInfo.name) ?? // levelProperties?.title could be an empty string
            (levelInfo.nameLc ? this._lgTranslate.translate(levelInfo.nameLc) : "");
        const itemCode =
            itemName != null
                ? (x: any) => x[itemName][levelInfo.field]
                : (x: any) => x[levelInfo.field];

        levelExportDefinitions.push({
            name: levelName,
            contentFn: x =>
                hasDefinitions
                    ? this._definitions.getDisplayName(levelInfo.type as any, itemCode(x), "code")
                    : itemName != null
                    ? x[itemName][levelInfo.field]
                    : x[levelInfo.field],

            hAlign: "left",
            width: 25
        });

        if (hasDefinitions) {
            levelExportDefinitions.push({
                name: `${levelName} ${this.translate(".Description")}`,
                contentFn: x =>
                    this._definitions.getDisplayName(
                        levelInfo.type as any,
                        itemCode(x),
                        "codeAndName"
                    ),
                hAlign: "left",
                width: 50
            });
        }

        return levelExportDefinitions;
    }

    _getOtherExportDefinitions(
        otherPivotColumns: PivotTableColumn[],
        scheme: FieldInfo[],
        references: ReferenceInSlot[],
        itemName?: string
    ): IColumnExportDefinition[] {
        const otherExportDefinitions: IColumnExportDefinition[] = [];
        let previousReferenceIdx: number | undefined;

        otherPivotColumns.forEach(x => {
            let groupWithPrevious = false;
            if (x.type === "default") {
                // TODO: shouldn't we also test for previousReferenceIdx being not undefined?
                groupWithPrevious = x.referenceIdx === previousReferenceIdx;
                previousReferenceIdx = x.referenceIdx;
            }

            const exportDefinition = this.getExportDefinition(
                x,
                scheme,
                references,
                itemName,
                groupWithPrevious
            );
            otherExportDefinitions.push(...exportDefinition);
        });

        return otherExportDefinitions;
    }

    getExportDefinition(
        column: PivotTableColumn,
        scheme: FieldInfo[],
        references: ReferenceInSlot[],
        itemName: string | undefined,
        groupWithPrevious = false
    ): IColumnExportDefinition[] {
        if (column.type === "default") {
            const columnDefinition = scheme.find(def => def.field === column.field);
            const reference = references.find(ref => ref.slotIdx === column.referenceIdx);
            const refName =
                reference?.name ??
                (reference?.nameLc != null ? this.translate(reference.nameLc) : "-");

            if (columnDefinition === undefined)
                throw Error(`Column ${column.field} is missing in the schema.`);

            return [
                {
                    name:
                        column.title ??
                        columnDefinition.name ??
                        (columnDefinition.nameLc
                            ? this._lgTranslate.translate(columnDefinition.nameLc)
                            : ""),
                    nameGroup: groupWithPrevious || refName,
                    content:
                        itemName != null
                            ? `${itemName}.${getColumnFieldName(column)}`
                            : getColumnFieldName(column),
                    format: this.getExcelFormat(columnDefinition),
                    hAlign: "right",
                    width: 25,
                    totals: true
                }
            ];
        }

        if (column.type === "difference") {
            const columnDefinition = scheme.find(def => def.field === column.field);
            if (columnDefinition === undefined)
                throw Error(`Column ${column.field} is missing in the schema.`);

            return [
                {
                    name: `${column.title ?? ""} ${this.translate(".AbsoluteDifference")}`,
                    content: `${itemName}.${getColumnFieldName({ ...column, mode: "diff" })}`,
                    format: this.getExcelFormat(columnDefinition),
                    hAlign: "right",
                    width: 25,
                    totals: true
                },
                {
                    name: `${column.title ?? ""} ${this.translate(".RelativeDifference")}`,
                    content: `${itemName}.${getColumnFieldName({ ...column, mode: "growth" })}`,
                    format: "percent:2",
                    hAlign: "right",
                    width: 25,
                    totals: true
                }
            ];
        }

        if (column.type === "formula") {
            for (const key in column.variables) {
                const variable = column.variables[key as PivotTableColumnFormulaVariableNames];
                const field = variable?.type !== "constant" && variable?.field;
                const columnDefinition = scheme.find(a => field === a.field);
                if (variable?.type !== "constant" && columnDefinition === undefined) {
                    throw Error(`Column ${field} is missing in the schema.`);
                }
            }

            return [
                {
                    name: column.title ?? this._lgTranslate.translate("formula"),
                    content: `${itemName}.${getColumnFieldName(column)}`,
                    format: this._getFormulaFormatter(column),
                    hAlign: "right",
                    width: 25,
                    totals: true
                }
            ];
        }

        return [];
    }

    _getFormulaFormatter(column: PivotTableColumnFormula): string {
        switch (column.formatType) {
            case "float":
                return `number:${column.formatPrecision}`;

            case "money":
                return `money:${column.formatPrecision}`;

            case "percentage":
                return `percent:${column.formatPrecision}`;

            default:
                return "";
        }
    }

    getExcelFormat(fieldInfo: FieldInfo): string {
        switch (fieldInfo.type) {
            case "float":
                return `number:${fieldInfo.numericPrecision}`;
            case "money":
                return `money:${fieldInfo.numericPrecision}`;
            default:
                return "";
        }
    }

    // ----------------------------------------------------------------------------------
    //
    protected getDefaultInfobox(): IInfoboxSpecification {
        return {
            row: 0,
            column: 0,
            spacing: 1,
            labelSpan: 1,
            textSpan: 1,
            textAlign: "left",
            info: [
                this.translate(".Hospital"),
                () => `${this._session.client.code} - ${this._session.client.name}`,
                this.translate(".Date"),
                () =>
                    new Date().toLocaleDateString(this._localizationSettings.locale, {
                        year: "numeric",
                        month: "short",
                        day: "numeric"
                    }) +
                    " " +
                    new Date().toLocaleTimeString(this._localizationSettings.locale)
            ]
        };
    }

    protected translate(translationId: string, interpolateParams?: object): string {
        if (this._translationNamespace && translationId[0] === ".") {
            translationId = this._translationNamespace + translationId;
        }
        return this._lgTranslate.translate(translationId, interpolateParams);
    }
}
