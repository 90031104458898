import { PageWidgetConfigBase, WidgetState } from "../../types";

export const CROSSTAB_WIDGET = "crosstabWidget";

export interface CrosstabWidgetConfig extends PageWidgetConfigBase {
    horizontalDimension: string;
    verticalDimension: string;
    value: string;
    referenceIdx?: number;
    sidebarWidth: number;
    cellWidth: number;
}

export type CrosstabWidgetState = WidgetState & {
    version: number;
    xPos: any;
    yPos: any;
};
