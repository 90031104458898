import * as _ from "lodash";
import { ChangeDetectorRef, ElementRef, inject } from "@angular/core";
import {
    ConnectedOverlayPositionChange,
    ConnectedPosition,
    Overlay,
    ScrollDispatcher
} from "@angular/cdk/overlay";
import { animate, AnimationEvent, state, style, transition, trigger } from "@angular/animations";
import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import { Observable, Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";
import { before } from "@logex/mixin-flavors";

import { IOverlayResultApi, LgOverlayService } from "@logex/framework/ui-core";
import { easingDefs } from "@logex/framework/utilities";

export class PopupHostMixin {
    // ----------------------------------------------------------------------------------
    // Dependencies

    _elementRef!: ElementRef;
    _changeDetectorRef!: ChangeDetectorRef;
    _overlay!: Overlay;
    _scrollDispatcher!: ScrollDispatcher;
    _overlayService!: LgOverlayService;

    // ----------------------------------------------------------------------------------
    // Fields

    private _phIsActive?: boolean;
    private _phPopupHidden$?: Subject<void>;
    private _phOverlayInstance?: IOverlayResultApi;
    private _phPopupInstance?: IPopupComponent;

    // ----------------------------------------------------------------------------------
    // Methods

    @before
    _initMixins(): void {
        if (this._elementRef == null) throw Error("_elementRef must be injected");
        if (this._changeDetectorRef == null) throw Error("_changeDetectorRef must be injected");
        if (this._overlay == null) throw Error("_overlay must be injected");
        if (this._scrollDispatcher == null) throw Error("_scrollDispatcher must be injected");
        if (this._overlayService == null) throw Error("_overlayService must be injected");

        this._phIsActive = false;
        this._phPopupHidden$ = new Subject<void>();
    }

    _doShowPopup<TPopup extends IPopupComponent, TResult = any>(args: {
        anchorElementRef: ElementRef;
        componentType: ComponentType<TPopup>;
        initPopupComponent: (instance: TPopup) => Observable<TResult>;
        anchorPositions: ConnectedPosition[];
    }): Promise<TResult> {
        _.defaults(args, {
            anchorElementRef: this._elementRef,
            anchorPositions: [
                { originX: "start", originY: "bottom", overlayX: "start", overlayY: "top" },
                { originX: "start", originY: "top", overlayX: "start", overlayY: "bottom" }
            ]
        });

        return new Promise<TResult>(resolve => {
            this._phPopupHidden$ = new Subject<void>();

            // this._popupHidden$
            //     .pipe( finalize( () => {
            //         resolve();
            //     } ) )
            //     .subscribe();

            // HACK: Exploits the fact that CDK overlay strategy uses only getBoundingClientRect to get position of anchor element
            const element = args.anchorElementRef.nativeElement as HTMLElement;
            const elementRect = element.getBoundingClientRect();

            const cachedElementRef = new ElementRef({
                getBoundingClientRect: () => {
                    const bcr = this._elementRef.nativeElement.getBoundingClientRect();
                    if (!(bcr.left === 0 && bcr.top === 0 && bcr.width === 0 && bcr.height === 0)) {
                        return bcr;
                    } else {
                        return elementRect;
                    }
                }
            });

            const strategy = this._overlay
                .position()
                .flexibleConnectedTo(args.anchorElementRef)
                .setOrigin(cachedElementRef)
                .withPositions(args.anchorPositions);

            strategy.withScrollableContainers(
                this._scrollDispatcher.getAncestorScrollContainers(args.anchorElementRef)
            );

            this._phOverlayInstance = this._overlayService.show({
                onClick: () => {
                    if (this._phPopupInstance) this._phPopupInstance.attemptClosePopup();
                },
                hasBackdrop: true,
                trapFocus: true,
                sourceElement: args.anchorElementRef,
                positionStrategy: strategy,
                // onDeactivate: () => {
                //     if ( this._poPopupInstance ) this._poPopupInstance.setIsTop( false );
                // },
                // onActivate: () => {
                //     if ( this._poPopupInstance ) this._poPopupInstance.setIsTop( true );
                // },
                scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 })
            });

            const portal = new ComponentPortal<TPopup>(args.componentType);
            this._phPopupInstance = this._phOverlayInstance.overlayRef.attach(portal).instance;

            strategy.positionChanges.pipe(takeUntil(this._phPopupHidden$)).subscribe(change => {
                this._phPopupInstance?.updatePopupPosition(change);
            });

            this._phPopupInstance.configurePopup(args.anchorElementRef, () => strategy.apply());

            args.initPopupComponent(this._phPopupInstance as TPopup)
                .pipe(takeUntil(this._phPopupHidden$))
                .subscribe(result => {
                    this._doClosePopup();
                    resolve(result);
                });

            this._phIsActive = true;

            this._changeDetectorRef.markForCheck();
        });
    }

    _doClosePopup(immediately = false): void {
        if (!this._phIsActive) return;

        this._phIsActive = false;
        this._phPopupHidden$?.next();
        this._phPopupHidden$?.complete();

        if (immediately) {
            this._phOverlayInstance?.hide();
        } else {
            const overlayInstance = this._phOverlayInstance;
            this._phPopupInstance
                ?.hidePopup()
                .pipe(first())
                .subscribe(() => {
                    overlayInstance?.hide();
                });
        }

        this._phPopupHidden$ = undefined;
        this._phOverlayInstance = undefined;
        this._phPopupInstance = undefined;

        this._changeDetectorRef.markForCheck();
    }

    get _popupIsActive(): boolean {
        return !!this._phIsActive;
    }
}

// ----------------------------------------------------------------------------------
//
export interface IPopupComponent {
    configurePopup(target: ElementRef<any>, repositionCb: () => void): void;
    updatePopupPosition(change: ConnectedOverlayPositionChange): void;
    attemptClosePopup(): void;
    hidePopup(): Observable<void>;
}

export type PopupComponentVisibility = "hidden" | "initial" | "visible";

export class PopupMixin implements IPopupComponent {
    // ----------------------------------------------------------------------------------
    // Standard configurations for mixin users

    public static readonly HostAttributes = {
        "[@state]": "_visibility",
        "(@state.done)": "_animationDone( $event )",
        "(document:keydown.escape)": "onEscKey( $event )"
    };

    public static Animations = trigger("state", [
        state("initial, hidden", style({ opacity: 0 })),
        state("visible", style({ opacity: 1 })),

        transition("* => visible", [
            style({ opacity: 0 }),
            animate(`200ms ${easingDefs.easeOutCubic}`, style({ opacity: 1 }))
        ]),

        transition("* => hidden", [animate(`200ms ${easingDefs.easeOutCubic}`)])
    ]);

    // ----------------------------------------------------------------------------------
    // Fields
    protected _anchorElementRef?: ElementRef;
    private _pmRepositionCb?: () => void;

    private _pmOnHide$!: Subject<void>;
    private _changeDetectorRefInternal = inject(ChangeDetectorRef);

    _visibility?: PopupComponentVisibility;

    // ----------------------------------------------------------------------------------
    // Methods

    @before
    _initMixins(): void {
        this._pmOnHide$ = new Subject();
        this._visibility = "initial";
    }

    configurePopup(target: ElementRef<any>, repositionCb: () => void): void {
        this._anchorElementRef = target;
        this._pmRepositionCb = repositionCb;
    }

    onEscKey(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        this.attemptClosePopup();
    }

    attemptClosePopup(): void {
        throw Error("'attemptClosePopup' must be implemented by the class that uses mixin.");
    }

    hidePopup(): Observable<void> {
        this._visibility = "hidden";
        this._changeDetectorRefInternal?.markForCheck();
        return this._pmOnHide$.asObservable();
    }

    updatePopupPosition(change: ConnectedOverlayPositionChange): void {
        // To be overridden if needed
    }

    // ----------------------------------------------------------------------------------
    //

    /**
     * In implementing class it should have the decoration:
     * ```
     * @HostListener( "@state.done", ["$event"] )
     * ```
     */
    @before
    _animationDone(event: AnimationEvent): void {
        if (event.toState === "hidden") {
            this._pmOnHide$.next();
        }
    }
}
