import { ChartWidgetConfig } from "../chart-widget.configuration.types";

export function chartV2toV3Migration(config: ChartWidgetConfig) {
    config.tablesConfig = config.levels.reduce((tableConfig, field, index) => {
        const id = index + 1;
        tableConfig.push({
            id,
            ignoreOwnFilters: false,
            type: "table",
            name: `Table ${id}`,
            stackedBarDimension: config.stackedBarDimension,
            dimensions: [
                {
                    id,
                    fieldId: field,
                    isSelectable: true,
                    labelDisplayMode: config.labelDisplayMode,
                    width: null,
                    disabled: false
                }
            ]
        });
        return tableConfig;
    }, []);

    delete config.labelDisplayMode;
    delete config.stackedBarDimension;

    return 3;
}
