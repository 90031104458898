import { IAppSession as IAppSessionBase, IScenario } from "@logex/framework/lg-application";

export interface IAppSession<TAppScenario extends IScenario> extends IAppSessionBase {
    scenario: TAppScenario;
    client: {
        id: number;
        code: string;
        name: string;
        abbr: string;
    };
}

export class AppSession<TAppScenario extends IScenario> implements IAppSessionBase {
    clientId: number;
    scenarioId: number;

    scenario: TAppScenario;
    client: {
        id: number;
        code: string;
        name: string;
        abbr: string;
    };
}
