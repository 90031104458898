import { PageWidgetConfigBase, PivotTableColumn, PivotTableLevel } from "../../../types";
import { Dictionary } from "lodash";
export interface FlexiblePivotConfigurationDialogSettings {
    forceIgnoreOwnFilters: boolean | null;
    allowDrilldown: boolean;
    isFlatTable: boolean;
}
export const DEPRECATED_DRILLDOWN_PIVOT_TABLE_WIDGET = "DrilldownPivotTableWidget";
export const DEPRECATED_DRILLDOWN_FLAT_TABLE_WIDGET = "DrilldownFlatTableWidget";

export interface ChartWidgetConfigBase extends PageWidgetConfigBase {
    title: string;
    columns: PivotTableColumn[];
    levels: string[];
    ignoreOwnFilters?: boolean;
    defaultOrderBy?: string;
}

export interface DrilldownPivotTableWidgetBaseConfig extends PageWidgetConfigBase {
    title: string;
    levels: string[][];
    levelProperties: Dictionary<PivotTableLevel>;
    columns: PivotTableColumn[];
    ignoreOwnFilters?: boolean;
    isFlatTable?: boolean;
    allowDrilldown?: boolean;
    settings?: FlexiblePivotConfigurationDialogSettings;
    defaultOrderBy?: string;
}
