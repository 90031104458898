<div class="bookmark-edit-dialog">
    <form [formGroup]="_newBookmarkForm" (ngSubmit)="_onSave()" class="form-group">
        <div class="bookmark-edit-dialog__name">
            <div class="form-row bookmark-edit-dialog__name__label-input-wrapper">
                <label for="newFilterName" class="bookmark-edit-dialog__name__label">
                    {{ ".Save_input_label" | lgTranslate }}
                </label>
                <div class="form-row__controls">
                    <input
                        id="newFilterName"
                        formControlName="name"
                        type="text"
                        autocomplete="off"
                        class="bookmark-edit-dialog__name__input"
                    />
                    <div class="form-row__controls form-row__controls--right-align">
                        <div
                            [ngClass]="{
                                'form-row__controls__message-error':
                                    _newBookmarkForm.controls.name.errors &&
                                    _newBookmarkForm.controls.name.errors['maxlength'],
                                'form-row__controls__message-info':
                                    !_newBookmarkForm.controls.name.errors ||
                                    !_newBookmarkForm.controls.name.errors['maxlength']
                            }"
                            lgTranslate=".Allowed_number_of_characters_text"
                            [translateParams]="{
                                current: _newBookmarkForm.value.name?.length ?? 0,
                                max: MAX_LENGTH
                            }"
                        ></div>
                    </div>
                    <div
                        *ngIf="
                            !_newBookmarkForm.controls.name.pristine &&
                            _newBookmarkForm.controls.name.errors
                        "
                        class="bookmark-edit-dialog__name__validation-errors"
                    >
                        <lg-icon icon="icon-warning" [inline]="true" type="error"></lg-icon>
                        <div
                            *ngIf="_newBookmarkForm.controls.name.errors['nameAlreadyExists']"
                            class="bookmark-edit-dialog__name__validation-errors__item"
                        >
                            {{ ".Save_new_input_validation_message_already_exists" | lgTranslate }}
                        </div>
                        <div
                            *ngIf="_newBookmarkForm.controls.name.errors['required']"
                            class="bookmark-edit-dialog__name__validation-errors__item"
                        >
                            {{ ".Save_new_input_validation_message_required" | lgTranslate }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row bookmark-edit-dialog__checkbox-row">
                <div class="form-row__controls">
                    <input
                        id="isDefault"
                        formControlName="isDefault"
                        type="checkbox"
                        lgStyledCheckbox
                    />
                </div>
                <label for="isDefault" class="bookmark-edit-dialog__name__label">
                    {{ ".Mark_as_default_layout_bookmark" | lgTranslate }}
                </label>
            </div>
        </div>
        <div class="form-group form-group--buttons">
            <div class="form-row">
                <button
                    class="button button--primary"
                    [disabled]="!_newBookmarkForm.dirty || !_newBookmarkForm.valid"
                >
                    {{ ".Save_confirm_button" | lgTranslate }}
                </button>
                <button type="button" class="button" (click)="_onCancel()">
                    {{ ".Save_cancel_button" | lgTranslate }}
                </button>
            </div>
        </div>
    </form>
</div>
