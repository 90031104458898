import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from "@angular/core";

@Component({
    selector: "lgflex-dialog-tab",
    templateUrl: "./dialog-tab.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogTabComponent {
    @Input() label = "";

    @ViewChild(TemplateRef, { static: true })
    template: TemplateRef<unknown>;
}
