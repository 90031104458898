<div
    class="table__row table__row--midheader table__row--flat-header"
    [lgColDefinition]="colDefinition"
    [lgColDefinitionWidth]="pivotWrapperWidth() - 10"
    [lgColRow]="'midHeader' + index"
>
    <ng-container *ngFor="let levelHeader of level.levelHeaders">
        <ng-container *ngIf="levelHeader.headerType === 'dimension'">
            <div [lgCol]="levelHeader.id" class="table__row--flat-header-item">
                <div
                    [lgSortByColumn]="levelHeader.orderBy!"
                    [sortBy]="maxVisibleLevel!.pivot.orderBy[maxVisibleLevel!.maxVisibleSubLevel]"
                    class="table__column table__column--header-item"
                    (sortByChange)="_onOrderByChange($event)"
                >
                    <span
                        class="crop-text"
                        *ngIf="
                            levelHeader.header != null && levelHeader.header.length;
                            else useHeaderLc
                        "
                    >
                        {{ levelHeader.header }}
                    </span>

                    <ng-template #useHeaderLc>
                        <span class="crop-text" lgTranslate> {{ levelHeader.headerLc }} </span>
                    </ng-template>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="levelHeader.headerType === 'icons'">
            <div [lgCol]="levelHeader.id"></div>
        </ng-container>
    </ng-container>
</div>
