import * as _ from "lodash-es";
import { Injectable, OnDestroy } from "@angular/core";
import { asyncScheduler, Observable, scheduled, Subject } from "rxjs";
import { ReferenceInfo, ReferenceSlot } from "../../types";
import { PageReferencesServiceState, ReferenceInSlot } from "./page-references.types";

@Injectable()
export class PageReferencesService implements OnDestroy {
    // ----------------------------------------------------------------------------------
    private _slots: ReferenceSlot[] = [];
    private _references: ReferenceInfo[] = [];
    private _selectedCodesRaw: string[] = [];
    private _selected: Array<string | null> = [];
    private _selectedReferences: ReferenceInSlot[] = [];
    private readonly _stateChangeSubject = new Subject<PageReferencesServiceState>();
    private readonly _stateChange$ = scheduled(this._stateChangeSubject, asyncScheduler);

    // ----------------------------------------------------------------------------------
    /**
     * Returns initial reference slots.
     * It doesn't reflect user selection! For that you need to use selectedReferences() method.
     */
    get slots(): ReferenceSlot[] {
        return this._slots;
    }

    set slots(value: ReferenceSlot[]) {
        this._slots = value;
        this._updateSelectedReferences();
        this._notifyOnStateChange();
    }

    get references(): ReferenceInfo[] {
        return this._references;
    }

    set references(value: ReferenceInfo[]) {
        this._references = value;
        this._updateSelectedReferences();
        this._notifyOnStateChange();
    }

    get selected(): Array<string | null> {
        return this._selected;
    }

    set selected(value: Array<string | null>) {
        if (_.isEqual(value, this._selectedCodesRaw)) return;

        this._selectedCodesRaw = value.map(x => x ?? "");
        this._updateSelectedReferences();
        this._notifyOnStateChange();
    }

    /**
     * Returns currently selected references by user
     */
    get selectedReferences(): ReferenceInSlot[] {
        return this._selectedReferences;
    }

    private _updateSelectedReferences(): void {
        if (!_.isEmpty(this._selectedCodesRaw)) {
            this._selectedReferences = this._slots.map((slot, i) => {
                const referenceCode = slot.isLocked
                    ? slot.referenceCode
                    : this._selectedCodesRaw[i];

                const referenceInfo = _.find(this._references, {
                    code: referenceCode ?? ""
                });

                return {
                    ...referenceInfo,
                    slotIdx: i,
                    isLocked: slot.isLocked
                };
            });
        } else {
            this._selectedReferences = [];
        }

        this._selected = _.map(this._selectedReferences, x => x.code || null);
    }

    private _notifyOnStateChange(): void {
        this._stateChangeSubject.next({
            references: this._references,
            slots: this._slots,
            selectedReferences: this._selectedReferences
        });
    }

    get stateChange(): Observable<PageReferencesServiceState> {
        return this._stateChange$;
    }

    isAllowed(): boolean {
        return this._references.length > 0;
    }

    ngOnDestroy(): void {
        this._stateChangeSubject.complete();
    }
}
