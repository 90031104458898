import { inject, Inject, Injectable } from "@angular/core";
import { mixins } from "@logex/mixin-flavors";
import { BehaviorSubject } from "rxjs";

import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { LgTranslateService } from "@logex/framework/lg-localization";
import {
    IDialogComponent,
    IDropdownDefinition,
    LgDialogFactory,
    LgDialogRef,
    LgPromptDialog,
    getDialogFactoryBase
} from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";

import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";
import { LgConsole } from "@logex/framework/core";

import { WidgetTypesRegistry } from "../../../services/widget-types-registry";
import {
    FieldInfo,
    PivotTableColumn,
    WidgetConfigurationDialogArguments,
    WidgetConfigurationDialogResponse
} from "../../../types";
import { CalcAstNodeFunctionCall, parseCalculate, translateNullableName } from "../../../utilities";
import {
    DropdownReferenceSlot,
    getDropdownReferenceSlots,
    getReferenceDropdownDefinition
} from "../../../utilities/references-helpers";
import { ChartWidgetConfigBase } from "../../../widgets/base/chart-widget-base/chart-widget-base.types";
import { LevelLegacy } from "../../lg-pivot-levels-selector-legacy/lg-pivot-levels-selector-legacy.component";
import { validateChartWidgetConfigBase } from "./chart-configuration-validation-base";

export interface ChartWidgetConfigurationDialogArgumentsBase
    extends WidgetConfigurationDialogArguments {
    allowMultipleReferences: boolean;
    ignoreOwnFilters: boolean;
}

export interface ChartWidgetConfigurationDialogResponseBase
    extends WidgetConfigurationDialogResponse {
    ignoreOwnFilters: boolean;
}

export interface ChartConfigurationDialogBase<
    TArgs extends ChartWidgetConfigurationDialogArgumentsBase,
    TRes extends ChartWidgetConfigurationDialogResponseBase
> extends DialogMixin<ChartConfigurationDialogBase<TArgs, TRes>>,
        ModalResultDialogMixin<TArgs, TRes> {}

@mixins(DialogMixin, ModalResultDialogMixin)
export abstract class ChartConfigurationDialogBase<
    TArgs extends ChartWidgetConfigurationDialogArgumentsBase,
    TRes extends ChartWidgetConfigurationDialogResponseBase
> implements
        IDialogComponent<
            ChartConfigurationDialogBase<TArgs, TRes>,
            WidgetConfigurationDialogResponse
        >
{
    protected _lgConsole: LgConsole = inject(LgConsole);

    constructor(
        @Inject(LG_APP_DEFINITIONS) public _definitions: IDefinitions<any>,
        public _lgTranslate: LgTranslateService,
        public _dialogRef: LgDialogRef<ChartConfigurationDialogBase<TArgs, TRes>>,
        public _promptDialog: LgPromptDialog,
        private _widgetTypes: WidgetTypesRegistry
    ) {
        this._initMixins();
    }

    _dialogClass = "lg-dialog lg-dialog--6col lg-dialog--no-spacing";
    _title = this._lgTranslate.translate(".DialogTitle");

    _widgetName = "";
    _widgetDescription = "";
    _widgetTooltip = "";
    _widgetTooltipLink = "";
    _availableLevels: LevelLegacy[] = [];
    _availableLevels$ = new BehaviorSubject<any[]>(this._availableLevels);
    _selectedLevelsIds: string[] = [];
    _ignoreOwnFilters = false;

    _valueField: string | undefined;
    _valueFieldDropdown: IDropdownDefinition<string> | undefined;

    _allowMultipleReferences = false;
    _referenceSlots: DropdownReferenceSlot[] | undefined;
    _enabledReferences = new Set<number>();
    _referenceDropdown: IDropdownDefinition<number> | undefined;

    _allowedValueFields: FieldInfo[] = [];
    _isReadOnly = false;

    // ----------------------------------------------------------------------------------
    //
    async _activate(): Promise<void> {
        this._widgetName = this._args.widgetName;
        this._widgetDescription = this._args.widgetDescription;
        this._widgetTooltip = this._args.widgetTooltip;
        this._widgetTooltipLink = this._args.widgetTooltipLink;
        this._selectedLevelsIds = this._selectAvailableLevels(this._args.levels, this._args.scheme);
        this._isReadOnly = this._args.isReadonly ?? this._isReadOnly;
        this._allowMultipleReferences = this._args.allowMultipleReferences;
        this._valueField =
            this._args.columns[0]?.type === "default" ? this._args.columns[0].field : undefined;
        this._ignoreOwnFilters = this._args.ignoreOwnFilters;

        this._initializeReferences();
        this._onDropdownChange();
    }

    // ----------------------------------------------------------------------------------
    //
    private _selectAvailableLevels(levels: string[], scheme: FieldInfo[]): string[] {
        const result = [];
        const deletedLvls = [];
        levels.forEach(level => {
            if (scheme.some(item => item.field === level)) {
                result.push(level);
            } else {
                deletedLvls.push(level);
            }
        });
        if (deletedLvls.length !== 0) {
            this._lgConsole.warn(
                `Selected levels ${deletedLvls} have been removed from the configuration, because they do not exist in the fields scheme.`
            );
        }
        return result;
    }

    // ----------------------------------------------------------------------------------
    //
    _initializeReferences(): void {
        if (this._args.columns.length > 0) {
            this._args.columns.forEach(
                col => col.type === "default" && this._enabledReferences.add(col.referenceIdx ?? 0)
            );
        } else {
            this._enabledReferences.add(0);
        }

        if (!this._args.pageReferences.isAllowed()) return;

        this._referenceSlots = getDropdownReferenceSlots(
            this._args.pageReferences.slots,
            this._args.pageReferences.references,
            this._lgTranslate
        );
        this._referenceDropdown = getReferenceDropdownDefinition(this._referenceSlots);
    }

    _referenceIsSelected(reference: DropdownReferenceSlot): boolean {
        return this._enabledReferences.has(reference.reference);
    }

    _onReferenceCheckboxClick(enable: boolean, reference: DropdownReferenceSlot): void {
        if (enable) {
            this._enabledReferences.add(reference.reference);
        } else {
            this._enabledReferences.delete(reference.reference);
        }
    }

    _getFirstEnabledReference(): number {
        return Array.from(this._enabledReferences)[0];
    }

    _setFirstEnabledReference(reference: number): void {
        this._enabledReferences = new Set([reference]);
    }

    // ----------------------------------------------------------------------------------
    //
    _onDropdownChange(): void {
        this._setAllowedValueFields();
        this._createValueFieldDropdown();
        this._refillLevels();
    }

    private _setAllowedValueFields(): void {
        const selectedLevelsInfo = this._args.scheme.filter(x =>
            this._selectedLevelsIds.includes(x.field)
        );
        const blockedBy = new Set<string>();
        selectedLevelsInfo.forEach(x => x.blockedBy?.forEach(val => blockedBy.add(val)));

        this._allowedValueFields = this._args.scheme.filter(
            field =>
                field.isValueField &&
                !this._selectedLevelsIds.some(level => field.blockedBy?.includes(level)) &&
                !this._isColumnFormulasBlocking(blockedBy, field)
        );
    }

    private _isColumnFormulasBlocking(blockedBy: Set<string>, field: FieldInfo): boolean {
        if (field.calculate) {
            const parsed = parseCalculate(field.calculate) as CalcAstNodeFunctionCall;
            return parsed.params.some(value => blockedBy.has(value));
        }

        return false;
    }

    protected _refillLevels(): void {
        this._availableLevels = this._args.scheme
            .filter(x => !x.isValueField)
            .map(x => ({
                field: x.field,
                name: x.name ?? (x.nameLc && this._lgTranslate.translate(x.nameLc)),
                nameLc: null,
                isBlocked: this._isLevelBlocked(x)
            }));

        this._availableLevels$.next(this._availableLevels);
    }

    private _createValueFieldDropdown(): void {
        const entries = this._allowedValueFields.map(x => ({
            field: x.field,
            name: translateNullableName(this._lgTranslate, x.name, x.nameLc)
        }));

        this._valueFieldDropdown = dropdownFlat({
            entryId: "field",
            entryName: "name",
            entries: [...entries, { field: null, name: "-" }]
        });
    }

    protected _isLevelBlocked(field: Partial<FieldInfo>): boolean {
        if (!field.blockedBy || !field.blockedBy.length) return false;

        const selectedColumnNames = [this._valueField];

        // Acknowledge fields participating in formulas
        const selectedColumnsWithCalculations = this._args.scheme.filter(
            x => selectedColumnNames.includes(x.field) && x.calculate
        );
        for (const column of selectedColumnsWithCalculations) {
            const parsed = parseCalculate(column.calculate!) as CalcAstNodeFunctionCall;
            selectedColumnNames.push(...parsed.params);
        }

        return field.blockedBy.some(value => selectedColumnNames.includes(value));
    }

    // ----------------------------------------------------------------------------------
    //
    async _save(): Promise<boolean> {
        const response: ChartWidgetConfigurationDialogResponseBase = {
            widgetName: this._widgetName,
            widgetDescription: this._widgetDescription,
            widgetTooltip: this._widgetTooltip,
            widgetTooltipLink: this._widgetTooltipLink,
            levels: this._selectedLevelsIds,
            columns: this._getResponseColumns()!,
            ignoreOwnFilters: this._ignoreOwnFilters
        };
        this._resolve(response as TRes);

        return true;
    }

    protected _getResponseColumns(): PivotTableColumn[] | null {
        if (this._valueField === undefined) return null;

        return Array.from(this._enabledReferences)
            .sort()
            .map(ref => ({
                type: "default",
                field: this._valueField!,
                isEnabled: true,
                referenceIdx: this._args.pageReferences.isAllowed() ? ref : undefined
            }));
    }

    _isValid(): boolean {
        const columns = this._getResponseColumns();
        if (columns === null) return false;

        return validateChartWidgetConfigBase<ChartWidgetConfigBase>(
            {
                title: this._widgetName,
                description: this._widgetDescription,
                levels: this._selectedLevelsIds,
                columns,
                ignoreOwnFilters: this._ignoreOwnFilters
            },
            this._args.pageReferences,
            this._args.scheme
        );
    }
}
