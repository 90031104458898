import { ChartWidgetConfigBase } from "../../base/chart-widget-base/chart-widget-base.types";
import { ChartWidgetColumn, ChartWidgetConfig } from "../chart-widget.configuration.types";
import { DefinitionDisplayMode } from "@logex/framework/lg-application";
import { PivotTableColumnFormulaFormatting, ReferenceSlot } from "../../../types";

export type HorizontalChartLabelsConfig = Record<HorizontalChartLabelsOption, boolean>;
export enum HorizontalChartLabelsOption {
    ChartLabels = "chartLabels",
    Tooltip = "tooltip",
    Legend = "legend"
}

export interface HorizontalChartSelectTopConfiguration {
    maxCount: number;
    reference: number | null;
    field: string | null;
    sortDescending: boolean;
}
export interface HorizontalChartWidgetConfig extends ChartWidgetConfigBase {
    totalsAsTopLevel: boolean;
    formatType: PivotTableColumnFormulaFormatting;
    formatPrecision: number;
    formatForceSign: boolean | undefined;
    labels?: HorizontalChartLabelsConfig;
    labelDisplayMode?: DefinitionDisplayMode;
    selectTop?: HorizontalChartSelectTopConfiguration;
    dataSource: string;
    selectedReferences: ReferenceSlot[];
}

export function convertHorizontalChartToChart(
    config: HorizontalChartWidgetConfig
): ChartWidgetConfig {
    const {
        totalsAsTopLevel,
        levels,
        title,
        tooltipLink,
        tooltip,
        selectTop,
        ignoreOwnFilters,
        labelDisplayMode,
        columns,
        labels,
        formatForceSign,
        formatType,
        formatPrecision,
        dataSource,
        selectedReferences
    } = config;

    const chartColumns = columns.map((column, index) => {
        if (column.type === "default") {
            return {
                id: index,
                chartType: "simpleBar",
                title: "New column",
                type: "formula",
                formula: "A",
                variables: {
                    a: {
                        type: "variable",
                        field: column.field,
                        reference: column.referenceIdx,
                        variable: "a"
                    }
                },
                formatType: "float",
                formatPrecision: 0
            } as ChartWidgetColumn;
        }

        return {
            ...column,
            chartType: "simpleBar"
        } as ChartWidgetColumn;
    });

    let orderBy = [];
    let limitRows: number;
    if (selectTop) {
        const item = `${selectTop.field}${selectTop.reference ?? ""}`;
        const columnCodesSet = columns.reduce(
            (codesSet: Set<string>, column: ChartWidgetColumn) => {
                Object.values(column.variables).forEach((value: any, index) => {
                    const code = `${value.field}${value.reference}`;
                    codesSet.add(code);
                });
                return codesSet;
            },
            new Set()
        );
        if (columnCodesSet.has(item)) {
            orderBy = [
                {
                    item,
                    type: selectTop.sortDescending ? "desc" : "asc"
                }
            ];
            limitRows = selectTop.maxCount;
        }
    }

    const tablesConfig = levels.reduce((tableConfig, field, index) => {
        const id = index + 1;
        tableConfig.push({
            id,
            ignoreOwnFilters: false,
            type: "table",
            name: `Table ${id}`,
            limitRows,
            orderBy,
            dimensions: [
                {
                    id,
                    fieldId: field,
                    isSelectable: true,
                    displayMode: labelDisplayMode,
                    width: null,
                    disabled: false,
                    orderBy: [{ item: `${chartColumns[0].type}${chartColumns[0].id}`, type: "asc" }]
                }
            ]
        });
        return tableConfig;
    }, []);

    return {
        chartOrientation: "horizontal",
        levels: [],
        columns: chartColumns,
        tablesConfig,
        title,
        tooltipLink,
        tooltip,
        formatType,
        formatPrecision,
        formatForceSign,
        labels,
        totalsAsTopLevel,
        ignoreOwnFilters,
        dataSource,
        selectedReferences
    };
}
