import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    inject,
    Injectable,
    Injector,
    Signal,
    signal,
    viewChild,
    ViewChild,
    WritableSignal
} from "@angular/core";

import {
    DefinitionDisplayMode,
    IDefinitions,
    LG_APP_DEFINITIONS
} from "@logex/framework/lg-application";
import { LgConsole } from "@logex/framework/core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    getDialogFactoryBase,
    IDialogComponent,
    IDropdownDefinition,
    LgDialogFactory,
    LgDialogRef,
    LgPromptDialog
} from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";
import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";
import { mixins } from "@logex/mixin-flavors";
import { WidgetTypesRegistry } from "../../../../services/widget-types-registry";
import {
    FieldInfo,
    PivotTableColumn,
    PivotTableColumnFormulaType,
    PivotTableLevel,
    ReferenceInfo,
    WidgetUsage
} from "../../../../types";
import {
    CalcAstNodeFunctionCall,
    getAllowedFields,
    parseCalculate,
    translateNullableName
} from "../../../../utilities";
import { validatePivotTableConfiguration } from "./pivot-table-configuration-validation";
import {
    DropdownReferenceSlot,
    getDropdownReferenceSlots
} from "../../../../utilities/references-helpers";
import {
    DimensionRow,
    PivotTableConfigurationColumn,
    PivotTableConfigurationColumnGroupOrColumn,
    PivotTableConfigurationConvertedColumn,
    PivotTableConfigurationDialogArguments,
    PivotTableConfigurationDialogResponse,
    TableRow
} from "./pivot-table-configuration-dialog.types";
import { PivotTableColumnsGroupsComponent } from "../pivot-table-columns-groups/pivot-table-columns-groups.component";
import { ColumnGroupOrColumnRow } from "../pivot-table-columns-groups/pivot-table-columns-groups.types";
import { TableDimensionsSelectorComponent } from "../../../../components/table-dimensions-selector/table-dimensions-selector.component";
import { AvailableDimension } from "../../../../components/table-dimensions-selector/table-dimensions-selector.types";
import { PivotTableConfigurationService } from "./pivot-table-configuration.service";
import { FlexibleLayoutDataSourcesService } from "../../../../services/flexible-layout-data-sources";
import { PageReferencesService } from "../../../../services/page-references/page-references.service";

export interface PivotTableConfigurationDialogComponent
    extends DialogMixin<PivotTableConfigurationDialogComponent>,
        ModalResultDialogMixin<
            PivotTableConfigurationDialogArguments,
            PivotTableConfigurationDialogResponse
        > {}

@Component({
    selector: "lgflex-pivot-table-configuration-dialog",
    templateUrl: "./pivot-table-configuration-dialog.component.html",
    providers: [...useTranslationNamespace("_Flexible.PivotTableConfigurationDialog")],
    styleUrls: ["./pivot-table-configuration-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class PivotTableConfigurationDialogComponent
    implements
        IDialogComponent<
            PivotTableConfigurationDialogComponent,
            PivotTableConfigurationDialogResponse
        >
{
    public readonly _dialogRef: LgDialogRef<PivotTableConfigurationDialogComponent> =
        inject(LgDialogRef);

    public _title = this._lgTranslate.translate(".DialogTitle");
    public _dialogClass = "lg-dialog lg-dialog--6col lg-dialog--no-spacing";

    private _pageReferences: PageReferencesService;

    protected _pivotTableConfigurationService = inject(PivotTableConfigurationService);
    protected _layoutDataSources = inject(FlexibleLayoutDataSourcesService);

    protected _injector = inject(Injector);
    protected _definitions = inject<IDefinitions<any>>(LG_APP_DEFINITIONS);
    protected _lgConsole: LgConsole = inject(LgConsole);
    protected _selectedLevelsIds: string[][] = [];
    protected _currentColumn: PivotTableColumn | null = null;
    protected _levelProperties: Dictionary<PivotTableLevel> = {};
    protected _referencesDict: Dictionary<ReferenceInfo> = {};
    protected _referenceDropdown: IDropdownDefinition<string | number> | undefined;
    protected _referencedFieldsDropdownDefinition: IDropdownDefinition<string> | undefined;
    protected _isReadOnly = false;
    protected _isDisplayModeDropdownVisible = false;
    protected _defaultDisplayMode: DefinitionDisplayMode = "code";
    protected _schemaLookup: Dictionary<FieldInfo> = {};
    protected _currentGroup = null;
    protected _currentValue = null;
    protected _areReferencesAllowed = false;
    protected _pivotWidgetTypeDropdown: IDropdownDefinition<string> = dropdownFlat({
        entryId: "id",
        entryName: "name",
        entries: this._widgetTypes.getAllForUsage(WidgetUsage.Pivot).map(x => ({
            id: x.id,
            name: this._lgTranslate.translate(x.nameLc)
        }))
    });

    protected defaultTableRowConfig = {
        type: "pivot"
    };

    protected _widgetName: WritableSignal<string> = signal("");
    protected _widgetDescription: WritableSignal<string> = signal("");
    protected _widgetTooltip: WritableSignal<string> = signal("");
    protected _widgetTooltipLink: WritableSignal<string> = signal("");
    protected _currentTable: WritableSignal<TableRow | null> = signal(null);
    protected _currentDimension: WritableSignal<DimensionRow | null> = signal(null);
    protected _tablesConfig: WritableSignal<TableRow[]> = signal([]);
    protected _groupsConfig: WritableSignal<PivotTableConfigurationColumnGroupOrColumn[]> = signal(
        []
    );

    protected _allowedValueFields = computed(() => {
        const selectedLevels = this._tablesConfig().map(t => t.dimensions.map(d => d.fieldId));
        const selectedColumns = this._groupsConfig().reduce(
            (selected, group: PivotTableConfigurationColumnGroupOrColumn) => {
                if ("columns" in group) {
                    const fields = group.columns.reduce((acc, col) => {
                        if (col.type === "default" || col.type === "difference") {
                            acc.push(col.field);
                        }

                        if (col.type === "formula") {
                            Object.values(col.variables).forEach((value: any, index) => {
                                acc.push(value.field);
                            });
                        }

                        return acc;
                    }, []);

                    selected = [...selected, ...fields];
                } else {
                    if ((group.type === "default" && group.field) || group.type === "difference") {
                        selected.push(group.field);
                    }
                    if (group.type === "formula") {
                        Object.values(group.variables).forEach((value: any) => {
                            selected.push(value.field);
                        });
                    }
                }

                return selected;
            },
            []
        );

        return getAllowedFields(this._pivotTableConfigurationService.scheme(), [
            ...new Set(selectedLevels.flat()),
            ...new Set(selectedColumns)
        ]);
    });

    protected _isDimensionSortingDisable = computed(() => {
        if (this._currentDimension()) {
            let currentTable = null;
            let currentDimensionIndex = null;

            this._tablesConfig().every(table => {
                if (!currentTable) {
                    return table.dimensions.every((dimension, index) => {
                        if (dimension.id === this._currentDimension().id) {
                            currentTable = table;
                            currentDimensionIndex = index;

                            return false;
                        }

                        return true;
                    });
                }

                return false;
            });

            return currentTable
                ? currentTable.type === "table" && currentDimensionIndex !== 0
                : false;
        }

        return false;
    });

    protected _fieldsDropdownDefinition = computed(() => {
        return dropdownFlat({
            entryId: "field",
            entryName: "name",
            entries: this._allowedValueFields().map(x => ({
                field: x.field,
                name: translateNullableName(this._lgTranslate, x.name, x.nameLc),
                isReferenceBound: x.isReferenceBound
            }))
        });
    });

    protected _isConfigValid = computed(() => {
        return validatePivotTableConfiguration(
            this._lgTranslate,
            this._injector,
            {
                title: this._widgetName(),
                description: this._widgetDescription(),
                tablesConfig: this._tablesConfig(),
                columns: this._groupsConfig(),
                levels: this._selectedLevelsIds,
                levelProperties: this._levelProperties
            },
            this._pivotTableConfigurationService.isDefaultDataSource()
                ? this._pageReferences
                : ({
                      isAllowed: () => true,
                      slots: this._pivotTableConfigurationService.selectedReferences()
                  } as unknown as PageReferencesService),
            this._pivotTableConfigurationService.scheme(),
            this._widgetTypes
        );
    });

    protected _availableLevels: Signal<AvailableDimension[]> = computed(() => [
        ...this._pivotTableConfigurationService
            .scheme()
            .filter(x => !x.isValueField)
            .map(x => ({
                fieldId: x.field,
                name: this._renderNameWithTitle(
                    x.name,
                    x.nameLc,
                    this._levelProperties[x.field]?.title
                ),
                disabled: this._isLevelBlocked(x)
            }))
    ]);

    protected _dimensionSortingDropdown = computed(() => {
        const columnsEntries = this._groupsConfig().reduce(
            (
                entries: Array<Record<"code" | "title", any>>,
                group: PivotTableConfigurationColumnGroupOrColumn
            ) => {
                if ("columns" in group) {
                    group.columns.forEach(value => {
                        if (value.type !== "widget") {
                            entries.push({
                                code: `${value.field}${
                                    value.type === "default" ? value.referenceIdx : ""
                                }`,
                                title: `${value.title}${value.type === "default" ? " - " + this._renderReferenceName(value.referenceIdx ?? 0) : ""}`
                            });
                        }
                    });
                } else if (group.type !== "widget") {
                    entries.push({
                        code: `${group.field}${group.type === "default" ? group.referenceIdx : ""}`,
                        title: `${group.title}${group.type === "default" ? " - " + this._renderReferenceName(group.referenceIdx ?? 0) : ""}`
                    });
                }

                return entries;
            },
            []
        );

        const tablesEntries = this._currentDimension()
            ? this._tablesConfig().reduce((entries, table) => {
                  const isDimensionInTable = table.dimensions.find(
                      d => d.id === this._currentDimension().id
                  );

                  if (isDimensionInTable) {
                      return entries.concat(
                          table.dimensions.map(d => {
                              return {
                                  code: d.fieldId,
                                  title: d.name
                              };
                          })
                      );
                  }
                  return entries;
              }, [])
            : [];

        const entries = [...tablesEntries, ...columnsEntries];

        return dropdownFlat({
            entryId: "code",
            entryName: "title",
            entries
        });
    });

    protected _levelSortingDropdown = computed(() => {
        const entries = this._groupsConfig().reduce(
            (
                entries: Array<Record<"code" | "title", any>>,
                group: PivotTableConfigurationColumnGroupOrColumn
            ) => {
                if ("columns" in group) {
                    group.columns.forEach(value => {
                        if (value.type === "default") {
                            entries.push({
                                code: `${value.field}${value.referenceIdx}`,
                                title: `${value.title} - ${this._renderReferenceName(value.referenceIdx ?? 0)}`
                            });
                        }
                    });
                } else if (group.type === "default") {
                    entries.push({
                        code: `${group.field}${group.referenceIdx}`,
                        title: `${group.title} - ${this._renderReferenceName(
                            group.type === "default" ? group.referenceIdx : 0
                        )}`
                    });
                }

                return entries;
            },
            []
        );

        return dropdownFlat({
            entryId: "code",
            entryName: "title",
            entries
        });
    });

    private _columnsGroups = viewChild<PivotTableColumnsGroupsComponent | undefined>(
        "columnsGroups"
    );

    private _tableDimensionSelector = viewChild<TableDimensionsSelectorComponent | null>(
        "tableDimensionSelector"
    );

    constructor(
        public _lgTranslate: LgTranslateService,
        public _promptDialog: LgPromptDialog,
        private _widgetTypes: WidgetTypesRegistry
    ) {
        this._initMixins();
    }

    // ----------------------------------------------------------------------------------

    //

    _onDataSourceChange(dataSource: string): void {
        this._pivotTableConfigurationService.setDataSource(dataSource);
    }

    async _activate(): Promise<void> {
        this._pageReferences = this._args.pageReferences;
        this._pivotTableConfigurationService.setDataSourcesService(this._layoutDataSources);
        await this._pivotTableConfigurationService.setDataSource(this._args.dataSource ?? null);

        this._widgetName.set(this._args.widgetName);
        this._widgetDescription.set(this._args.widgetDescription);
        this._widgetTooltip.set(this._args.widgetTooltip);
        this._widgetTooltipLink.set(this._args.widgetTooltipLink);
        this._tablesConfig.set((this._args.tablesConfig as TableRow[]) ?? []);
        this._pivotTableConfigurationService.setSelectedReferences(
            this._args.selectedReferences ?? [{ referenceCode: null, isLocked: false }]
        );
        this._isReadOnly = this._args.isReadonly ?? this._isReadOnly;

        this._schemaLookup = _.keyBy(this._pivotTableConfigurationService.scheme(), x => x.field);
        this._areReferencesAllowed = this._pageReferences.isAllowed();

        this._referencesDict = _.keyBy(
            this._pivotTableConfigurationService.references(),
            x => x.code
        );

        this._tablesConfig.update(value => {
            return value.map(t => {
                t.dimensions = t.dimensions.map(d => {
                    const schemaTitle = this._getSchemaFieldTitle(d.fieldId);

                    if (!d.name) {
                        d.name = schemaTitle;
                    }

                    d.schemaName = schemaTitle;

                    return { ...d };
                });

                return { ...t };
            });
        });

        this._refillLevelProperties();
    }

    ngAfterViewInit() {
        const initialField = this._allowedValueFields()[0];

        const availableValues = [
            {
                title: this._lgTranslate.translate(".Field"),
                type: "default"
            },
            {
                title: this._lgTranslate.translate(".Formula"),
                type: "formula",
                formula: PivotTableColumnFormulaType.AMinusB,
                variables: {
                    a: {
                        field: "",
                        variable: "a",
                        type: "variable"
                    },
                    b: {
                        field: "",
                        variable: "b",
                        type: "variable"
                    }
                },
                formatType: "float",
                formatPrecision: 0,
                formatForceSign: false
            },
            {
                title: this._lgTranslate.translate(".Difference"),
                type: "difference",
                referenceLeft: 0,
                referenceRight: 1,
                field: initialField.field,
                mode: "diff"
            }
        ];

        if (this._pivotWidgetTypeDropdown.groups?.[0]?.entries?.length) {
            availableValues.push({
                title: this._lgTranslate.translate(".Widget"),
                type: "widget"
            });
        }

        const referenceSlots = getDropdownReferenceSlots(
            !this._pivotTableConfigurationService.isDefaultDataSource()
                ? this._pivotTableConfigurationService.selectedReferences()
                : this._pageReferences.slots,
            this._pivotTableConfigurationService.references(),
            this._lgTranslate
        );

        const columns = (this._args.columns ?? []).map(columnOrGroup => {
            if ("columns" in columnOrGroup && columnOrGroup.columnType === "referenceSlot") {
                const referenceIdx = columnOrGroup.referenceIdx ?? 0;
                const columnReference = referenceSlots.find(
                    referenceSlot => referenceSlot.reference === referenceIdx
                );

                if (!columnReference) {
                    columnOrGroup.title = referenceSlots[0].name;
                    columnOrGroup.referenceIdx = 0;
                } else {
                    columnOrGroup.title = columnReference.name;
                }
            }

            return columnOrGroup;
        });

        this._groupsConfig.set([...(columns as PivotTableConfigurationColumnGroupOrColumn[])]);

        this._columnsGroups().initBaseConfig(this._groupsConfig(), availableValues);
    }

    _onTablesConfigChange(tablesConfig: TableRow[]) {
        this._tablesConfig.set([...tablesConfig]);
    }

    _onTableSelect(selectedTable: TableRow | null): void {
        this._resetSelections();
        this._columnsGroups().resetSelection();
        this._currentTable.set(selectedTable);
    }

    _onDimensionSelect({ dimension, tableId }: { dimension: DimensionRow; tableId: string }): void {
        this._resetSelections();
        this._columnsGroups().resetSelection();
        this._currentDimension.set({ ...dimension });

        this._updateDisplayModeDropdownVisibility();
    }

    _onTableChange(updatedTable: TableRow): void {
        this._tablesConfig.update(value =>
            value.map(t => (t.id === updatedTable.id ? { ...updatedTable } : t))
        );
    }

    _onDimensionUpdate(updatedDimension: DimensionRow): void {
        this._tablesConfig.update(value =>
            value.map(table => ({
                ...table,
                dimensions: table.dimensions.map(d =>
                    d.id === updatedDimension.id ? { ...updatedDimension } : d
                )
            }))
        );
    }

    _onCurrentGroupUpdate(updatedGroup: PivotTableConfigurationColumnGroupOrColumn): void {
        this._groupsConfig.update(groupConfig =>
            groupConfig.map((g: PivotTableConfigurationColumnGroupOrColumn) => {
                if (g.id === updatedGroup.id) {
                    return { ...updatedGroup };
                }
                return g;
            })
        );
    }

    _onCurrentValueUpdate(updatedValue: PivotTableConfigurationColumnGroupOrColumn): void {
        this._groupsConfig.update(groupConfig =>
            groupConfig.map((g: PivotTableConfigurationColumnGroupOrColumn) => {
                if ("columns" in g) {
                    if (g.columns && g.columns.length > 0) {
                        g.columns = g.columns.map((v: PivotTableConfigurationColumn) =>
                            v.id === updatedValue.id ? { ...updatedValue } : v
                        ) as PivotTableConfigurationColumn[];
                    }
                } else if (g.id === updatedValue.id) {
                    return { ...g, ...updatedValue };
                }

                return g;
            })
        );

        this._columnsGroups().updateGroups(this._groupsConfig() as ColumnGroupOrColumnRow[]);
        this._refillReferencedFieldsDropdownDefinition();
    }

    _onGroupsConfigChange(groupsConfig: PivotTableConfigurationColumnGroupOrColumn[]) {
        this._groupsConfig.set([...groupsConfig]);
    }

    _onGroupSelect(selectedGroup: PivotTableConfigurationColumnGroupOrColumn): void {
        this._resetSelections();
        this._tableDimensionSelector().resetSelection();

        this._refillDropdownDefinitions("name");
        this._currentGroup = selectedGroup;
    }

    _onValueSelect(selectedValue: PivotTableConfigurationColumnGroupOrColumn): void {
        this._resetSelections();
        this._tableDimensionSelector().resetSelection();

        this._refillDropdownDefinitions();
        this._refillReferencedFieldsDropdownDefinition();
        this._currentValue = selectedValue;
    }

    //
    async _save(): Promise<boolean> {
        const columns = this._groupsConfig().map(
            (group: PivotTableConfigurationColumnGroupOrColumn) => {
                if ("columns" in group) {
                    return {
                        ...group,
                        columns: group.columns.map(value =>
                            this._convertToColumn(value as PivotTableConfigurationColumn)
                        )
                    };
                }

                return this._convertToColumn(group as PivotTableConfigurationColumn);
            }
        );

        this._resolve({
            columns,
            dataSource: this._pivotTableConfigurationService.dataSourceCode(),
            selectedReferences: this._pivotTableConfigurationService.selectedReferences(),
            widgetName: this._widgetName(),
            widgetDescription: this._widgetDescription(),
            widgetTooltip: this._widgetTooltip(),
            widgetTooltipLink: this._widgetTooltipLink(),
            tablesConfig: this._tablesConfig()
        });

        return true;
    }

    protected _isLevelBlocked(field: Partial<FieldInfo>): boolean {
        if (!field.blockedBy || !field.blockedBy.length) return false;

        const selectedColumnNames = [];

        this._groupsConfig().forEach(group => {
            if ("columns" in group) {
                group.columns.forEach(column => {
                    if (column.type === "default") {
                        selectedColumnNames.push(column.field);
                    }
                });
            } else if (group.type === "default") {
                selectedColumnNames.push(group.field);
            }
        });

        // Acknowledge fields participating in formulas
        const selectedColumnsWithCalculations = this._pivotTableConfigurationService
            .scheme()
            .filter(x => selectedColumnNames.includes(x.field) && x.calculate);
        for (const column of selectedColumnsWithCalculations) {
            const parsed = parseCalculate(column.calculate!) as CalcAstNodeFunctionCall;
            selectedColumnNames.push(...parsed.params);
        }

        return !!_.intersection(field.blockedBy, selectedColumnNames).length;
    }

    protected _refillReferencedFieldsDropdownDefinition() {
        const allColumns = this._pivotTableConfigurationService
            .scheme()
            .filter(x => x.isValueField);
        const entries = [];
        const slots = !this._pivotTableConfigurationService.isDefaultDataSource()
            ? this._pivotTableConfigurationService.selectedReferences()
            : this._pageReferences.slots;
        const references = this._pivotTableConfigurationService.references();

        allColumns.forEach(column => {
            if (column.isReferenceBound) {
                const columnName = this._lgTranslate.translate(column.nameLc);

                slots.forEach((slot, index) => {
                    const ref = references.find(r => r.code === slot.referenceCode);
                    let name;
                    if (ref !== undefined && (ref.name !== null || ref.nameLc !== null)) {
                        name = translateNullableName(this._lgTranslate, ref.name, ref.nameLc, "-");
                    }
                    entries.push({
                        ...column,
                        referenceIdx: index,
                        disabled: this._isColumnDisabled(column),
                        referencedField: `${column.field}${index}`,
                        optionName:
                            name !== undefined
                                ? `${columnName} - ${name}`
                                : `${columnName} - ${this._lgTranslate.translate(".Reference")} ${
                                      index + 1
                                  }`
                    });
                });
            }
        });

        this._referencedFieldsDropdownDefinition = {
            entryId: "referencedField",
            entryName: "optionName",
            groups: [
                {
                    entries
                }
            ]
        };
    }

    protected _getSchemaFieldTitle(fieldName: string | undefined): string {
        if (fieldName == null) return "";

        const field = this._schemaLookup[fieldName];

        if (field === undefined) {
            this._lgConsole.error(
                `Field ${fieldName} does not exist in the flexible dataset scheme`
            );
            return "";
        }

        return (
            field.name ?? (field.nameLc != null ? this._lgTranslate.translate(field.nameLc) : "")
        );
    }

    protected _convertToColumn(
        valueToConvert: PivotTableConfigurationColumn
    ): PivotTableConfigurationConvertedColumn {
        if (valueToConvert.type === "default") {
            const {
                conditionalFormatting,
                visualizationWidget,
                width,
                referenceIdx,
                title,
                field
            } = valueToConvert;

            return {
                type: "default",
                isValueField: true,
                isEnabled: true,
                width,
                conditionalFormatting,
                visualizationWidget,
                referenceIdx,
                field,
                title
            };
        } else {
            return {
                ...valueToConvert,
                isEnabled: true
            };
        }
    }

    // ----------------------------------------------------------------------------------

    private _renderNameWithTitle(
        name: string | null | undefined,
        nameLc: string | null | undefined,
        title: string | undefined
    ): string {
        let titleRes: string | undefined = title?.trim();
        titleRes = titleRes === "" ? undefined : titleRes;
        const nameRes =
            name == null && nameLc == null
                ? undefined
                : translateNullableName(this._lgTranslate, name, nameLc);

        return nameRes && !titleRes
            ? nameRes
            : nameRes && titleRes
              ? nameRes + ` (${titleRes})`
              : !nameRes && titleRes
                ? titleRes
                : "-";
    }

    private _isColumnDisabled(column: FieldInfo): boolean {
        return (
            column.field && !this._allowedValueFields().some(field => field.field === column.field)
        );
    }

    private _renderReferenceName(referenceIdx: number): string {
        if (this._pageReferences.slots[referenceIdx] === undefined) {
            return this._lgTranslate.translate(".Unknown");
        }

        const reference =
            this._referencesDict[this._pageReferences.slots[referenceIdx].referenceCode ?? ""];

        return (
            this._lgTranslate.translate(".Reference") +
            " " +
            (referenceIdx + 1) +
            (reference
                ? ` (${translateNullableName(this._lgTranslate, reference.name, reference.nameLc)})`
                : "")
        );
    }

    private _refillDropdownDefinitions(entryId: "reference" | "name" = "reference"): void {
        this._referenceDropdown = this._getReferenceSlotDropdownDefinition(
            getDropdownReferenceSlots(
                !this._pivotTableConfigurationService.isDefaultDataSource()
                    ? this._pivotTableConfigurationService.selectedReferences()
                    : this._pageReferences.slots,
                this._pivotTableConfigurationService.references(),
                this._lgTranslate
            ),
            entryId
        );
    }

    private _getReferenceSlotDropdownDefinition(
        referenceSlots: DropdownReferenceSlot[],
        entryId: "reference" | "name"
    ): IDropdownDefinition<number> {
        return dropdownFlat({
            entryId,
            entryName: "name",
            entries: referenceSlots
        });
    }

    private _resetSelections(): void {
        this._currentColumn = null;
        this._currentTable.set(null);
        this._currentDimension.set(null);
        this._currentGroup = null;
        this._currentValue = null;
    }

    private _refillLevelProperties(): void {
        // TODO: flattening IDs from all levels could be a problem, because we can get duplicate IDs,
        //  and it also means that if several levels have the same dimension, then the dimension will have the same properties
        const levelIds = this._selectedLevelsIds.flat();
        // Add new levels
        for (const levelId of levelIds) {
            // Add missing levels
            if (this._levelProperties[levelId] == null) {
                this._levelProperties[levelId] = {
                    title: "",
                    displayMode: undefined
                };
            }
        }

        // Remove non-existing levels
        Object.keys(this._levelProperties)
            .filter(key => levelIds.indexOf(key) === -1)
            .forEach(key => {
                delete this._levelProperties[key];
            });
    }

    private _getFieldByName(fieldName: string): FieldInfo {
        const field = this._schemaLookup[fieldName];

        if (field === undefined) {
            throw Error(`Field ${fieldName} does not exist in the flexible dataset scheme`);
        }

        return field;
    }

    private _isFieldDefinitionType(field: FieldInfo): boolean {
        return field.type !== "string" && field.type !== "number";
    }

    private _updateDisplayModeDropdownVisibility(): void {
        if (this._currentDimension() == null) {
            this._isDisplayModeDropdownVisible = false;
            return;
        }

        const levelField = this._getFieldByName(this._currentDimension().fieldId);

        if (!this._isFieldDefinitionType(levelField)) {
            this._isDisplayModeDropdownVisible = false;
            return;
        }

        this._isDisplayModeDropdownVisible = true;
        this._defaultDisplayMode =
            this._definitions.getSection(levelField.type)?.displayMode ?? "code";
    }
}

@Injectable()
export class PivotTableConfigurationDialog extends getDialogFactoryBase(
    PivotTableConfigurationDialogComponent,
    "show"
) {
    constructor(_factory: LgDialogFactory) {
        super(_factory);
    }
}
