import {
    Axis,
    axisBottom,
    axisLeft,
    axisRight,
    interpolateRound,
    line,
    ScaleBand,
    scaleBand,
    ScaleLinear,
    scaleLinear,
    Selection
} from "d3";
import {
    ChartStrategy,
    LABEL_TEXT_NEGATIVE_Y_ADJUSTMENT,
    MARGIN,
    SPACE_BETWEEN_BAR_AND_LABEL_Y,
    SPACE_BETWEEN_Y_LABELS_AND_GRID,
    SPACE_FOR_Y_AXIS_LABELS,
    STACKED_BAR_GAP,
    VERTICAL_MIN_COLUMN_WIDTH,
    VERTICAL_TICK_COUNT,
    Y_AXIS_OFFSET
} from "./chart-strategy";
import {
    ChartData,
    ChartGroup,
    ChartRenderBaseDatum,
    ChartRenderColumnDatum,
    ChartRenderStackedBarDatum
} from "./chart.types";
import { coerceNumberProperty } from "@angular/cdk/coercion";
import { CHART_SEPARATOR_SIZE } from "@logex/framework/lg-charts";
import { ILgFormatter } from "@logex/framework/core";

export class VerticalChartStrategy extends ChartStrategy {
    private _xScale: ScaleBand<string>;
    private _yScale: ScaleLinear<number, number>;

    constructor() {
        super();
    }

    getLimit(): number {
        return Math.max(1, this._getXAxisWidth() / VERTICAL_MIN_COLUMN_WIDTH);
    }

    override defineScale(min: number, max: number, limitedData: ChartData<any>): void {
        super.defineScale(min, max, limitedData);

        this._yScale = scaleLinear()
            .domain([min, max])
            .range([this._height - this._spaceBelowAxis, MARGIN.top])
            .nice()
            .interpolate(interpolateRound);

        this._spaceForYAxisLabels = this._getSpaceForYAxisLabels(this._yScale);
        this._xScale = scaleBand()
            .range([this._horizontalPositionOfYAxis, this._width - MARGIN.right])
            .domain(limitedData.map(x => x.column))
            .paddingInner(0.5)
            .paddingOuter(0.3)
            .round(true);
    }

    override defineBarAxis(columnGroupIds: string[]): void {
        this._xGroupScale
            .domain(columnGroupIds)
            .range([0, this._xScale.bandwidth()])
            .padding(0)
            .round(true);
    }

    override defineAxisValuesPosition() {
        this._yAxisG!.attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);
        this._yAxisGridG!.attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);
    }

    override defineTitleAxis(): void {
        this._yAxisTitle!.attr(
            "transform",
            `translate(${MARGIN.left + 6}, ${this._yScale(0)}) rotate( -90 )`
        );
        this._xAxisTitle!.attr(
            "transform",
            `translate(
                ${SPACE_FOR_Y_AXIS_LABELS + this._width / 2},
                ${this._height - MARGIN.bottom}
            )`
        );
    }

    override drawAxes(count: number): void {
        this._xAxisG!.call(this._getXAxis(this._xScale)).attr(
            "transform",
            `translate(0, ${this._yScale(0)})`
        );
        this._cutXAxisLabels(count);

        this._yAxisG!.call(this._getYAxis(this._yScale));

        this._yAxisGridG!.call(this._getYAxisGrid(this._yScale));

        this._yAxisG!.selectAll<SVGTextElement, number>(".tick text").attr(
            "transform",
            `translate(${-SPACE_BETWEEN_Y_LABELS_AND_GRID}, 0)`
        );
    }

    private _getXAxis(scale: ScaleBand<string>): Axis<any> | any {
        return axisBottom(scale).tickSize(0).tickPadding(12);
    }

    private _getYAxis(scale: ScaleLinear<number, number>): Axis<any> | any {
        return axisLeft(scale)
            .tickSize(0)
            .tickPadding(3)
            .tickFormat(d => this._axisLabelsFormatter.format(d))
            .ticks(VERTICAL_TICK_COUNT);
    }

    private _getYAxisGrid(scale: ScaleLinear<number, number>): Axis<any> {
        return axisRight(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(coerceNumberProperty(VERTICAL_TICK_COUNT))
            .tickSizeOuter(0)
            .tickSizeInner(this._width - this._horizontalPositionOfYAxis - MARGIN.right);
    }

    override renderSimpleBars(
        mergedBars: Selection<SVGRectElement, any, SVGGElement, ChartRenderColumnDatum<any>>
    ): void {
        mergedBars
            .attr("x", d => this._calculateXPosition(d.column.column, d.group.id))
            .attr("y", d =>
                d.value > 0
                    ? (this._yScale as ScaleLinear<number, number>)(d.value)
                    : (this._yScale as ScaleLinear<number, number>)(Y_AXIS_OFFSET)
            )
            .attr("height", d =>
                Math.abs(
                    (this._yScale as ScaleLinear<number, number>)(Y_AXIS_OFFSET) -
                        (this._yScale as ScaleLinear<number, number>)(d.value)
                )
            )
            .attr("width", () => this._calculateBarWidth());
    }

    private _calculateXPosition(column: string, groupId: string): number {
        return (this._xScale as ScaleBand<string>)(column)! + this._xGroupScale(groupId)!;
    }

    private _calculateBarWidth(): number {
        const bandwidth = this._xGroupScale.bandwidth();
        return bandwidth - CHART_SEPARATOR_SIZE;
    }

    override _addGridG(): void {
        this._yAxisGridG = this._svg.append("g").attr("class", "y__axis__grid");
    }

    override renderStackedBars(
        mergedStackedBars: Selection<
            SVGRectElement,
            ChartRenderStackedBarDatum<any>,
            SVGGElement,
            ChartRenderColumnDatum<any>
        >
    ): void {
        mergedStackedBars
            .attr("x", d => this._calculateXPosition(d.column.column, d.group.id))
            .attr("y", d =>
                d.value > 0
                    ? (this._yScale as ScaleLinear<number, number>)(d.value + d.yValueOffset)
                    : (this._yScale as ScaleLinear<number, number>)(d.yValueOffset)
            )
            .attr("width", this._calculateBarWidth())
            .attr("height", d => {
                const height = Math.abs(
                    (this._yScale as ScaleLinear<number, number>)(Y_AXIS_OFFSET) -
                        (this._yScale as ScaleLinear<number, number>)(d.value)
                );
                return Math.max(height, 0);
            });
    }

    override renderStackedBarSeparators(
        stackedBarsSeparators: Selection<
            SVGRectElement,
            ChartRenderStackedBarDatum<any>,
            SVGGElement,
            ChartRenderColumnDatum<any>
        >
    ): void {
        stackedBarsSeparators
            .attr("x", d => this._calculateXPosition(d.column.column, d.group.id))
            .attr("y", d =>
                d.value > 0
                    ? (this._yScale as ScaleLinear<number, number>)(d.value + d.yValueOffset) - 1
                    : (this._yScale as ScaleLinear<number, number>)(d.value + d.yValueOffset)
            )
            .attr("width", this._calculateBarWidth())
            .attr("height", d =>
                Math.abs(
                    (this._yScale as ScaleLinear<number, number>)(Y_AXIS_OFFSET) -
                        (this._yScale as ScaleLinear<number, number>)(d.value)
                ) > STACKED_BAR_GAP
                    ? STACKED_BAR_GAP
                    : 0
            );
    }

    override renderLines(
        newLines: Selection<SVGPathElement, Array<ChartRenderBaseDatum<any>>, any, any>
    ): void {
        newLines.attr(
            "d",
            line<ChartRenderBaseDatum<any>>()
                .x(d => this._calculateLineXPosition(d.column.column))
                .y(d => (this._yScale as ScaleLinear<number, number>)(d.value))
        );
    }

    private _calculateLineXPosition(column: string): number {
        return (this._xScale as ScaleBand<string>)(column)! + this._xGroupScale.range()[1] / 2;
    }

    override renderCircles(
        mergedCircles: Selection<
            SVGCircleElement,
            ChartRenderBaseDatum<any>,
            SVGGElement,
            ChartGroup & {
                data: Array<ChartRenderBaseDatum<any>>;
            }
        >
    ): void {
        mergedCircles
            .attr("cx", d => this._calculateLineXPosition(d.column.column))
            .attr("cy", d => (this._yScale as ScaleLinear<number, number>)(d.value));
    }

    override renderLabels(
        mergedLabels: Selection<
            SVGTextElement,
            ChartRenderBaseDatum<any>,
            SVGGElement,
            ChartRenderColumnDatum<any>
        >,
        formatter: ILgFormatter<unknown>,
        labelsGroups?: {
            firstId?: string;
            lastId?: string;
        }
    ) {
        mergedLabels
            .attr("x", d => this._calculateLabelXPosition(d.column.column, d.group.id))
            .attr("y", d =>
                d.value > 0
                    ? (this._yScale as ScaleLinear<number, number>)(d.value) -
                      SPACE_BETWEEN_BAR_AND_LABEL_Y
                    : (this._yScale as ScaleLinear<number, number>)(d.value) +
                      SPACE_BETWEEN_BAR_AND_LABEL_Y +
                      LABEL_TEXT_NEGATIVE_Y_ADJUSTMENT
            )
            .attr("text-anchor", d => this._calculateLabelAnchor(d.group.id, labelsGroups))
            .text(d => {
                return formatter.format(d.value);
            });
    }

    private _calculateLabelXPosition(column: string, groupId: string): number {
        return this._calculateXPosition(column, groupId) + this._calculateBarWidth() / 2;
    }

    protected _calculateLabelAnchor(
        groupId: string,
        labelsGroups: {
            firstId?: string;
            lastId?: string;
        }
    ): "middle" | "start" | "end" {
        if (labelsGroups.firstId === groupId) return "end";
        if (labelsGroups.lastId === groupId) return "start";
        return "middle";
    }
}
