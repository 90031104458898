import { provideWidgetReplacements } from "../../../services/widget-types-registry";
import { CHART_WIDGET } from "./../chart-widget.configuration.types";
import { convertHorizontalChartToChart } from "./convertToChart";

export const replaceHorizontalChartWithChart = provideWidgetReplacements([
    {
        sourceType: "compareChartWidget",
        targetType: CHART_WIDGET,
        replacer: convertHorizontalChartToChart
    }
]);
