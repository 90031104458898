import { Component, EventEmitter, Input, Output } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    commonFormulas,
    PivotTableColumnFormula,
    PivotTableColumnFormulaType
} from "../../../../../../types";
import { IDropdownDefinition } from "@logex/framework/ui-core";

@Component({
    selector: "lgflex-drilldown-pivot-formula-configuration",
    templateUrl: "./drilldown-pivot-formula-configuration.component.html",
    providers: [...useTranslationNamespace("_Flexible._PivotConfigurationDialog")]
})
export class DrilldownPivotFormulaConfiguration {
    @Input() column!: PivotTableColumnFormula;
    @Input() fieldsDropdownDefinition!: IDropdownDefinition<string>;
    @Input() referencesDropdownDefinition: IDropdownDefinition<number> | undefined;
    @Input() areReferencesAllowed: boolean | undefined;
    @Output() columnPropertyChange = new EventEmitter<void>();

    protected _formulas: PivotTableColumnFormulaType[] = [
        ...commonFormulas,
        PivotTableColumnFormulaType.PercentOfParent,
        PivotTableColumnFormulaType.PercentOfTotal
    ];
}
