import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LG_APP_CONFIGURATION, ServerGatewayBase } from "@logex/framework/lg-application";
import { DataSource } from "../flex-data-sources.types";

@Injectable()
export class FlexDataSourcesServiceGateway extends ServerGatewayBase {
    constructor() {
        super();

        const appConfiguration = inject(LG_APP_CONFIGURATION);
        this._setBaseUrl(appConfiguration.applicationRoot);
    }

    getDataSources(url: string, clientId: number, scenarioId: number): Observable<DataSource[]> {
        return this._get<DataSource[]>(url, { params: { clientId, scenarioId } });
    }
}
