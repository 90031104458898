<div
    class="lg-tree-selector__wrapper"
    [class.lg-tree-selector__wrapper--has-border]="hideBorder === false"
    #wrapper
>
    <div lgScrollable cdkDropList style="height: 100%" [lgScrollableWrapper]="false" #scroller>
        <ng-container *ngFor="let row of flatRows; let i = index">
            <div
                class="lg-tree-selector__row table__row"
                [class.lg-tree-selector__row--current]="
                    enableSingleItemSelection && row.leaf === currentLeaf
                "
                [class.lg-tree-selector__row--has-children]="row.leaf.children != null"
                [class.lg-tree-selector__row--disabled]="row.leaf.isDisabled"
                [style.height.px]="rowHeight"
                cdkDrag
                [cdkDragDisabled]="!enableSorting"
                [cdkDragData]="row"
                cdkDragBoundary=".lgVirtualForOf-holder"
                cdkDragLockAxis="y"
                (cdkDragDropped)="onRowDrop($event)"
                (click)="onRowClick($event, row)"
                cdkDragHandle
            >
                <div
                    *ngIf="enableSorting"
                    class="lg-tree-selector__drag-handle table__column table__column--icons"
                    [style.width.px]="24"
                    [style.flex-shrink]="0"
                >
                    <lg-icon icon="icon-draggable" class="table__row__hover-only"></lg-icon>
                </div>

                <div *ngIf="!enableSelection" class="lg-tree-selector__spacer"></div>

                <div
                    class="lg-tree-selector__spacer"
                    *ngFor="let spacer of [].constructor(row.level)"
                ></div>

                <div *ngIf="enableSelection" class="lg-tree-selector__checkbox">
                    <lg-selection-checkbox
                        [state]="row.state!"
                        (checked)="onToggle(row)"
                    ></lg-selection-checkbox>
                </div>

                <div *ngIf="row.icon !== undefined" class="lg-tree-selector__row-icon">
                    <lg-icon [icon]="row.icon"></lg-icon>
                </div>

                <div class="lg-tree-selector__content crop-text" [title]="row.name">
                    {{ row.name }}
                </div>

                <div class="lg-tree-selector__icons table__column--icons icons table__column">
                    <lg-icon
                        *ngFor="let i of row.leaf.warningIcons"
                        [icon]="i.icon"
                        [lgTooltip]="i.title"
                    ></lg-icon>

                    <lg-icon
                        *ngIf="enableDeleting"
                        class="table__row__hover-only"
                        icon="icon-delete"
                        [clickable]="true"
                        [lgTooltip]="'_Flexible._.Delete' | lgTranslate"
                        (click)="deleteRow(row)"
                    ></lg-icon>
                </div>
            </div>
        </ng-container>
    </div>
</div>
