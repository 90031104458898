import * as _ from "lodash";
import { DrilldownPivotTableWidgetBaseConfig } from "../drilldown-table-widget-base.types";
import { PageReferencesService } from "../../../../services/page-references/page-references.service";
import { FieldInfo } from "../../../../types";
import {
    validateDescription,
    validateLevels,
    validateTableColumns,
    validateTitle,
    validateWidgets
} from "../../../../utilities/widget-configuration-validations";
import { CalcAstNodeFunctionCall, parseCalculate } from "../../../../utilities";
import { WidgetTypesRegistry } from "../../../../services/widget-types-registry";
import { Injector } from "@angular/core";

export function validateDrilldownPivotConfiguration(
    injector: Injector,
    config: DrilldownPivotTableWidgetBaseConfig,
    pageReferences: PageReferencesService,
    scheme: FieldInfo[],
    widgetTypes: WidgetTypesRegistry,
    allowDrilldown: boolean
): boolean {
    if (config.levels.length < 1) return false;
    if (!allowDrilldown && config.levels.length > 1) return false;

    const levelsSet = new Set<string>();
    config.levels.forEach(firstLevel =>
        firstLevel.forEach(secondLevel => levelsSet.add(secondLevel))
    );

    const allowedValueFields = getAllowedFields(scheme, levelsSet);

    return (
        validateTitle(config.title) &&
        validateDescription(config.description) &&
        validateTableColumns(config.columns, pageReferences, allowedValueFields) &&
        validateWidgets(injector, config.columns, widgetTypes, pageReferences) &&
        validateLevels(levelsSet, scheme, config.levelProperties)
    );
}

export function getAllowedFields(scheme: FieldInfo[], levelsSet: Set<string>): FieldInfo[] {
    const selectedLevelsInfo = scheme.filter(x => levelsSet.has(x.field));
    const blockedBy = new Set(selectedLevelsInfo.flatMap(x => x.blockedBy ?? []));
    return scheme.filter(
        field =>
            field.isValueField &&
            ![...levelsSet].some(level => field.blockedBy?.includes(level)) &&
            !isColumnBlocking([...blockedBy], field)
    );
}

function isColumnBlocking(blockedBy: string[], field: FieldInfo): boolean {
    if (field.calculate != null) {
        const parsed = parseCalculate(field.calculate) as CalcAstNodeFunctionCall;
        return _.intersection(blockedBy, parsed.params).length > 0;
    }

    return false;
}
