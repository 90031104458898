import {
    AfterContentInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    TemplateRef,
    ViewChild
} from "@angular/core";
import {
    BehaviorSubject,
    combineLatest,
    filter,
    map,
    Observable,
    of,
    ReplaySubject,
    startWith,
    Subject,
    take
} from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";

import { LgSimpleChanges } from "@logex/framework/types";
import { IDialogButton, LgDialogHolderComponent } from "@logex/framework/ui-core";
import { DialogTabComponent } from "../dialog-tab/dialog-tab.component";

const waitForZoneToStabilize = (zone: NgZone): Observable<void> => {
    if (zone.isStable) {
        return of();
    }
    return zone.onStable.pipe(
        take(1),
        map(() => undefined)
    );
};

@Component({
    selector: "lgflex-dialog",
    templateUrl: "./dialog.component.html",
    styleUrls: ["./dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent implements OnDestroy, AfterContentInit, AfterViewInit, OnChanges {
    private zone = inject(NgZone);
    private cdr = inject(ChangeDetectorRef);
    private dialogHolder = inject(LgDialogHolderComponent);

    @Input() isReadOnly: boolean;
    @Input() isValid: boolean;

    @Output() saveAction = new EventEmitter<void>();
    @Output() closeAction = new EventEmitter<void>();

    @ViewChild("headerTemplate", { static: true }) headerTemplate: TemplateRef<unknown>;
    @ContentChildren(DialogTabComponent) tabs: QueryList<DialogTabComponent>;

    private readonly destroy$ = new ReplaySubject<void>(1);
    readonly activeTabIndex$ = new BehaviorSubject(0);
    readonly tabs$ = new BehaviorSubject<DialogTabComponent[]>([]);
    readonly labels$ = this.tabs$.pipe(map(tabs => tabs.map(({ label }) => label)));
    readonly activeTab$ = combineLatest([this.tabs$, this.activeTabIndex$]).pipe(
        filter(([tabs, activeTabIndex]) => tabs[activeTabIndex] !== undefined),
        map(([tabs, activeTabIndex]) => tabs[activeTabIndex])
    );

    readonly portal$ = this.activeTab$.pipe(map(activeTab => activeTab.template));
    readonly activeTabChanged$ = new Subject<void>();
    readonly isValid$ = new BehaviorSubject<boolean>(false);

    ngOnChanges(changes: LgSimpleChanges<DialogComponent>): void {
        if (changes.saveAction || changes.closeAction) {
            if (this.dialogHolder._options.dialogButtons !== undefined) {
                this.dialogHolder._options.dialogButtons = this._configureDialogButtons();
            }
        }

        if (changes.isValid) {
            this.isValid$.next(changes.isValid.currentValue);
        }
    }

    get activeTabIndex(): number {
        return this.activeTabIndex$.value;
    }

    set activeTabIndex(value: number) {
        if (value === this.activeTabIndex) {
            return;
        }
        this.activeTabChanged$.next();
        this.activeTabIndex$.next(value);
        this.cdr.detectChanges();
    }

    ngAfterViewInit(): void {
        waitForZoneToStabilize(this.zone).subscribe(() => {
            this.dialogHolder._options = {
                ...this.dialogHolder._options,
                dialogHeaderTemplate: this.headerTemplate,
                dialogButtons: this._configureDialogButtons()
            };
        });
    }

    private _configureDialogButtons(): IDialogButton[] {
        const dialogButtons: IDialogButton[] = [];
        if (!this.isReadOnly) {
            dialogButtons.push({
                class: "button--primary",
                textLc: "FW.OK",
                onClick: () => {
                    this.saveAction.emit();
                },
                isDisabled$: this.isValid$.pipe(map(isValid => !isValid))
            });
        }

        dialogButtons.push({
            textLc: "FW.CLOSE",
            onClick: () => {
                this.closeAction.emit();
            }
        });

        return dialogButtons;
    }

    ngAfterContentInit(): void {
        this._setupTabsReBroadcast();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.activeTabChanged$.complete();
        this.isValid$.complete();
    }

    private _setupTabsReBroadcast(): void {
        this.tabs.changes
            .pipe(
                startWith(this.tabs.toArray()),
                takeUntil(this.destroy$),
                switchMap(tabs => {
                    return waitForZoneToStabilize(this.zone).pipe(map(() => tabs));
                })
            )
            .subscribe(tabs => this.tabs$.next(tabs));
    }
}
