<lgflex-form-section
    [hideDivider]="false"
    [sectionTitle]="'_Flexible.WidgetConfiguration.WidgetInformation' | lgTranslate"
>
    <lgflex-form-section-column>
        <lgflex-form-row [label]="'.WidgetName' | lgTranslate">
            <div class="input-with-help-info">
                <input
                    class="input input--wide"
                    type="text"
                    [value]="widgetName"
                    (change)="widgetNameChange.emit($any($event.target).value)"
                    [required]="true"
                />
                <lg-help-info
                    header="{{ '.WidgetName' | lgTranslate }}"
                    description="{{
                        '_Flexible.ConfigurationDialogCommon.WidgetNameHelpInfo' | lgTranslate
                    }}"
                ></lg-help-info>
            </div>
        </lgflex-form-row>
        <lgflex-form-row [label]="'.WidgetDescription' | lgTranslate">
            <textarea
                [value]="widgetDescription"
                (change)="widgetDescriptionChange.emit($any($event.target).value)"
                [required]="false"
                rows="3"
                style="min-height: 70px"
            ></textarea>
        </lgflex-form-row>
    </lgflex-form-section-column>
    <lgflex-form-section-column>
        <lgflex-form-row [label]="'.WidgetTooltip' | lgTranslate">
            <textarea
                [value]="widgetTooltip"
                (change)="widgetTooltipChange.emit($any($event.target).value)"
                [required]="false"
                rows="3"
                style="min-height: 70px"
            ></textarea>
        </lgflex-form-row>
        @if (_canEditWidgetTooltipLink | async) {
            <lgflex-form-row [label]="'.WidgetTooltipLink' | lgTranslate">
                <input
                    class="input input--wide"
                    type="text"
                    [value]="widgetTooltipLink"
                    (change)="widgetTooltipLinkChange.emit($any($event.target).value)"
                />
            </lgflex-form-row>
        }
    </lgflex-form-section-column>
</lgflex-form-section>
@if (dataSourceCode() !== undefined) {
    <lgflex-form-section
        [hideDivider]="true"
        [sectionTitle]="'_Flexible.WidgetConfiguration.DataSourceSection' | lgTranslate"
    >
        <lgflex-form-section-column>
            <lgflex-form-row
                [label]="'_Flexible.WidgetConfiguration.DataSourceSection' | lgTranslate"
            >
                <lg-dropdown
                    [definition]="_dataSourcesDropdown()"
                    [current]="dataSourceCode() ?? _defaultDataSourceCode()"
                    (currentChange)="onDataSourceChange.emit($event)"
                ></lg-dropdown>
            </lgflex-form-row>
        </lgflex-form-section-column>
        <lgflex-form-section-column></lgflex-form-section-column>
    </lgflex-form-section>
    <lgflex-form-section [hideDivider]="false">
        @if (dataSourceCode() !== null && !isDefaultDataSource()) {
            <lgflex-form-section-column>
                <lgflex-form-row [label]="'_Flexible.WidgetConfiguration.References' | lgTranslate">
                    <lg-button
                        class="widget-configuration-section-information__edit-references-button"
                        buttonClass="button--condensed"
                        (click)="_editReferences()"
                        textLc="_Flexible.WidgetConfiguration.ReferenceSlotsEdit"
                    />
                </lgflex-form-row>
            </lgflex-form-section-column>
        }
    </lgflex-form-section>
}
